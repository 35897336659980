/* import __COLOCATED_TEMPLATE__ from './beneficiary-item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

export default class RequestTransferBeneficiarySidebarBeneficiaryItemComponent extends Component {
  @service modals;
  @service intl;
  @service toastFlashMessages;
  @service segment;
  @service sensitiveActions;

  @action
  openTrustBeneficiary() {
    let { beneficiary } = this.args;

    this.segment.track(
      beneficiary.trusted ? 'beneficiary_untrust_clicked' : 'beneficiary_trust_clicked'
    );

    this.modals.open('beneficiary-trust-popup', {
      title: beneficiary.trusted
        ? this.intl.t('transfers.beneficiaries.trust-popup.untrust.title')
        : this.intl.t('transfers.beneficiaries.trust-popup.trust.title'),
      beneficiary,
      confirmTask: this.trustTask,
    });
  }

  markAsTrustedTask = dropTask(async beneficiary => {
    try {
      await beneficiary.markAsTrusted();
      let message = this.intl.t('transfers.beneficiaries.trust-popup.trust.success');
      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      if (error?.errors[0].code === 'declined') {
        this.toastFlashMessages.toastError(
          this.intl.t('transfers.beneficiaries.trust-popup.trust.declined-error')
        );
      }

      if (hasMFAError(error?.errors)) {
        throw error;
      }
    }
  });

  trustTask = dropTask(async close => {
    let { beneficiary } = this.args;

    try {
      if (beneficiary.trusted) {
        await beneficiary.markAsUntrusted();
        this.toastFlashMessages.toastInfo(
          this.intl.t('transfers.beneficiaries.trust-popup.untrust.success')
        );
      } else {
        this.segment.track('beneficiary_trust_modal_confirm');
        await this.sensitiveActions.runTask.perform(this.markAsTrustedTask, beneficiary);
      }
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      close();
    }
  });
}
