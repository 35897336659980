/* import __COLOCATED_TEMPLATE__ from './remunerated-accounts.hbs'; */
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { sort } from 'macro-decorators';

const sortByDateAscending = (a, b) => new Date(a.createdAt) - new Date(b.createdAt);

export default class RemuneratedAccountsComponent extends Component {
  @service organizationManager;

  @sort('organizationManager.organization.nonClosedRemuneratedAccounts', sortByDateAscending)
  sortedNonClosedAccounts;

  constructor() {
    super(...arguments);

    if (this.args.maybeScrollToLast) {
      next(() => {
        this.args.maybeScrollToLast(document.getElementById('remunerated-list'));
      });
    }
  }

  get matchingRemuneratedAccountInfos() {
    if (this.sortedNonClosedAccounts.length === 0) {
      return [];
    }

    return this.sortedNonClosedAccounts.map(remuneratedBankAccount => {
      let remuneratedAccountDetails = this.args.remuneratedAccountsDetails.find(
        ({ accountId }) => accountId === remuneratedBankAccount.id
      );

      return {
        ...remuneratedAccountDetails?.getProperties(
          'currentMaturity',
          'maxMaturity',
          'startDate',
          'endDate',
          'isTaxApplicable',
          'interestRates',
          'maxMaturityEarnings'
        ),
        ...remuneratedBankAccount?.getProperties(
          'id',
          'slug',
          'status',
          'accountType',
          'name',
          'authorizedBalance',
          'balanceCurrency',
          'main',
          'isRemunerated'
        ),
      };
    });
  }
}
