export default {
  "header-cell": "qJt",
  "first-col": "qJM",
  "mid-col": "qJP",
  "outstanding-col": "qJl",
  "header-content": "qJX caption-bold",
  "active": "qJC",
  "quick-actions": "qJk",
  "empty": "qJJ"
};
