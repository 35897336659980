/* import __COLOCATED_TEMPLATE__ from './empty-states.hbs'; */
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

export default class RequestsProvidersPendingEmptyStates extends Component {
  @service abilities;
  @service intl;
  @service router;
  @service flowLinkManager;
  @service organizationManager;
  @service zendeskLocalization;

  canReadEbics = this.abilities.can('read ebics request');
  canCreateCardRequests = this.abilities.can('create card request');
  canReviewCardRequests = this.abilities.can('review card request');
  canCreateTransferRequests = this.abilities.can('create transfer request');
  canReviewTransferRequests = this.abilities.can('review transfer request');
  canCreateExpenseReportRequests = this.abilities.can('create expense report requests');
  canReviewExpenseReportRequests = this.abilities.can('review expense report requests');
  isAccountant =
    this.canCreateTransferRequests &&
    !this.canCreateCardRequests &&
    !this.canReviewCardRequests &&
    !this.canCreateExpenseReportRequests &&
    !this.canReviewExpenseReportRequests &&
    !this.canInviteMembers;
  canInviteMembers = this.abilities.can('create invite');
  lottieSrc = '/lotties/empty-state/no-request.json';

  get subtitle() {
    if (this.canReadEbics) {
      let linkText = htmlSafe(
        `<a
            href=${this.intl.t('transfers.requests.third-party.empty.link', {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(4359616),
            })}
            target="_blank"
            rel="noopener noreferrer"
            class="body-link"
          >${this.intl.t('transfers.requests.third-party.empty.link-text')}</a>`
      );
      let { legalCountry } = this.organizationManager.organization;

      if (legalCountry === 'DE') {
        return this.intl.t('transfers.requests.ebics.empty.subtitle', { htmlSafe: true, linkText });
      } else {
        return this.intl.t('transfers.requests.third-party.empty.subtitle', {
          htmlSafe: true,
          linkText,
        });
      }
    }

    if (this.isAccountant) {
      return this.intl.t('empty-states.requests.accountant.with_request_ftr.subtitle');
    }

    if (this.canReviewTransferRequests && this.canReviewCardRequests && this.canInviteMembers) {
      return this.intl.t('empty-states.requests.rev-transfer-rev-card-invite.subtitle');
    }

    if (this.canCreateTransferRequests && this.canCreateCardRequests) {
      return this.intl.t('empty-states.requests.req-transfer-req-card.subtitle');
    }

    if (this.canReviewTransferRequests && this.canReviewCardRequests && !this.canInviteMembers) {
      return this.intl.t('empty-states.requests.rev-transfer-rev-card.subtitle');
    }

    if (this.canCreateTransferRequests && this.canReviewCardRequests) {
      return this.intl.t('empty-states.requests.req-transfer-rev-card.subtitle');
    }

    if (this.canReviewTransferRequests && this.canCreateCardRequests) {
      return this.intl.t('empty-states.requests.rev-transfer-req-card.subtitle');
    }
  }

  get emptyStates() {
    let transitionToRequestLanding = () =>
      this.router.transitionTo('requests.landing', this.organizationManager.organization.slug, {
        queryParams: { origin: 'requests' },
      });
    let transitionToInviteFlow = () => this.flowLinkManager.transitionTo({ name: 'member-invite' });

    // Organizations without the requests feature, but with the ebics feature
    if (this.canReadEbics) {
      return {
        title: this.intl.t('empty-states.requests.title.no-requests'),
        subtitle: this.subtitle,
        lottieSrc: this.lottieSrc,
      };
    }

    // Let's start with the special case of the accountants
    if (this.isAccountant) {
      return {
        title: this.intl.t('empty-states.requests.accountant.with_request_ftr.title'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.accountant.with_request_ftr.cta'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: '/lotties/empty-state/search-no-result.json',
      };
    }
    // Admins and managers with review permissions
    if (this.canReviewTransferRequests && this.canReviewCardRequests && this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-requests'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.invite-members'),
          callback: transitionToInviteFlow,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Employees and managers with create any request and without invite permissions
    if (this.canCreateTransferRequests && this.canCreateCardRequests && !this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.req-transfer-req-card.title'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with create any request and invite permissions
    if (this.canCreateTransferRequests && this.canCreateCardRequests && this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.req-transfer-req-card.title'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.invite-members'),
          callback: transitionToInviteFlow,
        },
        secondaryButton: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review permissions but without invite permissions
    if (this.canReviewTransferRequests && this.canReviewCardRequests && !this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-requests'),
        subtitle: this.subtitle,
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review card request, create transfer request and invite permissions
    if (this.canCreateTransferRequests && this.canReviewCardRequests && this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-pending-requests'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.invite-members'),
          callback: transitionToInviteFlow,
        },
        secondaryButton: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review transfer request, create card request and invite permissions
    if (this.canReviewTransferRequests && this.canCreateCardRequests && this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-pending-requests'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.invite-members'),
          callback: transitionToInviteFlow,
        },
        secondaryButton: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review card request and create transfer request permissions but without invite permissions
    if (this.canCreateTransferRequests && this.canReviewCardRequests && !this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-pending-requests'),
        subtitle: this.intl.t('empty-states.requests.req-transfer-rev-card.subtitle'),
        button: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review transfer request and create card request permissions but without invite permissions
    if (this.canReviewTransferRequests && this.canCreateCardRequests && !this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-pending-requests'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
  }
}
