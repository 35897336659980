/* import __COLOCATED_TEMPLATE__ from './topbar.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class SubscriptionTopbarComponent extends Component {
  topBanner = TopBanner;

  constructor() {
    super(...arguments);
    let data = safeLocalStorage.getItem('price-plan-change-in-progress');
    try {
      let { code, recurrence } = JSON.parse(data);
      if (!code || !recurrence) {
        this.close();
      }
      this.pricePlanCode = code;
      this.recurrence = recurrence;
    } catch {
      this.close();
    }
  }

  @action
  close() {
    this.args.close();
    safeLocalStorage.removeItem('price-plan-change-in-progress');
  }
}
