/* import __COLOCATED_TEMPLATE__ from './email-verification.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { authBaseURL } from 'qonto/constants/hosts';
import { ERRORS } from 'qonto/constants/sca';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class PopupSecurityEmailVerificationComponent extends Component {
  @service intl;
  @service toastFlashMessages;
  @service modals;
  @service networkManager;
  @service otpManager;
  @service sensitiveActions;
  @service scaManager;
  @service sessionManager;
  @service userManager;
  @service sentry;
  @service errors;

  @tracked errorMessage;
  @service segment;
  @tracked verificationCode;

  isEmailFilledKnown = false;

  @action updateVerificationCode(value) {
    this.verificationCode = value;
    if (this.errorMessage) {
      this.errorMessage = null;
    }
  }

  activateTask = dropTask(async () => {
    this.segment.track('profile_email-update_new-address_confirmed');
    let { verificationCode } = this;
    let { close } = this.args;

    let response;
    try {
      response = await this.sensitiveActions.runTask.perform(
        this.confirmNewEmailTask,
        verificationCode
      );
    } catch (error) {
      let code = error?.errors?.[0]?.code;
      if (code === ERRORS.INVALID_CODE) {
        this.errorMessage = this.intl.t('users.profile.security.errors.invalid-token');
        return;
      }
      await close();
      await this.modals.open('popup/security/sca/generic-error');
      throw error;
    }

    if (!response || response === 'cancel' || response === 'close') return;

    let result;
    if (response.current_user_auth_type) {
      let {
        current_user_auth_type: currentUserAuthType,
        current_user_auth_token: currentUserAuthToken,
      } = response;

      if (currentUserAuthType) {
        try {
          result = await this.sensitiveActions.runTask.perform(this.validateNewUserTask, {
            verificationCode,
            currentUserAuthToken,
            currentUserAuthType,
          });
        } catch (error) {
          if (hasMFAError(error)) {
            this.errorMessage = this.intl.t('users.profile.security.errors.invalid-token');
            return;
          }

          this.toastFlashMessages.toastError(
            this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
          );

          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }

          // return if we encounter an error so the user can retry
          return;
        }
        this.isEmailFilledKnown = true;
      }
    }
    if (result === 'cancel') return;

    await close();
    // Handle the XConnect case
    if (!this.userManager.currentUser.hasPassword) {
      Sentry.getCurrentScope().setUser(null);
      this.sessionManager.invalidate();
      return this.toastFlashMessages.toastInfo(this.intl.t('toasts.email_confirm_update'));
    }
    if (!this.isEmailFilledKnown) {
      this.segment.track('profile_email-update_success');
      this.toastFlashMessages.toastSuccess(this.intl.t('toasts.email_confirm_update'));
    } else {
      this.userManager.redirectUponLogout = 'signin?isUpdateSessionModalShown=true';
      this.sessionManager.invalidate();
    }
  });

  confirmNewEmailTask = dropTask(async verificationCode => {
    if (!this.isConfirmNewEmailHeader) {
      this.scaManager.clearToken();
      this.otpManager.otp.token = null;
      this.isConfirmNewEmailHeader = true;
    }
    let pendingEmail = this.userManager.currentUser.pendingEmail;
    let endpoint = `${authBaseURL}${'/v5/user/email_update/confirm_new_email'}`;

    let response = await this.networkManager.request(endpoint, {
      method: 'POST',
      data: JSON.stringify({
        pending_email_confirmation_code: verificationCode,
      }),
    });

    this.userManager.currentUser.email = pendingEmail;
    return response;
  });

  validateNewUserTask = dropTask(
    async ({ verificationCode, currentUserAuthToken, currentUserAuthType }) => {
      if (!this.isValidateNewUserHeader) {
        this.scaManager.clearToken();
        this.otpManager.otp.token = null;
        this.isValidateNewUserHeader = true;
      }
      let pendingEmail = this.userManager.currentUser.pendingEmail;
      let endpoint = `${authBaseURL}${'/v5/user/email_update/validate_new_user'}`;

      let response = await this.networkManager.request(endpoint, {
        method: 'POST',
        data: JSON.stringify({
          pending_email_confirmation_code: verificationCode,
          current_user_auth_token: currentUserAuthToken,
          current_user_auth_type: currentUserAuthType,
        }),
      });

      this.userManager.currentUser.email = pendingEmail;
      return response;
    }
  );
}
