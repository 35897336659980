/* import __COLOCATED_TEMPLATE__ from './physical-renew.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

export default class TopbarCardsPhysicalRenewComponent extends Component {
  topBanner = TopBanner;

  @service organizationManager;
  @service segment;

  @reads('organizationManager.organization') organization;

  @action
  handleClick() {
    this.segment.track('cards_renewal_top_banner');
    this.args.close();
  }
}
