export default {
  "row": "qQj",
  "active": "qQW",
  "dot": "qQu",
  "red": "qeS",
  "green": "qec",
  "yellow": "qeq",
  "status": "qeZ",
  "align-right": "qeR",
  "animated": "qeQ",
  "fadein-item": "qee",
  "cell": "qeB body-2",
  "cell-content": "qeE",
  "amount": "qed body-2",
  "subtitle": "qeI caption",
  "padding-left": "qet",
  "padding-right": "qeM",
  "note": "qeP"
};
