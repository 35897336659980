/* import __COLOCATED_TEMPLATE__ from './presets.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, waitForQueue } from 'ember-concurrency';

import { MAX_SEARCH_PRESETS } from 'qonto/constants/search-presets';
import { defaultPagination } from 'qonto/routes/transactions/index/controller';
import { getFilterGroupExpressions } from 'qonto/utils/search-preset';

function resetPresetPagination(preset) {
  preset.query.pagination = defaultPagination;
  return preset;
}

export default class PresetsComponent extends Component {
  @service modals;
  @service intl;
  @service toastFlashMessages;
  @service store;
  @service organizationManager;
  @service segment;
  @service subscriptionManager;

  @tracked showCreateForm = false;
  @tracked showEditForm = false;
  @tracked currentPreset;
  @tracked _formData;
  @tracked dropDownState;

  elementId = guidFor(this);
  presetButtonId = `${this.elementId}-presets-button`;
  dropdownBodyId = `${this.elementId}-dropdown-body`;

  get formData() {
    let cachedFormData = this.args.cachedFormData.find(({ id }) => id === this._formData.id);
    return cachedFormData || this._formData;
  }

  get selectedPresetName() {
    let { name } = this.args.selectedPreset;

    let translations = {
      missing_receipts: this.intl.t('transactions.filters.presets.items.missing_receipts'),
      settled_last_month: this.intl.t('transactions.filters.presets.items.settled_last_month'),
      to_verify: this.intl.t('transactions.filters.presets.items.to_verify'),
    };

    return translations[name] || name;
  }

  get selectedPresetCount() {
    let { matchesCount } = this.args.selectedPreset;
    return matchesCount.toString().length >= 4 ? '+999' : matchesCount;
  }

  closeOnEscape(dropdown, e) {
    if (e.key === 'Escape') {
      dropdown.actions.close();
    }
  }

  @action
  registerDropdownState(dropDownState) {
    if (dropDownState) {
      this.dropDownState = dropDownState;
    }
  }

  onLoadTask = dropTask(async () => {
    await waitForQueue('afterRender');
    this.focusedOptionElement?.focus();
  });

  canAdd(presets) {
    return presets?.length < MAX_SEARCH_PRESETS;
  }

  savePresetTask = dropTask(async preset => {
    try {
      // We need to reset the pagination to default because
      // the filtered results might change from the time the custom view was created.
      // See https://getqonto.atlassian.net/browse/BUG-6346
      let presetWithDefaultPagination = resetPresetPagination(preset);

      await presetWithDefaultPagination.save();

      this.args.refreshCache(this.formData);
      this.args.applyFilters(preset.query, preset.id);
      this.args.onSelect(preset, this.elementId);

      this._cleanDropdownState();

      this.toastFlashMessages.toastInfo(this.intl.t('transactions.success-toast.view-created'));
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  @action
  refreshCache(filterGroup, name) {
    let formData = {
      id: this.formData.id,
      name,
      query: { ...this.args.queryOptions, filter_group: filterGroup },
    };

    this.args.refreshCache(formData, true);
  }

  @action
  cancelFilters() {
    this.args.refreshCache(this.formData);
    this._cleanDropdownState();
  }

  @action
  onSelect(preset) {
    let { id, name: view_name } = preset;
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;

    let filterGroupsExpressions = [preset.query.filter_group];

    let filtersProperties = getFilterGroupExpressions(
      filterGroupsExpressions,
      this.organizationManager.organization.labelLists
    );

    // We need to reset the pagination to default because
    // the filtered results might change from the time the custom view was created.
    // See https://getqonto.atlassian.net/browse/BUG-6346
    let presetWithDefaultPagination = resetPresetPagination(preset);

    this.dropDownState.actions.close();

    this.segment.track('history_view_label_clicked', {
      view_name,
      price_plan,
      role,
      filter_types: filtersProperties,
    });

    this.args.applyFilters(presetWithDefaultPagination.query, id);
    this.args.onSelect(presetWithDefaultPagination, this.elementId);
  }

  @action
  cancelSelection() {
    this.args.applyFilters();
    this.args.onSelect();
  }

  @action
  onEdit(preset) {
    let { id, name, query } = preset;

    this.currentPreset = preset;
    this._formData = { id, name, query };
    this.showEditForm = true;
  }

  @action
  editPreset(filterGroup, name) {
    this.currentPreset.query.filter_group = filterGroup;
    this.currentPreset.name = name;

    return this.savePresetTask.perform(this.currentPreset);
  }

  @action
  addPreset(presets) {
    if (!presets) return;

    if (this.canAdd(presets)) {
      this._formData = { id: this.elementId };
      this.showCreateForm = true;
    }
  }

  @action
  createPreset(filterGroup, name) {
    let preset = this.store.createRecord('search-preset', {
      name,
      query: { ...this.args.queryOptions, filter_group: filterGroup },
      organization: this.organizationManager.organization,
    });

    return this.savePresetTask.perform(preset);
  }

  deletePresetTask = dropTask(async preset => {
    await this.modals.open('popup/destructive', {
      title: this.intl.t('transactions.delete-view-confirmation-modal.title'),
      description: this.intl.t('transactions.delete-view-confirmation-modal.body'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
      preset,
      confirmTask: this.deleteConfirmTask,
    });
  });

  deleteConfirmTask = dropTask(async (close, { preset }) => {
    try {
      await preset.destroyRecord();

      if (preset.id === this.args.selectedPreset?.id) {
        this.args.clearFilters();
      }

      this.toastFlashMessages.toastInfo(this.intl.t('transactions.success-toast.view-deleted'));
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
    await close();
  });

  _cleanDropdownState() {
    this.showCreateForm = false;
    this.showEditForm = false;
    this.currentPreset = undefined;
    this._formData = undefined;
    this.dropDownState.actions.close();
  }

  getOptionIds = presets => presets?.map(this.getOptionId).join(' ');

  getOptionId = ({ id }) => `${this.elementId}-preset-${id}`;

  @action
  onTriggerKeyDown(event) {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        this.dropDownState.actions.open();
        break;
    }
  }

  @action
  onOptionKeyDown(event) {
    let { focusedOptionElement } = this;
    let focusedIndex = Number(
      focusedOptionElement?.closest('[data-option-index]')?.dataset.optionIndex
    );

    switch (event.key) {
      case 'ArrowDown':
        this.dropdownElement
          .querySelector(`[data-option-index="${focusedIndex + 1}"] [role=option]`)
          ?.focus();
        break;
      case 'ArrowUp':
        this.dropdownElement
          .querySelector(`[data-option-index="${focusedIndex - 1}"] [role=option]`)
          ?.focus();
        break;
      case 'Escape':
        this.dropDownState.actions.close();
        break;
    }
  }

  get dropdownElement() {
    return document.getElementById(`ember-basic-dropdown-content-${this.dropDownState.uniqueId}`);
  }

  get focusedOptionElement() {
    return (
      this.dropdownElement?.querySelector('[role=option]:focus') ||
      this.dropdownElement?.querySelector('[role=option]:hover') ||
      this.dropdownElement?.querySelector('[role=option][aria-selected=true]') ||
      this.dropdownElement?.querySelector('[data-option-index="0"] [role=option]')
    );
  }
}
