/* import __COLOCATED_TEMPLATE__ from './discount-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { multiplyAndRound } from 'qonto/utils/receivable-invoicing';

const PERCENTAGE_SYMBOL = '%';
const DISCOUNT_PERCENTAGE = 'percentage';
const DISCOUNT_ABSOLUTE = 'absolute';

export default class ReceivableInvoicesFormDiscountFieldComponent extends Component {
  @tracked selectedDiscountType = this.discount?.type ?? 'percentage';

  @tracked discountValue = this.item.discount
    ? this.item.discount.type === 'percentage'
      ? this.item.discount.value * 100
      : this.item.discount.value
    : null;

  get item() {
    return this.args.item;
  }

  get discount() {
    return this.args.item?.discount;
  }

  get discountOptionsArray() {
    return [
      { value: DISCOUNT_PERCENTAGE, label: PERCENTAGE_SYMBOL },
      { value: DISCOUNT_ABSOLUTE, label: this.args.currency },
    ];
  }

  get discountOptions() {
    return this.discountOptionsArray.map(item => item.label);
  }

  get selectedDiscountOption() {
    if (this.selectedDiscountType === DISCOUNT_ABSOLUTE) {
      return this.args.currency;
    }
    return PERCENTAGE_SYMBOL;
  }

  get errorMessage() {
    if (this.selectedDiscountType === DISCOUNT_ABSOLUTE) {
      return this.args.amountErrorMessage;
    } else if (this.selectedDiscountType === DISCOUNT_PERCENTAGE) {
      return this.args.percentageErrorMessage;
    }
  }

  @action
  handleDiscountOptions(option) {
    if (this.item.errors?.has('discountAmount')) {
      this.item.errors.remove('discountAmount');
    }

    let optionType = this.discountOptionsArray.find(item => item.label === option);

    this.selectedDiscountType = optionType.value ?? null;

    // the percentage will be sent in decimals, so 10% = 0.1
    let percentageValue = this.setPercentageValue(this.discountValue);

    if (this.discountValue) {
      this.setDiscountObj(percentageValue, String(this.discountValue));
    }
  }

  @action updateDiscount(value) {
    // remove error for discount amount
    if (this.item.errors?.has('discountAmount')) {
      this.item.errors.remove('discountAmount');
    }

    if (!value) {
      this.item.discount = null;
      this.discountValue = null;
    } else {
      // negative number are not allowed
      let positiveValue = value < 0 ? Math.abs(value) : value;
      let newValue =
        positiveValue <= this.args.priceLimit
          ? String(positiveValue)
          : String(this.args.priceLimit);

      // the percentage will be sent in decimals, so 10% = 0.1
      let percentageValue = this.setPercentageValue(newValue);

      this.setDiscountObj(percentageValue, newValue);

      this.discountValue = newValue;
    }
  }

  @action setDiscountOnFocusOut() {
    if (this.discountValue) {
      let preciseValue = Math.round(this.discountValue * 100) / 100;
      this.discountValue = String(preciseValue);
      this.setDiscountObj(this.item.discount.value, String(preciseValue));
    }
  }

  setPercentageValue(value) {
    if (value) {
      // the percentage will be sent in decimals, so 11.11% = 0.1111
      let preciseValue = Math.round(parseFloat(value) * 100) / 100;
      let rate = preciseValue / 100;
      let preciseRate = Math.round(rate * 10000) / 10000;

      let stringValue = String(String(preciseRate).replace(/[,.]/g, '.'));
      return stringValue;
    }
    return null;
  }

  setDiscountObj(percentageValue, absoluteValue) {
    this.item.discount = {
      type: this.selectedDiscountType,
      value: this.selectedDiscountType === 'percentage' ? percentageValue : absoluteValue,
      amount:
        this.selectedDiscountType === 'absolute'
          ? absoluteValue
          : String(multiplyAndRound(percentageValue, this.item?.totalExcludingVat, 100)),
    };
  }
}
