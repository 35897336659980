export default {
  "item-status": "cHu",
  "item-date": "cOS",
  "item-amount": "cOc body-1",
  "item-amount-credit": "cOq",
  "row": "cOZ",
  "icon": "cOR",
  "disabled": "cOQ",
  "icon-container": "cOe",
  "text-container": "cOB",
  "text-row": "cOE",
  "item-details": "cOd",
  "counterparty-name": "cOI",
  "column": "cOt",
  "item-amount-disabled": "cOM",
  "attachment-tooltip": "cOP",
  "bank-account-avatar": "cOl"
};
