/* import __COLOCATED_TEMPLATE__ from './page-header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

export default class PageHeaderComponent extends Component {
  placeholderBlock = SkeletonLoader.Block;

  @service menu;
}
