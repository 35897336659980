export default {
  "sidebar-content": "qay",
  "section": "qaU",
  "header-wrapper": "qaj",
  "transfer-id": "qaW caption",
  "small-padding": "qau",
  "title": "qHS",
  "subtitle": "qHc",
  "actions": "qHq"
};
