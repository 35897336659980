/* import __COLOCATED_TEMPLATE__ from './bookkeeping-status.hbs'; */
import { assert } from '@ember/debug';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

export default class TransactionsBookkeepingStatusComponent extends Component {
  badgeStatus = BadgeStatus;

  @service intl;

  constructor() {
    super(...arguments);

    assert('@transaction is required', this.args.transaction);
  }

  get level() {
    let transaction = this.args.transaction;
    if (transaction.bookkeepingStatus) {
      return transaction.bookkeepingStatus === 'verified' ? 'validated' : 'in-progress';
    } else {
      return transaction.qualifiedForAccounting ? 'validated' : 'error';
    }
  }

  get text() {
    let transaction = this.args.transaction;
    if (transaction.bookkeepingStatus) {
      return transaction.bookkeepingStatus === 'verified'
        ? this.intl.t('bookkeeping.transactions.details-page.status.verified')
        : this.intl.t('bookkeeping.transactions.details-page.status.to-verify');
    } else {
      return transaction.qualifiedForAccounting
        ? this.intl.t('transactions.sidebar.bookkeeping.badge.verified')
        : this.intl.t('transactions.sidebar.bookkeeping.badge.to-be-verified');
    }
  }
}
