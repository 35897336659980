/* import __COLOCATED_TEMPLATE__ from './activity-tag-select.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import filterActivityTagCodes from 'qonto/utils/filter-activity-tag-codes';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class ActivityTagSelectComponent extends Component {
  @service intl;
  searchField = 'label';

  get errorMessage() {
    let { errors } = this.args;
    return errors?.[0]?.message || errors;
  }

  get selectedActivityTagObject() {
    let { value } = this.args;
    value = Array.isArray(value) ? value[0] : value; // value can be wrapped in array
    return this.filteredActivityTagObjects.find(({ code }) => code === value);
  }

  @cached
  get filteredActivityTagObjects() {
    let { transactionTypes, transactionType } = this.args;
    let filteredCodes = filterActivityTagCodes(transactionTypes, transactionType);

    return filteredCodes.map(code => this._createActivityTagObject(code)).sort(sortByKey('label'));
  }

  @action
  handleUpdate(activityTagObject) {
    let code = activityTagObject?.code;
    return this.args.update(code);
  }

  _createActivityTagObject(code) {
    if (!code) {
      return null;
    }

    let label = this.intl.t(`activities.${code}`);
    return { code, label };
  }
}
