export default {
  "header-cell": "qmy",
  "empty": "qmU",
  "header-content": "qmj caption-bold",
  "active": "qmW",
  "header-type": "qmu",
  "header-reason": "qpS",
  "header-status": "qpc",
  "header-amount": "qpq",
  "row-sidebar": "qpZ",
  "hidden": "qpR",
  "animated": "qpQ",
  "fadein-item": "qpe",
  "animated-cell": "qpB"
};
