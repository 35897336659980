export default {
  "invoice-sidebar": "qIG",
  "body": "qIr",
  "box": "qIb",
  "box-header": "qIy",
  "sidebar-box": "qIU",
  "border-top": "qIj",
  "row": "qIW",
  "greyed-out": "qIu",
  "strike-through": "qtS"
};
