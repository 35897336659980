export default {
  "row": "qFK",
  "icon": "qFG",
  "cell": "qFr",
  "cell-date": "qFb",
  "cell-amount": "qFy",
  "cell-status": "qFU",
  "cell-content": "qFj",
  "align-right": "qFW"
};
