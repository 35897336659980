/* import __COLOCATED_TEMPLATE__ from './items.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { defaultValues } from 'qonto/models/receivable-invoice/item';

export default class SelfBillingFormItemsComponent extends Component {
  @action
  addItem(invoice) {
    invoice.items.createRecord(defaultValues(this, invoice.items[0].vatRate));
  }

  @action
  deleteItem(item) {
    if (item.isNew) {
      item.deleteRecord();
    } else {
      item.unloadRecord();
    }
  }
}
