/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class RequestMultiTransferTable extends Component {
  @service segment;

  @action
  trackEmptyState() {
    this.segment.track('request_empty_state_displayed', {
      original_price_plan_code: this.args.organization?.originalPricePlanCode,
    });
  }
}
