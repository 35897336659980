/* import __COLOCATED_TEMPLATE__ from './analytic-labels.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LabelCategorization } from 'qonto/react/components/transactions/table/labels/categorization/label-categorization';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class TransactionsSidebarAnalyticLabelsComponent extends Component {
  @service categorizationRulesManager;

  @tracked selectedLabelIds = new Map();

  LabelCategorization = LabelCategorization;

  get transactions() {
    return this.args.transactions || [this.args.transaction];
  }

  get allTransactionsLabels() {
    let labelsTransactions = [];
    this.transactions.forEach(transaction => {
      transaction.labels.forEach(label => labelsTransactions.push(label));
    });

    return labelsTransactions;
  }

  get allTransactionsLabelsIds() {
    return [
      ...this.allTransactionsLabels.map(label => {
        return {
          labelListId: label.labelList.id,
          labelId: label.id,
        };
      }),
    ];
  }

  get labelIds() {
    let labelIdsMap = new Map();
    let filteredLabels = this._filterBulkLabels(this.allTransactionsLabelsIds);

    filteredLabels.forEach(({ labelListId, labelId }) => {
      labelIdsMap.set(labelListId, labelId);
    });

    Array.from(this.selectedLabelIds).forEach(([labelListId, labelId]) => {
      labelIdsMap.set(labelListId, labelId);
    });

    return Array.from(labelIdsMap, ([key, value]) => ({ labelListId: key, labelId: value }));
  }

  @action
  onResetSelectedLabelIds(callback) {
    next(() => {
      this.selectedLabelIds = new Map();
      callback();
    });
  }

  @action
  onSelectLabel(labelList, label) {
    let updatedMap = new Map(this.selectedLabelIds);
    updatedMap.set(labelList.id, label?.id);
    this.selectedLabelIds = updatedMap;

    this.args.saveLabel(labelList, label);
  }

  @action
  onGenerateRules(rules) {
    if (this.args.onGenerateRules) {
      this.args.onGenerateRules(rules);
    } else {
      this.categorizationRulesManager.onCreateAnalyticLabelRulesTask
        .perform(rules)
        .catch(ignoreCancelation);
    }
  }

  _filterBulkLabels(labelsArray) {
    // Count occurrences of each labelListId
    let labelListCounts = labelsArray.reduce((acc, curr) => {
      acc[curr.labelListId] = (acc[curr.labelListId] || 0) + 1;
      return acc;
    }, {});

    // Filter out labelListIds with fewer occurrences than the number of transactions
    let filteredByCount = labelsArray.filter(
      label => labelListCounts[label.labelListId] >= this.transactions.length
    );

    // Group by labelListId
    let groupedByLabelListId = filteredByCount.reduce((acc, curr) => {
      if (!acc[curr.labelListId]) {
        acc[curr.labelListId] = [];
      }
      acc[curr.labelListId].push(curr);
      return acc;
    }, {});

    // Filter out labelListId groups where labelIds are not the same
    return Object.values(groupedByLabelListId)
      .filter(group => {
        let firstLabelId = group[0].labelId;
        return group.every(item => item.labelId === firstLabelId);
      })
      .flat();
  }
}
