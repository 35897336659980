/* import __COLOCATED_TEMPLATE__ from './illustration.hbs'; */
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

import { USER_ACTIONS_ILLUSTRATION_TYPE } from 'qonto/constants/user-actions';

export default class ProductDiscoveryUserActionsIllustrationComponent extends Component {
  lottiePlayer = LottiePlayer;

  get svgPath() {
    return this.args.type === USER_ACTIONS_ILLUSTRATION_TYPE.SVG && this.args.name
      ? `/illustrations/user-actions/${this.args.name}.svg`
      : '';
  }

  get lottiePath() {
    return this.args.type === USER_ACTIONS_ILLUSTRATION_TYPE.LOTTIE && this.args.name
      ? `/lotties/user-actions/${this.args.name}.json`
      : '';
  }

  get remoteImagePath() {
    return this.args.type === USER_ACTIONS_ILLUSTRATION_TYPE.REMOTE_IMAGE && this.args.name
      ? this.args.name
      : '';
  }

  get remoteLottiePath() {
    return this.args.type === USER_ACTIONS_ILLUSTRATION_TYPE.REMOTE_LOTTIE && this.args.name
      ? this.args.name
      : '';
  }
}
