export default {
  "row": "qzA",
  "item-bulk": "qzY",
  "active": "qzh",
  "disabled": "qzf",
  "cell": "qzK body-2",
  "not-disabled": "qzG",
  "item-amount-disabled": "qzr",
  "item-amount-credit": "qzb",
  "no-padding": "qzy",
  "cell-content": "qzU",
  "centered-cell": "qzj",
  "item-counterparty-icon": "qzW mr-16",
  "avatar": "qzu",
  "item-counterparty-name": "qaS",
  "item-counterparty-name-status": "qac",
  "disputing-chargeback": "qaq",
  "item-counterparty-name-text": "qaZ",
  "item-amount": "qaR body-1",
  "item-amount-foreign": "qaQ",
  "attachment-icon": "qae",
  "item-method": "qaB",
  "bank-account-avatar": "qaE",
  "attachment-status-dot": "qad",
  "attached": "qaI",
  "missing": "qat",
  "item-attachment": "qaM",
  "compact": "qaP",
  "fadein-item": "qal"
};
