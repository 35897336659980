/* import __COLOCATED_TEMPLATE__ from './rename-team.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { TEAM_NAME_MIN_LENGTH } from 'qonto/constants/teams';

export default class RenameTeamModal extends Component {
  @service intl;
  @service toastFlashMessages;
  @service segment;

  @tracked name = this.args.data.team.name;
  @tracked error = null;

  renameTeamTask = dropTask(async () => {
    let { close, data } = this.args;
    let { team } = data;

    this.error = this.validateName();

    if (this.error) return;

    team.name = this.value.trim();

    try {
      await team.save();
    } catch (error) {
      team.rollbackAttributes();
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
      throw error;
    }

    let successMessage = this.intl.t('teams.rename.toast-success');
    this.toastFlashMessages.toastSuccess(successMessage);
    this.segment.track('teams_rename_success');

    close();
  });

  @action
  cancel() {
    if (this.renameTeamTask.isRunning) {
      return;
    }
    this.args.close();
  }

  @action
  updateField(value) {
    this.value = value;
    this.error = null;
  }

  validateName() {
    return this.value?.trim().length >= TEAM_NAME_MIN_LENGTH
      ? null
      : this.intl.t('validations.errors.tooShort', { min: TEAM_NAME_MIN_LENGTH });
  }
}
