/* import __COLOCATED_TEMPLATE__ from './new-mandate-option.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class PowerSelectMandatesNewMandateOptionComponent extends Component {
  @action
  onClick(e) {
    e.stopPropagation();
    this.args.select.actions.select(null);
    this.args.select.actions.close();
  }
}
