/* import __COLOCATED_TEMPLATE__ from './teams.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class PageHeaderTeamsComponent extends Component {
  @service intl;
  @service router;
  @service abilities;
  @service segment;
  @service menu;

  get title() {
    return this.args.title ?? this.intl.t('navigation.settings.teams_management.members');
  }

  get allTeamsOption() {
    return {
      id: '',
      name: this.intl.t('activities.teams.filter_all'),
    };
  }

  get selectedTeam() {
    let teamId = this.router.currentRoute?.queryParams['teamId'];
    let selectedTeam = this.args.teams.find(({ id }) => teamId === id);
    return selectedTeam || this.allTeamsOption;
  }

  get options() {
    let options = [...this.args.teams].sort();
    return [this.allTeamsOption, ...options];
  }

  get canShowTeamFilter() {
    return this.abilities.can('filter team') && this.args.teams?.length > 1;
  }

  @action
  updateTeam({ id }) {
    this.segment.track('members_filter_team');
    this.router.transitionTo({
      queryParams: {
        teamId: id === '' ? null : id,
      },
    });
  }
}
