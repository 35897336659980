/* import __COLOCATED_TEMPLATE__ from './beneficiary-card.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { Information } from 'qonto/react/components/transfers/international-out/beneficiary-card/information';
import isFunction from 'qonto/utils/is-function';

export default class TransfersInternationalOutBeneficiaryCardComponent extends Component {
  @service abilities;

  informationComponent = Information;

  get canDelete() {
    return isFunction(this.args.onDelete) && this.abilities.can('delete beneficiary');
  }

  get canView() {
    return isFunction(this.args.onView) && this.abilities.can('access beneficiary');
  }

  get hasActions() {
    return this.canDelete || this.canView;
  }

  onDelete = dropTask(async beneficiary => {
    await this.args.onDelete(beneficiary);
  });

  onSelect = dropTask(async beneficiary => {
    await this.args.onSelect(beneficiary);
  });

  onView = dropTask(async beneficiary => {
    await this.args.onView(beneficiary);
  });
}
