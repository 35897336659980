/* import __COLOCATED_TEMPLATE__ from './details-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SupplierInvoicesDetailsModal extends Component {
  @reads('detailsTaskValue.displayAttachment') displayAttachment;

  get detailsTaskValue() {
    let { isRunning, lastSuccessful } = this.args.data.detailsTask || {};

    return !isRunning && lastSuccessful?.value;
  }

  get attachment() {
    return this.detailsTaskValue?.attachment;
  }

  get invoice() {
    return this.detailsTaskValue?.invoice;
  }

  get request() {
    return this.detailsTaskValue?.request;
  }

  get confirmTransferResult() {
    return this.args.data.confirmTransferTask.lastSuccessful?.value;
  }

  @action
  reloadInvoice() {
    this.args.data.detailsTask.perform(this.invoice.id).catch(ignoreCancelation);
  }

  @action handleEscape(e) {
    if (e.key === 'Escape') {
      this.args.data.onClose();
    }
  }

  onAccountChangeTask = dropTask(async (request, account) => {
    request.bankAccount = account;
    await this.args.data.confirmTransferTask.perform(request, this.invoice);
  });
}
