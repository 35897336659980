/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField, Disclaimer, TextField } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';

import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { calculateVatAmount } from 'qonto/utils/transfers';

export default class TransfersSepaDetailsComponent extends Component {
  @service featuresManager;

  operationTypes = Object.values(SCHEDULE_OPERATION_TYPES);
  amountField = AmountField;
  textField = TextField;
  disclaimerInline = Disclaimer.Inline;
  @tracked amountIsEdited = false;

  get organization() {
    return this.args.sepaTransfer.get('organization');
  }

  get hasCreditNoteLinked() {
    return this.args.context?.invoice?.relatedInvoices?.length;
  }

  get showPayableAmountDisclaimer() {
    return (
      variation('experiment--boolean-ap-credit-notes') &&
      !this.amountIsEdited &&
      this.hasCreditNoteLinked
    );
  }

  @action
  onReferenceChange(event) {
    this.args.sepaTransfer.reference = event.currentTarget.value;
  }

  @action
  onAmountChange(amount) {
    this.amountIsEdited = true;
    this.args.sepaTransfer.amount = amount;
    this._updateVatAmount();
  }

  _updateVatAmount() {
    let { sepaTransfer } = this.args;
    let { vatRate, amount } = sepaTransfer;

    if (vatRate > 0) {
      sepaTransfer.vatAmount = calculateVatAmount(amount, vatRate);
    }
  }
}
