/* import __COLOCATED_TEMPLATE__ from './voucher.hbs'; */
import Component from '@glimmer/component';

export default class TransfersInternationalOutProvidersVoucher extends Component {
  get isApplicable() {
    let { maximumAmount = 0 } = this.rules ?? {};
    return this.args.amount <= maximumAmount;
  }

  get isVisible() {
    let { fees, voucher } = this.args;
    return Boolean(fees) && Boolean(voucher?.enabled);
  }

  get rules() {
    return this.args.voucher?.rules;
  }
}
