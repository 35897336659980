export default {
  "transfer-form": "qHb",
  "transfer-form-title": "qHy",
  "transfer-form-subtitle": "qHU",
  "form": "qHj",
  "form-footer": "qHW",
  "footer-btn": "qHu",
  "header": "qOS",
  "main-settings": "qOc",
  "additional-settings": "qOq",
  "form-container": "qOZ"
};
