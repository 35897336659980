/* import __COLOCATED_TEMPLATE__ from './allocation-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { EXERCISE_KINDS } from 'qonto/constants/budget';

export default class RequestHeaderMultiTransferDetailAllocationDetailsComponent extends Component {
  @service intl;

  get periodStatus() {
    let { budget, period } = this.args.potentialBudgetAllocation;
    let overBudget = period.amountRemainingValue < 0;

    if (budget.exerciseKind === EXERCISE_KINDS.MONTHLY) {
      if (overBudget) {
        return this.intl.t('bulk-request.team-budget.no-budget-remaining-monthly', {
          month: period.name,
        });
      } else {
        return this.intl.t('bulk-request.team-budget.status.remaining-monthly', {
          month: period.name,
        });
      }
    } else {
      let periodEndDate = dateToken({
        date: period.endDate,
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      });

      if (overBudget) {
        return this.intl.t('bulk-request.team-budget.no-budget-remaining-global', {
          periodEndDate,
        });
      } else {
        return this.intl.t('bulk-request.team-budget.status.remaining-global', { periodEndDate });
      }
    }
  }

  get displayRemainingAmount() {
    let { period } = this.args.potentialBudgetAllocation;
    return period.amountRemainingValue > 0;
  }

  get noBudgetedPeriodDisclaimer() {
    let { period } = this.args.potentialBudgetAllocation;

    return this.intl.t('bulk-request.team-budget.status.no-budget-period', {
      budget_period_name: period.name,
    });
  }

  @action
  formatAmount(amount, currency = 'eur') {
    let integer = parseInt(amount, 10);
    let difference = Number(amount) - integer;
    let numberToFormat = difference > 0 ? amount : integer;

    return this.intl.formatNumber(numberToFormat, {
      minimumFractionDigits: difference > 0 ? 2 : 0,
      currency,
      style: 'currency',
    });
  }
}
