export default {
  "filters-checkbox": "qVV mb-8",
  "csv-options-button": "qVL",
  "active": "qVT",
  "options-format-settings-wrapper": "qVz",
  "checkbox": "qVa",
  "options-format-settings": "qVH",
  "hidden": "qVO",
  "visible": "qVm",
  "disabled": "qVp",
  "buttons": "qVx"
};
