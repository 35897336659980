/* import __COLOCATED_TEMPLATE__ from './all.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DEFAULT_ORIGIN_URL } from 'qonto/constants/bank-account';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const REVEAL_BALANCE_KEY = `reveal-history-balance`;

export default class OverviewWidgetsAllComponent extends Component {
  @service router;
  @service segment;

  @service userManager;
  @tracked showBalance = true;

  constructor() {
    super(...arguments);

    let { id } = this.userManager.currentUser;
    let item = safeLocalStorage.getItem(`${REVEAL_BALANCE_KEY}-${id}`);

    if (item !== null) {
      this.showBalance = JSON.parse(item);
    }
  }

  @action
  toggleBalanceVisibility() {
    let { id } = this.userManager.currentUser;
    this.showBalance = !this.showBalance;

    safeLocalStorage.setItem(`${REVEAL_BALANCE_KEY}-${id}`, this.showBalance);

    let trackingEventName = this.showBalance
      ? 'dashboard_balance_reveal'
      : 'dashboard_balance_hide';
    this.segment.track(trackingEventName);
  }

  get origin() {
    return this.router.currentRoute.name || DEFAULT_ORIGIN_URL;
  }
}
