/* import __COLOCATED_TEMPLATE__ from './cashflow.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import 'd3-transition';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { dropTask, waitForQueue } from 'ember-concurrency';

import ComboChartComponent from 'qonto/components/overview/chart/combo-chart';
import { getExtrema } from 'qonto/utils/chart/scale';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const DATA_TYPES = {
  inflows: 'inflows',
  outflows: 'outflows',
};

export default class CashflowChartComponent extends ComboChartComponent {
  @service intl;
  @service sentry;

  @tracked tooltipTarget;
  @tracked monthlyCashflow;
  @tracked flow;
  @tracked activeMonthIndex;
  chartId = `chart-container-${guidFor(this)}`;

  chartContainer;

  constructor() {
    super(...arguments);
    this.setGraphTask.perform().catch(ignoreCancelation);
  }

  setGraphTask = dropTask(async () => {
    try {
      await waitForQueue('afterRender');
      this.chartContainer = document.getElementById(this.chartId);
      this.drawGraph();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get hasData() {
    return (
      Array.isArray(this.args.timeseries) &&
      this.args.timeseries.some(
        ({ data }) => data?.startingBalance !== 0 || data?.inflows > 0 || data?.outflows > 0
      )
    );
  }

  get currentDateIndex() {
    return this.timeseries.findIndex(timevalue =>
      dayjs(timevalue.time).startOf('month').isSame(dayjs().startOf('month'))
    );
  }

  get isCurrentPeriodDisplayed() {
    return (
      this.hasData &&
      dayjs().isBetween(
        dayjs(this.args.timeseries[0].time).startOf('month'),
        dayjs(this.args.timeseries.at(-1).time).endOf('month'),
        'day',
        '[]'
      )
    );
  }

  ignoredFirstResize = false;

  @action
  onResize() {
    if (this.chartContainer && this.ignoredFirstResize) {
      this.resetTooltip(); // Needed as tooltip may not be cleared if a resize happens while scrolling
      this.drawGraph();
    } else {
      this.ignoredFirstResize = true;
    }
  }

  @action
  drawGraph() {
    /**
     * Core graph
     */
    this._setRenderer(this.chartContainer);
    this._setScales(this.timeseries);

    /**
     * Axis
     */
    this._setYAxis(this.yScale);
    this._setYGridlines(this.yScale);
    this._setXAxis(this.xScale, this.tickFormatter);
    /**
     * Bars
     */
    this._drawMonthContainers(this.timeseries, this.xScale);

    if (this.hasData) {
      this._drawBars(this.timeseries, this.xScale, this.yScale);
      this._drawBalance(this.timeseries, this.xScale, this.yScale);
    }

    this._linkFlowTooltips();
    this._linkMonthTooltips();

    this.isFirstDraw = false;
  }

  @action
  resetTooltip() {
    let monthContainers = this.chartContainer.querySelectorAll('.cashflow-month-container');
    monthContainers.forEach(monthContainer => monthContainer.blur());
    this._handleFlowActivation();
    this.tooltipTarget = null;
    this.monthlyCashflow = null;
    this.activeMonthIndex = null;
  }

  _getYExtrema() {
    let series = this.timeseries.map(({ data }) => Object.values(data)).flat();
    return getExtrema(series, this.defaultYMax, this.headroom);
  }

  /**
   * Draws a container cell for each time value of a timeseries, over a given scale
   * @param {*} timeseries Timeseries
   * @param {*} scale D3 X Scale
   */
  _drawMonthContainers(timeseries, scale) {
    let containerHeight =
      this.chartInnerHeight + this.chartSpacing.top + this.chartSpacing.vertical * 2;
    let dataSelection = this.chartRenderer
      .selectAll('.cashflow-month-container')
      .data(timeseries)
      .enter();

    dataSelection
      .append('g')
      .append('rect')
      .attr('class', 'cashflow-month-container')
      .attr('data-test-cashflow-month-container', (_, index) => index)
      .attr('tabindex', 0)
      .attr('rx', 16)
      .attr('x', d => scale(d.time))
      .attr('y', 0)
      .attr('width', scale.bandwidth())
      .attr('height', containerHeight);
  }

  _getLineYPosition(index, timeseries, scale, isCurrent) {
    let isLastBalancePoint = index === timeseries.length - 1 && isCurrent;
    let timevalueData = timeseries[index].data;
    let balance = isLastBalancePoint ? timevalueData.endingBalance : timevalueData.startingBalance;
    return scale(balance);
  }

  _linkFlowTooltips() {
    this.chartRenderer
      .selectAll('.cashflow-flow-container')
      .selectAll('.cashflow-flow-bar')
      .attr('tabindex', 0)
      .attr(
        'id',
        (flow, index) => `${this.guid}-cashflow-flow-bar-${this._getFlowIdentifier(flow, index)}`
      )
      .attr('data-test-cashflow-flow-bar', (flow, index) => this._getFlowIdentifier(flow, index))
      .attr('aria-label', flow => this._getFlowAriaLabel(flow))
      .on('click mouseover focus', ({ target }, flow) => this._setFlowTooltip(target, flow))
      .on('mouseout focusout', () => this.resetTooltip());
  }

  _linkMonthTooltips() {
    this.chartRenderer
      .selectAll('.cashflow-month-container')
      .attr('id', (_, index) => `${this.guid}-cashflow-month-container-${index}`)
      .attr('aria-label', d => this._getMonthAriaLabel(d))
      .on('mouseover focus', ({ target }, month) => this._setMonthTooltip(target, month))
      .on('click', (_, month) => this._activateMonth(month))
      .on('mouseout focusout', () => this.resetTooltip());
  }

  _activateMonth(month) {
    let index = this.timeseries.findIndex(({ time }) => time === month.time);
    this.args.onMonthClicked?.(index, month);
  }

  _getFlowIdentifier(flow, flowIndex) {
    let flowContainerIndex = this.timeseries.findIndex(({ time }) => time === flow.time);
    return `${flowContainerIndex}-${flowIndex}`;
  }

  /**
   * Splits a Timevalue into an array of values describing inflows and outflows
   * @param {*} timevalue Timevalue
   * @returns {Array} Array of inflows (index 0) and outflows (index 1)
   */
  _getFlowBarsData({ time, data, display }) {
    return Array.from({ length: 2 }).map((value, index) => {
      let flowValue = 0;
      let flowDisplay;

      if (data) {
        flowValue = index === 0 ? data[DATA_TYPES.inflows] : data[DATA_TYPES.outflows];
      }
      if (display) {
        flowDisplay = index === 0 ? display[DATA_TYPES.inflows] : display[DATA_TYPES.outflows];
      }

      return {
        time,
        value: flowValue,
        display: flowDisplay,
        type: index === 0 ? DATA_TYPES.inflows : DATA_TYPES.outflows,
      };
    });
  }

  /**
   * Builds aria labels for month containers
   * @param {*} month Timevalue for a given month
   * @returns {string} Aria label for month container cells
   */
  _getMonthAriaLabel(month) {
    let formattedDate = dateToken({
      date: month.time,
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
    });
    let balance = this.intl.formatNumber(month.data?.endingBalance, { format: 'EURNAME' });
    return `${formattedDate}, ${balance}`;
  }

  /**
   * Sets the monthly tooltip data and DOM target element
   * @param {*} month Timevalue for a given month
   */
  _setMonthTooltip(target, { time, data, display }) {
    let index = this.timeseries.findIndex(value => value.time === time);
    if (index !== -1) {
      if (display) {
        data.netChange = display.balance.netChange;
      }

      this.tooltipTarget = target;
      this.activeMonthIndex = index;
      this.monthlyCashflow = {
        ...data,
        inflows: display.inflows.amount,
        outflows: display.outflows.amount,
        time,
      };
    }
  }

  /*
   * Builds aria labels for flow bars
   * @param {*} flow Flow data
   * @returns {string} Aria label for flow bars
   */
  _getFlowAriaLabel({ time, value }) {
    let formattedDate = dateToken({
      date: time,
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
    });
    let flowValue = this.intl.formatNumber(value, { format: 'EURNAME' });
    return `${formattedDate}, ${flowValue}`;
  }

  /**
   * Sets the flow tooltip data and DOM target element
   * @param {*} flow Flow data
   */
  _setFlowTooltip(target, flow) {
    let index = this.timeseries.findIndex(({ time }) => time === flow.time);
    if (index !== -1) {
      let isInflow = flow.type === DATA_TYPES.inflows;
      this.flow = { ...flow.display, isInflow };

      this._handleFlowActivation(index, isInflow);
      this.activeMonthIndex = index;
      this.tooltipTarget = target;
    }
  }

  /**
   * Manages flow bars opacity when focusing a flow bar.
   * Resets all flow bars if not index is passed.
   * @param {*} flowContainerIndex Focused flow bar (optional)
   * @param {*} dataTypeIndex Targeted flow index (optional)
   */
  _handleFlowActivation(flowContainerIndex, isInflow) {
    let dataTypeIndex = isInflow ? 0 : 1;
    this.chartRenderer
      .selectAll('.cashflow-flow-bar')
      .nodes()
      .forEach((flowBar, index) => {
        let targetFlowBarIndex = flowContainerIndex * 2 + dataTypeIndex;
        let isInactive = flowContainerIndex > -1 && index !== targetFlowBarIndex;
        if (isInactive) {
          flowBar.classList.add('inactive');
        } else {
          flowBar.classList.remove('inactive');
        }
      });
  }

  /**
   * Calculates a flow variation month-to-month
   * @param {*} flow Flow data
   * @param {*} monthIndex Month index of the current value
   * @returns Rounded month-to-month variation ratio
   */
  _getFlowVariation({ type, value }, monthIndex) {
    let previousFlowValue = this.timeseries[monthIndex - 1].data[type];
    let ratio = value / previousFlowValue;
    let variation = Math.round((ratio - 1) * 1000) / 1000;

    return variation;
  }
}
