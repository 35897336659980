/* import __COLOCATED_TEMPLATE__ from './icon-item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TabsPageIconItemComponent extends Component {
  @service keyboardFocus;

  @tracked focused = false;

  @action setFocused(focused) {
    this.focused = focused;
  }
}
