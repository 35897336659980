export default {
  "referral-invite-new": "cKB",
  "referral": "cKE",
  "center": "cKd",
  "illustration": "cKI",
  "share-referral": "cKt",
  "choice": "cKM",
  "line": "cKP",
  "email": "cKl",
  "email-input": "cKX",
  "input-field--error": "cKC",
  "error-message": "cKk",
  "external-link-icon": "cKJ"
};
