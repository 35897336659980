/* import __COLOCATED_TEMPLATE__ from './power-of-attorney-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

export default class PowerOfAttorneyFormComponent extends Component {
  @service modals;
  @service intl;
  @service organizationManager;
  @service zendeskLocalization;

  @tracked showFileRequiredError = false;

  fileMaxFileSize = 30 * 1e6; // 30MB

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  files = new TrackedArray([]);

  get fileDropzoneLabel() {
    return this.intl.t('labels.upload-message', {
      maxSize: formatFileSize(this.intl, this.fileMaxFileSize),
    });
  }

  get subtitle() {
    return this.intl.t('on-behalf.power-of-attorney-form.subtitle', {
      legalCountry: this.legalCountry,
      htmlSafe: true,
      faqUrl: this.zendeskLocalization.getLocalizedArticle(4359530),
    });
  }

  @action
  handleFormChange() {
    this.args.onChange?.({ files: this.files });
  }

  @action
  handleDeleteFile(file) {
    let index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.handleFormChange();
  }

  @action
  handleOpenFilePreviewModal(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: file,
    });
  }

  @action
  handleAddFile(file) {
    this.showFileRequiredError = false;
    this.files.push(file);
    this.handleFormChange();
  }

  submitTask = dropTask(async () => {
    if (!this.files.length) {
      this.showFileRequiredError = true;
      this.error = this.intl.t('on-behalf.power-of-attorney-form.errors.empty');
      return;
    }

    await this.args.onFormSubmitTask.linked().perform({ files: this.files });
  });
}
