export default {
  "row": "qQi",
  "active": "qQA",
  "animated": "qQY",
  "fadein-item": "qQh",
  "cell": "qQf body-2",
  "cell-content": "qQK",
  "subtitle": "qQG caption",
  "no-padding": "qQr",
  "note": "qQb",
  "cell-amount": "qQy",
  "cell-content-amount": "qQU body-1"
};
