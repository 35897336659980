export default {
  "wrapper": "qdf",
  "content": "qdK",
  "title": "qdG",
  "aside": "qdr",
  "description": "qdb",
  "faq-link": "qdy",
  "close-btn": "qdU",
  "container": "qdj",
  "mandate-field-list": "qdW",
  "mandate-field-item": "qdu",
  "mandate-field-item__dot": "qIS",
  "mandate-field-item__text": "qIc"
};
