export default {
  "wrapper": "qgh",
  "section-wrapper": "qgf",
  "header": "qgK",
  "title": "qgG body-1",
  "close-icon": "qgr",
  "section": "qgb",
  "section-title": "qgy caption-bold",
  "labels": "qgU",
  "labels-title": "qgj",
  "section-checkbox": "qgW",
  "grow": "qgu scroll",
  "label": "qDS",
  "buttons": "qDc"
};
