export default {
  "request-transfer-validation": "qBp",
  "account-selector": "qBx",
  "has-error": "qBw",
  "actions": "qBN",
  "action-btn": "qBv",
  "warnings": "qBo",
  "top-border": "qBn",
  "account-selector-subtitle": "qBi",
  "action-btn-right": "qBA",
  "funds-disclaimer": "qBY",
  "account-options": "qBh",
  "account-balance": "qBf",
  "request-multi-transfer-validation": "qBK",
  "wrapper": "qBG",
  "warning-message-row": "qBr",
  "multi-transfer-align": "qBb",
  "button-approve": "qBy",
  "button-approve-spinner": "qBU",
  "button-decline": "qBj"
};
