/* import __COLOCATED_TEMPLATE__ from './tabs.hbs'; */
import Component from '@glimmer/component';

import { QUOTE_STATUSES } from 'qonto/constants/quotes';

export default class Tabs extends Component {
  get isCompletedTabActive() {
    let statuses = this.args.status?.split(',') || [];

    return statuses.includes(QUOTE_STATUSES.APPROVED) || statuses.includes(QUOTE_STATUSES.CANCELED);
  }
}
