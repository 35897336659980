/* import __COLOCATED_TEMPLATE__ from './toast.hbs'; */
import Component from '@glimmer/component';

import { Toast } from '@repo/design-system-kit';

export default class ToastComponent extends Component {
  toast = Toast;

  get text() {
    return String(this.args.content.message);
  }
}
