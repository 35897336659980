/* import __COLOCATED_TEMPLATE__ from './status-tag.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';

import { INVOICE_STATUSES, STATUS_LEVELS } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesStatusTagComponent extends Component {
  tag = BadgeStatus;

  @service intl;

  get status() {
    switch (this.args.status) {
      case INVOICE_STATUSES.scheduled:
        return {
          display: this.intl.t(
            'supplier-list.sidebar.supplier-information.upcoming-invoices.scheduled.status'
          ),
          level: STATUS_LEVELS.scheduled,
        };
      case INVOICE_STATUSES.pending:
        return {
          display: this.intl.t('supplier-invoices.preview.status.pending-request'),
          level: STATUS_LEVELS.pending,
        };
      case INVOICE_STATUSES.archived:
        return {
          display: this.intl.t('supplier-invoices.preview.status.archived'),
          level: STATUS_LEVELS.archived,
        };
      case INVOICE_STATUSES.paid:
        return {
          display: this.intl.t('supplier-invoices.preview.status.paid'),
          level: STATUS_LEVELS.paid,
        };
      case INVOICE_STATUSES.toReview:
        return {
          display: variation('feature--boolean-approval-workflow-for-supplier-invoices')
            ? this.intl.t('supplier-invoices.preview.status.imported')
            : this.intl.t('supplier-invoices.preview.status.to-pay'),
          level: STATUS_LEVELS.toReview,
        };
      case INVOICE_STATUSES.toPay:
        return {
          display: this.intl.t('supplier-invoices.preview.status.to-pay'),
          level: STATUS_LEVELS.inbox,
        };
      case INVOICE_STATUSES.toApprove:
        return {
          display: this.intl.t('supplier-invoices.preview.status.to-approve'),
          level: STATUS_LEVELS.toApprove,
        };
      default:
        return {
          display: this.intl.t('supplier-invoices.section.tab.inbox'),
          level: STATUS_LEVELS.inbox,
        };
    }
  }
}
