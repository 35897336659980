export default {
  "banner": "cLU",
  "inner": "cLj",
  "header": "cLW title-3",
  "content": "cLu",
  "subtitle": "cTS body-1",
  "list": "cTc",
  "item": "cTq body-1",
  "icon": "cTZ",
  "cta": "cTR btn btn--primary",
  "spinner": "cTQ",
  "illustration": "cTe",
  "image": "cTB"
};
