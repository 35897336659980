export default {
  "wrapper": "cHE",
  "cashflow-status": "cHd",
  "status": "cHI",
  "estimated": "cHt",
  "vat-due-amount": "cHM title-3",
  "vat-tooltip": "cHP",
  "icon-info": "cHl",
  "charts-wrapper": "cHX",
  "chart-error": "cHC",
  "chart-controls": "cHk",
  "frequency-selector": "cHJ",
  "amount-loading": "cHs"
};
