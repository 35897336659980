/* import __COLOCATED_TEMPLATE__ from './storage.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const NEW_BADGE_STORAGE_KEY = 'new-badge-viewed';

export default class NewBadgeStorageComponent extends Component {
  get viewedItems() {
    return new Set(JSON.parse(safeLocalStorage.getItem(NEW_BADGE_STORAGE_KEY) || '[]'));
  }

  @tracked viewed = this.viewedItems.has(this.args.id);

  @action
  markAsViewed(callback) {
    this.viewed = true;
    let viewedItems = this.viewedItems;
    viewedItems.add(this.args.id);
    safeLocalStorage.setItem(NEW_BADGE_STORAGE_KEY, JSON.stringify(Array.from(viewedItems)));
    callback?.();
  }
}
