/* import __COLOCATED_TEMPLATE__ from './account-information.hbs'; */
import { service } from '@ember/service';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

import maybeT from 'qonto/utils/maybe-t';
import transformKeys from 'qonto/utils/transform-keys';

export default class TransfersInternationalOutProvidersAccountInformation extends Component {
  @service intl;
  @service localeManager;

  get #counterpartyAccountInformation() {
    return this.args.counterpartyAccountInformation || {};
  }

  get #formattedCounterpartyAccountInformation() {
    return transformKeys(this.#counterpartyAccountInformation);
  }

  get #accountType() {
    return this.#formattedCounterpartyAccountInformation.accountType;
  }

  get #identifiers() {
    let { accountIdentifier, bankIdentifier, branchIdentifier } =
      this.#formattedCounterpartyAccountInformation;
    return {
      ...(accountIdentifier && { accountIdentifier }),
      ...(bankIdentifier && { bankIdentifier }),
      ...(branchIdentifier && { branchIdentifier }),
    };
  }

  get accountInformation() {
    return Object.entries(this.#identifiers).reduce(
      (accumulator, [identifierKey, identifierValue]) => {
        if (identifierValue !== null) {
          let formattedIdentifierKey = dasherize(identifierKey);
          accumulator.push({
            key: formattedIdentifierKey,
            label: maybeT(
              {
                key: `international-out.account.label.${this.#accountType}.${formattedIdentifierKey}`,
                fallback: this.intl.t(
                  `international-out.account.label.generic.${formattedIdentifierKey}`
                ),
              },
              this.intl,
              this.localeManager.locale
            ),
            value: identifierValue,
          });
        }
        return [...accumulator];
      },
      []
    );
  }
}
