/* import __COLOCATED_TEMPLATE__ from './invoices-list.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

const SENTRY_IGNORE_HTTP_STATUSES = [401];

export default class SelfBillingInvoiceListComponent extends Component {
  @service abilities;
  @service flowLinkManager;
  @service router;
  @service store;
  @service segment;
  @service sentry;
  @service toastFlashMessages;
  @service intl;
  @service errors;

  get selfInvoices() {
    return !this.isLoading && this.args.fetchSelfInvoicesTask.last.value;
  }

  get isLoading() {
    return this.args.fetchSelfInvoicesTask.isRunning;
  }

  createSelfInvoiceTask = dropTask(async () => {
    try {
      this.segment.track('self-invoice_creation_started');
      let canCreate = this.abilities.can('create selfInvoice');

      if (canCreate) {
        let supplierInvoiceId = this.args.supplierInvoice.id;
        let organizationId = this.args.supplierInvoice.belongsTo('organization').id();

        let eInvoiceActivation = await this.store.findRecord(
          'e-invoice-activation',
          organizationId
        );

        if (eInvoiceActivation.eInvoicingActivated) {
          this.router.transitionTo('self-billing.new', {
            queryParams: {
              supplierInvoiceId,
            },
          });
        } else {
          this.flowLinkManager.replaceWith({
            name: 'invoice-onboarding',
            stepId: 'einvoice-activation',
            queryParams: { supplierInvoiceId },
          });
        }
      }
    } catch (error) {
      this.handleErrors(error);
    }
  });

  handleErrors(error) {
    if (
      ErrorInfo.for(error).shouldSendToSentry &&
      !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
    ) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(
      this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
    );
  }
}
