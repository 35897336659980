/* import __COLOCATED_TEMPLATE__ from './upload-power-of-attorney.hbs'; */
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

import splitText from 'qonto/utils/split-text';

export default class DeFreelancersFlexTopbarComponent extends Component {
  topBanner = TopBanner;

  get splitMessage() {
    let text = this.args.content.message;
    return splitText(text);
  }

  get content() {
    return {
      type: this.args.content.type,
      message: null,
    };
  }
}
