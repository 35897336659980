/* import __COLOCATED_TEMPLATE__ from './mfa-options.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { MFA_METHODS } from '@qonto/qonto-sca/constants/mfa';
import { dropTask } from 'ember-concurrency';

export default class PopupSecurityMfaOptionsComponent extends Component {
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service userManager;

  selectMethodTask = dropTask(async option => {
    try {
      await this.args.data.selectMethodTask.perform(option);
    } catch (error) {
      this.toastFlashMessages.toastError(
        this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
      );
    }
  });

  titleTranslationMap = {
    [MFA_METHODS.SMS]: this.intl.t('users.profile.modals.mfa-options.options.by_sms.title'),
    [MFA_METHODS.EMAIL]: this.intl.t('users.profile.modals.mfa-options.options.by_email.title'),
  };

  subtitleTranslationMap = {
    [MFA_METHODS.SMS]: this.intl.t('users.profile.modals.mfa-options.options.by_sms.subtitle', {
      phoneNumber: this.userManager.currentUser.phoneNumber,
    }),
    [MFA_METHODS.EMAIL]: this.intl.t('users.profile.modals.mfa-options.options.by_email.subtitle', {
      email: this.userManager.currentUser.email,
    }),
  };
}
