/* import __COLOCATED_TEMPLATE__ from './archive-popup.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ArchivePopup extends Component {
  @service intl;
  @service networkManager;
  @service router;
  @service sentry;
  @service toastFlashMessages;
  @service supplierInvoicesInsights;

  archiveTask = dropTask(async close => {
    let { id } = this.args.data;
    try {
      await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/bulk_archive`,
        {
          method: 'POST',
          body: JSON.stringify({
            supplier_invoice_ids: [id],
          }),
        }
      );

      this.supplierInvoicesInsights.fetchInsights().catch(() => {});

      this.toastFlashMessages.toastSuccess(this.intl.t('supplier-invoices.archive.toast.success'));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      this.router.transitionTo('supplier-invoices');
      close();
    }
  });
}
