/* import __COLOCATED_TEMPLATE__ from './phone-number-verification.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { MFA_METHODS } from '@qonto/qonto-sca/constants/mfa';
import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { authBaseURL } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const ERROR_CODES = ['invalid_token', 'missing_token'];

export default class PopupSecurityPhoneNumberVerificationComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service networkManager;
  @service otpManager;
  @service segment;
  @service sensitiveActions;
  @service sentry;
  @service userManager;

  @tracked errorMessage;
  @tracked verificationCode;

  @tracked mfaOptionsModal = null;
  mfaOptions = [];

  constructor() {
    super(...arguments);
    let { currentUser } = this.userManager;
    if (currentUser.mfaEnabled && !currentUser.scaEnabled && currentUser.phoneNumber) {
      this.fetchMFAOptionsTask.perform().catch(ignoreCancelation);
    }
  }

  willDestroy() {
    this.mfaOptionsModal?.close();
    super.willDestroy(...arguments);
  }

  get description() {
    let phoneNumber = this.userManager.currentUser?.pendingPhoneNumber;
    return this.intl.t('users.profile.modals.confirm-phone-number.subtitle', { phoneNumber });
  }

  fetchMFAOptionsTask = dropTask(async () => {
    try {
      let { mfa_options } = await this.networkManager.request(
        `${authBaseURL}/v5/user/phone_number/mfa_options`
      );
      this.mfaOptions = mfa_options;
    } catch {
      // ignore error, if it fails we default to MFA_METHODS.SMS
    }
  });

  @action updateVerificationCode(value) {
    this.errorMessage = null;
    this.verificationCode = value;
  }

  validateOtpTask = dropTask(async otp => {
    try {
      await this.networkManager.request(
        `${authBaseURL}/v5/user/phone_number/validate_new_phone_otp`,
        {
          method: 'POST',
          data: JSON.stringify({
            new_phone_otp: otp,
          }),
        }
      );
      return true;
    } catch (error) {
      if (error.errors?.some(({ code }) => ERROR_CODES.includes(code))) {
        return false;
      }
      throw error;
    }
  });

  showMfaOptionsTask = dropTask(async () => {
    try {
      if (this.mfaOptions.length > 1) {
        let isOtpValid = await this.validateOtpTask.perform(this.verificationCode);
        if (!isOtpValid) {
          this.errorMessage = this.intl.t('users.profile.security.errors.invalid-token');
          return;
        }
        this.mfaOptionsModal = this.modals.open('popup/security/mfa-options', {
          mfaOptions: this.mfaOptions,
          selectMethodTask: this.activatePhoneNumberSensitiveTask,
        });
        await this.mfaOptionsModal;
        // mfaOptionsModal might be set to null at line 125
        if (this.mfaOptionsModal) {
          this.args.close();
        }
      } else {
        await this.activatePhoneNumberSensitiveTask.perform(MFA_METHODS.SMS);
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) this.sentry.captureException(error);
      this.toastFlashMessages.toastError(
        this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
      );
    }
  });

  activatePhoneNumberSensitiveTask = dropTask(async mfaMethod => {
    this.otpManager.clearToken(); // clear mfa token
    await this.sensitiveActions.runTask.linked().perform(this.activatePhoneNumberTask, mfaMethod);
  });

  activatePhoneNumberTask = dropTask(async mfaMethod => {
    try {
      switch (mfaMethod) {
        case 'by_email':
          this.segment.track('profile_phone-update_mfa_email');
          break;
        case 'by_sms':
          this.segment.track('profile_phone-update_mfa_text', {
            has_options: this.mfaOptions.length > 1,
          });
          break;
      }

      await this.networkManager.request(`${authBaseURL}/v5/user/phone_number/activate`, {
        method: 'POST',
        data: JSON.stringify({
          new_phone_otp: this.verificationCode,
          mfa_method: mfaMethod,
        }),
      });

      this.args.close('success');
    } catch (error) {
      if (error.errors?.some(({ code }) => ERROR_CODES.includes(code))) {
        this.errorMessage = this.intl.t('users.profile.security.errors.invalid-token');
        this.mfaOptionsModal?.close();
        this.mfaOptionsModal = null;
        return;
      }
      throw error;
    }
  });
}
