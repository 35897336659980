export default {
  "bank-account-option": "qFa",
  "row": "qFH",
  "active": "qFO",
  "animated": "qFm",
  "fadein-item": "qFp",
  "cell": "qFx body-2",
  "cell-content": "qFw",
  "subtitle": "qFN caption",
  "no-padding": "qFv",
  "empty": "qFo",
  "note": "qFn",
  "cell-content-amount": "qFi body-1",
  "cell-content-receipt": "qFA body-1",
  "cell-quick-actions": "qFY",
  "account-selector": "qFh",
  "cell-type": "qFf"
};
