export default {
  "item-wrapper": "qPI",
  "title-sub-wrapper": "qPt",
  "icon-title-wrapper": "qPM",
  "icon": "qPP",
  "icon-wrapper": "qPl",
  "title": "qPX body-1",
  "subtitle": "qPC body-2",
  "amount": "qPk body-1"
};
