export default {
  "button-wrapper": "qVb",
  "btn-filter": "qVy",
  "name-wrapper": "qVU",
  "preset-name": "qVj",
  "cancel-placeholder": "qVW",
  "cancel-button": "qVu",
  "dropdown-opened": "qLS",
  "dropdown-closed": "qLc",
  "dropdown-menu": "qLq",
  "loading-item": "qLZ",
  "placeholder": "qLR",
  "list-body": "qLQ",
  "list-item": "qLe",
  "error-state": "qLB body-2",
  "warning": "qLE",
  "warning-icon": "qLd",
  "footer": "qLI",
  "add-icon": "qLt",
  "empty-state": "qLM"
};
