export default {
  "row": "cre",
  "active": "crB",
  "animated": "crE",
  "fadein-item": "crd",
  "cell": "crI",
  "cell-content": "crt",
  "row-sidebar": "crM",
  "empty": "crP",
  "cell-amount": "crl",
  "subtitle": "crX caption",
  "hidden": "crC",
  "cell-approve": "crk",
  "quick-actions": "crJ",
  "account-select": "crs",
  "note": "crF",
  "cell-content-amount": "crg body-1",
  "cell-status": "crD"
};
