export default {
  "credit-note-sidebar": "cvG",
  "body": "cvr",
  "box": "cvb",
  "box-header": "cvy",
  "footer": "cvU",
  "footer-button": "cvj",
  "sidebar-box": "cvW",
  "border-top": "cvu",
  "row": "coS",
  "related-invoice-title": "coc caption-bold",
  "einvoice-rejected": "coq"
};
