/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { MIN_SAVINGS_ACCOUNT_AMOUNT } from 'qonto/constants/savings-account';

export default class SavingsAccountsStepsDetailsComponent extends Component {
  amountField = AmountField;

  @service intl;
  @service segment;

  @service organizationManager;
  @tracked amountErrorMessage = null;
  @tracked durationError = false;

  @reads('organizationManager.organization.authorizedBalance') authorizedBalance;

  maturityTranslations = {
    '6M': this.intl.t('accounts.savings.creation.details.duration-6'),
    '12M': this.intl.t('accounts.savings.creation.details.duration-12'),
    '18M': this.intl.t('accounts.savings.creation.details.duration-18'),
    '24M': this.intl.t('accounts.savings.creation.details.duration-24'),
    '36M': this.intl.t('accounts.savings.creation.details.duration-36'),
    '60M': this.intl.t('accounts.savings.creation.details.duration-60'),
  };

  get durationOptions() {
    return this.args.context.rates.map(({ nominalMaturity, nominalInterestRate }) => ({
      code: nominalMaturity,
      label: this.maturityTranslations[nominalMaturity],
      ratio: this._formatRate(nominalInterestRate),
    }));
  }

  @action
  handleUpdateAmount(value) {
    this.args.context.updateAmount(value);
    this.validateAmountAuthorizedBalance();
  }

  @action
  handleUpdateDurationOption(value) {
    this.args.context.updateDurationOption(value);
    this.validateDurationExists();
  }

  get maxAmount() {
    return this.args.context.information.maxAmountCents / 100 || 0;
  }

  @action
  handleNext() {
    let validAmount = this.validateAmount();
    this.validateDurationExists();
    let isValid = !this.durationError && validAmount;

    if (isValid) {
      this.segment.track('savings_amount_duration_cta');
      this.args.transitionToNext();
    }
  }

  validateDurationExists() {
    this.durationError =
      this.args.context.maturity === undefined
        ? this.intl.t('accounts.savings.creation.details.error.select-duration')
        : false;
  }

  validateAmountExists() {
    if (!this.args.context.amount) {
      this.amountErrorMessage = this.intl.t('accounts.savings.creation.details.error.amount-empty');
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmountMinimum() {
    if (Number(this.args.context.amount) < MIN_SAVINGS_ACCOUNT_AMOUNT) {
      this.amountErrorMessage = this.intl.t(
        'accounts.savings.creation.details.error.amount-minimum'
      );
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmountMaximum() {
    if (Number(this.args.context.amount) > this.maxAmount) {
      this.amountErrorMessage = this.intl.t(
        'accounts.savings.creation.details.error.amount-maximum',
        { amount: this._formatPrice(this.maxAmount) }
      );
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmountAuthorizedBalance() {
    if (Number(this.args.context.amount) > this.authorizedBalance) {
      this.amountErrorMessage = this.intl.t(
        'accounts.savings.creation.details.error.amount-input',
        { amount: this._formatPrice(this.authorizedBalance) }
      );
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmount() {
    return (
      this.validateAmountExists() &&
      this.validateAmountMinimum() &&
      this.validateAmountMaximum() &&
      this.validateAmountAuthorizedBalance()
    );
  }

  _formatRate(rate) {
    return this.intl.formatNumber(rate / 100, {
      style: 'percent',
      minimumFractionDigits: 3,
    });
  }

  _formatPrice(price) {
    return this.intl.formatNumber(price, {
      minimumFractionDigits: 0,
      currency: 'EUR',
      style: 'currency',
    });
  }
}
