/* import __COLOCATED_TEMPLATE__ from './dropdown-transaction-volume.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { YEARLY_TRANSACTION_VOLUME_OPTIONS } from 'qonto/constants/business-finances';

export default class DropdownTransactionVolume extends Component {
  @service intl;

  get selectedValue() {
    let code = this.args.organizationChangeRequest.yearlyTransactionVolume;

    if (code) {
      let name = this.yearlyTransactionVolumeOptionsTranslations[code];
      return { code, name };
    }
    return null;
  }

  get yearlyTransactionVolumeOptionsTranslations() {
    let [option1, option2, option3, option4, option5] = YEARLY_TRANSACTION_VOLUME_OPTIONS;
    return {
      [option1]: this.intl.t(
        'organizations.profile.company_profile.organization_edit.business_finances.yearly_transaction_volume.option1'
      ),
      [option2]: this.intl.t(
        'organizations.profile.company_profile.organization_edit.business_finances.yearly_transaction_volume.option2'
      ),
      [option3]: this.intl.t(
        'organizations.profile.company_profile.organization_edit.business_finances.yearly_transaction_volume.option3'
      ),
      [option4]: this.intl.t(
        'organizations.profile.company_profile.organization_edit.business_finances.yearly_transaction_volume.option4'
      ),
      [option5]: this.intl.t(
        'organizations.profile.company_profile.organization_edit.business_finances.yearly_transaction_volume.option5'
      ),
    };
  }

  get options() {
    return YEARLY_TRANSACTION_VOLUME_OPTIONS.map(code => ({
      code,
      name: this.yearlyTransactionVolumeOptionsTranslations[code],
    }));
  }

  @action
  handleUpdate(transactionVolumeObject) {
    this.args.organizationChangeRequest.yearlyTransactionVolume = transactionVolumeObject.code;
  }
}
