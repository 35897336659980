/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

import { NRC_STATUS } from 'qonto/constants/nrc-payments';

export default class NrcPaymentsTableItemComponent extends Component {
  @service intl;
  badgeStatus = BadgeStatus;

  status = {
    [NRC_STATUS.SCHEDULED]: this.intl.t('aeat.status.scheduled'),
    [NRC_STATUS.COMPLETED]: this.intl.t('aeat.status.completed'),
    [NRC_STATUS.DECLINED]: this.intl.t('aeat.status.declined'),
    [NRC_STATUS.CANCELED]: this.intl.t('aeat.status.canceled'),
  };

  icons = {
    [NRC_STATUS.SCHEDULED]: 'status_scheduled',
    [NRC_STATUS.COMPLETED]: 'status_approved',
    [NRC_STATUS.DECLINED]: 'status_cancelled',
    [NRC_STATUS.CANCELED]: 'status_cancelled',
  };

  get statusLevel() {
    switch (this.args.item.status) {
      case NRC_STATUS.SCHEDULED:
        return 'waiting';
      case NRC_STATUS.COMPLETED:
        return 'validated';
      case NRC_STATUS.DECLINED:
        return 'error';
      case NRC_STATUS.CANCELED:
        return 'error';
      default:
        return 'waiting';
    }
  }

  get statusIcon() {
    return this.icons[this.args.item.status];
  }

  get statusText() {
    return this.status[this.args.item.status];
  }

  get isTerminatedPayment() {
    return this.args.item.status === 'declined' || this.args.item.status === 'canceled';
  }

  get modeloDescription() {
    let taxCode = this.args.item.get('taxCode');
    let taxDescription = this.args.item.get('taxDescription');
    return `${this.intl.t('aeat.sidepanel.labels.modelo')} ${taxCode} - ${taxDescription}`;
  }
}
