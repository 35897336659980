export default {
  "sections": "qeb",
  "actions": "qey",
  "search-input": "qeU",
  "no-upload": "qej",
  "new-link": "qeW body-2",
  "between-links": "qeu",
  "action-bar": "qBS",
  "empty-state": "qBc"
};
