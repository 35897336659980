/* import __COLOCATED_TEMPLATE__ from './month-dropdown.hbs'; */
import Component from '@glimmer/component';

import { eachMonthOfInterval } from 'qonto/utils/date';

export default class CustomPeriodMonthDropdown extends Component {
  get months() {
    return eachMonthOfInterval(new Date(this.args.originDate), new Date()).reverse();
  }
}
