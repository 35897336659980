export default {
  "company-logo": "cVX",
  "list-item": "cVC",
  "link": "cVk",
  "title-wrapper": "cVJ",
  "title": "cVs body-2",
  "subtitle": "cVF caption",
  "truncated-text": "cVg",
  "image-placeholder": "cVD"
};
