/* import __COLOCATED_TEMPLATE__ from './blockers-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { codeToName } from 'qonto/models/subscription-product';

export default class BlockersModalComponent extends Component {
  @service intl;
  @service router;
  @service abilities;
  @service segment;

  constructor() {
    super(...arguments);
    let { organization } = this.subscription;

    if (!organization.get('bankAccounts.length')) {
      organization.loadBankAccounts();
    }

    // tracking
    let { errors, subscription, currentPricePlanCode } = this.args.data;

    this.segment.track('plans_blockers_displayed', {
      current_plan: currentPricePlanCode,
      target_plan: subscription.product.get('code'),
      blockers: errors.map(it => it.code),
    });
  }

  get subscription() {
    return this.args.data.subscription;
  }

  get blockers() {
    return this.args.data.errors.map(error =>
      this.mapErrorToBlocker(error.code, error.additional_data)
    );
  }

  mapErrorToBlocker(code, additionalData) {
    let { intl } = this;
    let { organization } = this.subscription;

    switch (code) {
      case 'user_limit_reached': {
        let membershipsToRevokeCount = additionalData.memberships_to_revoke_count;
        let isDowngradingToAddAdminOnly =
          this.abilities.cannot('add admin only member') &&
          // target plan is eligible to admins for solo
          additionalData.user_limit === 1 &&
          !additionalData.plan_has_team_management_feature;

        let price;
        if (additionalData.additional_user_price) {
          price = this.intl.formatNumber(additionalData.additional_user_price.value, {
            minimumFractionDigits: 0,
            currency: additionalData.additional_user_price.currency,
            style: 'currency',
          });
        }

        return {
          link: 'members',
          title: intl.t('subscription.change.blockers.team.title', {
            count: membershipsToRevokeCount,
          }),
          linkText: intl.t('subscription.change.blockers.team.link'),
          description: isDowngradingToAddAdminOnly
            ? intl.t('subscription.change.blockers.team.subtitle-solo', {
                count: membershipsToRevokeCount,
                price,
              })
            : intl.t('subscription.change.blockers.team.subtitle', {
                count1: additionalData.user_limit,
                count2: membershipsToRevokeCount,
              }),
        };
      }
      case 'accountant_access_disallowed': {
        let accountantsCountToDisable = organization.get(
          'accountantsCountingTowardsPlanLimitCount'
        );
        return {
          link: 'members',
          title: intl.t('subscription.change.blockers.accountant.title', {
            count: accountantsCountToDisable,
          }),
          linkText: intl.t('subscription.change.blockers.accountant.link'),
          description: intl.t('subscription.change.blockers.accountant.subtitle', {
            count: accountantsCountToDisable,
          }),
        };
      }

      case 'bank_account_limit_reached': {
        let bankAccountLimit = additionalData.bank_account_limit;
        return {
          link: 'accounts',
          title: intl.t('subscription.change.blockers.accounts.title', {
            count: this.bankAccountCountToDisable(bankAccountLimit),
          }),
          linkText: intl.t('subscription.change.blockers.accounts.link'),
          description: intl.t('subscription.change.blockers.accounts.subtitle', {
            count1: bankAccountLimit,
            count2: this.bankAccountCountToDisable(bankAccountLimit),
          }),
        };
      }

      case 'current_addon_not_supported_with_target_plan':
        return {
          link: { name: 'addon-change', addon_recurrence: additionalData.addon_product_recurrence },
          title: intl.t('subscription.change.blockers.addon.title', {
            addonName: codeToName(intl, additionalData.addon_product_code),
          }),
          linkText: intl.t('subscription.change.blockers.addon.link'),
          description: intl.t('subscription.change.blockers.addon.subtitle'),
          blockerTrackEvent: {
            blockerType: 'removal',
            addon_code: additionalData.addon_product_code,
          },
        };

      case 'current_addon_recurrence_not_supported_with_target_plan_recurrence':
        return {
          link: { name: 'addon-change', addon_recurrence: additionalData.addon_product_recurrence },
          title: intl.t('subscription.change.blockers.addon-billing.title', {
            addonName: codeToName(intl, additionalData.addon_product_code),
          }),
          linkText: intl.t('subscription.change.blockers.addon.link'),
          description: intl.t('subscription.change.blockers.addon-billing.subtitle'),
          blockerTrackEvent: {
            blockerType: 'downsize',
            addon_code: additionalData.addon_product_code,
          },
        };
    }
  }

  bankAccountCountToDisable(bankAccountLimit) {
    let { organization } = this.subscription;
    let bankAccounts = organization.get('bankAccounts');

    if (bankAccounts) {
      return (
        bankAccounts.filter(({ status }) => status === 'active' || status === 'pending').length -
        bankAccountLimit
      );
    }
  }

  @action
  transitionTo(selectedRoute, blockerTrackEvent) {
    this.args.close();
    if (selectedRoute?.name === 'addon-change') {
      this.segment.track('addon_blocker_clicked', blockerTrackEvent);

      this.args.data.transitionToFlow({
        flowName: 'addon-change',
        stepId: 'addons',
        queryParams: {
          addon_recurrence: selectedRoute.addon_recurrence,
        },
      });
    } else {
      this.router.transitionTo(selectedRoute);
    }
    let { product, organization, recurrence } = this.subscription;

    let data = {
      code: product.get('code'),
      orgId: organization.get('id'),
      recurrence,
    };

    safeLocalStorage.setItem('price-plan-change-in-progress', JSON.stringify(data));
  }
}
