/* import __COLOCATED_TEMPLATE__ from './confirm-creation.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { LOCAL_STORAGE_WARNING_DISMISSED_KEY } from 'qonto/constants/receivable-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class ReceivableInvoicesConfirmCreationModalComponent extends Component {
  @service organizationManager;
  @service modals;
  @service intl;
  @service segment;

  triggerConfirmationTask = dropTask(async () => {
    if (this.args.event) {
      this.segment.track(this.args.event);
    }
    await this.modals.open(
      'receivable-invoices/confirm-creation-modal',
      {
        title: this.intl.t('receivable-invoices.issue-modal.title'),
        description: this.invoiceCreationDescription,
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('receivable-invoices.issue-modal.cta-issue'),
        confirmTask: this.confirmTask,
        shouldDisplayCheckbox: this.shouldDisplayCheckbox,
        shouldDisplayEinvoicing: this.shouldDisplayEinvoicing,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  });

  confirmTask = dropTask(async closeModal => {
    await this.args.primaryTask.perform(closeModal);
    if (this.shouldDisplayCheckbox) {
      safeLocalStorage.setItem(LOCAL_STORAGE_WARNING_DISMISSED_KEY, 'true');
    }
  });

  get invoiceCreationDescription() {
    let shouldDisplayItalyCopy = this.organizationManager.organization.legalCountry === 'IT';

    if (this.args.invoice?.status === 'draft') {
      if (this.shouldDisplayEinvoicing && variation('run-task--boolean-einvoicing-fr-rollback')) {
        return this.intl.t('receivable-invoices.issue-modal.einvocing-subtitle');
      } else {
        return shouldDisplayItalyCopy
          ? this.intl.t('receivable-invoices.issue-modal.edit-draft.italy.subtitle')
          : this.intl.t('receivable-invoices.issue-modal.edit-draft.subtitle');
      }
    }

    // FR e-invoicing condition
    if (this.shouldDisplayEinvoicing) {
      if (variation('run-task--boolean-einvoicing-fr-rollback')) {
        return this.intl.t('receivable-invoices.issue-modal.einvocing-subtitle');
      } else {
        return this.intl.t('receivable-invoices.issue-modal.subtitle');
      }
    }

    return shouldDisplayItalyCopy
      ? this.intl.t('receivable-invoices.issue-modal.italy.subtitle')
      : this.intl.t('receivable-invoices.issue-modal.subtitle');
  }

  get shouldDisplayCheckbox() {
    return (
      this.args.settings?.numberingMode === 'manual' &&
      safeLocalStorage.getItem(LOCAL_STORAGE_WARNING_DISMISSED_KEY) !== 'true'
    );
  }

  get shouldDisplayEinvoicing() {
    return this.args.invoice?.isEinvoice;
  }
}
