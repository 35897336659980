/* import __COLOCATED_TEMPLATE__ from './navigable.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { restartableTask, timeout } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';

import { getExtrema } from 'qonto/utils/chart/scale';

import CashflowChartComponent from '../cashflow';

export default class CashflowNavigableChartComponent extends CashflowChartComponent {
  @service segment;

  tickFormatter = d => {
    let frequency = this.args.selectedFrequency || 'monthly';
    if (frequency === 'monthly') {
      return dateToken({ date: d, locale: this.intl.primaryLocale, token: 'month-year-s' });
    }

    return `Q${dayjs(d).quarter()} ${dayjs(d).year()}`;
  };

  get balanceTimeseries() {
    return this.args.balanceTimeseries || this.timeseries;
  }

  get fullTimeseries() {
    return this.args.fullTimeseries || this.timeseries;
  }

  @action
  drawGraph() {
    /**
     * Core graph
     */
    this._setRenderer(this.chartContainer);
    this.yScale = this._getYScale(this.fullTimeseries);
    this.xScale = this._getXScale(this.timeseries);

    /**
     * Axis
     */
    this._setYAxis(this.yScale);
    this._setYGridlines(this.yScale);
    this._setXAxis(this.xScale, this.tickFormatter);
    /**
     * Bars
     */
    this._drawMonthContainers(this.timeseries, this.xScale);

    if (this.hasData) {
      this._drawBars(this.timeseries, this.xScale, this.yScale);
      this._drawBalance(this.balanceTimeseries, this.xScale, this.yScale);
    }

    this._linkFlowTooltips();
    this._linkMonthTooltips();

    this.isFirstDraw = false;
  }

  @action drawXAxis() {
    this.chartRenderer.selectAll('.x-axis-tick').remove();
    this._setXAxis(this.xScale, this.tickFormatter);
  }

  @action onPreviousMonth() {
    this.args.onPreviousMonth?.();
    this.segment.track('cash-flow_combo-chart_next-period', { direction: 'past' });
    this.onResize();
  }

  @action onNextMonth() {
    this.args.onNextMonth?.();
    this.segment.track('cash-flow_combo-chart_next-period', { direction: 'future' });
    this.onResize();
  }

  _getYExtrema() {
    let series = this.fullTimeseries.map(({ data }) => Object.values(data)).flat();
    return getExtrema(series, this.defaultYMax, this.headroom);
  }

  drawXAxisTask = restartableTask(async () => {
    await timeout(0);
    this.drawXAxis();
    return this.args.selectedFrequency;
  });

  lastSelectedFrequency = trackedTask(this, this.drawXAxisTask, () => [
    this.args.selectedFrequency,
  ]);
}
