/* import __COLOCATED_TEMPLATE__ from './invoice-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DOCUMENT_TYPE } from 'qonto/constants/self-billing';

export default class SelfBillingFormInvoiceDetailsComponent extends Component {
  @service intl;

  get documentTypes() {
    return [
      {
        code: DOCUMENT_TYPE.TD17,
        label: this.intl.t('self-billing.document-type.td17'),
      },
      { code: DOCUMENT_TYPE.TD18, label: this.intl.t('self-billing.document-type.td18') },
      { code: DOCUMENT_TYPE.TD19, label: this.intl.t('self-billing.document-type.td19') },
    ];
  }

  get selectedDocumentType() {
    return this.documentTypes.find(({ code }) => code === this.args.invoice?.documentType);
  }

  @action updateDocumentType({ code }) {
    this.args.invoice.documentType = code;
  }

  @action
  onIssueDateUpdate(date) {
    this.args.invoice.issueDate = date;
  }
}
