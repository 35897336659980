export default {
  "header": "cUQ",
  "header-type": "cUe",
  "close": "cUB",
  "close-icon": "cUE",
  "header-infos": "cUd",
  "title": "cUI body-1",
  "declined": "cUt",
  "canceled": "cUM",
  "approved": "cUP",
  "pending": "cUl",
  "date": "cUX body-2",
  "container": "cUC",
  "picto": "cUk",
  "picto-status": "cUJ",
  "general": "cUs",
  "amount": "cUF",
  "counterparty": "cUg body-2",
  "initiator": "cUD",
  "initiator-avatar": "cUV",
  "avatar-image": "cUL",
  "initiator-id": "cUT",
  "name": "cUz body-2",
  "role": "cUa caption-bold",
  "infobox": "cUH",
  "account-infobox": "cUO cUH",
  "beneficiary-warning": "cUm caption-bold",
  "dates-wrapper": "cUp",
  "dates": "cUx",
  "recurrence": "cUw caption-bold",
  "arrow": "cUN",
  "notify-checkbox": "cUv"
};
