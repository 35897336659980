export default {
  "header": "qZY",
  "header-type": "qZh",
  "close": "qZf",
  "close-icon": "qZK",
  "header-infos": "qZG",
  "title": "qZr body-1",
  "declined": "qZb",
  "canceled": "qZy",
  "approved": "qZU",
  "date": "qZj body-2",
  "container": "qZW",
  "picto": "qZu",
  "request": "qRS",
  "picto-status": "qRc",
  "check": "qRq",
  "stop": "qRZ",
  "warning": "qRR",
  "general": "qRQ",
  "amount": "qRe",
  "counterparty": "qRB body-2",
  "initiator": "qRE",
  "initiator-avatar": "qRd",
  "avatar-image": "qRI",
  "initiator-id": "qRt",
  "name": "qRM body-2",
  "role": "qRP caption-bold",
  "infobox": "qRl",
  "account-infobox": "qRX qRl",
  "beneficiary-warning": "qRC caption-bold",
  "dates-wrapper": "qRk",
  "dates": "qRJ",
  "recurrence": "qRs caption-bold",
  "arrow": "qRF",
  "notify-checkbox": "qRg"
};
