/* import __COLOCATED_TEMPLATE__ from './cashflow-jumbo.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

import { FrequencySelector } from 'qonto/react/components/cash-flow/components/frequency-selector';
import { buildCashflowTimevalue } from 'qonto/utils/chart/display';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

import CashflowComponent from './cashflow';

export default class CashflowJumboComponent extends CashflowComponent {
  FrequencySelector = FrequencySelector;
  @service abilities;
  @service intl;
  @tracked selectedDatapoint = undefined;

  get maxDisplayedMonths() {
    return this.args.maxDisplayedMonths;
  }

  get indexOffset() {
    return this.args.indexOffset;
  }

  get isCurrentPeriodSelected() {
    if (!this.selectedDatapoint) return true;

    let frequencyInterval = 'month';
    if (this.args.selectedFrequency === 'quarterly') {
      frequencyInterval = 'quarter';
    }

    return dayjs(this.selectedDatapoint.time).isSame(dayjs(), frequencyInterval);
  }

  get cashflowTimeseries() {
    if (this.args.isLoading || this.args.isUpdating || !this.statistics.base) return;

    if (this.abilities.cannot('assign category')) return this.timeseries;

    let [inflows, outflows, balances] = this.statistics.base;
    let baseArray = Array.from({ length: balances.length });

    return baseArray.map((_, index) => {
      return buildCashflowTimevalue(
        inflows[index],
        outflows[index],
        balances[index],
        this.timezone
      );
    });
  }

  get availableDatapointValue() {
    if (!this.cashflowTimeseries) return;
    if (this.selectedDatapoint) return this.selectedDatapoint.data;

    return this.cashflowTimeseries.at(-1).data;
  }

  get balance() {
    return this.availableDatapointValue?.endingBalance;
  }

  get balanceLabel() {
    return this.isCurrentPeriodSelected
      ? this.intl.t('cash-flow.balance.current-header')
      : this.intl.t('cash-flow.balance.selected-period-header');
  }

  get vatDue() {
    return this.availableDatapointValue?.vatDue;
  }

  get vatLabel() {
    return this.isCurrentPeriodSelected
      ? this.intl.t('cash-flow.vat.current-header')
      : this.intl.t('cash-flow.vat.selected-period-header');
  }

  get vatTooltip() {
    let credit = this.intl.formatNumber(this.availableDatapointValue?.vatCredit, {
      currency: 'EUR',
      style: 'currency',
    });
    let debit = this.intl.formatNumber(this.availableDatapointValue.vatDebit, {
      currency: 'EUR',
      style: 'currency',
    });
    return this.intl.t('cash-flow.vat.tooltip', {
      htmlSafe: true,
      credit,
      debit,
    });
  }

  /**
   * Displayed timeseries
   */
  get chartTimeseries() {
    if (!this.cashflowTimeseries) return;

    let offset = this.indexOffset;
    let cutoff = offset + this.maxDisplayedMonths;
    let chartTimeseries = [...this.cashflowTimeseries].slice(offset, cutoff);

    return chartTimeseries;
  }

  /**
   * Chart timeseries + next available timevalue
   */
  get balanceTimeseries() {
    if (!this.cashflowTimeseries) return;

    let timeseriesLastIndex = this.cashflowTimeseries.length - 1;
    let cutoff = this.indexOffset + this.maxDisplayedMonths;
    let lastBalanceIndex = cutoff < timeseriesLastIndex ? cutoff + 1 : timeseriesLastIndex;
    let lastBalancePoint = this.cashflowTimeseries.at(lastBalanceIndex);

    let balanceTimeseries = [...this.chartTimeseries, lastBalancePoint];

    return balanceTimeseries;
  }

  constructor() {
    super(...arguments);
    this.storageKey = `cashflow-jumbo-period-${this.membershipId}`;
  }

  @action
  onSavePeriod(period, closeDropdown) {
    this.args.onResetOffset();

    closeDropdown?.();
    if (!period) return;

    this._selectedPeriod = this._formatPeriod(period);
    this.persistPeriod(period);

    this.segment.track('cash-flow_filter_clicked', {
      filtertype: 'period',
    });
    this.refreshWidgetTask.perform(period).catch(ignoreCancelation);
  }

  @action onMonthClicked(index, selectedDatapoint) {
    this.args.onSetFocus(index);

    if (this.args.selectedFrequency) {
      this.selectedDatapoint = selectedDatapoint;
    }
  }
}
