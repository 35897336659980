/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class PresetsListItemComponent extends Component {
  @service intl;

  @tracked hovered = false;
  @tracked showActions = false;

  get localizedName() {
    let { name } = this.args;

    let translations = {
      missing_receipts: this.intl.t('transactions.filters.presets.items.missing_receipts'),
      settled_last_month: this.intl.t('transactions.filters.presets.items.settled_last_month'),
      to_verify: this.intl.t('transactions.filters.presets.items.to_verify'),
    };

    return translations[name] || name;
  }

  get count() {
    let { count } = this.args;
    return count.toString().length >= 4 ? '+999' : count;
  }

  @action
  hoverActions(value) {
    this.hovered = value;
    this.showActions = value;
  }

  @action
  handleShowActions(value) {
    this.showActions = value;
  }
}
