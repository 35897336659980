export default {
  "file-uploader": "cNq",
  "file-uploader-content": "cNZ",
  "file-uploader-dropzone": "cNR",
  "fixedSize": "cNQ",
  "file-uploader-label": "cNe body-1",
  "required-chip": "cNB",
  "animated-container": "cNE",
  "toggle-button": "cNd",
  "toggle-button-icon": "cNI"
};
