export default {
  "custom-period-wrapper": "caZ",
  "fadeIn": "caR",
  "custom-period-description": "caQ body-2",
  "header": "cae",
  "arrow-left": "caB",
  "body": "caE",
  "period-wrapper": "cad",
  "back-button": "caI",
  "footer": "cat"
};
