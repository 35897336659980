/* import __COLOCATED_TEMPLATE__ from './ubo.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { countries } from 'qonto/constants/countries';
import { sortByPreferedCountriesWith } from 'qonto/utils/sorters';

export default class SavingsAccountsUbo extends Component {
  @service intl;

  countryOptions = [];

  constructor() {
    super(...arguments);
    this.countryOptions = countries
      .map(code => ({
        key: code,
        value: this.intl.formatCountry(code),
      }))
      .sort(sortByPreferedCountriesWith({ code: 'key', name: 'value' }));
  }

  get selectedCountryOptions() {
    let { selectedCountryOptions } = this.args;

    return Array.isArray(selectedCountryOptions)
      ? selectedCountryOptions.map(country =>
          this.countryOptions.find(({ key }) => country.key === key)
        )
      : [];
  }
}
