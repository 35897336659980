/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class TransferTableComponent extends Component {
  @service segment;

  @action
  handleClick(id) {
    this.args.highlightItem(id);
    this.segment.track('transfer_list_open_side_panel');
  }
}
