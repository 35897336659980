/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';

export default class SavingsAccountsStepsReviewComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @tracked hasTermsConditionsError = false;

  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service segment;

  get interestRate() {
    return this.args.context.rates.find(
      ({ nominalMaturity }) => nominalMaturity === this.args.context.maturity
    ).nominalInterestRate;
  }

  get interestEarned() {
    return Number(this.args.context.interestEarned);
  }

  get totalAmount() {
    return this.interestEarned + Number(this.args.context.amount);
  }

  get contractDuration() {
    return this.args.context.maturity.slice(0, -1);
  }

  get contractUrl() {
    return `${apiBaseURL}/v3/savings_accounts/contract?organization_id=${this.organizationManager.organization.id}&procedure_id=${this.args.context.procedureId}`;
  }

  @action
  toggleHasAgreedTermsConditions() {
    this.args.context.toggleHasAgreedTermsConditions();
    if (this.hasTermsConditionsError) {
      this.hasTermsConditionsError = false;
    }
  }

  handleNextTask = dropTask(async () => {
    this.hasTermsConditionsError = false;
    if (this.args.context.hasAgreedTermsConditions) {
      try {
        let { signSuccessful } = await this.args.context.signContractAndTransferTask.perform(
          this.args.context.amount,
          this.args.context.procedureId,
          this.args.context.sourceOfFunds,
          this.args.context.membershipFiscalResidences
        );
        if (signSuccessful) {
          this.segment.track('savings_summary_cta');
          this.args.transitionToNext();
        }
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    } else {
      this.hasTermsConditionsError = true;
    }
  });
}
