export default {
  "review": "qdP",
  "review-content": "qdl",
  "review-parameters": "qdX",
  "row": "qdC",
  "row__border": "qdk",
  "label": "qdJ",
  "value": "qds",
  "error": "qdF",
  "tooltip-icon": "qdg",
  "terms-link": "qdD"
};
