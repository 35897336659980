/* import __COLOCATED_TEMPLATE__ from './deactivation-btns.hbs'; */
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class SubscriptionDeactivationBtnsComponent extends Component {
  @service intl;
  @service segment;
  @service toastFlashMessages;
  @service organizationManager;
  @service flowLinkManager;
  @service abilities;
  @service modals;

  get organization() {
    return this.organizationManager.organization;
  }

  cancelDeactivationTask = dropTask(async close => {
    this.segment.track('account_closing_cancel_closing_confirmed');
    try {
      await this.organization.cancelDeactivation();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('subscription.account-closing.cancel-closing.closing-cancelled')
      );
      this.args.onDeactivationCanceled?.();
      await close();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  checkDeactivationRequirementsTask = dropTask(async () => {
    try {
      let { valid } = await this.organization.checkDeactivationRequirements();
      return valid;
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return false;
    }
  });

  startDeactivationTask = dropTask(
    waitFor(async () => {
      if (this.abilities.cannot('deactivate organization')) {
        this.modals.open('subscription/close/unauthorized-modal');
        return;
      }

      this.segment.track('account_closing_start_button_clicked');

      let isValid = await this.checkDeactivationRequirementsTask.perform();

      if (isValid) {
        return this.flowLinkManager.transitionTo({
          name: 'subscription-close',
          stepId: 'introduction',
          queryParams: { origin: this.args.origin },
        });
      } else {
        this.modals.open('subscription/close/contact-cs-modal');
        this.segment.track('account_closing_error_modal_displayed');
      }
    })
  );

  showCancelDeactivationModalTask = dropTask(async () => {
    this.segment.track('account_closing_cancel_closing_clicked');
    await this.modals.open('popup/confirmation', {
      title: this.intl.t('subscription.account-closing.cancel-closing.title'),
      description: this.intl.t('subscription.account-closing.cancel-closing.description'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.confirm'),
      confirmTask: this.cancelDeactivationTask,
    });
  });
}
