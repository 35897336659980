export default {
  "wrapper": "cuI",
  "card-header": "cut",
  "subtitle-caption": "cuM",
  "estimated-earnings": "cuP",
  "progress": "cul",
  "progress-bar": "cuX",
  "progress-bar-text": "cuC",
  "subtitle": "cuk",
  "badge": "cuJ",
  "core-information": "cus",
  "secondary-information": "cuF",
  "remuneration-table": "cug",
  "name": "cuD title-3",
  "separator": "cuV",
  "remuneration-line": "cuL",
  "german-disclaimer": "cuT",
  "table": "cuz",
  "table-right": "cua"
};
