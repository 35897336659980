export default {
  "row": "qQV",
  "active": "qQL",
  "dot": "qQT",
  "red": "qQz",
  "green": "qQa",
  "yellow": "qQH",
  "status": "qQO",
  "align-right": "qQm",
  "animated": "qQp",
  "fadein-item": "qQx",
  "cell": "qQw body-2",
  "cell-content": "qQN",
  "amount": "qQv body-1",
  "subtitle": "qQo caption",
  "no-padding": "qQn"
};
