/* import __COLOCATED_TEMPLATE__ from './cta.hbs'; */
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import {
  USER_ACTIONS_CTA_BUTTON_TYPE,
  USER_ACTIONS_CTA_BUTTON_TYPE_MAP,
  USER_ACTIONS_CTA_TYPE,
} from 'qonto/constants/user-actions';

export default class ProductDiscoveryUserActionsCtaComponent extends Component {
  get isLinkType() {
    return this.args.type === USER_ACTIONS_CTA_TYPE.LINK && this.args.href && this.args.text;
  }

  get isLinkToType() {
    return this.args.type === USER_ACTIONS_CTA_TYPE.LINK_TO && this.args.route && this.args.text;
  }

  get isFlowInFlowType() {
    return (
      this.args.type === USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW &&
      this.args.name &&
      this.args.stepId &&
      this.args.text
    );
  }

  get isButtonType() {
    return (
      this.args.type === USER_ACTIONS_CTA_TYPE.BUTTON && this.args.text && this.args.onClickTask
    );
  }

  get buttonClass() {
    return (
      USER_ACTIONS_CTA_BUTTON_TYPE_MAP[this.args.buttonType] ??
      USER_ACTIONS_CTA_BUTTON_TYPE_MAP[USER_ACTIONS_CTA_BUTTON_TYPE.PRIMARY]
    );
  }

  get queryParams() {
    return this.args.query ?? {};
  }

  handleClickTask = dropTask(async e => {
    await this.args.onClickTask?.perform(e);
  });
}
