/* import __COLOCATED_TEMPLATE__ from './cancel.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

import { STATUS } from 'qonto/constants/requests';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';

export default class RequestTransferModalsCancelComponent extends Component {
  @service toastFlashMessages;
  @service intl;
  @service router;
  @service errors;

  get showSupplierInvoicesModal() {
    let { invoice, request } = this.args.data || {};
    return invoice?.status === INVOICE_STATUSES.pending && request?.status === STATUS.PENDING;
  }

  get description() {
    if (!this.showSupplierInvoicesModal) {
      return this.intl.t('requests.modals.cancel-modal.description');
    }

    return this.intl.t('requests.modals.cancel-modal.supplier-invoices.description');
  }

  confirmTask = task({ drop: true }, async () => {
    let { request, isMultiTransfer, invoice } = this.args.data;

    try {
      await request.cancelRequest();

      if (!invoice) {
        let message = this.intl.t('transfers.toasts.info.cancel-request');
        this.toastFlashMessages.toastInfo(message, 'transfer_create');
      }

      if (isMultiTransfer) {
        this.router.transitionTo('requests.pending');
      }

      this.args.close('success');
    } catch (error) {
      if (error.errors?.[0]?.detail?.code === 'request_not_pending') {
        this.args.close();

        this.toastFlashMessages.toastError(
          this.intl.t('supplier-invoices.preview.request.info-toast.same-invoice')
        );

        // Now that we know that request status is outdated, we refresh it for the request and the invoice
        try {
          await Promise.all([this.args.data.request.reload(), this.args.data.invoice.reload()]);
        } catch (error) {
          this.errors.handleError(error);
        }
      } else {
        this.errors.handleError(error);
      }
    }
  });
}
