/* import __COLOCATED_TEMPLATE__ from './supplier-selector.hbs'; */
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

import { Disclaimer } from '@repo/design-system-kit';

import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import { DEBOUNCE_MS } from 'qonto/constants/timers';

export default class SupplierSelector extends Component {
  disclaimerInline = Disclaimer.Inline;

  @tracked defaultOptions;

  @service intl;
  @service store;

  get options() {
    return this.defaultOptions || this.args.fetchSuppliersTask?.lastSuccessful?.value || [];
  }

  get filteredOptions() {
    let options = this.options.filter(({ status }) => status !== SUPPLIER_STATUSES.ARCHIVED);

    if (!options.length) return this.defaultOptions;

    if (
      this.selectedSupplier &&
      this.selectedSupplier === SUPPLIER_STATUSES.UNARCHIVED &&
      !options.some(({ id }) => id === this.selectedSupplier?.id)
    ) {
      return [...options, this.selectedSupplier];
    }

    return options;
  }

  get selectedSupplier() {
    let snapshot = this.args.invoice.supplierSnapshot;
    return { ...snapshot, tinNumber: snapshot?.tin };
  }

  get dropdownSelectedValue() {
    if (this.isArchived) {
      return `${this.selectedSupplier?.name} ${this.intl.t('supplier-invoices.supplier-invoice-details.supplier-information.archived-supplier-label')}`;
    }

    return this.selectedSupplier?.name;
  }

  get isArchived() {
    return this.selectedSupplier?.status === SUPPLIER_STATUSES.ARCHIVED;
  }

  searchSuppliers(query, resolve, reject) {
    return this.args.fetchSuppliersTask
      .perform(query)
      .catch(error => reject(error))
      .then(res => resolve(res));
  }

  @action handleSupplierSearch(query) {
    if (!this.defaultOptions) {
      this.defaultOptions = this.filteredOptions;
    }

    return new RSVP.Promise((resolve, reject) => {
      debounce(this, this.searchSuppliers, query, resolve, reject, DEBOUNCE_MS);
    });
  }

  @action updateSupplier({ id, iban, name, tinNumber, email }) {
    this.args.updateSupplier('supplierSnapshot', { id, name, iban, tin: tinNumber, email });
    this.args.updateSupplier('supplierName', name);
    this.args.invoice.supplierId = id;
    this.args.invoice.supplierSnapshot = {
      id,
      name,
      iban,
      tin: tinNumber,
      email,
    };
  }
}
