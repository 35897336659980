/* import __COLOCATED_TEMPLATE__ from './related-documents.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class RelatedDocumentsComponent extends Component {
  @service intl;
  @service toastFlashMessages;

  get originType() {
    if (this.args.invoice.isCreditNote) return 'credit_note';
    return 'invoice';
  }

  @action amount({ value, currency }) {
    let { isCreditNote } = this.args.invoice || {};

    return this.intl.formatMoney(Number(value), {
      currency,
      signus: isCreditNote ? null : '-',
    });
  }

  @action triggerToast() {
    this.toastFlashMessages.toastSuccess(
      this.intl.t('supplier-invoices.experiment.under-development.toast')
    );
  }
}
