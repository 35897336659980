export default {
  "wrapper": "cNt",
  "per-page-container": "cNM",
  "options": "cNP",
  "option": "cNl",
  "active": "cNX",
  "description": "cNC",
  "description-per-page": "cNk",
  "summary": "cNJ"
};
