/* import __COLOCATED_TEMPLATE__ from './file-preview.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class TransfersInternationalOutModalsFilePreviewComponent extends Component {
  @action
  onRemove(file) {
    this.args.data.onRemove(file);
    this.args.close('confirm');
  }
}
