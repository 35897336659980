/* import __COLOCATED_TEMPLATE__ from './not-sepa-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class TransfersSepaNotSepaModalComponent extends Component {
  @action
  cancel() {
    this.args.data.onCancel();
    this.args.close();
  }

  @action
  confirm() {
    this.args.data.onConfirm();
    this.args.close();
  }
}
