/* import __COLOCATED_TEMPLATE__ from './review-duplicates-modal.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { TrackedSet } from 'tracked-built-ins';

import { apiBaseURL } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class ReviewDuplicatesModal extends Component {
  @service sentry;
  @service networkManager;
  @service intl;
  @service router;
  @service store;
  @service toastFlashMessages;
  @service modals;
  @service segment;

  @tracked showEmptySelectionError = false;
  selected = new TrackedSet([]);

  constructor() {
    super(...arguments);

    this.fetchDuplicatesTask
      .perform()
      .then(() => this.preselectDuplicates())
      .catch(ignoreCancelation);
  }

  get currentDocument() {
    let { invoiceId } = this.args.data;
    return this.store.peekRecord('receivable-invoice', invoiceId);
  }

  get duplicates() {
    return this.fetchDuplicatesTask.lastSuccessful?.value;
  }

  get isFetchError() {
    return this.fetchDuplicatesTask.last?.isError;
  }

  @action
  isSelected(id) {
    return this.selected.has(id);
  }

  @action
  updateSelection(id) {
    let fn = this.selected.has(id) ? 'delete' : 'add';
    this.selected[fn](id);
  }

  preselectDuplicates() {
    this.duplicates.forEach(e => {
      this.selected.add(e.id);
    });
  }

  fetchDuplicatesTask = dropTask(async () => {
    let { invoiceId } = this.args.data;

    let duplicatesIds = await this.store
      .adapterFor('receivable-invoice')
      .fetchDuplicates(invoiceId);

    let duplicates = [];
    await duplicatesIds.forEach(id => {
      duplicates.push(this.store.peekRecord('receivable-invoice', id));
    });
    return duplicates;
  });

  confirmDeleteSelected = dropTask(async () => {
    if (this.selected.size === 0) {
      this.showEmptySelectionError = true;
      next(() => scrollIntoView('[data-has-error]'));
      return;
    }
    this.showEmptySelectionError = false;
    await this.modals.open('popup/destructive', {
      title: this.intl.t('receivable-invoices.modals.review-duplicates.confirm-deletion.title', {
        count: this.selected.size,
      }),
      description: this.intl.t(
        'receivable-invoices.modals.review-duplicates.confirm-deletion.subtitle',
        {
          count: this.selected.size,
        }
      ),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete', { count: this.selected.size }),
      confirmTask: this.deleteDuplicatesTask,
    });
  });

  #trackDeleteEvent() {
    let listOfDeletableIds = [...this.duplicates, this.currentDocument].map(({ id }) => id);

    let remainingDeletableInvoices = listOfDeletableIds.filter(id => !this.selected.has(id)).length;

    let keptInvoices;

    if (remainingDeletableInvoices === 1) {
      keptInvoices = 'one';
    } else if (remainingDeletableInvoices > 1) {
      keptInvoices = 'multiple';
    } else {
      keptInvoices = 'none';
    }

    this.segment.track('invoice_imported_duplicates_deleted', { keptInvoices });
  }

  deleteDuplicatesTask = dropTask(async closeConfirmation => {
    this.#trackDeleteEvent();
    try {
      let ids = Array.from(this.selected).toString();
      await this.networkManager.request(
        `${apiBaseURL}/v4/receivable_invoices/invoices?ids=${ids}`,
        {
          method: 'DELETE',
        }
      );

      let deletedCurrentDocument = this.selected.has(this.currentDocument.id);
      let deletedAllDuplicates =
        !deletedCurrentDocument && this.duplicates.every(({ id }) => this.selected.has(id));

      if (deletedAllDuplicates) {
        let receivableInvoice = this.store.peekRecord(
          'receivable-invoice',
          this.currentDocument.id
        );
        receivableInvoice.hasDuplicates = false;
      }

      if (deletedCurrentDocument) {
        this.router.transitionTo('receivable-invoices.index');
      }

      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.modals.review-duplicates.confirm-deletion.success-toast', {
          count: this.selected.size,
        })
      );
      await closeConfirmation();
      this.args?.close();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      await closeConfirmation();
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (errorInfo.shouldSendToSentry && error.status !== 422) {
        this.sentry.captureException(error);
      }
    }
  });
}
