/* import __COLOCATED_TEMPLATE__ from './kyc-in-app-waiting-document.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

export default class KycInAppWaitingDocumentTopbarComponent extends Component {
  topBanner = TopBanner;

  @service intl;
  @service router;

  get text() {
    return this.intl.t('kyc.banner.fourthline.failed-identity-verification.body', {
      kycFlowLink: this.intl.t('kyc.banner.fourthline.failed-identity-verification.link'),
    });
  }

  get content() {
    return {
      type: 'warning',
    };
  }
}
