export default {
  "row": "qJg",
  "greyed-out": "qJD",
  "supplier-name": "qJV",
  "cell": "qJL",
  "supplier": "qJT",
  "email-address": "qJz",
  "outstanding-balance": "qJa",
  "quick-actions": "qJH",
  "empty": "qJO",
  "supplier-container": "qJm",
  "supplier-avatar": "qJp",
  "grey-background": "qJx"
};
