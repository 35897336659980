/* import __COLOCATED_TEMPLATE__ from './attachment-status-select.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { OPERATION_TYPES } from 'qonto/constants/transactions';

const ATTACHMENT_STATUS = {
  NOT_REQUIRED: 'not_required',
  REQUIRED: 'required',
  LOST: 'lost',
};

export default class AttachmentStatusSelectComponent extends Component {
  @service intl;
  @service abilities;

  @tracked _selectedStatus;

  get selectedStatus() {
    return this._selectedStatus || this.attachmentStatus;
  }

  get isAllAttachmentLost() {
    return this.args.transactions.every(({ attachmentLost }) => attachmentLost);
  }

  get isAllAttachmentRequired() {
    return this.args.transactions.every(
      ({ attachmentRequired, attachmentLost }) => attachmentRequired && !attachmentLost
    );
  }

  get isAllAttachmentNotRequired() {
    return this.args.transactions.every(({ attachmentRequired }) => !attachmentRequired);
  }

  get isAllAttachmentCount() {
    return this.args.transactions.every(({ attachmentCount }) => attachmentCount);
  }

  get isAllFee() {
    return this.args.transactions.every(
      ({ operationType }) => operationType === OPERATION_TYPES.BILLER
    );
  }

  get attachmentStatuses() {
    let commonStatuses = [
      {
        code: ATTACHMENT_STATUS.REQUIRED,
        label: this.intl.t('transactions.sidebar.bulk.attachment-status.option.required'),
        disabled: this.isAllAttachmentRequired && !this.isAllAttachmentLost,
      },
      {
        code: ATTACHMENT_STATUS.LOST,
        label: this.intl.t('transactions.sidebar.bulk.attachment-status.option.lost'),
        disabled: this.isAllAttachmentLost,
      },
    ];

    // If a user without the transaction update attachment required status permission selects only one or more transactions with the not_required status
    // (updated by a role with the permission), we want to prevent them from changing the status again (see isDropdownDisabled)
    // and display the value to them
    if (this.abilities.cannot('update attachment required status transaction')) {
      if (this.isAllAttachmentNotRequired) {
        return [
          {
            code: ATTACHMENT_STATUS.NOT_REQUIRED,
            label: this.intl.t('transactions.sidebar.bulk.attachment-status.option.not-required'),
          },
        ];
      } else {
        return commonStatuses;
      }
    }

    return [
      {
        code: ATTACHMENT_STATUS.NOT_REQUIRED,
        label: this.intl.t('transactions.sidebar.bulk.attachment-status.option.not-required'),
        disabled: this.isAllAttachmentNotRequired,
      },
      ...commonStatuses,
    ];
  }

  get attachmentStatus() {
    let canUpdateAttachmentRequiredStatus = this.abilities.can(
      'update attachment required status transaction'
    );

    if (this.isAllAttachmentCount) {
      return canUpdateAttachmentRequiredStatus
        ? this.attachmentStatuses[1]
        : this.attachmentStatuses[0];
    }
    if (this.isAllAttachmentLost) {
      return canUpdateAttachmentRequiredStatus
        ? this.attachmentStatuses[2]
        : this.attachmentStatuses[1];
    }
    if (this.isAllAttachmentRequired) {
      return canUpdateAttachmentRequiredStatus
        ? this.attachmentStatuses[1]
        : this.attachmentStatuses[0];
    }
    if (this.isAllAttachmentNotRequired) {
      return this.attachmentStatuses[0];
    }
  }

  get isDropdownDisabled() {
    return (
      this.isAllAttachmentCount ||
      this.isAllFee ||
      (this.isAllAttachmentNotRequired &&
        this.abilities.cannot('update attachment required status transaction'))
    );
  }

  @action
  updateStatus(selectedStatus) {
    this._selectedStatus = selectedStatus;

    let lost = this.selectedStatus.code === ATTACHMENT_STATUS.LOST;
    let required = this.selectedStatus.code === ATTACHMENT_STATUS.REQUIRED || lost;

    this.args.onUpdate(lost, required);
  }
}
