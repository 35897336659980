/* import __COLOCATED_TEMPLATE__ from './details-sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import styles from 'qonto/components/supplier-invoices/details-sidebar.module.css';
import {
  EINVOICING_LIFECYCLE_EVENT_DESCRIPTION,
  EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES,
  EINVOICING_LIFECYCLE_EVENT_STATUS_CODES,
  EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES,
  INVOICE_STATUSES,
  SOURCE_TYPES,
} from 'qonto/constants/supplier-invoice';

export default class DetailsSidebarComponent extends Component {
  @service abilities;
  @service toastFlashMessages;
  @service router;
  @service segment;
  @service organizationManager;
  @service intl;
  @service localeManager;

  @reads('organizationManager.organization') organization;

  @tracked showEditForm =
    this.router.currentRoute?.queryParams.edit === 'true' || this.showEinvoicingForm;

  @action
  toggleShowEditForm() {
    this.showEditForm = !this.showEditForm;
    this.router.transitionTo({ queryParams: { edit: this.showEditForm } });
  }

  get showEinvoicingForm() {
    return (
      (this.args.invoice?.frenchEInvoicing || this.args.invoice?.isGermanEInvoice) &&
      this.args.invoice?.status === INVOICE_STATUSES.paid
    );
  }

  get showCTA() {
    return (
      this.abilities.can('update supplierInvoice') &&
      [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay, INVOICE_STATUSES.pending].includes(
        this.args.invoice?.status
      )
    );
  }

  get showSection() {
    return this.showCTA && !this.showEditForm;
  }

  get showRequestValidation() {
    return this.args.request && this.args.invoice.status === INVOICE_STATUSES.pending;
  }

  get showSelfInvoiceSection() {
    let isEInvoice = this.args.invoice?.source === SOURCE_TYPES.E_INVOICING;
    return this.abilities.can('read self-invoice') && !isEInvoice;
  }

  get beneficiaryWarning() {
    return this.args.confirmTransferResult?.beneficiaryWarning;
  }

  get confirmErrors() {
    return this.args.confirmTransferResult?.confirmErrors;
  }

  get confirmWarnings() {
    return this.args.confirmTransferResult?.confirmWarnings;
  }

  get spendLimitsWarning() {
    return this.args.confirmTransferResult?.spendLimitsWarning;
  }

  get spendLimits() {
    return this.args.confirmTransferResult?.spendLimits;
  }

  onApproveRequestTask = dropTask(async request => {
    await this.args.approveRequestTask.perform(request, this.args.invoice);
  });

  onDeclineRequestTask = dropTask(async request => {
    await this.args.declineRequestTask.perform(request, this.args.invoice);
  });

  @action
  onCancel() {
    this.args.onRequestWorkflow?.();
    this.segment.track('supplier-invoices_request-cancel_clicked');

    this.toastFlashMessages.clearMessages();
    this.toastFlashMessages.toastInfo(
      this.intl.t('supplier-invoices.info-toast.transfers.request-cancelled')
    );
  }

  // French E-invoicing
  get frenchEinvoicingEventsToDisplay() {
    let events = this.args.invoice?.einvoicingLifecycleEvents || [];

    return events?.filter(item =>
      EINVOICING_LIFECYCLE_EVENT_STATUS_CODES.includes(item.status_code)
    );
  }

  get showFrenchEinvoicingTimeline() {
    return Boolean(this.frenchEinvoicingEventsToDisplay.length);
  }

  get frenchEinvoicingCurrentStatusCode() {
    return this.frenchEinvoicingEventsToDisplay.at(-1)?.status_code;
  }

  get frenchEinvoicingTimelineTriggerText() {
    let latestEventDescription = EINVOICING_LIFECYCLE_EVENT_DESCRIPTION(
      this.intl,
      this.frenchEinvoicingCurrentStatusCode
    );

    let htmlTemplate = `<div><span class="${styles['timeline-status-label']}">${this.intl.t('supplier-invoices.einvoicing-timeline.title')}</span><span class="ml-8">${latestEventDescription}</span></div>`;

    return htmlSafe(htmlTemplate);
  }

  get frenchEinvoicingTimelineSteps() {
    return this.frenchEinvoicingEventsToDisplay.map((event, index) => {
      let description = EINVOICING_LIFECYCLE_EVENT_DESCRIPTION(this.intl, event.status_code);
      let timeFormat = this.localeManager.locale === 'en' ? 'hh:mm A' : 'HH:mm';
      let caption = dayjs(event.timestamp).format(`MMM DD, YYYY · ${timeFormat}`);
      let reason = event.reason;
      let comment = event.reason_message;
      let showPdpTooltip = description?.includes('PDP');
      let isLastEvent = index === this.frenchEinvoicingEventsToDisplay.length - 1;
      let stepIcon = this.frenchEInvoicingTimelineStepIcon(event.status_code, isLastEvent);

      return {
        icon: { type: stepIcon, filled: isLastEvent },
        description,
        caption,
        additionalInformation:
          (reason || comment) &&
          htmlSafe(`${
            reason
              ? this.intl.t('supplier-invoices.einvoicing-timeline.subtitle.reason', { reason })
              : ''
          }
          ${
            comment
              ? `${reason ? `<br/>` : ''}${this.intl.t('supplier-invoices.einvoicing-timeline.subtitle.comment', { comment })}`
              : ''
          }`),
        tooltipInfoMessage: showPdpTooltip
          ? this.intl.t('receivable-invoices.einvoicing-timeline.pdp-tooltip')
          : null,
        isLastEvent,
      };
    });
  }

  frenchEInvoicingTimelineStepIcon(statusCode, isLastEvent) {
    let isfrenchEInvoicingWarningStatus =
      EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES.includes(statusCode);
    let isfrenchEInvoicingErrorStatus =
      EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES.includes(statusCode);

    if (isfrenchEInvoicingWarningStatus && isLastEvent) {
      return `${statusCode === 214 ? 'default-colour ' : ''}warning-filled`;
    }
    if (isfrenchEInvoicingWarningStatus) {
      return 'warning';
    }
    if (isLastEvent && !isfrenchEInvoicingErrorStatus) {
      return 'success';
    }
    if (isfrenchEInvoicingErrorStatus) {
      return 'reject';
    }

    return 'default';
  }

  @action
  trackTimelineOpened() {
    this.segment.track('invoice-timeline_drawer_opened', {
      source: 'AP',
      latest_status: this.frenchEinvoicingCurrentStatusCode,
    });
  }
}
