/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/requests';

export default class RequestSidebarExpenseReportLayoutComponent extends Component {
  @service abilities;

  get isPastExpenseReport() {
    let { status } = this.args.request;
    let pastStatuses = [STATUS.CANCELED, STATUS.DECLINED, STATUS.APPROVED];

    return pastStatuses.includes(status);
  }

  get showValidations() {
    let { request } = this.args;
    let userId = request.get('initiator.userId');
    let isNotPastExpenseReport = !this.isPastExpenseReport;

    return (
      (this.abilities.can('cancel expense report request', userId) ||
        this.abilities.can('review expense report request')) &&
      isNotPastExpenseReport
    );
  }
}
