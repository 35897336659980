export default {
  "attachment-viewer": "qcm",
  "hide-sidebar": "qcp",
  "sidebar": "qcx",
  "header": "qcw",
  "header-top": "qcN",
  "header-icon": "qcv",
  "header-amount": "qco",
  "infobox": "qcn",
  "vat": "qci",
  "validation": "qcA"
};
