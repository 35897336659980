/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { DEFAULT_SORT_ORDER } from 'qonto/constants/sort';

export default class ReimbursementsTableHeaderCompletedComponent extends Component {
  get sortField() {
    let { sortBy } = this.args;
    return sortBy?.split(':')[0];
  }

  get sortOrder() {
    let { sortBy } = this.args;
    return sortBy ? sortBy.split(':')[1] : DEFAULT_SORT_ORDER;
  }

  @action
  handleSortBy(name) {
    let { handleSortBy } = this.args;
    let sortOrder = DEFAULT_SORT_ORDER;

    if (name === this.sortField) {
      sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    return handleSortBy?.(`${name}:${sortOrder}`);
  }
}
