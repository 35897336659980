/* import __COLOCATED_TEMPLATE__ from './balance.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import buildQuery from 'qonto/utils/statistics';

export default class OverviewStatisticsBalanceComponent extends Component {
  @service store;
  @service organizationManager;
  @service sentry;

  @tracked balances = [];

  get isLoading() {
    return this.queryBalanceTask.isRunning && this.queryBalanceTask.performCount === 1;
  }

  get isUpdating() {
    return this.queryBalanceTask.isRunning && this.queryBalanceTask.performCount > 1;
  }

  get isError() {
    return this.getBalanceTask.last?.isError;
  }

  queryBalanceTask = dropTask(async (aggregations, period, bankAccounts) => {
    try {
      await this.getBalanceTask.linked().perform(aggregations, period, bankAccounts);
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(errorInfo.error);
      }
    }
  });

  getBalanceTask = dropTask(async (aggregations, period, bankAccounts) => {
    let organizationId = this.organizationManager.organization.id;
    let query = buildQuery(organizationId, aggregations, period, undefined, bankAccounts);

    this.balances = await this.store.adapterFor('transaction').getBalance(query);
  });
}
