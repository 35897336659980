/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class TasksTableComponent extends Component {
  @service abilities;
  @service intl;

  get emptyStateSubtitle() {
    if (this.abilities.can('read ebics request')) {
      return this.args.type === 'pending'
        ? this.intl.t('tasks.tabs.to-approve.empty-state.subtitle-micro')
        : this.intl.t('tasks.tabs.completed.empty-state.subtitle-micro');
    } else {
      return this.args.type === 'pending'
        ? this.intl.t('tasks.tabs.to-approve.empty-state.subtitle')
        : this.intl.t('tasks.tabs.completed.empty-state.subtitle');
    }
  }
}
