/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
import Component from '@glimmer/component';

export default class MultiTransferDetailStatus extends Component {
  get membershipName() {
    let { initiator, status, approver } = this.args.request.getProperties(
      'initiator',
      'approver',
      'status'
    );
    let member = status === 'canceled' ? initiator : approver;
    return `${member?.get('firstName')?.substr(0, 1)}. ${member?.get('lastName')}`;
  }
}
