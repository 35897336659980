/* import __COLOCATED_TEMPLATE__ from './reset-password-form.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer, Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { alias } from 'macro-decorators';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class ResetPasswordFormComponent extends Component {
  disclaimer = Disclaimer.Block;

  spinner = Spinner;

  @service errors;
  @service toastFlashMessages;
  @service sentry;

  @tracked done = false;

  @alias('args.userPassword.hasPassword') hasPassword;

  signInTask = dropTask(async userPassword => {
    await this.args.signInTask.linked().perform(userPassword);
  });

  resetPasswordTask = dropTask(async userPassword => {
    try {
      await this.args.resetPasswordTask.linked().perform(userPassword);
      this.done = true;
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });
}
