/* import __COLOCATED_TEMPLATE__ from './global-discount.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { DISCOUNT_TYPE } from 'qonto/constants/receivable-invoice';

const PERCENT_SYMBOL = '%';

export default class ReceivableInvoicesFormGlobalDiscountComponent extends Component {
  @reads('args.document.discount') discount;

  @tracked discountValue = this.initializeDiscountValue();
  @tracked discountType = this.discount?.type ?? DISCOUNT_TYPE.ABSOLUTE;

  get discountTypeOptionsArray() {
    return [
      { value: DISCOUNT_TYPE.ABSOLUTE, label: this.args.currency },
      { value: DISCOUNT_TYPE.PERCENTAGE, label: PERCENT_SYMBOL },
    ];
  }

  get discountTypeOptions() {
    return this.discountTypeOptionsArray.map(({ label }) => label);
  }

  get selectedDiscountOption() {
    return this.discountType === DISCOUNT_TYPE.ABSOLUTE ? this.args.currency : PERCENT_SYMBOL;
  }

  initializeDiscountValue() {
    if (!this.discount) return null;
    let value = parseFloat(this.discount.value);
    return this.discount.type === DISCOUNT_TYPE.PERCENTAGE
      ? this.formatPercentageValue(value)
      : value.toFixed(2);
  }

  formatPercentageValue(value) {
    let percentage = value * 100;
    return Number.isInteger(percentage) ? String(percentage) : percentage.toFixed(2);
  }

  formatDiscountValue() {
    if (this.discountType === DISCOUNT_TYPE.ABSOLUTE) {
      this.discountValue = parseFloat(this.discountValue).toFixed(2);
    } else {
      let percentage = parseFloat(this.discountValue);
      this.discountValue = Number.isInteger(percentage)
        ? String(percentage)
        : percentage.toFixed(2);
    }
  }

  calculatePercentageValue(value) {
    if (!value || isNaN(value)) return null;
    let decimalValue = Math.abs(parseFloat(value)) / 100;
    return decimalValue.toFixed(4);
  }

  updateDiscountObject() {
    if (!this.discountValue || isNaN(this.discountValue)) {
      this.args.document.discount = null;
      return;
    }

    let value =
      this.discountType === DISCOUNT_TYPE.ABSOLUTE
        ? String(this.discountValue)
        : this.calculatePercentageValue(this.discountValue);

    this.args.document.discount = { type: this.discountType, value };
  }

  @action
  handleDiscountOptionChange(optionLabel) {
    let option = this.discountTypeOptionsArray.find(({ label }) => label === optionLabel);
    if (!option) return;

    this.discountType = option.value;
    if (this.discountValue !== null) {
      this.formatDiscountValue();
      this.updateDiscountObject();
    }
  }

  @action
  updateDiscountValue(value) {
    let parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      this.args.document.discount = null;
      return;
    }

    this.discountValue = Math.abs(parsedValue);
    this.updateDiscountObject();
  }

  @action
  setDiscountOnFocusOut() {
    if (!this.discountValue) return;

    this.formatDiscountValue();
    this.updateDiscountObject();
  }
}
