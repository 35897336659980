/* import __COLOCATED_TEMPLATE__ from './document-collection-banner.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

import { ORGA_STATUS, ORGA_SUSPENDED_REASON } from 'qonto/constants/organization';

export default class DocumentCollectionBanner extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;

  get isDocumentCollectionCreated() {
    return this.args.documentCollectionProcess.status === 'created';
  }

  get isDocumentCollectionPendingUpload() {
    return this.args.documentCollectionProcess.status === 'pending_upload';
  }

  get isDocumentCollectionPendingReview() {
    return this.args.documentCollectionProcess.status === 'pending_review';
  }

  get documentCollectionPendingReviewSubtitle() {
    let { organization } = this.args;

    if (organization.hasRestrictSensitiveActionsFeature && organization.isItalian) {
      if (
        organization.status === ORGA_STATUS.SUSPENDED &&
        organization.suspensionReason === ORGA_SUSPENDED_REASON.EXPIRED_DUE_DILIGENCE
      ) {
        return this.intl.t(
          'organizations.profile.company_profile.manual-validation.subtitle-suspended'
        );
      } else {
        return this.intl.t('organizations.profile.company_profile.manual-validation.subtitle-v2');
      }
    } else {
      return this.intl.t('organizations.profile.company_profile.manual-validation.subtitle');
    }
  }

  get documentCollectionPendingReviewBannerIllustrationSrc() {
    let { organization } = this.args;

    if (organization.hasRestrictSensitiveActionsFeature && organization.isItalian) {
      return '/illustrations/settings/review_processing.svg';
    } else {
      return '/illustrations/settings/confirmed.svg';
    }
  }

  get bannerState() {
    let { hasChanges, status } = this.args.updateProcess;

    let commonTexts = {
      header: this.intl.t('periodic-review.document-upload.banner-manual-confirm.header'),
      subtitle: this.intl.t('periodic-review.document-upload.banner-manual-confirm.subtitle'),
      'task-2': this.intl.t('periodic-review.document-upload.banner-manual-confirm.task-2'),
    };

    // Visibility conditions:
    // 1. Document collection is `pending_upload`, user has not made changes so that status can be `created`;
    // 2. Document collection is `pending_upload`, there have been changes, but BE has already changed
    //    the status to `pending_review` or `accepted`.
    if (this.isDocumentCollectionPendingUpload && (!hasChanges || status !== 'created')) {
      if (status === 'pending_review') {
        // Upload documents banner with the first tick ticked:
        return {
          ...commonTexts,
          'task-1': this.intl.t(
            'periodic-review.document-upload.banner-manual-confirm.task-1-complete'
          ),
          isTask1Completed: true,
          cta: this.intl.t('periodic-review.document-upload.banner-manual-confirm.documents-cta'),
          action: this.args.onDocumentCollectionUpload,
          bannerIllustrationSrc: '/illustrations/company-profile/upload-document.svg',
        };
      }

      // Upload documents banner:
      return {
        header: this.intl.t('periodic-review.document-upload.banner-automatic-confirm.title'),
        subtitle: this.intl.t('periodic-review.document-upload.banner-automatic-confirm.subtitle'),
        cta: this.intl.t('periodic-review.document-upload.banner-automatic-confirm.cta'),
        action: this.args.onDocumentCollectionUpload,
        bannerIllustrationSrc: '/illustrations/company-profile/upload-document.svg',
      };
    }

    if (status === 'pending_review' && this.isDocumentCollectionPendingReview) {
      // Reviewing information and document collection banner:
      return {
        header: this.intl.t('organizations.profile.company_profile.manual-validation.title'),
        subtitle: this.documentCollectionPendingReviewSubtitle,
        bannerIllustrationSrc: this.documentCollectionPendingReviewBannerIllustrationSrc,
      };
    }

    // Visibility conditions:
    // 1. KYB/C might start in `created` status, but after the `.save()` status is changed to `pending_review`.
    //    So, while we wait for the `.save()` to finish, we should show the same initial banner;
    // 2. This is a fallback in case the document collection process is in `pending_upload`,
    //    but the update process `hasChanges` and the status is `created`.
    if (
      (status === 'created' || status === 'pending_review') &&
      (this.isDocumentCollectionCreated || this.isDocumentCollectionPendingUpload)
    ) {
      // Confirm information banner:
      return {
        ...commonTexts,
        'task-1': this.intl.t('periodic-review.document-upload.banner-manual-confirm.task-1'),
        cta: this.intl.t('periodic-review.document-upload.banner-manual-confirm.data-cta'),
        submitTask: this.submitDocumentCollectionTask,
        bannerIllustrationSrc: '/illustrations/settings/updates-required.svg',
      };
    }

    return {};
  }

  get disclaimerText() {
    let { legallyRequired } = this.args.updateProcess;

    if (legallyRequired) {
      return this.intl.t('organizations.profile.company-profile.banner-confirm.error-message');
    }

    return this.intl.t('organizations.profile.company-profile.banner-review.error-message');
  }

  submitDocumentCollectionTask = task(async () => {
    await this.args.submitDocumentCollectionTask.unlinked().perform();
  });
}
