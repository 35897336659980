export default {
  "container": "che",
  "main": "chB",
  "preview": "chE",
  "form": "chd",
  "scrollable": "chI",
  "header": "cht title-1",
  "form-sections": "chM",
  "form-section": "chP",
  "payment-methods": "chl",
  "footer": "chX",
  "preview-header": "chC"
};
