/* import __COLOCATED_TEMPLATE__ from './uploader.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { Disclaimer } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

export default class TransfersSepaPayByInvoiceUploaderComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service organizationManager;
  @service intl;
  @service payByInvoiceUploadManager;
  @reads('payByInvoiceUploadManager.files') files;

  formattedMaxSize = formatFileSize(this.intl, this.payByInvoiceUploadManager.maxSize);

  constructor() {
    super(...arguments);

    this.payByInvoiceUploadManager.registerCallback({
      onUploadFinished: this.onUploadFinish,
      onUploadErrors: this.onUploadError,
    });

    this.firstRenderCheck();
  }

  get maybeInvoice() {
    return this.args.invoice || this.files[0] || null;
  }

  get invoiceId() {
    return this.maybeInvoice?.id || this.maybeInvoice?.invoiceId || null;
  }

  get dropzoneDescription() {
    return variation('feature--invoices-e-invoicing-germany') &&
      this.organizationManager.organization.legalCountry === 'DE'
      ? this.intl.t('labels.upload-message-with-xml', { maxSize: this.formattedMaxSize })
      : this.intl.t('transfers.sepa.invoice-uploader.description', {
          maxSize: this.formattedMaxSize,
        });
  }

  @action
  firstRenderCheck() {
    this.onUploadFinish();
    this.onUploadError();
  }

  @action
  onUploadFinish() {
    if (this.maybeInvoice?.attachment) {
      this.args.onInvoiceUploaded(this.invoiceId);
    }
  }

  @action
  onUploadError() {
    if (this.maybeInvoice?.hasErrors) {
      this.args.onUploadError(this.maybeInvoice.errors);
    }
  }

  @action
  onCancel() {
    if (this.invoiceId) {
      this.args.onRemoveFile(this.invoiceId);
    }
    this.payByInvoiceUploadManager.resetState();
  }
}
