/* import __COLOCATED_TEMPLATE__ from './validation.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class ReimbursementsRequestsSidebarExpenseReportValidation extends Component {
  @service intl;
  @service segment;
  @service toastFlashMessages;
  @service modals;

  close() {
    this.args.close?.();
  }

  cancelRequestModalTask = dropTask(async () => {
    this.segment.track('request_cancel_clicked', {
      request_type: 'expense_report',
      origin: 'reimbursements',
      method: 'sidepanel',
    });
    await this.modals.open('popup/destructive', {
      title: this.intl.t('requests.modals.cancel-modal.title'),
      description: this.intl.t('requests.modals.cancel-modal.description'),
      cancel: this.intl.t('btn.close'),
      confirm: this.intl.t('btn.cancel'),
      confirmTask: this.cancelRequestTask,
    });
  });

  cancelRequestTask = dropTask(async close => {
    await this.args.request.cancelRequest();
    this.segment.track('reimbursement_request_cancelled');
    this.toastFlashMessages.toastInfo(this.intl.t('requests.cards.toasts.request-cancelled'));
    close();
  });
}
