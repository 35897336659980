export default {
  "modal-wrapper": "chr",
  "spinner-container": "chb",
  "spinner": "chy",
  "container": "chU",
  "tabs-item": "chj",
  "form-container": "chW",
  "fieldset": "chu",
  "preview-container": "cfS",
  "switch-container": "cfc",
  "pdf-preview-container": "cfq",
  "form-content": "cfZ",
  "row": "cfR",
  "numbering-format": "cfQ",
  "numbering-fields": "cfe",
  "input-settings": "cfB",
  "next-invoice-number": "cfE",
  "spacing": "cfd",
  "label-tooltip": "cfI",
  "tooltip-icon": "cft",
  "customize-field": "cfM",
  "button-container-footer": "cfP"
};
