export default {
  "navigation-dropdown": "cDj",
  "expanded": "cDW",
  "trigger": "cDu",
  "trigger-title-icon": "cVS",
  "collapsed": "cVc",
  "responsive": "cVq",
  "logo": "cVZ",
  "active": "cVR",
  "trigger-title": "cVQ",
  "content": "cVe",
  "content-container": "cVB",
  "dropdown-icon": "cVE",
  "link": "cVd"
};
