export default {
  "header": "qcb",
  "header-type": "qcy",
  "close": "qcU",
  "close-icon": "qcj",
  "header-infos": "qcW",
  "title": "qcu body-1",
  "declined": "qqS",
  "canceled": "qqc",
  "approved": "qqq",
  "pending": "qqZ",
  "date": "qqR body-2",
  "container": "qqQ",
  "picto": "qqe",
  "picto-status": "qqB",
  "general": "qqE",
  "amount": "qqd",
  "counterparty": "qqI body-2",
  "initiator": "qqt",
  "initiator-avatar": "qqM",
  "avatar-image": "qqP",
  "initiator-id": "qql",
  "name": "qqX body-2",
  "role": "qqC caption-bold",
  "infobox": "qqk",
  "account-infobox": "qqJ qqk",
  "beneficiary-warning": "qqs caption-bold",
  "dates-wrapper": "qqF",
  "dates": "qqg",
  "recurrence": "qqD caption-bold",
  "arrow": "qqV",
  "notify-checkbox": "qqL"
};
