/* import __COLOCATED_TEMPLATE__ from './upsell-card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class BulkTransferUpsellCard extends Component {
  badge = Badge;

  @service intl;
  @service flowLinkManager;
  @service segment;

  TRACKING_TRIAL_STATE = TRACKING_TRIAL_STATE;

  get badgeType() {
    return this.args.trialInfo ? 'tryForFree' : 'upgrade';
  }

  get badgeText() {
    return this.args.trialInfo
      ? this.intl.t('upsell.discover.trial.badge')
      : this.intl.t('upsell.discover.badge');
  }

  @action
  handleUpsell() {
    if (this.args.trialInfo) {
      this.args.onTrialClick();
    } else {
      this.segment.track('upsell_bulk-transfer_clicked', {
        trial_state: this.TRACKING_TRIAL_STATE.NONE,
      });
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
      });
    }
  }
}
