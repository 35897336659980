/* import __COLOCATED_TEMPLATE__ from './navigation-dropdown.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';
import { NavigationDropdownFooter } from 'qonto/react/components/navigation-dropdown/footer';

export default class NavigationDropdownComponent extends Component {
  @service organizationManager;
  @service intl;

  @tracked dropdownOpen = false;

  Footer = NavigationDropdownFooter;

  @reads('organizationManager.organization') currentOrganization;

  @action
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  get shouldShowOrganizationName() {
    return this.args.state === DUAL_NAV_STATES.RESPONSIVE;
  }

  get toggleOrganizationAriaLabel() {
    return this.intl.t('dual-nav.aria-labels.toggle-organization', {
      organizationName: this.currentOrganization.name,
    });
  }
}
