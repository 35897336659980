export default {
  "wrapper": "cHw",
  "header": "cHN",
  "fadeIn": "cHv",
  "header-content": "cHo",
  "with-cta": "cHn",
  "header-title": "cHi title-4",
  "advanced-filters-trigger": "cHA",
  "filters-button": "cHY",
  "filters-button-upsell": "cHh",
  "filters-upsell-item": "cHf",
  "filters-badge": "cHK",
  "chart-container": "cHG",
  "chart-legend-container": "cHr mb-24",
  "chart-watermark-container": "cHb",
  "chart-watermark": "cHy",
  "placeholder-title": "cHU",
  "placeholder-subtitle": "cHj",
  "advanced-filters": "cHW"
};
