/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReviewDuplicatesModalItem extends Component {
  checkbox = Checkbox;

  @service intl;
  @service store;
  @service toastFlashMessages;
  @service sentry;

  openAttachmentInNewWindow = dropTask(async () => {
    try {
      let attachment = await this.store.findRecord('attachment', this.args.duplicate.attachment_id);
      window.open(attachment.file.fileUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get amountLabel() {
    return this.args.duplicate.status === INVOICE_STATUSES.toReview
      ? this.intl.t('supplier-invoices.modals.review-duplicates.item.invoice-amount')
      : this.intl.t('supplier-invoices.modals.review-duplicates.item.payment-amount');
  }

  get dateLabel() {
    let DATE_LABELS = {
      [INVOICE_STATUSES.toReview]: this.intl.t(
        'supplier-invoices.modals.review-duplicates.item.import-date'
      ),
      [INVOICE_STATUSES.pending]: this.intl.t(
        'supplier-invoices.modals.review-duplicates.item.due-date'
      ),
      [INVOICE_STATUSES.scheduled]: this.intl.t(
        'supplier-invoices.modals.review-duplicates.item.operation-date'
      ),
      [INVOICE_STATUSES.paid]: this.intl.t(
        'supplier-invoices.modals.review-duplicates.item.payment-date'
      ),
    };

    return DATE_LABELS[this.args.duplicate.status];
  }
}
