/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

export default class RequestTransferForm extends Component {
  @service toastFlashMessages;
  @service intl;
  @service segment;

  @tracked enableTransferValidations = false;

  get hasUploadTaskRunning() {
    return this.args.requestTransfer.attachmentsFiles.some(file => file.task?.isRunning);
  }

  submitTransferTask = dropTask(
    waitFor(async requestTransfer => {
      this.enableTransferValidations = false; // Hide errors
      // eslint-disable-next-line ember/no-array-prototype-extensions
      this.args.requestTransfer.errors.clear(); // Clearing previous BE apiAction errors
      requestTransfer.beneficiaryEmail = requestTransfer.email;

      let { validations: transferValidation } = await requestTransfer.validate();

      let isEndDateInvalid = false;
      if (
        (requestTransfer.standingEndingDate &&
          dayjs(requestTransfer.standingEndingDate).isBefore(
            dayjs(requestTransfer.scheduledDate)
          )) ||
        dayjs(requestTransfer.standingEndingDate).isSame(dayjs(requestTransfer.scheduledDate))
      ) {
        isEndDateInvalid = true;
      }

      this.enableTransferValidations = true; // Show errors

      if (transferValidation.isValid && !isEndDateInvalid) {
        let doNotUpdateEmail = !requestTransfer.notifyByEmail;
        if (doNotUpdateEmail) {
          requestTransfer.beneficiaryEmail = requestTransfer.originalBeneficiaryEmail;
        }

        requestTransfer.beneficiaryId = requestTransfer.get('beneficiary.id');
        requestTransfer.beneficiaryIban = requestTransfer.iban;
        requestTransfer.beneficiaryName = requestTransfer.name;
        requestTransfer.beneficiaryBic = requestTransfer.bic;

        this.segment.track('request_note_filled', {
          request_type: requestTransfer.requestType,
        });

        if (requestTransfer.beneficiaryEmail) {
          this.segment.track('single_transfer_request_beneficiary_notification');
        }

        try {
          await this.args.saveTransfer(requestTransfer);
        } catch {
          // don't display toast for validation errors
          if (requestTransfer.isValid) {
            let message = this.intl.t('toasts.errors.server_error');
            this.toastFlashMessages.toastError(message);
          }
        }
      }
    })
  );
}
