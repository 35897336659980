export default {
  "section": "cYL",
  "header-section": "cYT",
  "header-title": "cYz",
  "header-item": "cYa",
  "item": "cYH",
  "center-align": "cYO",
  "content-gap": "cYm",
  "cta-footer": "cYp",
  "section-content": "cYx"
};
