/* import __COLOCATED_TEMPLATE__ from './payment-limit-bar.hbs'; */
import Component from '@glimmer/component';

import { METER_AMOUNTS_THRESHOLDS } from 'qonto/constants/budget';
import { Meter } from 'qonto/react/components/meter';

export default class PaymentLimitBar extends Component {
  amountsThresholds = METER_AMOUNTS_THRESHOLDS;
  meter = Meter;

  get meterHeight() {
    if (this.args.layout === 'list') {
      return 4;
    }

    return 6;
  }
}
