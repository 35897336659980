export default {
  "wrapper": "cGd",
  "tabs": "cGI",
  "tabs-nav": "cGt",
  "tabs-panel-wrapper": "cGM",
  "list-head": "cGP",
  "list": "cGl",
  "footer": "cGX",
  "reward-amount": "cGC",
  "earned": "cGk",
  "pending": "cGJ",
  "empty-state-wrapper": "cGs",
  "empty-state": "cGF",
  "illustration": "cGg",
  "error-wrapper": "cGD",
  "error-illustration": "cGV",
  "error-description": "cGL",
  "spinner": "cGT",
  "total-earned": "cGz"
};
