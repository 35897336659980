/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge } from '@repo/design-system-kit';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class OverviewWidgetsLayoutComponent extends Component {
  badgeHighlight = Badge;

  @service abilities;
  @service flowLinkManager;
  @service modals;
  @service segment;
  @service subscriptionManager;

  elementId = guidFor(this);

  @tracked disabledOperatorProperties = [
    'activity_tag',
    'operation_method',
    'attachment_status',
    'vat_completeness',
  ];
  @tracked excludedProperties = [
    'note',
    'status',
    'qualified_for_accounting',
    'emitted_at',
    'updated_at',
    'settled_at',
    ...(this.abilities.can('assign category') ? ['category_assignment_category_id'] : []),
  ];

  get formData() {
    return this.args.advancedFilters
      ? { id: this.elementId, query: { filter_group: this.args.advancedFilters } }
      : undefined;
  }

  get showFiltersButton() {
    return (
      this.args.onAdvancedFilters &&
      !this.args.isAdvancedFiltersMode &&
      this.abilities.can('access or update overview')
    );
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('advanced_dashboard');
  }

  trackEvent(free_trial) {
    this.segment.track('upsell_dashboard_clicked', {
      widget_type: this.args.trackingWidgetType,
      upsell_type: 'advanced_filters',
      trial_state: free_trial ? TRACKING_TRIAL_STATE.FREE_TRIAL : TRACKING_TRIAL_STATE.NONE,
    });
  }

  @action onTrialClick() {
    this.modals.open('discover/trial/confirm', {
      isFullScreenModal: true,
      trialInfo: this.trialInfo,
    });
    this.trackEvent(true);
  }

  @action
  openUpsellModal() {
    this.flowLinkManager.transitionTo({
      name: 'subscription-change',
      stepId: 'discover-overview',
      queryParams: { upsellTrigger: 'advancedDashboard' },
    });
    this.trackEvent(false);
  }
}
