/* import __COLOCATED_TEMPLATE__ from './donut.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

export default class OverviewChartTooltipDonutComponent extends Component {
  @service intl;

  get localClass() {
    let { data } = this.args;

    if (data.name === 'other') {
      return 'slice-other';
    } else if (data.name === 'uncategorized') {
      return 'slice-uncategorized';
    } else {
      return `slice-${data.index}`;
    }
  }

  get dataLabel() {
    let { data, groupByType } = this.args;

    if (data.name === 'other') {
      return this.intl.t('overview.other');
    } else if (data.name === 'uncategorized') {
      if (groupByType.key === 'initiator_id') {
        return this.intl.t('overview.uncategorized.team-member');
      } else if (groupByType.key === 'team_id') {
        return this.intl.t('overview.uncategorized.team');
      } else if (groupByType.key === 'label_lists') {
        return this.intl.t('overview.uncategorized.custom-label', {
          customLabelCategory: groupByType.label,
        });
      }
    } else {
      return data.name;
    }
  }

  get dataDetailLabels() {
    let { data, groupByType } = this.args;

    return data.details.map(({ name }) => {
      if (name === 'uncategorized') {
        if (groupByType.key === 'initiator_id') {
          return this.intl.t('overview.uncategorized.team-member');
        } else if (groupByType.key === 'team_id') {
          return this.intl.t('overview.uncategorized.team');
        } else if (groupByType.key === 'label_lists') {
          return this.intl.t('overview.uncategorized.custom-label', {
            customLabelCategory: groupByType.label,
          });
        }
      }
      return name;
    });
  }

  get flowVariation() {
    let { variation } = this.args.data;

    return variation
      ? this.intl.formatNumber(variation, {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
          signDisplay: 'always',
        })
      : '--';
  }

  get flowVariationDisplayClass() {
    let { variation } = this.args.data;

    if (variation) {
      let isPositive;
      isPositive = this.args.side === 'credit' ? variation > 0 : variation < 0;

      return isPositive ? 'positive' : 'negative';
    }
  }

  get previousDateRange() {
    let { previousBounds } = this.args;

    if (!previousBounds) {
      return '-';
    }

    let startDate = dateToken({
      date: previousBounds.min,
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });

    let endDate = dateToken({
      date: previousBounds.max,
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });

    return `${startDate} - ${endDate}`;
  }
}
