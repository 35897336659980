/* import __COLOCATED_TEMPLATE__ from './smart-picture.hbs'; */
import Component from '@glimmer/component';

/**
 * Creates a Picture component with default Fallback to PNG format
 */
export default class SmartPictureComponent extends Component {
  get fallback() {
    return this.args.fallback || this.defaultFallback;
  }

  // By default, we serve the PNG with the same name-location
  get defaultFallback() {
    return this.args.src.replace(/\.[^/.]+$/, '').concat('.png');
  }
}
