/* import __COLOCATED_TEMPLATE__ from './pdf-preview.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DOCUMENT_TYPE } from 'qonto/constants/self-billing';

export default class SelfBillingPdfPreviewComponent extends Component {
  @service intl;
  @service localeManager;

  get displayLanguage() {
    let { invoice } = this.args;

    return invoice.isNew ? this.localeManager.locale : invoice.locale;
  }

  get documentTypeNote() {
    let documentTypes = {
      [DOCUMENT_TYPE.TD17]: this.intl.t('self-billing.pdf-preview.document-type.td17', {
        language: this.displayLanguage,
      }),
      [DOCUMENT_TYPE.TD18]: this.intl.t('self-billing.pdf-preview.document-type.td18', {
        language: this.displayLanguage,
      }),
      [DOCUMENT_TYPE.TD19]: this.intl.t('self-billing.pdf-preview.document-type.td19', {
        language: this.displayLanguage,
      }),
    };
    return documentTypes[this.args.invoice?.documentType];
  }
}
