export default {
  "header-cell": "qQX",
  "header-content": "qQC caption-bold",
  "active": "qQk",
  "empty": "qQJ",
  "request-type": "qQs",
  "status": "qQF",
  "note": "qQg",
  "amount": "qQD"
};
