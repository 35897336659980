export default {
  "item": "chv",
  "details": "cho",
  "flex": "chn",
  "header": "chi chn",
  "flex-no-gap": "chA chn",
  "file-name": "chY",
  "label": "chh body-2",
  "self-icon": "chf",
  "with-separator": "chK",
  "dimmed": "chG"
};
