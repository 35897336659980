export default {
  "wrapper": "qBq",
  "beneficiary": "qBZ",
  "name": "qBR body-1",
  "beneficiary-email": "qBQ body-2",
  "dropdown-icon": "qBe",
  "trusted": "qBB",
  "account-number": "qBE flex body-2",
  "account-icon": "qBd",
  "m-chip": "qBI",
  "dropdown": "qBt body-2",
  "delete-option": "qBM",
  "beneficiary-name": "qBP"
};
