/* import __COLOCATED_TEMPLATE__ from './q-file-uploader.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEqual } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import fade from 'ember-animated/transitions/fade';
import { UploadFile } from 'ember-file-upload';

const DEFAULT_EXTENSIONS = 'pdf,jpg,jpeg,png';
const DEFAULT_FILES_LIMIT = 5;
const DEFAULT_MAX_SIZE = 15 * 1e6;

export default class QFileUploaderComponent extends Component {
  @tracked isExpanded = false;
  @service segment;
  transition = fade;

  @action
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.segment.track('transaction-details_show-more_clicked');
    }
  }

  get sortedFiles() {
    return (this.args.localFiles || []).sort((a, b) => {
      if (a.isProcessing || a instanceof UploadFile) return -1;
      if (b.isProcessing || b instanceof UploadFile) return 1;

      return b.createdAt - a.createdAt;
    });
  }

  get slicedFilesCollapsed() {
    return this.sortedFiles.slice(0, 3);
  }

  get slicedFilesExpanded() {
    return this.sortedFiles.slice(3);
  }

  get multipart() {
    return this.args.multipart ?? false;
  }

  get extensions() {
    return this.args.extensions ?? DEFAULT_EXTENSIONS;
  }

  get filesLimit() {
    return this.args.filesLimit ?? DEFAULT_FILES_LIMIT;
  }

  get maxSize() {
    return this.args.maxSize ?? DEFAULT_MAX_SIZE;
  }

  get minSize() {
    return this.args.minSize ?? 0;
  }

  get bigLabel() {
    return this.args.bigLabel ?? false;
  }

  get colors() {
    return this.args.colors ?? true;
  }

  get disabled() {
    return this.args.disabled ?? false;
  }

  get fixedSize() {
    return this.args.fixedSize ?? false;
  }

  get multiple() {
    return this.args.multiple ?? true;
  }

  get required() {
    return this.args.required ?? false;
  }

  get requiredLocalFiles() {
    return this.args.requiredLocalFiles ?? [];
  }

  get dropzoneComponent() {
    return this.multipart ? 'x-dropzone-multipart' : 'x-dropzone';
  }

  get fileComponent() {
    return this.multipart ? 'q-file-multipart' : 'q-file';
  }

  get deletable() {
    return this.args.deletable ?? true;
  }

  @action
  isFileDeletable(file) {
    if (file.deletable === false) {
      return false;
    }
    return this.deletable;
  }

  @action
  handleCancel(file) {
    this.args.cancelLocalFile?.(file);
  }

  @action
  isLocalFileRequired(file) {
    return (
      Boolean(this.args.requiredAttachmentDisclaimer) ||
      Boolean(this.requiredLocalFiles.find(requiredFile => isEqual(requiredFile, file)))
    );
  }
}
