/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ReceivableInvoicesTableComponent extends Component {
  @service router;
  @service intl;

  @action handleShowItem(document) {
    if (this.args.isQuote) {
      this.router.transitionTo('quotes.show', document.id);
      return;
    }
    this.router.transitionTo('receivable-invoices.show', document.id, {
      queryParams: document?.imported ? { imported: document.imported } : {},
    });
  }

  @action handleItemKeydown(document, event) {
    if (event.key === 'Enter') {
      this.handleShowItem(document);
    }
  }

  get noResultsError() {
    if (this.args.isQuote) {
      return {
        title: this.intl.t(
          'receivable-invoices.quotes-list.tabs.completed.errors.no-results.empty-state.title'
        ),
        body: this.intl.t(
          'receivable-invoices.quotes-list.tabs.completed.errors.no-results.empty-state.body'
        ),
      };
    }

    return {
      title: this.intl.t(
        'receivable-invoices.invoices-list.tabs.completed.errors.no-results.empty-state.title'
      ),
      body: this.intl.t(
        'receivable-invoices.invoices-list.tabs.completed.errors.no-results.empty-state.body'
      ),
    };
  }
}
