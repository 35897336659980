/* import __COLOCATED_TEMPLATE__ from './select-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import { Flag } from '@repo/design-system-kit';

import { TRIGGER_SELECT_COMPONENTS } from 'qonto/constants/dynamic-form';
import isFunction from 'qonto/utils/is-function';

export const MINIMUM_OPTIONS_THRESHOLD_FOR_SEARCH = 5;

export default class TransfersInternationalOutDynamicFormSelectFieldComponent extends Component {
  flag = Flag;

  get hasFlagIcon() {
    return this.args.field.triggerComponent === TRIGGER_SELECT_COMPONENTS.COUNTRY;
  }

  get isSearchEnabled() {
    return this.options.length > MINIMUM_OPTIONS_THRESHOLD_FOR_SEARCH;
  }

  @cached
  get options() {
    return (
      this.args.field.valuesAllowed?.map(({ key, name, ...rest }) => ({
        ...rest,
        key,
        name,
        searchableKeywords: [key, name],
      })) || []
    );
  }

  get selectedOption() {
    return this.options.find(option => option.key === this.args.value);
  }

  get shouldRefreshOnChange() {
    let { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  onSelect(selectedOption) {
    let { field, onRefresh, onUpdate } = this.args;

    onUpdate(field.key, selectedOption.key);

    if (this.shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }
}
