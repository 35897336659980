/* import __COLOCATED_TEMPLATE__ from './transfer.hbs'; */
import Component from '@glimmer/component';

export default class TaskTableCellTransferComponent extends Component {
  get icon() {
    let { request } = this.args;

    if (request.once) {
      return 'feature_transfer_direct-m';
    } else if (request.standing) {
      return 'feature_transfer_recurring-m';
    } else {
      return 'feature_transfer_scheduled-m';
    }
  }
}
