/* import __COLOCATED_TEMPLATE__ from './timeline.hbs'; */
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

import { STATUS, STEPS } from 'qonto/constants/international-out/lifecycle';
import { Event } from 'qonto/react/components/transfers/international-out/timeline/event';

const UNKNOWN_EVENT = {
  name: 'unknown',
  status: STATUS.AWAITING,
  date: null,
};

export const DEFAULT_LOADING_OPTIONS = {
  stepsCount: 4,
  linesCount: 2,
};

export default class TransfersInternationalOutTimelineComponent extends Component {
  eventComponent = Event;

  placeholderLine = SkeletonLoader.Line;

  loadingOptions = DEFAULT_LOADING_OPTIONS;

  get #loadingEvents() {
    return Array.from({ length: this.loadingOptions.stepsCount }, (_, index) => ({
      ...UNKNOWN_EVENT,
      order: index,
    }));
  }

  get #sortedEvents() {
    if (!Array.isArray(this.args.events)) {
      return [];
    }

    return [...this.args.events].sort(
      (firstEvent, secondEvent) => firstEvent.order - secondEvent.order
    );
  }

  get events() {
    return this.args.isLoading ? this.#loadingEvents : this.#sortedEvents;
  }

  isFirst = event => {
    return (
      event.name === STEPS.CREATION ||
      event.order === Math.min(...this.events.map(event => event.order))
    );
  };

  isLast = event => {
    return (
      [STEPS.COMPLETION, STEPS.REJECTION].includes(event.name) ||
      event.order === Math.max(...this.events.map(event => event.order))
    );
  };

  isInProgress = event => {
    return event.status === STATUS.PROGRESS;
  };

  isAwaiting = event => {
    return event.status === STATUS.AWAITING;
  };

  isCompleted = event => {
    return event.status === STATUS.COMPLETED;
  };

  isLastCompleted = event => {
    let eventIndex = this.events.indexOf(event);
    if (eventIndex === -1 || eventIndex === this.events.length - 1) {
      return false;
    }

    let nextEvent = this.events[eventIndex + 1];

    return this.isCompleted(event) && !this.isCompleted(nextEvent);
  };

  isSuccessful = event => {
    return this.isCompleted(event) && event.name === STEPS.COMPLETION;
  };
}
