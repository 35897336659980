/* import __COLOCATED_TEMPLATE__ from './refuse-delete-team.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class RefuseDeleteTeamModal extends Component {
  @action
  handleClick() {
    this.args.close();
    this.args.data.viewTeamMemberships();
  }
}
