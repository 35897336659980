/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { EmailPreviewFooter } from 'qonto/react/components/receivable-invoices/email-preview';

export default class RemindersConfigurationEmailPreview extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service theme;

  emailPreviewFooter = EmailPreviewFooter;

  @service intl;
  @service organizationManager;

  get footerDisclaimer() {
    return this.intl
      .t('receivable-invoices.invoicing-sending.preview.invoicing-disclaimer', {
        language: this.args.language,
        htmlSafe: true,
      })
      .toString();
  }
}
