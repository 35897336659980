/* import __COLOCATED_TEMPLATE__ from './products-and-services.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import { defaultValues } from 'qonto/models/receivable-invoice/item';

export default class InvoiceSubscriptionsFormProductsAndServicesComponent extends Component {
  @service organizationManager;
  @service productsCatalog;
  @service modals;
  @service intl;
  @service segment;

  @reads('organizationManager.organization') organization;
  @reads('args.document.currency', CURRENCIES.default) currency;

  @action
  addItem(invoicingDocument) {
    invoicingDocument.items.createRecord(
      defaultValues(
        this,
        invoicingDocument.items[0]?.vatRate,
        invoicingDocument.items[0]?.vatExemptionCode
      )
    );

    // fill the current new item unit with the last item's unit
    let currentIndex = invoicingDocument.items.length - 1;
    invoicingDocument.items[currentIndex].unit = invoicingDocument.items[currentIndex - 1]?.unit;
  }

  @action
  deleteItem(item) {
    if (item.isNew) {
      item.deleteRecord();
    } else {
      item.unloadRecord();
    }
  }
}
