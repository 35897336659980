export default {
  "row": "qFM",
  "active": "qFP",
  "dot": "qFl",
  "red": "qFX",
  "green": "qFC",
  "yellow": "qFk",
  "align-right": "qFJ",
  "animated": "qFs",
  "fadein-item": "qFF",
  "cell": "qFg body-2",
  "cell-content": "qFD",
  "amount": "qFV body-1",
  "subtitle": "qFL caption",
  "status": "qFT",
  "cell-amount": "qFz"
};
