/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class TransferSidebarFooter extends Component {
  @service intl;
  @service modals;
  @service abilities;
  @service segment;

  showCancelModalTask = dropTask(async () => {
    this.segment.track('transfer_list_open_side_panel_cancel');
    await this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.modals.cancel-transfer.title'),
      description: this.intl.t('transfers.modals.cancel-transfer.body'),
      cancel: this.intl.t('transfers.modals.cancel-transfer.cancel-button'),
      confirm: this.intl.t('transfers.modals.cancel-transfer.confirm-button'),
      confirmTask: this.cancelTransferTask,
    });
  });

  cancelTransferTask = dropTask(async close => {
    await this.args.cancelTransfer.linked().perform(this.args.transfer, close);
  });

  get areBothButtonsVisible() {
    let { editable, cancelable } = this.args.transfer;
    let isEditable = this.abilities.can('update transfer') && editable;
    let isCancelable = this.abilities.can('update transfer') && cancelable;

    return isEditable && isCancelable;
  }
}
