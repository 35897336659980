/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { variation } from 'ember-launch-darkly';

import {
  EDITABLE_DESCRIPTION_INVOICE_STATUSES,
  INVOICE_STATUSES,
  STATUS_COLORS,
} from 'qonto/constants/supplier-invoice';
import formats from 'qonto/formats';
import { PayableAmountDisclaimer } from 'qonto/react/components/supplier-invoices/experiments';

export default class SupplierInvoicesDetailsSidebarHeaderComponent extends Component {
  badge = Badge;
  disclaimerBlock = Disclaimer.Block;
  payableAmountDisclaimer = PayableAmountDisclaimer;

  @service abilities;
  @service intl;
  @service organizationManager;
  @tracked invoiceStatuses = INVOICE_STATUSES;

  get strikeTotalAmount() {
    return (
      variation('experiment--boolean-ap-credit-notes') &&
      Number(this.args.invoice?.payableAmount?.value) === 0 &&
      Number(this.args.invoice?.totalAmount?.value) !== 0 &&
      !this.args.invoice?.isCreditNote
    );
  }

  get amount() {
    let { value, currency } = this.args.invoice?.totalAmount || {};
    let { isCreditNote } = this.args.invoice || {};

    if (currency && value !== null && value !== undefined) {
      return this.intl.formatMoney(value, {
        currency,
        // The condition will be removed after the experiment for ap-credit-notes
        signus: variation('experiment--boolean-ap-credit-notes')
          ? isCreditNote
            ? '-'
            : null
          : '-',
      });
    }

    return `--\xA0${formats.number.EUR.currency}`;
  }

  get status() {
    let { invoice = {} } = this.args;
    let { status, paymentDate, scheduledDate } = invoice;

    if (status === INVOICE_STATUSES.scheduled) {
      return {
        dateLabel: this.intl.t('supplier-invoices.preview.operation-date'),
        date: scheduledDate,
      };
    }
    if (status === INVOICE_STATUSES.paid) {
      return {
        display: this.intl.t('supplier-invoices.preview.status.paid'),
        dateLabel: this.intl.t('supplier-invoices.preview.payment-date'),
        color: STATUS_COLORS.paid,
        date: paymentDate,
      };
    }
  }

  get hasDescription() {
    return Boolean(this.args.invoice?.description?.trim());
  }

  get isOverdue() {
    let { dueDate, status } = this.args.invoice || {};
    let canOverdue = dueDate && status !== INVOICE_STATUSES.paid;

    return canOverdue ? dayjs().isAfter(dueDate) : false;
  }

  get showStatusDate() {
    return [INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid].includes(this.args.invoice?.status);
  }

  get showEditButton() {
    let canUpdateSupplierInvoices = this.abilities.can('update supplierInvoices');

    let hasToReviewOrPaidStatus = [INVOICE_STATUSES.toReview, INVOICE_STATUSES.paid].includes(
      this.args.invoice?.status
    );

    return (
      hasToReviewOrPaidStatus &&
      canUpdateSupplierInvoices &&
      !this.args.invoice?.frenchEInvoicing &&
      !this.args.invoice?.isGermanEInvoice
    );
  }

  get eInvoiceToolTipMessage() {
    let { organization } = this.organizationManager;
    switch (organization?.legalCountry) {
      case 'DE':
        return this.intl.t('supplier-invoices.preview.einvoice.tooltip');

      case 'FR':
        return this.intl.t('supplier-invoices.preview.supplier-einvoicing.tooltip');

      default:
        break;
    }
  }

  get isEinvoicingDescriptionFormShown() {
    return (
      (this.args.invoice?.frenchEInvoicing || this.args.invoice?.isGermanEInvoice) &&
      EDITABLE_DESCRIPTION_INVOICE_STATUSES.includes(this.args.invoice?.status)
    );
  }

  get isFrenchOrGermanEInvoicing() {
    return (this.args.invoice?.frenchEInvoicing || this.args.invoice?.isGermanEInvoice) ?? false;
  }

  // Will be removed after the experiment for ap-credit-notes
  get documentNumberLabel() {
    let { isCreditNote } = this.args.invoice || {};
    if (isCreditNote && variation('experiment--boolean-ap-credit-notes')) {
      return this.intl.t('supplier-invoices.preview.credit-note-number');
    }
    return this.intl.t('supplier-invoices.preview.invoice-number');
  }

  get payableAmount() {
    let { payableAmount } = this.args.invoice || {};
    return this.intl.formatMoney(payableAmount?.value, { currency: payableAmount?.currency });
  }

  get totalAmount() {
    let { totalAmount } = this.args.invoice || {};
    return this.intl.formatMoney(totalAmount?.value, { currency: totalAmount?.currency });
  }

  get creditNotesAmount() {
    let { relatedInvoices } = this.args.invoice || [];

    if (!relatedInvoices?.length) {
      return '';
    }

    let creditNotesSumUp = relatedInvoices
      .map(invoice => parseFloat(invoice.total_amount?.value))
      .reduce((accumulator, currentValue) => accumulator + currentValue);

    return this.intl.formatMoney(creditNotesSumUp, {
      currency: relatedInvoices[0]?.total_amount?.currency,
      signus: '-',
    });
  }
}
