/* import __COLOCATED_TEMPLATE__ from './importing-popover.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ImportingPopoverComponent extends Component {
  @service segment;
  @service intl;

  constructor() {
    super(...arguments);
    this.onPreviewFile = this.onPreviewFile.bind(this);
  }

  get uploadingState() {
    let { files = [] } = this.args;

    let { filesFailed, filesUploading, filesUploaded } = files.reduce(
      (counts, file) => {
        if (file.hasErrors) {
          counts.filesFailed++;
        } else if (file.isUploading || file.isProcessing) {
          counts.filesUploading++;
        } else {
          counts.filesUploaded++;
        }
        return counts;
      },
      { filesFailed: 0, filesUploading: 0, filesUploaded: 0 }
    );
    if (filesUploading > 0) {
      return this.getFileStatusCopy('in-progress', filesUploading);
    } else if (filesFailed) {
      return this.getFileStatusCopy('errors', filesFailed);
    } else {
      return this.getFileStatusCopy('complete', filesUploaded);
    }
  }

  getFileStatusCopy(status, count) {
    let tag = this.args.copies[status];
    return this.intl.t(tag, { count });
  }

  @action
  handleClose() {
    this.args.onClose?.();
  }

  onPreviewFile(file) {
    this.args.onPreviewFile?.(file);
  }
}
