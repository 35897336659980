export default {
  "refunds-total": "qPY",
  "switch-recurrence": "qPh",
  "flex-wrapper": "qPf",
  "promo-code": "qPK",
  "promo-code-total": "qPG",
  "others": "qPr",
  "others-total": "qPb",
  "subscription-detail": "qPy",
  "total-vat": "qPU",
  "fee-description": "qPj"
};
