/* import __COLOCATED_TEMPLATE__ from './membership-card.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

export default class MembershipCard extends Component {
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service segment;
  @service sentry;

  get formattedBirth() {
    let { birthCity, birthdate } = this.args.kycKybUpdateMembershipChangeRequest;
    return `${dayjs(birthdate).format('DD/MM/YYYY')}, ${birthCity}`;
  }

  get address() {
    let ocr = this.args.kycKybUpdateMembershipChangeRequest;

    if (!ocr.addressCountry || !ocr.addressFirstLine || !ocr.addressZipCode || !ocr.addressCity) {
      return false;
    }

    let country = this.intl.formatCountry(ocr.addressCountry);
    return `${ocr.addressFirstLine}, ${ocr.addressZipCode} ${ocr.addressCity} ${country}`;
  }

  get shouldShowTin() {
    return this.args.kycKybUpdateMembershipChangeRequest.needsTaxNumber;
  }

  get shouldShowFiscalInformation() {
    return this.args.kycKybUpdateMembershipChangeRequest.missingFiscalInformation;
  }

  handleDeleteMembershipTask = dropTask(async () => {
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('organizations.profile.company-profile.modal-remove.title', {
        representativeFullname:
          this.args.kycKybUpdateMembershipChangeRequest.membership.get('fullName'),
      }),
      description: this.intl.t('organizations.profile.company-profile.modal-remove.subtitle'),
      cancel: this.intl.t('organizations.profile.company-profile.modal-remove.button.cancel'),
      confirm: this.intl.t('organizations.profile.company-profile.modal-remove.button.remove'),
    });

    if (result === 'confirm') {
      let kycKybUpdateProcess =
        await this.args.kycKybUpdateMembershipChangeRequest.get('kycKybUpdateProcess');

      try {
        this.args.kycKybUpdateMembershipChangeRequest.corporateOfficer = false;
        this.args.kycKybUpdateMembershipChangeRequest.ubo = false;
        this.args.kycKybUpdateMembershipChangeRequest.legalRepresentative = false;
        await kycKybUpdateProcess.save().then(() =>
          this.args.kycKybUpdateMembershipChangeRequest.save().then(() => {
            this.segment.track('kycb_update_delete_membership');

            this.toastFlashMessages.toastInfo(
              this.intl.t(
                'on-boarding.capital-deposit.shareholders.shareholder-delete.success-toast'
              )
            );
          })
        );
      } catch (error) {
        if ([401, 403].includes(error.status)) {
          this.sentry.captureException(error);
        }
        this.args.kycKybUpdateMembershipChangeRequest.rollbackAttributes();
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });
}
