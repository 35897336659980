export default {
  "gap-32": "cor",
  "fieldset": "cob",
  "date-picker": "coy",
  "with-performance-date": "coU",
  "mt-4": "coj",
  "clients": "coW",
  "dropdown-option": "cou",
  "dropdown-content": "cnS",
  "select-client": "cnc",
  "client-option": "cnq",
  "icon-tooltip": "cnZ",
  "payment-details": "cnR",
  "dates-container": "cnQ",
  "automatic-number-label": "cne",
  "label": "cnB",
  "numbering-container": "cnE",
  "fix-width": "cnd",
  "customer-error": "cnI",
  "amount-text": "cnt",
  "header-text-field-container": "cnM",
  "header-text-field": "cnP",
  "header-text-field-close-button": "cnl",
  "mt-16": "cnX"
};
