/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/requests';

export default class ReimbursementsPendingMileageLayoutComponent extends Component {
  @service abilities;

  get isPastMileage() {
    let { status } = this.args.request;
    let pastStatuses = [STATUS.CANCELED, STATUS.DECLINED, STATUS.APPROVED];

    return pastStatuses.includes(status);
  }

  get showValidations() {
    let { request } = this.args;
    let userId = request.get('initiator.userId');

    return (
      (this.abilities.can('cancel mileage request', userId) ||
        this.abilities.can('review mileage request')) &&
      !this.isPastMileage
    );
  }
}
