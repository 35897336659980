export default {
  "beneficiary-details": "qpv",
  "beneficiary-account-types": "qpo",
  "divider": "qpn",
  "label": "qpi",
  "labels": "qpA",
  "error": "qpY",
  "link": "qph caption",
  "sticky-panel": "qpf"
};
