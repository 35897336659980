/* import __COLOCATED_TEMPLATE__ from './sort-cell.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class ProductsTableHeaderSortCellComponent extends Component {
  get sortField() {
    return this.args.sortBy.includes(':') ? this.args.sortBy.split(':')[0] : this.args.sortBy;
  }

  get isAscending() {
    return this.args.sortBy.includes('asc');
  }

  @action
  handleSortBy(sortBy) {
    if (sortBy === this.sortField) {
      sortBy = this.isAscending ? sortBy.split(':')[0] : sortBy + ':asc';
    }

    return this.args.handleSortBy?.(sortBy);
  }
}
