/* import __COLOCATED_TEMPLATE__ from './org-list.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { allSettled, dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { companyCreationJsURL, registerJsURL, registerPartnersJsURL } from 'qonto/constants/hosts';
import { ROLES } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export const isAccountant = role => role === ROLES.REPORTING;
export const not = fn => role => !fn(role);
export const findInList =
  idList =>
  isRoleFn =>
  ({ id, role }) =>
    idList.includes(id) && isRoleFn(role);

export default class ListOrgaComponent extends Component {
  @service organizationManager;
  @service userManager;
  @service store;
  @service router;
  @service segment;
  @service sentry;

  companyCreationJsURL = companyCreationJsURL;
  registerPartnersJsURL = registerPartnersJsURL;
  registerJsURL = registerJsURL;

  @reads('organizationManager.organization') currentOrganization;

  get companyCreationOrganizations() {
    let creationOrganizations = this.organizationManager.companyCreationOrganizations;
    if (creationOrganizations) {
      return [...creationOrganizations].sort(sortByKey('name'));
    }
  }

  get partnerOrganizations() {
    let partnerOrganizations = this.organizationManager.partnerOrganizations;
    if (partnerOrganizations) {
      return [...partnerOrganizations].sort(sortByKey('legalName'));
    }
  }

  get registeringOrganizations() {
    // these are not an EmberArray since they're loaded through the network-manager
    let { registeringOrganizations } = this.organizationManager;
    if (registeringOrganizations) {
      return [...registeringOrganizations].sort(sortByKey('name'));
    }
  }

  get organizations() {
    if (variation('feature--boolean-login-improvement')) {
      return this.organizationManager.organizations
        .filter(organization => {
          let isReportingRole = organization.membershipRole === ROLES.REPORTING;

          switch (this.args.accountantHubMode) {
            case 'include':
              return isReportingRole;
            case 'exclude':
              return !isReportingRole;
            default:
              return true;
          }
        })
        .sort((orgA, orgB) => orgA.name.localeCompare(orgB.name));
    } else {
      let { accountantHubMode } = this.args;
      let organizationsEnabled;

      if (accountantHubMode) {
        let userMembershipsIds = this.userManager.currentUser.memberships.map(({ id }) => id);
        let findMatchingMember = findInList(userMembershipsIds);
        let filterMembersWithRole =
          accountantHubMode === 'include'
            ? organization => organization.get('memberships').find(findMatchingMember(isAccountant))
            : organization =>
                organization.get('memberships').find(findMatchingMember(not(isAccountant)));

        organizationsEnabled =
          this.userManager.currentUser.organizationsEnabled.filter(filterMembersWithRole);
      } else {
        organizationsEnabled = this.userManager.currentUser.organizationsEnabled;
      }

      if (organizationsEnabled) {
        // Proxies properties need to be accessed with .get()
        return [...organizationsEnabled].sort((a, b) => a.get('name').localeCompare(b.get('name')));
      }
    }
  }

  constructor() {
    super(...arguments);
    // Task already handles errors
    if (!variation('feature--boolean-remove-price-plan-from-org-list')) {
      // eslint-disable-next-line ember-concurrency/no-perform-without-catch
      this.fetchPricePlansTask.perform();
    }
  }

  fetchPricePlansTask = dropTask(async () => {
    let organizations = variation('feature--boolean-login-improvement')
      ? this.organizationManager.organizations
      : this.userManager.currentUser.organizationsEnabled;

    let pricePlans = await allSettled(
      organizations.map(async organization => {
        let id = organization.get('id');
        let sub = this.store.peekAll('organization-subscription').find(subscription => {
          return (
            get(subscription, 'organization.id') === id &&
            get(subscription, 'product.productType') === 'core'
          );
        });

        if (!sub) {
          let allProductSubscriptions = await this.store
            .query(
              'organization-subscription',
              {
                includes: ['product'],
              },
              {
                adapterOptions: {
                  headers: {
                    'X-Qonto-Organization-ID': id,
                  },
                },
              }
            )
            .catch(error => {
              if (error.status !== 404 && ErrorInfo.for(error).shouldSendToSentry) {
                this.sentry.captureException(error);
              }
            });

          sub = allProductSubscriptions?.find(({ product }) => !product.isAddon);
        }
        return { organizationId: id, pricePlan: sub?.product };
      })
    );

    this.pricePlans = pricePlans.reduce((plans, { value }) => {
      if (value) {
        plans[value.organizationId] = value.pricePlan;
      }
      return plans;
    }, {});
  });

  @action
  trackOrgClick() {
    let origin = this.router.currentRouteName.includes('accounting-hub')
      ? 'accounting-hub'
      : 'organizations';
    this.segment.track('switch_organization', { origin });
  }
}
