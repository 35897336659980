export default {
  "transfer-header": "qzP",
  "infobox": "qzl header-section",
  "proof-section": "qzX",
  "proof-link": "qzC",
  "download-icon": "qzk",
  "cancel-button": "qzJ",
  "request-refund-button": "qzs",
  "refund-requested-indicator": "qzF",
  "recall-indicator": "qzg",
  "payment-purpose": "qzD",
  "payment-purpose-label": "qzV",
  "repeat-infobox": "qzL qzl header-section",
  "additional-infobox": "qzT qzl header-section",
  "repeat-transfer-action": "qzz",
  "financing-not-compliant-disclaimer": "qza"
};
