/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { ProgressBar } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { DEFAULT_ORIGIN_URL, SAVINGS_DEFAULT_URL } from 'qonto/constants/bank-account';

export default class RemuneratedAccountsCard extends Component {
  progressBar = ProgressBar;

  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @reads('organizationManager.organization.remuneratedAccounts') remuneratedAccounts;

  get remuneratedAccount() {
    return this.remuneratedAccounts.find(
      remuneratedAccount => remuneratedAccount.id === this.args.remuneratedAccount.id
    );
  }

  get origin() {
    let origin = DEFAULT_ORIGIN_URL;
    if (!this.router.currentRouteName?.includes(DEFAULT_ORIGIN_URL)) {
      origin = SAVINGS_DEFAULT_URL;
    }
    return origin;
  }

  showRenameAccountModalTask = task(async () => {
    await this.modals.open('bank-accounts/account-rename-modal', {
      account: this.remuneratedAccount,
    });
  });

  showCloseAccountModalTask = task(async () => {
    let bankAccount = this.remuneratedAccount;
    let remuneratedAccountDetails = this.args.remuneratedAccount;
    this.segment.track('bank_account_close_clicked', {
      slug: bankAccount.slug,
      account_type: bankAccount.accountType,
    });
    await this.modals.open('bank-accounts/account-close-modal', {
      bankAccount,
      remuneratedAccountDetails,
    });
  });

  get maxMaturityRate() {
    return this.args.remuneratedAccount.interestRates.at(-1).rate / 100;
  }
}
