/* import __COLOCATED_TEMPLATE__ from './related-document.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { EINVOICING_STATUS, STATUS } from 'qonto/constants/receivable-invoice';

export default class RelatedDocument extends Component {
  @service intl;

  get isRejected() {
    return [EINVOICING_STATUS.SUBMISSION_FAILED, EINVOICING_STATUS.REJECTED].includes(
      this.args.document.get('einvoicingStatus')
    );
  }

  get amountStyle() {
    if (this.isRejected) {
      return 'rejected';
    } else if (this.args.showSign) {
      return this.args.document.get('amountDue') < 0 ? 'negative' : 'positive';
    }
  }

  get isInvoiceWithCreditNote() {
    return (
      this.args.isQuoteModal &&
      this.creditNotes?.length > 0 &&
      this.args.document.status === STATUS.PAID
    );
  }

  get creditNotes() {
    return this.args.document?.receivableCreditNotes;
  }

  get currency() {
    return this.args.document.get('currency') || 'EUR';
  }

  get creditNotesAmount() {
    let sum = 0;
    if (this.creditNotes) {
      sum = this.creditNotes.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.amountDue),
        0
      );
    }

    return sum.toFixed(2);
  }

  get creditNotesAmountText() {
    return this.intl.formatMoney(this.creditNotesAmount || 0, {
      currency: this.currency,
    });
  }

  get invoicedAmountText() {
    return this.intl.formatMoney(this.args.document?.amountDue, {
      currency: this.currency,
    });
  }
}
