/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { STATUS } from 'qonto/constants/transfers';

export default class RequestSidebarExpenseReportDetailsComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service modals;
  @service abilities;
  @service attachmentsManager;

  get showDisclaimer() {
    let { request } = this.args;
    let userId = request.get('initiator.userId');

    return (
      request.status === STATUS.PENDING &&
      this.abilities.can('cancel expense report request', userId) &&
      this.abilities.cannot('see state approval-workflow')
    );
  }

  @action
  handleOpenFilePreviewModal(file) {
    this.modals.open('reimbursements/requests/sidebar/expense-report/attachment-modal', {
      model: this.args.request,
      selectedFile: file,
      onDownload: this.attachmentsManager.downloadAttachment,
      decline: this.args.decline,
      approve: this.args.approve,
      setAccount: this.args.setAccount,
      close: this.args.close,
      // We need to pass the args here to keep the reactivity
      // of the errors and warnings generated by the setAccount callback
      parentArgs: this.args,
    });
  }

  get shouldDisplayApprovalWorkflowState() {
    let { approvalWorkflowState, canceled } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');
    return (
      this.abilities.can('see state approval-workflow') &&
      approvalWorkflowState &&
      steps &&
      conditions &&
      !canceled
    );
  }

  get approvalWorkflowState() {
    let { approvalWorkflowState } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return {
      id: approvalWorkflowState.id,
      conditions,
      steps,
    };
  }
}
