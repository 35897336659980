/* import __COLOCATED_TEMPLATE__ from './vat-rate-selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { VAT_RATE_PRECISION } from 'qonto/constants/receivable-invoice';
import { getVatExemptionReasons } from 'qonto/utils/receivable-invoicing';

export default class VatRateSelectorComponent extends Component {
  @service intl;
  @service organizationManager;

  @tracked selectedVatOption = this.getVatRateOption();
  @tracked vatRatePercentage = String(this.model.vatRate * 100).replace(
    /[,.]/g,
    this.decimalSeparator
  );
  @reads('args.model') model;
  @reads('organizationManager.organization.legalCountry') legalCountry;

  @cached
  get vatExemptionReasons() {
    return getVatExemptionReasons(this.intl, this.legalCountry);
  }

  get decimalSeparator() {
    return new Intl.NumberFormat(this.intl.primaryLocale)
      .formatToParts(0.5)
      .find(({ type }) => type === 'decimal').value;
  }

  get shouldDisplayVatExemptionField() {
    return parseFloat(this.model.vatRate) === 0 || this.model.errors.get('vatExemptionCode');
  }

  get selectedVatExemptionReason() {
    let vatExemptionCode;

    if (this.model.vatExemptionCode) {
      vatExemptionCode = this.model.vatExemptionCode;
    } else if (this.model.vatRate === 0 && this.legalCountry === 'IT') {
      vatExemptionCode = 'N2.2';
    }

    return this.vatExemptionReasons.find(
      vatExemptionReason => vatExemptionReason.key === vatExemptionCode
    );
  }

  @action handleVatRateInput(newRate) {
    this.vatRatePercentage = newRate;
    this.setItemVatRate(newRate.replace(/[,.]/g, '.') / 100);
  }

  @action handleVatRateChange(newRate) {
    this.vatRatePercentage = newRate.replace(/[,.]/g, this.decimalSeparator);
    this.setItemVatRate(newRate.replace(/[,.]/g, '.') / 100);
  }

  @action updateVatOption(option) {
    this.selectedVatOption = option;
    if (option.value >= 0) {
      this.setItemVatRate(option.value / 100);
    } else {
      this.vatRatePercentage = null;
      this.setItemVatRate(null);
    }
  }

  @action updateVatExemptionReason({ key }) {
    this.model.vatExemptionCode = key;
  }

  setItemVatRate(value) {
    this.model.vatRate = value === null ? null : String(value);
    if (value !== 0) this.model.vatExemptionCode = null;
    if (value === 0 && this.legalCountry === 'IT') {
      this.model.vatExemptionCode = 'N2.2';
    } else this.model.vatExemptionCode = undefined;
  }

  getVatRateOption() {
    if (this.model.vatRate === undefined) return undefined;
    let vatCategory = this.organizationManager.organization.vatRatesCategories.find(
      vatRateCategory =>
        Math.abs(vatRateCategory - parseFloat(this.model.vatRate) * 100) < VAT_RATE_PRECISION
    );

    if (vatCategory !== undefined)
      return {
        clearable: false,
        value: vatCategory,
      };

    return { clearable: true, value: -1 };
  }
}
