export default {
  "header": "qqW",
  "header-type": "qqu",
  "close": "qZS",
  "close-icon": "qZc",
  "header-infos": "qZq",
  "title": "qZZ body-1",
  "declined": "qZR",
  "canceled": "qZQ",
  "approved": "qZe",
  "pending": "qZB",
  "date": "qZE body-2",
  "mileage-icon": "qZd",
  "distance-subtitle": "qZI body-2",
  "link-icon": "qZt",
  "container": "qZM",
  "picto": "qZP",
  "general": "qZl",
  "amount": "qZX",
  "calculation-container": "qZC",
  "counterparty": "qZk body-2",
  "infobox": "qZJ"
};
