/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { REQUEST_ORIGINS } from 'qonto/constants/requests';

export default class ReimbursementsPendingTable extends Component {
  @service organizationManager;
  @service subscriptionManager;
  @service abilities;

  REIMBURSEMENTS_EMPTY_STATE_ORIGIN = REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE;

  get isApproveCompactView() {
    return this.args.isSingleAccountApprover && !this.args.isSidepanelOpen;
  }

  get remainingInvitationCount() {
    let limit = this.subscriptionManager.getLimitByFeatureCode('additional_users');
    return limit === null ? null : limit - this.activeMembershipsCount;
  }

  get activeMembershipsCount() {
    let { organization } = this.organizationManager;

    let activeMembershipsCount = this.abilities.can('access accountant-access')
      ? organization.membershipsCountingTowardsPlanLimitCount
      : organization.activeMembershipsCount;

    return activeMembershipsCount;
  }
}
