/* import __COLOCATED_TEMPLATE__ from './fetch-transactions.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { DEFAULT_SEARCH_INCLUDES } from 'qonto/constants/transactions';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FetchTransactionsComponent extends Component {
  @service errors;
  @service toastFlashMessages;
  @service sentry;
  @service store;
  @service organizationManager;

  constructor() {
    super(...arguments);
    this.fetchTransactions();
  }

  @action
  fetchTransactions(searchQuery) {
    this.fetchTransactionsTask
      .perform(searchQuery)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        if (this.errors.shouldFlash(error)) {
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      });
  }

  fetchTransactionsTask = task(this, { restartable: true }, async (searchQuery = '') => {
    await timeout(DEBOUNCE_MS);

    let { transactions } = await this.store.adapterFor('transaction').search({
      includes: DEFAULT_SEARCH_INCLUDES,
      filter_group: this.args.filterGroup,
      sort: { property: 'emitted_at', direction: 'desc' },
      pagination: { page: 1, per_page: 20 },
      search: searchQuery,
      organization_id: this.organizationManager.organization.id,
    });

    return transactions;
  });
}
