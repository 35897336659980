export default {
  "card": "cTE",
  "header": "cTd",
  "avatar": "cTI",
  "title": "cTt",
  "subtitle": "cTM",
  "label": "cTP",
  "item": "cTl body-2",
  "header-icon": "cTX",
  "dropdown-menu": "cTC",
  "menu-item": "cTk body-2",
  "delete": "cTJ"
};
