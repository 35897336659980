/* import __COLOCATED_TEMPLATE__ from './create-invoice-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { ToggleButton } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export const SWITCH_VALUES = {
  SUPPLIER_INVOICE: 'SUPPLIER_INVOICE',
  SELF_INVOICE: 'SELF_INVOICE',
};
export default class SelfBillingCreateInvoiceModalComponent extends Component {
  toggleButton = ToggleButton;

  @service intl;
  @service modals;
  @service router;
  @service sentry;
  @service toastFlashMessages;
  @service segment;

  @tracked activeSwitchItem = SWITCH_VALUES.SUPPLIER_INVOICE;

  SWITCH_VALUES = SWITCH_VALUES;
  switchItems = [
    {
      value: SWITCH_VALUES.SELF_INVOICE,
      label: this.intl.t('self-billing.create.toggle.self-invoice'),
    },
    {
      value: SWITCH_VALUES.SUPPLIER_INVOICE,
      label: this.intl.t('self-billing.create.toggle.supplier-invoice'),
    },
  ];

  get hideSelfInvoicePreview() {
    return this.activeSwitchItem === this.SWITCH_VALUES.SUPPLIER_INVOICE;
  }

  @action togglePreview(state) {
    this.activeSwitchItem = state;
    this.segment.track('invoice_preview_switch');
  }

  confirmExitTask = dropTask(async () => {
    await this.modals.open('popup/destructive', {
      title: this.intl.t('self-billing.create.exit-modal.title'),
      description: this.intl.t('self-billing.create.exit-modal.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
      confirmTask: this.exitTask,
    });
  });

  exitTask = task(async close => {
    await close();
    this.router.transitionTo('supplier-invoices.index');
    this.segment.track('self-invoice_creation_canceled');
  });

  confirmInvoiceCreationTask = dropTask(async () => {
    this.segment.track('self-invoice_creation_submitted');
    await this.modals.open('popup/confirmation', {
      title: this.intl.t('self-billing.create.confirm-modal.title'),
      description: this.intl.t('self-billing.create.confirm-modal.description', { htmlSafe: true }),
      confirm: this.intl.t('self-billing.create.confirm-modal.create-invoice'),
      cancel: this.intl.t('btn.cancel'),
      confirmTask: this.saveInvoiceTask,
    });
  });

  saveInvoiceTask = dropTask(async close => {
    try {
      await this.args.data.selfInvoice.save();
      this.segment.track('self-invoice_creation_confirmed');
      this.args.data.supplierInvoice.selfInvoiceId = true;
      this.args.data.selfInvoice.clearItemsWithNoId();
      this.router.transitionTo('supplier-invoices.index');
      this.toastFlashMessages.toastSuccess(this.intl.t('self-billing.toast.creation-success'));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('self-billing.toast.creation-fail'));
    }
    await close();
  });
}
