export default {
  "row": "qpl body-1",
  "active": "qpX",
  "cell": "qpC",
  "empty": "qpk",
  "ellipsis": "qpJ",
  "text-secondary": "qps",
  "cell-type": "qpF",
  "cell-reason": "qpg",
  "cell-amount": "qpD",
  "row-sidebar": "qpV",
  "animated": "qpL",
  "fadein-item": "qpT",
  "animated-cell": "qpz"
};
