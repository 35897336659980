export default {
  "header": "qPF",
  "subtitle": "qPg body-2",
  "date": "qPD",
  "subtitle-error": "qPV",
  "card": "qPL",
  "card-header": "qPT",
  "line-placeholder": "qPz",
  "post-closing-list": "qPa",
  "post-closing-container": "qPH",
  "post-closing-list-element": "qPO",
  "free-trial-disclaimer": "qPm",
  "description": "qPp body-2",
  "mw-100": "qPx",
  "addon-overview": "qPw",
  "addons-container": "qPN"
};
