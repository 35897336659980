/* import __COLOCATED_TEMPLATE__ from './org-deactivation-suspended-expired-due-diligence.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

export default class orgDeactivationSuspendedExpiredDueDiligenceTopbarComponent extends Component {
  topBanner = TopBanner;

  @service intl;
  @service router;

  get intro() {
    return this.intl.t('subscription.account-closing.suspended-failed-kyb-update.intro');
  }

  get cta() {
    return this.intl.t('subscription.account-closing.suspended-failed-kyb-update.cta');
  }

  get outro() {
    return this.intl.t('subscription.account-closing.suspended-failed-kyb-update.outro');
  }

  get content() {
    return {
      type: 'info',
    };
  }
}
