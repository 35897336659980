export default {
  "account-description": "cpD",
  "account-identification": "cpV",
  "account-avatar": "cpL",
  "account-name": "cpT",
  "account-balance": "cpz body-2",
  "status": "cpa",
  "external-accounts-disclaimer": "cpH",
  "failed-connection": "cpO"
};
