/* import __COLOCATED_TEMPLATE__ from './theme-selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';

import APPEARANCE from 'qonto/constants/appearance-setting';

const TRACKING_EVENT_NAMES = {
  [APPEARANCE.LIGHT]: 'appearance_light_selected',
  [APPEARANCE.DARK]: 'appearance_dark_selected',
  [APPEARANCE.SYSTEM]: 'appearance_automatic_selected',
};

export default class ThemeSelector extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service segment;
  @service tandem;

  @tracked groupValue = this.args.currentAppearance;

  APPEARANCE = APPEARANCE;

  @action
  onCheck(value) {
    let trackingEventName = TRACKING_EVENT_NAMES[value];

    this.groupValue = value;
    this.args.setAppearance(value);
    this.tandem.setTheme(value);
    this.segment.track(trackingEventName);
  }
}
