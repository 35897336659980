export default {
  "card-sidebar-header": "qce",
  "content": "qcB",
  "header-type": "qcE",
  "close": "qcd",
  "close-icon": "qcI",
  "header-infos": "qct",
  "title": "qcM body-1",
  "danger": "qcP",
  "success": "qcl",
  "warning": "qcX",
  "date": "qcC body-2",
  "card-image-container": "qck",
  "card-image": "qcJ",
  "info-container": "qcs",
  "spending-reason": "qcF",
  "spending-reason-label": "qcg body-2 mr-8",
  "spending-reason-note": "qcD body-2"
};
