export default {
  "custom-export-loading": "qDW",
  "title": "qDu",
  "description": "qVS",
  "section": "qVc",
  "placeholder-avatar": "qVq",
  "_with-avatar": "qVZ",
  "_with-list": "qVR",
  "list-item": "qVQ",
  "radio": "qVe",
  "header": "qVB",
  "header-line": "qVE"
};
