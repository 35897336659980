export default {
  "wrapper": "qHe",
  "ember-power-select-dropdown": "qHB",
  "status": "qHE body-1",
  "error": "qHd",
  "success": "qHI",
  "type-container": "qHt",
  "type-close": "qHM",
  "date": "qHP",
  "close-icon": "qHl",
  "container": "qHX",
  "container-picto": "qHC",
  "avatar": "qHk",
  "container-picto-status": "qHJ",
  "general": "qHs",
  "general-amount": "qHF",
  "unprocessed": "qHg",
  "fx-rate": "qHD",
  "general-counterparty": "qHV body-2",
  "infobox": "qHL",
  "account-infobox": "qHT qHL",
  "initiator-infobox": "qHz qHL",
  "transaction-infobox": "qHa qHL",
  "repeat-infobox": "qHH qHL",
  "item": "qHO body-2",
  "eye-icon": "qHm",
  "repeat-transfer-action": "qHp",
  "disclaimer": "qHx",
  "start-verification-link": "qHw",
  "financing-not-compliant-disclaimer": "qHN"
};
