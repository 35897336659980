/* import __COLOCATED_TEMPLATE__ from './indicator.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

export default class TooltipIndicatorComponent extends Component {
  @service intl;

  titleTranslations = {
    daily: this.intl.t('overview.dates.today'),
    weekly: this.intl.t('overview.dates.current-week'),
    monthly: this.intl.t('overview.dates.current-month'),
  };

  amountLabelTranslations = {
    balance: {
      daily: this.intl.t('overview.balance.end-of-day'),
      weekly: this.intl.t('overview.balance.end-of-week'),
      monthly: this.intl.t('overview.balance.end-of-month'),
      current: this.intl.t('overview.balance.current'),
    },
    'net-change': this.intl.t('overview.net-change.amount'),
    inflows: this.intl.t('overview.amount'),
    outflows: this.intl.t('overview.amount'),
  };

  get hasCount() {
    return Number.isFinite(this.args.sample?.count);
  }

  get isCurrent() {
    let { time } = this.args.sample;

    switch (this.args.rate) {
      case 'daily':
        return dayjs().isSame(time, 'day');
      case 'weekly':
        return dayjs().isSame(time, 'week');
      case 'monthly':
        return dayjs().isSame(time, 'month');
    }
  }

  get title() {
    let { rate, sample } = this.args;
    let { time } = sample;

    if (this.isCurrent) {
      return this.titleTranslations[rate];
    } else {
      switch (rate) {
        case 'daily':
          return dateToken({
            date: time,
            locale: this.intl.primaryLocale,
            token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
          });
        case 'weekly':
          return this._formatWeek(time);
        case 'monthly':
          return dateToken({
            date: time,
            locale: this.intl.primaryLocale,
            token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
          });
      }
    }
  }

  get amountLabel() {
    if (this.args.type === 'balance') {
      return this.isCurrent
        ? this.amountLabelTranslations.balance.current
        : this.amountLabelTranslations.balance[this.args.rate];
    } else {
      return this.amountLabelTranslations[this.args.type];
    }
  }

  get amountValue() {
    let { time, value } = this.args.sample;

    return dayjs(time).isAfter(dayjs()) ? '--' : value;
  }

  get countValue() {
    let { time, count } = this.args.sample;

    return dayjs(time).isAfter(dayjs()) ? '--' : count;
  }

  _formatWeek(time) {
    let startDay = dateToken({
      date: dayjs(time).startOf('isoWeek').toDate(),
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });

    let endDay = dateToken({
      date: dayjs(time).endOf('isoWeek').toDate(),
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });

    return this.intl.t('overview.dates.week-of', { startDay, endDay });
  }
}
