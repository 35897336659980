export default {
  "header": "cjQ",
  "header-type": "cje",
  "close": "cjB",
  "close-icon": "cjE",
  "header-infos": "cjd",
  "title": "cjI body-1",
  "declined": "cjt",
  "canceled": "cjM",
  "approved": "cjP",
  "pending": "cjl",
  "date": "cjX body-2",
  "mileage-icon": "cjC",
  "distance-subtitle": "cjk body-2",
  "link-icon": "cjJ",
  "container": "cjs",
  "picto": "cjF",
  "general": "cjg",
  "amount": "cjD",
  "calculation-container": "cjV",
  "counterparty": "cjL body-2",
  "infobox": "cjT"
};
