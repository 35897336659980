/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

export default class ProductDiscoveryUserActionsCardComponent extends Component {
  badgeStatus = BadgeStatus;

  constructor() {
    super(...arguments);
    this.args.onDidConstruct?.();
  }

  get showCta() {
    return this.args.cta && !this.args.isDisabled;
  }

  get showDismissButton() {
    return (
      !this.isPrimaryCard && this.showCta && this.args.isDismissible && this.args.onDismissClick
    );
  }

  get isPrimaryCard() {
    return this.args.isPrimary && this.args.description;
  }

  handleCtaClickTask = dropTask(async () => {
    // The cta.onClick comes from the product-discovery service configuration
    await this.args.cta.onClick?.();
    this.args.onCtaClick?.();
  });
}
