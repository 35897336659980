/* import __COLOCATED_TEMPLATE__ from './selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

import { PERIOD_KEYS } from 'qonto/constants/bookkeeping';

export default class MatchAttachmentPeriodSelectorComponent extends Component {
  @tracked dropDownState;
  @tracked customPeriodIsOpen = false;
  @service intl;

  lastIndex = 0;

  get periodOptions() {
    return [
      {
        key: PERIOD_KEYS.THIS_MONTH,
        label: this.intl.t('bookkeeping.transactions.filters.option.period.month'),
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
      },
      {
        key: PERIOD_KEYS.LAST_MONTH,
        label: this.intl.t('bookkeeping.transactions.filters.option.period.last-month'),
        startDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      },
      {
        key: PERIOD_KEYS.CUSTOM_PERIOD,
        label: this.intl.t('bookkeeping.transactions.filters.option.period.custom.label'),
        startDate: null,
        endDate: null,
      },
    ];
  }

  get selectedIndex() {
    return this.periodOptions.findIndex(option => option.key === this.args.selectedPeriod?.key);
  }

  @action
  updateCustomPeriod({ startDate, endDate }) {
    let item = { ...this.customPeriodItem, startDate, endDate };
    this.args.onChangePeriod(item);
    this.dropDownState?.actions.close();
  }

  @action
  cancelPeriodSelection() {
    this.closeCustomPeriod();
    this.args.cancelPeriodSelection();
  }

  @action
  selectItem(index) {
    let selectedItem = this.periodOptions[index];
    if (this.isCustom(selectedItem.key)) {
      this.customPeriodIsOpen = true;
    } else {
      this.args.onChangePeriod(selectedItem);
      this.dropDownState?.actions.close();
      this.resetLastIndex();
    }
  }

  @action
  getIndex() {
    return this.lastIndex++;
  }

  resetLastIndex() {
    this.lastIndex = 0;
  }

  @action
  closeCustomPeriod() {
    this.resetLastIndex();
    this.customPeriodIsOpen = false;
  }

  get customPeriodItem() {
    return this.isCustom(this.args.selectedPeriod?.key)
      ? this.args.selectedPeriod
      : this.periodOptions.find(option => this.isCustom(option.key));
  }

  isCustom(key) {
    return key === PERIOD_KEYS.CUSTOM_PERIOD;
  }

  @action
  registerDropdownState(dropDownState) {
    if (dropDownState) {
      this.closeCustomPeriod();
      this.resetLastIndex();
      this.dropDownState = dropDownState;
    }
  }
}
