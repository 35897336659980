/* import __COLOCATED_TEMPLATE__ from './show-invoice-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ShowInvoiceModalComponent extends Component {
  @service router;
  @service intl;
  @service segment;

  get title() {
    return this.intl.t('self-billing.view-modal.title', {
      selfInvoiceNumber: this.args.data.selfInvoice.number,
    });
  }

  @action
  goBack() {
    this.segment.track('self-invoice_options_escape');
    this.router.transitionTo(
      'supplier-invoices.show',
      this.args.data.selfInvoice.belongsTo('supplierInvoice').id()
    );
  }
}
