export default {
  "qonto-pilot-modal": "cNo",
  "content": "cNn",
  "header": "cNi",
  "transition-state-container": "cNA",
  "lottie": "cNY",
  "prompt-input": "cNh",
  "buttons": "cNf",
  "footer": "cNK caption",
  "divider": "cNG",
  "illustration": "cNr",
  "icon": "cNb",
  "quick-actions-container": "cNy",
  "action": "cNU body-2",
  "action-title": "cNj",
  "disabled": "cNW",
  "action-tag": "cNu caption-bold",
  "hotkey": "cvS body-1",
  "footer-action": "cvc",
  "grow": "cvq",
  "footer-feedback-link": "cvZ",
  "buttons-container": "cvR",
  "transfer-details-container": "cvQ",
  "error-container": "cve",
  "option-iban": "cvB",
  "actions": "cvE",
  "title": "cvd",
  "group-title": "cvI caption-bold",
  "beta-badge": "cvt",
  "hotkeys": "cvM",
  "supplier-invoices-dropzone": "cvP",
  "success-icon": "cvl",
  "error-icon": "cvX",
  "file-items": "cvC",
  "file-item": "cvk",
  "uploader-file": "cvJ",
  "transactions-table": "cvs",
  "transaction": "cvF",
  "prompt-example": "cvg"
};
