/* import __COLOCATED_TEMPLATE__ from './invoice-pdf-preview.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DEFAULT_LOCALE_PER_COUNTRY } from 'qonto/constants/receivable-invoice';

export default class InvoicePdfPreview extends Component {
  @service intl;
  @service organizationManager;

  get customerLocale() {
    return this.args.invoice.locale && !this.args.useCustomerRecord
      ? this.args.invoice.locale
      : this.args.invoice.get('customer.locale');
  }

  get userLocale() {
    return this.intl.primaryLocale || 'en';
  }

  get currencyIsDifferentThanEUR() {
    return this.args.invoice.currency !== 'EUR';
  }

  get isOrganizationCountryDifferentThanCustomerCountry() {
    let customerCountryCode = this.args.invoice.customer?.content?.countryCode;
    if (!customerCountryCode) return false;
    return this.organizationManager.organization.legalCountry !== customerCountryCode;
  }

  get showLanguageTooltip() {
    let orgaCountry = this.organizationManager.organization.legalCountry;
    return (
      (this.args.showLanguageTooltip &&
        this.customerLocale &&
        this.customerLocale !== DEFAULT_LOCALE_PER_COUNTRY[orgaCountry]) ||
      (this.args.showLanguageTooltip && this.currencyIsDifferentThanEUR) ||
      (this.args.showLanguageTooltip && this.isOrganizationCountryDifferentThanCustomerCountry)
    );
  }

  get showGermanTemplate() {
    return this.organizationManager.organization.legalCountry === 'DE';
  }
}
