/* import __COLOCATED_TEMPLATE__ from './information.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Checkbox, DatePicker, TextAreaField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

export default class TransferMultiInformation extends Component {
  checkbox = Checkbox;
  datePicker = DatePicker;
  textAreaField = TextAreaField;

  @service organizationManager;
  @service intl;

  @reads('organizationManager.organization') organization;
  @reads('organization.hasMultipleActiveCurrentRemuneratedAccounts') showAccountSelect;
  @reads('organization.activeSortedRemuneratedAndCurrentAccounts') activeAccounts;

  get scheduledDate() {
    let { multiTransfer } = this.args;
    return multiTransfer.scheduledDate ? parseDate(multiTransfer.scheduledDate) : null;
  }

  get minValue() {
    let { multiTransfer } = this.args;
    return multiTransfer.minDate ? parseDate(multiTransfer.minDate) : null;
  }

  customValidationMessages = {
    valueMissing: this.intl.t('validations.errors.presence'),
    rangeUnderflow: this.intl.t('transfers.multi.datepicker.error.minimum-date', {
      date: dateToken({
        date: new Date(),
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.COMPACT,
      }),
    }),
  };

  @action
  onChange(date) {
    this.args.updateScheduleSelection(date);
  }
}
