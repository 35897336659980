export default {
  "insight-pill": "qXp",
  "interactive": "qXx",
  "focus": "qXw",
  "subtitle": "qXN caption",
  "hidden": "qXv",
  "missing-amounts-warning": "qXo",
  "totals": "qXn",
  "counter": "qXi title-3",
  "loading": "qXA",
  "error": "qXY",
  "title": "qXh title-4"
};
