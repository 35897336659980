export default {
  "row": "qSp",
  "empty": "qSx",
  "active": "qSw",
  "animated": "qSN",
  "fadein-item": "qSv",
  "cell": "qSo body-2",
  "cell-content": "qSn",
  "cell-amount": "qSi",
  "cell-content-amount": "qSA body-1"
};
