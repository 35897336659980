/* import __COLOCATED_TEMPLATE__ from './update-password-form.hbs'; */
import Component from '@glimmer/component';

export default class UpdatePasswordForm extends Component {
  willDestroy() {
    super.willDestroy(...arguments);

    // if the store is destorying or destroyed we can skip cleanup
    // otherwise we will get an error in ember data 4.7 in integration tests:
    // "A record in a disconnected state cannot utilize the store."
    if (this.args.user.isDestroying || this.args.user.isDestroyed) return;

    this.args.user.rollbackAttributes();
  }
}
