/* import __COLOCATED_TEMPLATE__ from './dropdown-annual-turnover.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class DropdownAnnualTurnover extends Component {
  @service annualTurnover;
  @service intl;

  get selectedValue() {
    let code = this.args.organizationChangeRequest.annualTurnover;

    if (code) {
      let name = this.annualTurnoverOptionsTranslations[code];
      return { code, name };
    }
    return null;
  }

  get annualTurnoverOptionsTranslations() {
    let {
      options: [option1, option2, option3, option4, option5],
      translations: [translation1, translation2, translation3, translation4, translation5],
    } = this.annualTurnover.getAnnualTurnover(
      this.args.organizationChangeRequest.kycKybUpdateProcess
        .get('organization')
        .get('legalCountry')
    );

    return {
      [option1]: translation1,
      [option2]: translation2,
      [option3]: translation3,
      [option4]: translation4,
      [option5]: translation5,
    };
  }

  get options() {
    return this.annualTurnover
      .getAnnualTurnover(
        this.args.organizationChangeRequest.kycKybUpdateProcess
          .get('organization')
          .get('legalCountry')
      )
      .options.map(code => ({
        code,
        name: this.annualTurnoverOptionsTranslations[code],
      }));
  }

  @action
  handleUpdate(annualTurnoverObject) {
    this.args.organizationChangeRequest.annualTurnover = annualTurnoverObject.code;
  }
}
