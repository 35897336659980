/* import __COLOCATED_TEMPLATE__ from './combo.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { all, dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import buildQuery from 'qonto/utils/statistics';

export default class OverviewStatisticsComboComponent extends Component {
  @service store;
  @service organizationManager;
  @service sentry;

  @tracked statistics = [];
  @tracked comparisonStatistics = [];

  get isLoading() {
    return this.queryStatisticsTask.isRunning && this.queryStatisticsTask.performCount === 1;
  }

  get isUpdating() {
    return this.queryStatisticsTask.isRunning && this.queryStatisticsTask.performCount > 1;
  }

  get isError() {
    return this.getStatisticsTask.last?.isError;
  }

  queryStatisticsTask = dropTask(
    async (aggregations, basePeriod, bankAccounts, comparisonPeriod) => {
      try {
        await this.getStatisticsTask
          .linked()
          .perform(aggregations, basePeriod, bankAccounts, comparisonPeriod);
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(errorInfo.error);
        }
      }
    }
  );

  getStatisticsTask = dropTask(async (aggregations, basePeriod, bankAccounts, comparisonPeriod) => {
    let adapter = this.store.adapterFor('transaction');
    let organizationId = this.organizationManager.organization.id;

    let isPeriod = period => period?.interval || period?.startDate || period?.endDate;

    let getQuery = (period, side) =>
      buildQuery(organizationId, aggregations, period, side, bankAccounts);

    let getStatistics = period => [
      adapter.getCashflow(getQuery(period, 'credit')),
      adapter.getCashflow(getQuery(period, 'debit')),
      adapter.getBalance(getQuery(period)),
    ];

    this.statistics = await all(getStatistics(basePeriod));

    if (isPeriod(comparisonPeriod)) {
      this.comparisonStatistics = await all(getStatistics(comparisonPeriod));
    }
  });
}
