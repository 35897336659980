export default {
  "supplier-selector-wrapper": "qXB",
  "select-supplier": "qXE",
  "dropdown": "qXd",
  "iban": "qXI",
  "card": "qXt body-2",
  "add-btn": "qXM btn btn--square btn--icon-only",
  "archived-card": "qXP",
  "title": "qXl body-1",
  "archived-title": "qXX",
  "edit-supplier-tooltip": "qXC",
  "add-supplier": "qXk btn btn--tertiary",
  "error": "qXJ caption"
};
