/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class NotificationsSettingsSection extends Component {
  @service intl;
  get titleKey() {
    try {
      return this.args.catCode
        ? this.intl.t(`users.subscriptions.${this.args.catCode}.name`)
        : this.intl.t('users.subscriptions.account.name');
    } catch {
      this.intl.t('users.subscriptions.account.name');
    }
  }
}
