/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus, ProgressBar } from '@repo/design-system-kit';

import { DEFAULT_ORIGIN_URL, SAVINGS_DEFAULT_URL } from 'qonto/constants/bank-account';
import { NOTICE_PERIOD_AMOUNT_THRESHOLD } from 'qonto/constants/savings-account';

export default class SavingsAccountCardComponent extends Component {
  progressBar = ProgressBar;

  badgeStatus = BadgeStatus;

  @service intl;
  @service abilities;
  @service router;

  statusBadgeStrings = {
    closed: this.intl.t('accounts.savings.card.status.closed'),
    closing: this.intl.t('accounts.savings.card.status.closing'),
    processing: this.intl.t('accounts.savings.card.status.processing'),
  };

  statusBadgeColors = {
    closed: 'inactive',
    closing: 'in-progress',
    processing: 'in-progress',
  };

  get noticePeriod() {
    return this.args.savingsAccount.amount > NOTICE_PERIOD_AMOUNT_THRESHOLD ? 31 : 3;
  }

  statusTooltips = {
    closing: this.intl.t('accounts.savings.card.closing-tooltip', { duration: this.noticePeriod }),
    processing: this.intl.t('accounts.savings.card.processing-tooltip'),
  };

  get badgeString() {
    return this.statusBadgeStrings[this.args.savingsAccount.status];
  }

  get badgeColor() {
    return this.statusBadgeColors[this.args.savingsAccount.status];
  }

  get tooltip() {
    return this.statusTooltips[this.args.savingsAccount.status];
  }

  get showOpenContractAction() {
    return (
      this.abilities.can('create savingsAccount') && Boolean(this.args.savingsAccount.procedureId)
    );
  }

  get origin() {
    let origin = DEFAULT_ORIGIN_URL;
    if (this.router.currentRouteName?.includes(SAVINGS_DEFAULT_URL)) {
      origin = SAVINGS_DEFAULT_URL;
    }
    return origin;
  }

  get showOpenClosureContractAction() {
    return (
      this.abilities.can('create savingsAccount') &&
      (this.args.savingsAccount.isClosing || this.args.savingsAccount.isClosedEarly)
    );
  }

  get showActionsDropdown() {
    return [
      this.showOpenClosureContractAction,
      this.showOpenContractAction,
      this.args.savingsAccount.isCloseable,
    ].some(Boolean);
  }
}
