/* import __COLOCATED_TEMPLATE__ from './beneficiary-sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class RequestTransferBeneficiarySidebarComponent extends Component {
  @service modals;

  get emptyState() {
    let { beneficiarySearchQuery, beneficiaries } = this.args;
    return beneficiarySearchQuery === null && !beneficiaries?.length;
  }

  get showUpload() {
    return this.args.showUpload ?? true;
  }

  @action
  addOrEditBeneficiary(beneficiary) {
    let { transfer, refreshBeneficiariesList } = this.args;
    this.modals.open('beneficiary-form-modal', {
      isFullScreenModal: true,
      beneficiary,
      transfer,
      refreshBeneficiariesList,
    });
  }
}
