/* import __COLOCATED_TEMPLATE__ from './rate.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

export default class VatAccountingRateComponent extends Component {
  @service intl;
  @service organizationManager;

  amountField = AmountField;

  @reads('organizationManager.organization.vatRatesCategories') vatRatesCategories;

  @cached
  get optionsVatRates() {
    return this.filteredVatRatesCategories.map(vatRate => {
      let label = '';
      if (vatRate > 0) {
        label = `${this.intl.formatNumber(vatRate)} %`;
      } else if (vatRate === null) {
        label = this.intl.t('transactions.sidebar.vat.accounting.none');
      } else {
        label =
          vatRate === 0
            ? this.intl.t('transactions.sidebar.vat.accounting.no-rate')
            : this.intl.t('transactions.sidebar.vat.accounting.other');
      }
      return {
        clearable: this.args.hideCustomInputs ? false : vatRate !== 0,
        label,
        value: vatRate,
      };
    });
  }

  get filteredVatRatesCategories() {
    if (this.args.hideCustomInputs) {
      return [
        null,
        ...this.vatRatesCategories.filter(vatRate => {
          return vatRate !== -1;
        }),
      ];
    }

    return this.vatRatesCategories;
  }

  get selectedVat() {
    return {
      clearable: Boolean(this.args.model.vatRate),
      value: this.args.model.vatRate,
    };
  }

  @action
  onAmountChange(amountValue) {
    this.args.model.set('vatAmount', amountValue);
  }

  @action
  handleSaveVatRate({ value }) {
    let model = this.args.model;
    let vatStatus = model.vatStatus;
    let vatAmount = 0;
    let amount = model.amount;

    if (this.args.hideCustomInputs && isNaN(value)) {
      return;
    }

    if (value > 0) {
      vatAmount = ((amount / (1 + value / 100) - amount) * -1).toFixed(2);
    } else if (value >= -1) {
      vatAmount = 0;
    } else {
      vatAmount = null;
      vatStatus = null;
    }

    this.args.onSelect(model, {
      vatRate: value,
      vatAmount,
      vatStatus,
    });
  }
}
