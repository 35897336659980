/* import __COLOCATED_TEMPLATE__ from './education.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Badge, Button, ExternalLink } from '@repo/design-system-kit';
import { LOCALES_DEFINITION } from '@repo/shared-config/constants/locales';

import { NAVATIC_TOUR_URLS } from 'qonto/constants/receivable-invoice';
import { Cards } from 'qonto/react/components/account-receivable/empty-state/cards';

export default class EmptyStateEducationComponent extends Component {
  @service intl;
  @service receivableInvoicesUploadManager;
  @service organizationManager;
  @service subscriptionManager;
  @service abilities;

  Badge = Badge;
  Button = Button;
  Cards = Cards;
  externalLink = ExternalLink;

  get hideTourCta() {
    // eslint-disable-next-line no-restricted-syntax -- temporary, see MR details
    return this.subscriptionManager.currentPricePlan.groupCode === 'light';
  }

  get cardsContent() {
    let { legalCountry } = this.organizationManager.organization || {};
    return [
      {
        type: 'compliant',
        illustration: '/illustrations/receivable-invoices/invoices_educational_compliant.svg',
        title: this.intl.t(
          'receivable-invoices.invoices-list.empty-state.education.compliant.title'
        ),
        list: [
          ...(['FR', 'IT'].includes(legalCountry)
            ? [
                this.intl.t(
                  'receivable-invoices.invoices-list.empty-state.education.compliant.bullet-1-with-e-invoicing'
                ),
              ]
            : ['DE', 'ES'].includes(legalCountry)
              ? [
                  this.intl.t(
                    'receivable-invoices.invoices-list.empty-state.education.compliant.bullet-1'
                  ),
                ]
              : []),
          this.intl.t('receivable-invoices.invoices-list.empty-state.education.compliant.bullet-2'),
          ['FR', 'DE', 'IT', 'ES'].includes(legalCountry)
            ? this.intl.t(
                'receivable-invoices.invoices-list.empty-state.education.compliant.bullet-3-with-vat-exemptions'
              )
            : this.intl.t(
                'receivable-invoices.invoices-list.empty-state.education.compliant.bullet-3'
              ),
        ],
      },
      {
        type: 'flexible',
        illustration: '/illustrations/receivable-invoices/invoices_educational_flexible.svg',
        title: this.intl.t(
          'receivable-invoices.invoices-list.empty-state.education.flexible.title'
        ),
        list: [
          this.intl.t('receivable-invoices.invoices-list.empty-state.education.flexible.bullet-1'),
          this.intl.t('receivable-invoices.invoices-list.empty-state.education.flexible.bullet-2'),
          ['DE'].includes(legalCountry)
            ? this.intl.t(
                'receivable-invoices.invoices-list.empty-state.education.flexible.bullet-3-with-messages'
              )
            : this.intl.t(
                'receivable-invoices.invoices-list.empty-state.education.flexible.bullet-3'
              ),
          this.intl.t('receivable-invoices.invoices-list.empty-state.education.flexible.bullet-4'),
        ],
      },
      {
        type: 'integrated',
        illustration: '/illustrations/receivable-invoices/invoices_educational_integrated.svg',
        title: this.intl.t(
          'receivable-invoices.invoices-list.empty-state.education.integrated.title'
        ),
        list: [
          this.intl.t(
            'receivable-invoices.invoices-list.empty-state.education.integrated.bullet-1'
          ),
          this.intl.t(
            'receivable-invoices.invoices-list.empty-state.education.integrated.bullet-2'
          ),
          this.intl.t(
            this.abilities.can('access accountant-access')
              ? 'receivable-invoices.invoices-list.empty-state.education.integrated.bullet-3'
              : 'receivable-invoices.invoices-list.empty-state.education.integrated.bullet-3-bankless'
          ),
        ],
      },
    ];
  }

  get navaticTourHref() {
    let userLocale = this.intl.primaryLocale;
    return NAVATIC_TOUR_URLS[userLocale] ?? NAVATIC_TOUR_URLS[LOCALES_DEFINITION.EN.code];
  }
}
