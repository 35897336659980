export default {
  "numbering-container": "cnf",
  "automatic-number-label": "cnK",
  "icon-tooltip": "cnG",
  "fix-width": "cnr",
  "dates-container": "cnb",
  "with-performance-date": "cny",
  "date-picker": "cnU",
  "label": "cnj",
  "header-text-field-container": "cnW",
  "mt-16": "cnu",
  "header-text-field": "ciS",
  "header-text-field-close-button": "cic"
};
