export default {
  "row": "qMJ",
  "disabled": "qMs",
  "cell": "qMF body-2",
  "cell-link": "qMg",
  "cell-content": "qMD",
  "item-truncate-text": "qMV",
  "item-name-cell": "qML",
  "item-account-cell": "qMT",
  "icon": "qMz",
  "icon-download": "qMa"
};
