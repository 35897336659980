/* import __COLOCATED_TEMPLATE__ from './matched-section.hbs'; */
import Component from '@glimmer/component';

import { dropTask, timeout } from 'ember-concurrency';

import { TRANSACTION_UPDATE_DELAY_MS } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesDetailsSidebarMatchedSectionComponent extends Component {
  filterGroup = {
    conditional: 'and',
    expressions: [
      {
        property: 'attachment_ids',
        values: [this.args.invoice.attachment.id],
        operator: 'in',
      },
    ],
  };

  refreshTransactionsTask = dropTask(async searchTransactions => {
    await timeout(TRANSACTION_UPDATE_DELAY_MS);
    searchTransactions();
  });
}
