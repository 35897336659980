/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class PresetsListComponent extends Component {
  @service errors;
  @service toastFlashMessages;
  @service sentry;
  @service store;
  @service organizationManager;

  constructor() {
    super(...arguments);
    this.queryPresets();
  }

  @action
  queryPresets() {
    this.queryPresetsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        if (this.errors.shouldFlash(error)) {
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      });
  }

  queryPresetsTask = dropTask(async () => {
    let presets = await this.store.query('search-preset', {
      organization_id: this.organizationManager.organization.id,
    });
    this.args.onLoad?.();
    return presets;
  });
}
