export default {
  "header-cell": "qOU",
  "empty": "qOj",
  "header-type": "qOW",
  "header-requester": "qOu",
  "header-amount": "qmS",
  "header-approval": "qmc",
  "header-content": "qmq caption-bold",
  "active": "qmZ",
  "row-compact": "qmR",
  "row-sidebar": "qmQ",
  "hidden": "qme",
  "animated": "qmB",
  "fadein-item": "qmE",
  "animated-cell": "qmd"
};
