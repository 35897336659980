/* import __COLOCATED_TEMPLATE__ from './row-v2.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Checkbox } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask, race, rawTimeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
import { INVOICE_STATUSES, SOURCE_TYPES, TABS } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesTableRowComponent extends Component {
  checkbox = Checkbox;

  @service abilities;
  @service intl;
  @service store;
  @service requestsManager;
  @service notifierManager;
  @service organizationManager;

  @tracked isRowActive = false;

  get showPendingInvoiceQuickActions() {
    return this.args.tab === TABS.TASKS;
  }

  get requesterName() {
    let { requestTransfer } = this.args.invoice || {};

    if (requestTransfer?.initiator_id) {
      let { fullName } = this.store.peekRecord('membership', requestTransfer.initiator_id) || {};

      return fullName || '-';
    }
  }

  get supplierName() {
    let {
      invoiceNumber,
      isAttachmentNonFinancial,
      source,
      hasDuplicates,
      selfInvoiceId,
      status,
      id,
      supplierSnapshot,
    } = this.args.invoice;

    let isItalian = this.organizationManager.organization?.isItalian;

    return {
      id: id || '-',
      status: status || '-',
      title: supplierSnapshot?.name || '-',
      subtitle: invoiceNumber || '-',
      missing: !supplierSnapshot?.name,
      isEInvoice: source === SOURCE_TYPES.E_INVOICING && isItalian,
      hasSelfInvoice: selfInvoiceId,
      hasDuplicates,
      isAttachmentNonFinancial,
    };
  }

  get totalAmount() {
    let { totalAmount, isCreditNote } = this.args.invoice;
    return {
      title: this.intl.formatMoney(totalAmount?.value, {
        currency: totalAmount?.currency,
        // The condition will be removed after the experiment for ap-credit-notes
        signus: variation('experiment--boolean-ap-credit-notes')
          ? isCreditNote
            ? '-'
            : null
          : '-',
      }),
      missing: !totalAmount || !totalAmount.value,
    };
  }

  get showInvoiceSelector() {
    return (
      [TABS.ALL_INVOICES, TABS.INBOX, TABS.TO_PAY].includes(this.args.tab) &&
      this.abilities.can('update supplier-invoice')
    );
  }

  get isSupplierInvoiceReviewable() {
    let { invoice, status } = this.args;
    let { selfInvoiceId } = invoice;

    return status === INVOICE_STATUSES.toReview && !selfInvoiceId;
  }

  get specificCells() {
    let { invoice, tab } = this.args;
    let { paymentDate, dueDate, status, matchedTransactionsIds } = invoice;

    let showOverdue =
      dueDate && status === INVOICE_STATUSES.toReview && dayjs().isAfter(new Date(dueDate));

    let cellTypes = {
      [TABS.TASKS]: {
        ...(this.abilities.can('load transfer request') ? { requester: this.requesterName } : {}),
        dueDate: {
          date: dueDate
            ? dateToken({
                date: dueDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              })
            : '-',
          overdue: dueDate ? dayjs().isAfter(new Date(dueDate)) : false,
          missing: !dueDate,
        },
      },
      [TABS.ALL_INVOICES]: {
        status,
        dueDate: {
          date: dueDate
            ? dateToken({
                date: dueDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              })
            : '-',
          overdue: showOverdue,
          missing: !dueDate,
        },
        paymentDate: {
          date: ![INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid].includes(status)
            ? '-'
            : dateToken({
                date: paymentDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              }),
          withClockIcon: INVOICE_STATUSES.scheduled === status,
        },
        transactions: {
          shouldDisplayTransactionsBadge: true,
          hasMatchedTransactions: Boolean(matchedTransactionsIds.length),
        },
      },
      [TABS.INBOX]: {
        dueDate: {
          date: dueDate
            ? dateToken({
                date: dueDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              })
            : '-',
          overdue: dueDate ? dayjs().isAfter(new Date(dueDate)) : false,
          missing: !dueDate,
        },
      },
      [TABS.TO_PAY]: {
        dueDate: {
          date: dueDate
            ? dateToken({
                date: dueDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              })
            : '-',
          overdue: dueDate ? dayjs().isAfter(new Date(dueDate)) : false,
          missing: !dueDate,
        },
      },
      [TABS.SCHEDULED]: {
        dueDate: {
          date: dueDate
            ? dateToken({
                date: dueDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              })
            : '-',
          missing: !dueDate,
        },
        paymentDate:
          status === INVOICE_STATUSES.archived
            ? '-'
            : dateToken({
                date: paymentDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              }),
      },
      [TABS.COMPLETED]: {
        status,
        paymentDate:
          status === INVOICE_STATUSES.archived
            ? '-'
            : dateToken({
                date: paymentDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              }),
        transactions: {
          shouldDisplayTransactionsBadge: true,
          hasMatchedTransactions: Boolean(matchedTransactionsIds.length),
        },
      },
    };

    return cellTypes[tab];
  }

  @action
  toggleRowState(boolean) {
    this.isRowActive = boolean;
  }

  approveRequestTask = dropTask(async ({ supplierInvoice, bankAccount }) => {
    let requestId = supplierInvoice.requestTransfer?.id;
    let request = await this.store.findRecord('request-transfer', requestId);
    request.bankAccount = bankAccount;
    await this.requestsManager.quickApproveTransferRequestTask.perform(request);
    let isInvoiceUpdated = this.notifierManager.waitForEventTask.perform(
      SUPPLIER_INVOICE_EVENTS.SCHEDULED
    );
    await race([isInvoiceUpdated, rawTimeout(1000)]);
  });

  rejectRequestTask = dropTask(async supplierInvoice => {
    let requestId = supplierInvoice.requestTransfer?.id;
    let request = await this.store.findRecord('request-transfer', requestId);
    await this.requestsManager.quickRejectTransferRequestTask.perform(request);
    let isInvoiceUpdated = this.notifierManager.waitForEventTask.perform(
      SUPPLIER_INVOICE_EVENTS.REQUEST_DECLINED
    );
    await race([isInvoiceUpdated, rawTimeout(1000)]);
  });
}
