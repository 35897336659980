export default {
  "mileage-validation": "qZL",
  "actions": "qZT",
  "action-btn": "qZz",
  "approve-btn": "qZa",
  "request-mileage-validation": "qZH",
  "account-selector": "qZO",
  "has-error": "qZm",
  "funds-disclaimer": "qZp",
  "account-options": "qZx",
  "account-balance": "qZw",
  "warnings": "qZN"
};
