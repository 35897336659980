/* import __COLOCATED_TEMPLATE__ from './illustration.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class QStateIllustration extends Component {
  lottiePlayer = LottiePlayer;

  @service sentry;

  @action
  handleError(error) {
    if (Boolean(error.status) && error.status >= 400 && error.status < 500) {
      this.sentry.captureException(error);
    }
  }
}
