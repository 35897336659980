/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';
import { alias } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import { ES_FREELANCER_LEGAL_FORM, INVOICED_STATUS } from 'qonto/constants/receivable-invoice';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class ReceivableInvoicesFormComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service store;
  @service organizationManager;
  @service intl;
  @service router;
  @service zendeskLocalization;

  @alias('args.invoice.customer.content') selectedCustomer;

  get isQuote() {
    return this.args.isQuote;
  }

  get isFromQuote() {
    return this.args.isFromQuote;
  }

  // only for invoice created from a quote that has already some linked invoices with the same currency
  get showInvoicedAmount() {
    return (
      this.isFromQuote &&
      this.args.invoice.quote?.get('invoicedStatus') !== INVOICED_STATUS.CURRENCY_MISMATCH &&
      this.currency === this.args.invoice.quote?.get('currency') &&
      this.args.invoice.quote?.get('invoicedAmount') &&
      Number(this.args.invoice.quote?.get('invoicedAmount')) !== 0
    );
  }

  get currency() {
    return this.args.invoice?.currency || CURRENCIES.default;
  }

  get quoteCurrency() {
    return this.args.invoice.quote?.get('currency') || CURRENCIES.default;
  }

  get invoicedAmountDisclaimer() {
    return this.intl.t('receivable-invoices.invoice-creation.partially-invoiced.disclaimer', {
      quoteNumber: this.args.invoice?.quote.get('number'),
      quoteTotalAmount: this.intl.formatNumber(this.args.invoice?.quote.get('amountDue'), {
        currency: this.quoteCurrency,
        style: 'currency',
      }),
      invoicedAmount: this.intl.formatNumber(this.args.invoice?.quote.get('invoicedAmount'), {
        currency: this.quoteCurrency,
        style: 'currency',
      }),
      htmlSafe: true,
    });
  }

  get origin() {
    // storing the current route, to be used when transitioning to the invoicing-settings route
    return this.router?.currentRoute;
  }

  get formOrigin() {
    // storing params if any (document id), to be used when transitioning to the invoicing-settings route
    let { currentRoute } = this.router;
    let params = currentRoute?.params?.id;

    // when transitioning to the invoicing-settings route, the query obj will contained the origin route
    // as the transition.from is not always available
    return { origin: this.origin?.name, params };
  }

  get fullListDisclaimer() {
    if (this.isQuote) {
      let quoteDisclaimer = {
        firstPart: this.intl.t(
          'receivable-invoices.quote-creation.additional-details.terms.disclaimer.body-1'
        ),
        href: this.intl.t(
          'receivable-invoices.quote-creation.additional-details.terms.disclaimer.url',
          {
            faqUrl: this.zendeskLocalization.getLocalizedArticle(7958908),
          }
        ),
        textLink: this.intl.t(
          'receivable-invoices.quote-creation.additional-details.terms.disclaimer.link'
        ),
        secondPart: this.intl.t(
          'receivable-invoices.quote-creation.additional-details.terms.disclaimer.body-2'
        ),
      };
      return quoteDisclaimer;
    }
    let invoiceDisclaimer = {
      firstPart: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.part-1'
      ),
      href: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.faq-link',
        {
          legalCountry: this.legalCountry,
          faqUrl: this.zendeskLocalization.getLocalizedArticle(6211274),
        }
      ),
      textLink: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.faq-text-link'
      ),
      secondPart: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.part-2'
      ),
    };
    return invoiceDisclaimer;
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get customers() {
    if (variation('feature--boolean-client-hub')) {
      let clients = this.store
        .peekAll('client-hub')
        .filter(customer => {
          return (
            customer.belongsTo('organization').id() === this.organizationManager.organization.id &&
            !customer.isNew
          );
        })
        .sort(sortByKey('name'));

      //This is to be cleaned up with the FF above, meanwhile customers will be used as fallback
      if (clients.length) {
        return clients;
      }
    }
    return this.store
      .peekAll('customer')
      .filter(customer => {
        return (
          customer.belongsTo('organization').id() === this.organizationManager.organization.id &&
          !customer.isNew
        );
      })
      .sort(sortByKey('name'));
  }

  get isEInvoicing() {
    return this.legalCountry === 'IT';
  }

  get isGermanOrganization() {
    return this.legalCountry === 'DE';
  }

  get isFrenchOrganization() {
    return this.legalCountry === 'FR';
  }

  get isQuoteAndDEInvoicing() {
    return this.isQuote && this.isGermanOrganization;
  }

  get showTermsAndConditionsDisclaimer() {
    return !this.isQuote && !this.isGermanOrganization && !this.isFrenchOrganization;
  }

  get isSpanishFreelancer() {
    return (
      this.legalCountry === 'ES' &&
      this.organizationManager.organization.legalForm === ES_FREELANCER_LEGAL_FORM
    );
  }

  get showFiscalDetails() {
    let clientId = this.args.invoice?.customer?.get('id');
    let client = clientId ? this.store.peekRecord('client-hub', clientId) : null;
    let clientBillingCountry = client?.get('billingAddress.countryCode');

    return (
      this.isEInvoicing ||
      (variation('improvement--boolean-invoicing-es-irpf') &&
        this.isSpanishFreelancer &&
        clientBillingCountry === 'ES')
    );
  }
}
