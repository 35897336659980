export default {
  "sidepanel": "qkf",
  "close-icon": "qkK",
  "close-button": "qkG",
  "supplier-card": "qkr",
  "supplier-card-details": "qkb",
  "supplier-name": "qky title-3 mb-8",
  "supplier-card-placeholder": "qkU",
  "box": "qkj",
  "box-element": "qkW",
  "related-invoices-title": "qku",
  "placeholder-container": "qJS",
  "related-invoices-wrapper": "qJc",
  "related-invoice-placeholder": "qJq",
  "related-invoice-placeholder-inner": "qJZ",
  "actions": "qJR",
  "currency": "qJQ"
};
