/* import __COLOCATED_TEMPLATE__ from './insight-pill-v2.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DUE_FILTER_EVENT_MAP } from 'qonto/constants/supplier-invoice';
import { Counter } from 'qonto/react/components/counter';

export default class SupplierInvoicesInsightPillV2 extends Component {
  @service keyboardFocus;
  @service abilities;
  @service intl;

  @tracked focused = false;

  Counter = Counter;

  constructor() {
    super(...arguments);
    this.keyboardFocus.init();
  }

  get eventProps() {
    return { card_type: DUE_FILTER_EVENT_MAP[this.args.query.dueDate] };
  }

  get hideMissingAmounts() {
    return this.args.isLoading || !this.args.missingAmounts;
  }

  get missingAmountsMessage() {
    let missingAmounts = this.intl.formatNumber(this.args.missingAmounts);

    return this.intl.t('supplier-invoices.cockpit.to-pay-today.missing-amount-tooltip-label', {
      count: missingAmounts,
    });
  }

  get isInteractive() {
    return this.args.currentInsight > 0 && this.args.query;
  }

  @action
  setFocus(focused) {
    this.focused = focused;
  }
}
