/* import __COLOCATED_TEMPLATE__ from './amount-with-reference.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';

export default class TransferFormAmountWithReference extends Component {
  @service intl;

  @tracked isAmountFieldDirty = false;

  amountField = AmountField;

  get shouldDisplayAmountValidationErrorMessage() {
    return this.isAmountFieldDirty || this.args.enableTransferValidations;
  }

  @action onAmountChange(amountValue) {
    this.args.transfer.set('amount', amountValue);
  }

  @action onAmountFocus() {
    this.isAmountFieldDirty = true;
  }
}
