export default {
  "section-container": "qqA",
  "disclaimer-section": "qqY",
  "trip-title": "qqh caption-bold mb-16",
  "google-link-container": "qqf",
  "link-icon": "qqK",
  "trip-details": "qqG body-2",
  "trip-details-icon": "qqr",
  "trip-details-title": "qqb",
  "subtitle": "qqy caption-bold mb-16",
  "disclaimer": "qqU",
  "visualizer": "qqj"
};
