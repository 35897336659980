export default {
  "request-expense-report-validation": "qqz",
  "account-selector": "qqa",
  "has-error": "qqH",
  "actions": "qqO",
  "action-btn": "qqm",
  "action-btn-success": "qqp",
  "warnings": "qqx",
  "top-border": "qqw",
  "account-selector-subtitle": "qqN",
  "action-btn-right": "qqv",
  "funds-disclaimer": "qqo",
  "account-options": "qqn",
  "account-balance": "qqi"
};
