/* import __COLOCATED_TEMPLATE__ from './organization-card.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { ORIGIN } from 'qonto/routes/flows/setup/subscription/close';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class OrganizationCard extends Component {
  @service intl;
  @service sentry;

  origin = ORIGIN.COMPANY_PROFILE;

  get address() {
    let ocr = this.args.kycKybUpdateOrganizationChangeRequest;

    if (!ocr.addressCountry || !ocr.addressFirstLine || !ocr.addressZipCode || !ocr.addressCity) {
      return false;
    }

    let country = this.intl.formatCountry(ocr.addressCountry);
    return `${ocr.addressFirstLine}, ${ocr.addressZipCode} ${ocr.addressCity} ${country}`;
  }

  get legalFormValue() {
    let legalCode = this.args.kycKybUpdateOrganizationChangeRequest.legalCode;
    let legalCountry = this.args.kycKybUpdateOrganizationChangeRequest
      ?.get('kycKybUpdateProcess')
      ?.get('organization')
      ?.get('legalCountry');

    if (legalCode && legalCountry) {
      try {
        let companyTypeOption = this.intl.t(
          `organizations.profile.company_profile.company_type_option.${legalCountry}.${legalCode}`
        );

        return companyTypeOption;
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        return null; // mitigation for incorrect legalCountry vs legalCode
      }
    }
    return null;
  }

  get isGermanSoloLegalFormOrGbr() {
    return (
      this.args.kycKybUpdateOrganizationChangeRequest.isGbR ||
      this.args.kycKybUpdateOrganizationChangeRequest.isGermanFreelancer
    );
  }

  get legalNumberLabel() {
    let legalCountry = this.args.kycKybUpdateOrganizationChangeRequest.get(
      'kycKybUpdateProcess.organization.legalCountry'
    );
    return this.isGermanSoloLegalFormOrGbr
      ? this.intl.t(
          'organizations.profile.company_profile.organization_info.legal_number_solo_and_gbr'
        )
      : this.intl.t('organizations.profile.company_profile.organization_info.legal_number', {
          country: legalCountry,
        });
  }
}
