/* import __COLOCATED_TEMPLATE__ from './custom-period.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

import { PERIOD_KEYS } from 'qonto/constants/overview';

export default class CustomPeriodComponent extends Component {
  @service intl;

  @tracked startDate;
  @tracked _endDate;
  @tracked isPristine = true;

  constructor() {
    super(...arguments);
    this.startDate =
      this.args.selectedPeriod?.key === PERIOD_KEYS.CUSTOM_PERIOD
        ? this.args.selectedPeriod.startDate
        : dayjs().startOf('day').toDate();
    this._endDate = this.args.selectedPeriod?.endDate;
  }

  get endDate() {
    if (this._endDate) {
      if (dayjs(this.startDate).isAfter(this._endDate)) {
        return dayjs(this.startDate).add(1, 'day').endOf('day').toDate();
      } else {
        return dayjs(this._endDate).endOf('day').toDate();
      }
    } else if (this.args.type !== 'date-picker' && this.startDate) {
      return dayjs(this.startDate).add(11, 'month').endOf('month').toDate();
    }
  }

  set endDate(date) {
    this._endDate = date;
  }

  get period() {
    let { startDate, endDate } = this;

    if (startDate && endDate) {
      return {
        label: this.intl.t('overview.period-selection.custom-period'),
        key: PERIOD_KEYS.CUSTOM_PERIOD,
        startDate,
        endDate,
      };
    }
  }

  get isDisabled() {
    return !this.startDate || !this.endDate || this.isPristine;
  }

  @action
  onSelectCustomPeriod(startDate) {
    // we must reset the cached _endDate, otherwise it will never update
    this.endDate = undefined;
    this.onSelectStartDate(startDate);
  }

  @action
  onSelectStartDate(date) {
    this.startDate = date;
    this.isPristine = false;
  }

  @action
  onSelectEndDate(date) {
    this.endDate = date;
    this.isPristine = false;
  }

  @action
  onApply(event) {
    event.preventDefault();
    if (!this.period || !this.args.onSave) return;

    this.args.onSave(this.period, this.args.close);
  }
}
