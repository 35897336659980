export default {
  "modal": "qDR",
  "section": "qDQ",
  "header": "qDe",
  "title": "qDB",
  "separator-title": "qDE",
  "icon": "qDd",
  "formats": "qDI",
  "columns": "qDt",
  "column-list": "qDM",
  "draggable-column": "qDP",
  "column-field": "qDl",
  "column-selector": "qDX",
  "column-with-error": "qDC",
  "handle": "qDk",
  "label-color": "qDJ",
  "disclaimer": "qDs"
};
