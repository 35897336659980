export default {
  "team-card": "qgQ",
  "card-link": "qge",
  "shadow": "qgB",
  "history-button": "qgE",
  "cards-button": "qgd",
  "menu-item": "qgI",
  "subtitle": "qgt",
  "delete-team": "qgM"
};
