/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/requests';

export default class ReimbursementsRequestsSidebarExpenseReportHeaderComponent extends Component {
  @service intl;

  get lastActionDate() {
    let { processedAt, createdAt } = this.args.request;
    return processedAt || createdAt;
  }

  get titleStatus() {
    let { approver, initiator, status } = this.args.request;
    let pastStatuses = [STATUS.CANCELED, STATUS.DECLINED, STATUS.APPROVED];

    if (pastStatuses.includes(status)) {
      let user = approver || initiator;
      let { firstName, lastName } = user.getProperties('firstName', 'lastName');
      let fullName = `${firstName.charAt(0)}. ${lastName}`;

      let statuses = {
        [STATUS.DECLINED]: this.intl.t('requests.sidebar.header.status.declined', {
          approver_name: fullName,
        }),
        [STATUS.APPROVED]: this.intl.t('requests.sidebar.header.status.reimbursed', {
          approver_name: fullName,
        }),
        [STATUS.CANCELED]: this.intl.t('requests.transfers.final_status.canceled', {
          name: fullName,
        }),
      };

      return statuses[status];
    }

    return this.intl.t('requests.sidebar.header.status.pending');
  }

  get subtitle() {
    let { status } = this.args.request;
    let formattedDate = this.intl.formatDate(this.lastActionDate, { format: 'short' });

    if (status === 'pending') {
      return this.intl.t('checks.sidebar.requested_at') + ' ' + formattedDate;
    } else if (['declined', 'approved'].includes(status)) {
      return this.intl.t('requests.sidebar.header.updated-date', { updated_at: formattedDate });
    }

    return formattedDate;
  }
}
