export default {
  "wrapper": "cOX",
  "header": "cOC",
  "fadeIn": "cOk",
  "header-title": "cOJ title-4",
  "transactions-container": "cOs",
  "placeholder-title": "cOF",
  "transaction-item-list-container": "cOg",
  "transaction-item-list": "cOD",
  "item": "cOV"
};
