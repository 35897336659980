export default {
  "row": "cyt body-1",
  "active": "cyM",
  "cell": "cyP",
  "empty": "cyl",
  "ellipsis": "cyX",
  "text-secondary": "cyC",
  "transfer-type": "cyk",
  "transfer-type__icon": "cyJ",
  "quick-actions": "cys",
  "amount": "cyF",
  "cell-approve": "cyg",
  "account-select": "cyD",
  "row-compact": "cyV",
  "cell-amount": "cyL",
  "cell-content-receipt": "cyT body-1",
  "cell-date": "cyz",
  "row-sidebar": "cya",
  "hidden": "cyH",
  "animated": "cyO",
  "fadein-item": "cym",
  "animated-cell": "cyp",
  "note": "cyx",
  "checkbox": "cyw"
};
