/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

export default class SubscriptionPromocodeFormComponent extends Component {
  @service intl;
  @service store;
  @service toastFlashMessages;
  @service segment;
  @service sentry;

  @tracked promoCode = null;
  @tracked errorMessage = null;

  @action
  updatePromoCode(val) {
    this.promoCode = val;
    this.errorMessage = null;
  }

  saveDiscountTask = dropTask(async () => {
    if (!this.promoCode?.trim()) {
      this.errorMessage = this.intl.t('subscription.promo-code.form.empty-error');
      return;
    }

    let { organization } = this.args;
    let discount = this.store.createRecord('discount', {
      organization,
      voucherCode: this.promoCode,
    });

    let outcome;
    try {
      await discount.save();

      let message = this.intl.t('subscription.promo-code.form.success-toast');
      this.toastFlashMessages.toastSuccess(message);

      this.promoCode = null;
      this.errorMessage = null;
      outcome = 'success';
    } catch (error) {
      let errors = discount.errors.get('voucherCode') || [];
      discount.unloadRecord();

      if (errors.some(({ message: m }) => m === 'invalid')) {
        this.errorMessage = this.intl.t('subscription.promo-code.form.invalid-error');
        outcome = 'invalid';
      } else if (errors.some(({ message: m }) => m === 'already_used')) {
        this.errorMessage = this.intl.t('subscription.promo-code.form.used-error');
        outcome = 'already_used';
      } else if (errors.some(({ message: m }) => m === 'reached_limit')) {
        this.errorMessage = this.intl.t('subscription.promo-code.form.reached-limit-error');
        outcome = 'reached_limit';
      } else if (error.errors?.discounts === 'rate_limit_reached') {
        this.errorMessage = this.intl.t('subscription.promo-code.form.rate-limit-reached-error');
        outcome = 'rate_limit_reached';
      } else {
        outcome = 'backend_error';
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    } finally {
      this.args.onPromoCodeSave();
      if (outcome) {
        this.segment.track('subscription_promo_clicked', { outcome });
      }
    }
  });
}
