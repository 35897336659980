/* import __COLOCATED_TEMPLATE__ from './delivery-address-selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CustomerFormDeliveryAddressSelector extends Component {
  @service abilities;
  @service intl;
  @service segment;

  @tracked checked = this.hasDeliveryAddress;

  get isActive() {
    return this.checked || this.hasDeliveryAddress;
  }

  get hasDeliveryAddress() {
    let { deliveryAddress } = this.args.customer;
    return Boolean(deliveryAddress);
  }

  @action
  toggleDeliveryAddress() {
    let { customer } = this.args;
    this.checked = !this.checked;

    if (this.args.isEdit) {
      this.segment.track('invoice-creation_client-edition_delivery-address', {
        status: this.checked ? 'enabled' : 'disabled',
      });
    } else {
      this.segment.track('invoice-creation_client-creation_delivery-address', {
        status: this.checked ? 'enabled' : 'disabled',
      });
    }

    if (!this.checked) {
      customer.setProperties({ deliveryAddress: null });
    } else {
      customer.setProperties({
        deliveryAddress: { countryCode: customer.billingAddress.countryCode },
      });
    }

    this.args.onToggle?.();
  }

  @action onUpdateDeliveryAddressCountryCode(countryCode) {
    let { customer } = this.args;
    customer.set('deliveryAddress', {
      ...customer.deliveryAddress,
      countryCode,
    });
  }
}
