export default {
  "wrapper": "cKT",
  "tabs": "cKz",
  "tabs-nav": "cKa",
  "tabs-panel": "cKH",
  "tabs-panel-wrapper": "cKO",
  "list-head": "cKm",
  "list": "cKp",
  "footer": "cKx",
  "reward-amount": "cKw",
  "rewarded": "cKN",
  "registered": "cKv",
  "disclaimer": "cKo",
  "empty-state-wrapper": "cKn",
  "empty-state": "cKi",
  "illustration": "cKA",
  "spinner": "cKY",
  "total-earned": "cKh"
};
