export default {
  "subtitle": "cWW",
  "green-text": "cWu",
  "balance-subtitle": "cuS",
  "options": "cuc",
  "shadow": "cuq",
  "menu-item": "cuZ body-2",
  "close-account": "cuR",
  "footer": "cuQ",
  "progress": "cue",
  "progress-bar": "cuB",
  "progress-bar-text": "cuE",
  "progress-active": "cud"
};
