export default {
  "sidebar-header": "qTz",
  "sidebar-header__top": "qTa",
  "sidebar-header__body": "qTH",
  "sidebar-header__content": "qTO",
  "sidebar-header__date": "qTm",
  "infobox": "qTp",
  "infobox--link": "qTx",
  "ghost": "qTw",
  "fail": "qTN",
  "r-transaction-disclaimer": "qTv",
  "hold-disclaimer": "qTo",
  "transaction-link": "qTn body-2",
  "icon-link": "qTi"
};
