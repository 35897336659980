/* import __COLOCATED_TEMPLATE__ from './activation-levers.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class PopupConfirmationActivationLeversComponent extends Component {
  @service segment;
  @service flowLinkManager;

  constructor() {
    super(...arguments);
    this.segment.track('request_modal_opened');
  }

  confirmTask = dropTask(async () => {
    this.segment.track('request_modal_accepted');
    await this.args.close();
    this._setSawActivationLeversModal();
    this.flowLinkManager.transitionTo({
      name: 'requests-cards',
      stepId: 'budget',
      queryParams: { cardLevel: CARD_LEVELS.FLASH },
    });
  });

  @action
  close() {
    this.segment.track('request_modal_canceled');
    this._setSawActivationLeversModal();
    this.args.close();
  }

  _setSawActivationLeversModal() {
    safeLocalStorage.setItem('saw-activation-levers-modal', true);
  }
}
