export default {
  "header-cell": "cws",
  "checkbox-cell": "cwF",
  "title-col": "cwg",
  "internal-note-col": "cwD",
  "unit-col": "cwV",
  "price-col": "cwL",
  "header-content": "cwT caption-bold",
  "active": "cwz",
  "empty": "cwa",
  "align-checkbox": "cwH"
};
