export default {
  "label-select": "qtu",
  "puce": "qMS",
  "puce-wrapper": "qMc",
  "puce-container": "qMq",
  "readonly": "qMZ",
  "label-display": "qMR",
  "label-list-name": "qMQ body-1",
  "label-list-value": "qMe body-2"
};
