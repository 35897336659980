/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { EmailPreviewFooter } from 'qonto/react/components/receivable-invoices/email-preview';

export default class EmailPreviewComponent extends Component {
  emailPreviewFooter = EmailPreviewFooter;

  @service intl;
  @service theme;

  get footerDisclaimer() {
    return this.intl
      .t('receivable-invoices.share-email.preview.credit-note-disclaimer', {
        language: this.args.displayLanguage,
        htmlSafe: true,
      })
      .toString();
  }

  get currency() {
    return this.args.creditNote.currency || 'EUR';
  }
}
