export default {
  "insight-pill": "qXf",
  "insight-pill-figure": "qXK",
  "insight-pill-renderer": "qXG",
  "loading": "qXr",
  "counter": "qXb",
  "error": "qXy",
  "insight-pill-label": "qXU",
  "insight-pill-subtitle": "qXj caption",
  "hidden": "qXW",
  "missing-amounts-warning": "qXu",
  "missing-amounts": "qCS"
};
