/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */

import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { and, reads } from 'macro-decorators';

import { FINANCING_TRANSFER_DECLINED_REASON } from 'qonto/constants/financing';
import { ROLES } from 'qonto/constants/membership';
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { STATUS, TRANSFER_DECLINED_REASONS, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';

export default class TransferSidebarHeaderComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;
  @service organizationManager;
  @service abilities;
  @service toastFlashMessages;
  @service flowLinkManager;
  @service segment;
  @service zendeskLocalization;
  @service router;

  @reads('organizationManager.organization.remuneratedAccounts')
  remuneratedAccounts;

  @reads('args.transfer.initiator')
  membership;

  @and(
    'args.transfer.bankAccount.name',
    'args.transfer.bankAccount.organization.hasMultipleBankAccounts'
  )
  showAccountName;

  Disclaimer = Disclaimer.Block;

  get isPastTransfer() {
    let { status, transaction } = this.args.transfer;

    let pastTransferStatus = [
      STATUS.ACCOUNT_CANCELED,
      STATUS.BENEFICIARY_ACCOUNT_CANCELED,
      STATUS.CANCELED,
      STATUS.COMPLETED,
      STATUS.DECLINED,
    ];

    return pastTransferStatus.includes(status) && transaction;
  }

  get nextRecursionDate() {
    let { scheduled, isDeclined, isCanceled, isStanding } = this.args.transfer;

    if (scheduled && !isDeclined && !isCanceled && isStanding) {
      return this.args.transfer.nextRecursionDate;
    }
  }

  get isRemuneratedBeneficiary() {
    return this.remuneratedAccounts?.some(
      remuneratedAccount => remuneratedAccount.id === this.args.transfer.creditBankAccountId
    );
  }

  get isPersonalInfoProvided() {
    return this.organizationManager.membership.personalInfoProvided;
  }

  get transferHeaderTypeWording() {
    let { operationType, status, instant } = this.args.transfer;

    return instant
      ? this._getInstantTransferStatus(status)
      : this._getRegularTransferStatus(status, operationType);
  }

  get statusClassName() {
    let { status } = this.args.transfer;
    let classNames = {
      declined: 'error',
      canceled: 'error',
      completed: 'success',
    };
    return classNames[status];
  }

  get shouldDisplayRepeatTransfer() {
    return this.abilities.can('create transfer') && this.args.transfer.isRepeatable;
  }

  get hasValidDeclinedReason() {
    let declinedReason = this.args.transfer.declinedReason;
    return declinedReason && Object.values(TRANSFER_DECLINED_REASONS).includes(declinedReason);
  }

  get declinedReason() {
    switch (this.args.transfer.declinedReason) {
      case TRANSFER_DECLINED_REASONS.ACCOUNT_UNREACHABLE:
        return this.intl.t('transfers.declined-reasons.unreachable-account');

      case TRANSFER_DECLINED_REASONS.BENEFICIARY_ALREADY_USED_BY_ANOTHER:
        return this.intl.t('transfers.declined-reasons.beneficiary-already-used-by-another', {
          organizationSlug: this.organizationSlug,
          htmlSafe: true,
          faqUrl: this.zendeskLocalization.getLocalizedArticle(4359541),
        });

      case TRANSFER_DECLINED_REASONS.BENEFICIARY_BIC_INVALID:
        return this.intl.t('transfers.declined-reasons.beneficiary-bic-invalid');

      case TRANSFER_DECLINED_REASONS.BENEFICIARY_IBAN_INVALID:
        return this.intl.t('transfers.declined-reasons.beneficiary-iban-invalid');

      case TRANSFER_DECLINED_REASONS.BENEFICIARY_CANCELED:
      case TRANSFER_DECLINED_REASONS.INST_BENEFICIARY_NOT_SEPA:
      case TRANSFER_DECLINED_REASONS.INVALID_BENEFICIARY_DATA:
        return this.intl.t('transfers.declined-reasons.invalid-beneficiary');

      case TRANSFER_DECLINED_REASONS.INST_BENEFICIARY_INVALID:
        return this.intl.t('transfers.declined-reasons.instant-invalid-beneficiary');

      case TRANSFER_DECLINED_REASONS.BENEFICIARY_NOT_SEPA:
        return this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.body', {
          transferFaqLink: htmlSafe(
            `<a
              href="${this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.url', {
                faqUrl: this.zendeskLocalization.getLocalizedArticle(4359546),
              })}"
              target="_blank"
              rel="noopener noreferrer"
              data-test-transfer-sidebar-declined-reason-link
            >${this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.link-text')}</a>`
          ),
          htmlSafe: true,
        });

      case TRANSFER_DECLINED_REASONS.CREDITOR_TIMEOUT:
      case TRANSFER_DECLINED_REASONS.FORMAT_REJECTED:
      case TRANSFER_DECLINED_REASONS.PAYMENT_FAILED:
      case TRANSFER_DECLINED_REASONS.PROCESSING_ERROR:
        return this.intl.t('transfers.declined-reasons.processing-error');

      case TRANSFER_DECLINED_REASONS.OPERATIONAL_ERROR:
      case TRANSFER_DECLINED_REASONS.DISPOSITION_ERROR:
      case TRANSFER_DECLINED_REASONS.SCREENING_REJECTED:
        return this.intl.t('transfers.declined-reasons.regulatory-error');
      case TRANSFER_DECLINED_REASONS.INST_PROCESSING_ERROR:
        return this.intl.t('transfers.declined-reasons.account-type-not-supported');

      case TRANSFER_DECLINED_REASONS.FX_BENEFICIARY_INVALID:
        return this.intl.t('transfers.declined-reasons.fx-beneficiary-invalid');

      case TRANSFER_DECLINED_REASONS.INCOHERENT_KYC_LEVEL:
        return this.intl.t('transfers.declined-reasons.incoherent-kyc-level', {
          organizationSlug: this.organizationSlug,
          htmlSafe: true,
          faqUrl: this.zendeskLocalization.getLocalizedArticle(4359541),
        });

      case TRANSFER_DECLINED_REASONS.INSUFFICIENT_FUNDS:
        if (this.organizationManager.membership.role === ROLES.MANAGER) {
          return this.intl.t('transfers.declined-reasons.manager.insufficient-funds.body', {
            topUpFaqLink: htmlSafe(
              `<a
                href="${this.intl.t('transfers.declined-reasons.manager.insufficient-funds.url', {
                  faqUrl: this.zendeskLocalization.getLocalizedArticle(4359537),
                })}"
                target="_blank"
                rel="noopener noreferrer"
                data-test-transfer-sidebar-declined-reason-link
              >${this.intl.t(
                'transfers.declined-reasons.manager.insufficient-funds.link-text'
              )}</a>`
            ),
            htmlSafe: true,
          });
        }
        return this.intl.t('transfers.declined-reasons.insufficient-funds', {
          htmlSafe: true,
        });

      case TRANSFER_DECLINED_REASONS.KYC_LEVEL_REFUSED:
        return this.intl.t('transfers.declined-reasons.kyc-level-refused', {
          organizationSlug: this.organizationSlug,
          htmlSafe: true,
          faqUrl: this.zendeskLocalization.getLocalizedArticle(4359541),
        });

      case TRANSFER_DECLINED_REASONS.USER_ASSETS_FROZEN:
        return this.intl.t('transfers.declined-reasons.user-assets-frozen', {
          organizationSlug: this.organizationSlug,
          htmlSafe: true,
          faqUrl: this.zendeskLocalization.getLocalizedArticle(4359541),
        });
    }
  }

  get disclaimerLevel() {
    let { isUserInitiator, isUserKycSubmittedAndPending } = this.args;

    if (isUserInitiator) {
      return isUserKycSubmittedAndPending ? 'info' : 'warning';
    }

    return 'info';
  }

  get shouldDisplayAmount() {
    let { transfer } = this.args;
    let { amountCurrency, localAmountCurrency } = transfer;

    return (
      amountCurrency !== localAmountCurrency &&
      transfer.get('beneficiary') &&
      transfer.get('beneficiary.fx')
    );
  }

  get shouldDisplayFinancingNotCompliantDisclaimer() {
    return this.args.transfer.declinedReason === FINANCING_TRANSFER_DECLINED_REASON;
  }

  @action
  repeatTransfer() {
    let { transfer } = this.args;
    this.segment.track('transfer-side-panel_repeat-transfer_clicked');

    if (transfer.get('beneficiary') && !transfer.get('beneficiary.hidden')) {
      return this.flowLinkManager.replaceWith({
        name: 'sepa-transfer',
        stepId: 'details',
        queryParams: {
          repeatedTransferId: this.args.transfer.id,
          origin: TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER,
        },
      });
    } else {
      this.toastFlashMessages.toastError(this.intl.t('transfers.repeat.sepa.error.toast'));
    }
  }

  _getInstantTransferStatus(status) {
    let instantStatus = {
      processing: this.intl.t('transfers.sidebar.header.instant.pending'),
      declined: this.intl.t('transfers.sidebar.header.instant.declined'),
      completed: this.intl.t('transfers.sidebar.header.instant.completed'),
    };
    return instantStatus[status];
  }

  _getRegularTransferStatus(status, operationType) {
    switch (status) {
      case STATUS.DECLINED:
      case STATUS.BENEFICIARY_ACCOUNT_CANCELED:
        return this.intl.t('transfers.sidebar.header.rejected');

      case STATUS.CANCELED:
      case STATUS.ACCOUNT_CANCELED:
        return this.intl.t('transfers.sidebar.header.canceled');

      case STATUS.PROCESSING:
      case STATUS.PENDING_REVIEW:
      case STATUS.PENDING_SEIZURE:
        return this.intl.t('transfers.sidebar.header.in_progress');

      case STATUS.KYB_ONHOLD:
        return this.intl.t('transfers.sidebar.header.pending');

      case STATUS.PENDING:
      case STATUS.STANDING_PROCESSING:
        if (
          [
            SCHEDULE_OPERATION_TYPES.WEEKLY,
            SCHEDULE_OPERATION_TYPES.MONTHLY,
            SCHEDULE_OPERATION_TYPES.QUARTERLY,
            SCHEDULE_OPERATION_TYPES.HALF_YEARLY,
            SCHEDULE_OPERATION_TYPES.YEARLY,
          ].includes(operationType)
        ) {
          return this.intl.t('transfers.sidebar.header.recurring');
        } else if (operationType === SCHEDULE_OPERATION_TYPES.SCHEDULED) {
          return this.intl.t('transfers.sidebar.header.scheduled');
        } else {
          return this.intl.t('transfers.sidebar.header.pending');
        }

      default:
        if (operationType === SCHEDULE_OPERATION_TYPES.SCHEDULED) {
          return this.intl.t('transfers.sidebar.header.scheduled');
        } else {
          return this.intl.t('transfers.sidebar.header.transfer');
        }
    }
  }
}
