/* import __COLOCATED_TEMPLATE__ from './add-transaction.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class AddTransactionComponent extends Component {
  @service modals;

  @action
  openTransactionsModal() {
    this.modals.open('supplier-invoices/transactions-modal', {
      isFullScreenModal: true,
      attachment: this.args.invoice.attachment,
      invoice: this.args.invoice,
      onCloseModal: () => this.args.refreshTransaction(),
    });
  }
}
