/* import __COLOCATED_TEMPLATE__ from './confirm-delete-team.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class ConfirmDeleteTeamModal extends Component {
  @service intl;
  @service toastFlashMessages;
  @service segment;

  deleteTeamTask = dropTask(async () => {
    let { team, confirmTask } = this.args.data;

    try {
      await team.destroyRecord();
      await confirmTask.linked().perform();
    } catch (error) {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
      throw error;
    }

    let successMessage = this.intl.t('teams.delete.toast-success');
    this.toastFlashMessages.toastSuccess(successMessage);
    this.segment.track('teams_delete_success');

    this.args.close();
  });

  @action
  cancel() {
    if (this.deleteTeamTask.isRunning) {
      return;
    }

    this.args.close();
  }
}
