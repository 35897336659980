export default {
  "item": "qCT",
  "checkbox": "qCz",
  "details": "qCa",
  "flex": "qCH",
  "header": "qCO qCH",
  "flex-no-gap": "qCm qCH",
  "file-name": "qCp",
  "label": "qCx body-2",
  "self-icon": "qCw",
  "einvoice-icon": "qCN",
  "type-value": "qCv",
  "with-separator": "qCo",
  "dimmed": "qCn"
};
