export default {
  "header-cell": "cbr",
  "empty": "cbb",
  "header-bulk-checkbox": "cby",
  "header-type": "cbU",
  "header-requester": "cbj",
  "header-date": "cbW",
  "header-amount": "cbu",
  "header-approval": "cyS",
  "header-content": "cyc caption-bold",
  "active": "cyq",
  "header-receipt": "cyZ",
  "row-compact": "cyR",
  "row-sidebar": "cyQ",
  "hidden": "cye",
  "animated": "cyB",
  "fadein-item": "cyE",
  "animated-cell": "cyd",
  "checkbox": "cyI"
};
