/* import __COLOCATED_TEMPLATE__ from './information.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

export default class SavingsAccountsStepsInformationComponent extends Component {
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @tracked sourceOfFundsError = null;
  @tracked fundOriginOtherError = null;

  get sourceOfFundsOptions() {
    return [
      {
        code: 'asset_disposal',
        label: this.intl.t('accounts.savings.creation.information.choice1'),
      },
      { code: 'cash_excess', label: this.intl.t('accounts.savings.creation.information.choice2') },
      { code: 'other', label: this.intl.t('accounts.savings.creation.information.choice3') },
    ];
  }

  @action
  handleUpdateSourceOfFundsOption(value) {
    this.sourceOfFundsError = false;
    this.args.context.updateSourceOfFundsOption(value);
  }

  @action
  handleUpdateFundOriginOther(value) {
    this.fundOriginOtherError = false;
    this.args.context.updateFundOriginOther(value);
  }

  handleNextTask = dropTask(async () => {
    if (!this.args.context.sourceOfFunds) {
      this.sourceOfFundsError = true;
    } else if (this.args.context.sourceOfFunds === 'other' && !this.args.context.fundOriginOther) {
      this.fundOriginOtherError = true;
    } else {
      try {
        await this.args.context.createProcedure();
        this.segment.track('savings_source_funds_cta');
        this.args.transitionToNext();
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });
}
