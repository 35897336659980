export default {
  "details-box": "cLe",
  "picto": "cLB",
  "picto-status": "cLE",
  "ghost": "cLd",
  "primary-info": "cLI",
  "date": "cLt",
  "account-infobox": "cLM",
  "declined-disclaimer": "cLP",
  "nrc-download": "cLl"
};
