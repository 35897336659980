export default {
  "iban": "qem",
  "checkbox": "qep",
  "toggle-content": "qex",
  "beneficiary-name": "qew",
  "category": "qeN",
  "beneficiary-email": "qev",
  "beneficiary-form": "qeo",
  "beneficiary-email-container": "qen"
};
