/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';
import { and, reads } from 'macro-decorators';

export default class RequestSidebarTransferHeaderComponent extends Component {
  checkbox = Checkbox;

  @service intl;
  @service organizationManager;

  @reads('args.request.approver') approver;
  @reads('args.request.initiator') initiator;

  @and(
    'args.request.isApproved',
    'args.request.bankAccount.name',
    'args.request.bankAccount.organization.hasMultipleActiveAccounts'
  )
  showAccountName;

  constructor() {
    super(...arguments);
    this.state = this.args.state || 'pending';
  }

  get lastActionDate() {
    let { processedAt, createdAt } = this.args.request;
    return processedAt || createdAt;
  }

  get labelStanding() {
    if (this.args.request.weekly) {
      return this.intl.t(`transfers.frequency.standing_weekly_short`);
    } else if (this.args.request.quarterly) {
      return this.intl.t(`transfers.frequency.standing_quarterly_gender`);
    } else if (this.args.request.yearly) {
      return this.intl.t(`transfers.frequency.standing_yearly_gender`);
    } else if (this.args.request.halfYearly) {
      return this.intl.t(`transfers.frequency.standing_half_yearly_gender`);
    }

    return this.intl.t(`transfers.frequency.standing_monthly_gender`);
  }

  get isStandingOrder() {
    let { request } = this.args;
    return (
      request.weekly || request.monthly || request.quarterly || request.halfYearly || request.yearly
    );
  }

  get requestIcon() {
    if (this.args.request.once) {
      return 'feature_transfer_direct-l';
    } else if (this.args.request.later) {
      return 'feature_transfer_scheduled-l';
    }
    return 'feature_transfer_recurring-l';
  }

  get _isStatusPrintable() {
    let status = this.args.request.status;
    return ['approved', 'canceled', 'declined'].includes(status);
  }

  get requestHeaderTypeWording() {
    let { approver, initiator, _isStatusPrintable } = this;
    let status = this.args.request.status;
    let name = this._membershipName(approver, initiator);

    if (_isStatusPrintable) {
      return this.intl.t(`requests.transfers.final_status.${status}`, {
        name,
      });
    }

    if (this.args.request.later) {
      return this.intl.t('requests.sidebar.header.type.transfer_scheduled');
    } else if (this.args.request.weekly) {
      return this.intl.t('requests.sidebar.header.type.transfer_standing_weekly');
    } else if (this.args.request.monthly) {
      return this.intl.t('requests.sidebar.header.type.transfer_standing_monthly');
    } else if (this.args.request.quarterly) {
      return this.intl.t('requests.sidebar.header.type.transfer_standing_quarterly');
    } else if (this.args.request.halfYearly) {
      return this.intl.t('requests.sidebar.header.type.transfer_standing_half_yearly');
    } else if (this.args.request.yearly) {
      return this.intl.t('requests.sidebar.header.type.transfer_standing_yearly');
    } else {
      return this.intl.t('requests.sidebar.header.type.transfer');
    }
  }

  _formatMembershipName(value) {
    return `${value.get('firstName')?.substr(0, 1)}. ${value.get('lastName')}`;
  }

  _membershipName(approver, initiator) {
    let isRequestApproved = this.args.request.status === 'approved';
    let membership = isRequestApproved ? approver : initiator;

    return this._formatMembershipName(membership);
  }
}
