/* import __COLOCATED_TEMPLATE__ from './kyc-in-app.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';
import dayjs from 'dayjs';

export default class KycInAppTopbarComponent extends Component {
  topBanner = TopBanner;

  @service intl;
  @service organizationManager;
  @service segment;
  @service router;

  get isInFirst3DaysPeriod() {
    let today = dayjs();
    return (
      Math.floor(today.diff(this.organizationManager.membership.createdAt, 'hours') / 24) + 1 < 3
    );
  }

  get text() {
    if (this.isInFirst3DaysPeriod) {
      return this.intl.t('kyc.banner.unsubmitted.first-nudge.body', {
        kycFlowLink: this.intl.t('kyc.banner.unsubmitted.first-nudge.link-text'),
      });
    }

    return this.intl.t('kyc.banner.unsubmitted.second-nudge.body', {
      kycFlowLink: this.intl.t('kyc.banner.unsubmitted.second-nudge.link-text'),
    });
  }

  get content() {
    return {
      type: this.isInFirst3DaysPeriod ? 'warning' : 'error',
    };
  }
}
