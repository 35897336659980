/* import __COLOCATED_TEMPLATE__ from './choose-type.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Badge, Disclaimer } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { REQUEST_ORIGINS, REQUEST_TYPES } from 'qonto/constants/requests';

export default class RequestChooseType extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service abilities;
  @service flowLinkManager;
  @service intl;
  @service organizationManager;
  @service router;
  @service segment;
  @service modals;
  @service sentry;
  @service subscriptionManager;
  @service toastFlashMessages;

  badgeHighlight = Badge;

  typesPerOrigin = {
    default: [
      REQUEST_TYPES.TRANSFER,
      REQUEST_TYPES.MULTI_TRANSFER,
      REQUEST_TYPES.FLASH_CARD,
      REQUEST_TYPES.VIRTUAL_CARD,
      REQUEST_TYPES.EXPENSE_REPORT,
      REQUEST_TYPES.MILEAGE,
    ],
    cards: [REQUEST_TYPES.FLASH_CARD, REQUEST_TYPES.VIRTUAL_CARD],
    transfers: [REQUEST_TYPES.TRANSFER, REQUEST_TYPES.MULTI_TRANSFER],
    reimbursements: [REQUEST_TYPES.EXPENSE_REPORT, REQUEST_TYPES.MILEAGE],
  };

  get availableTypes() {
    return this.typesPerOrigin[this.args.origin] || this.typesPerOrigin.default;
  }

  get title() {
    let canCreateCardRequest = this.abilities.can('create card request');
    let canCreateTransfer =
      this.abilities.can('create transfer request') || this.abilities.can('create bulk transfer');
    let canCreateExpenseReport = this.abilities.can('use expense reports request');

    if (
      this.args.origin === 'reimbursements' ||
      this.args.origin === REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE
    ) {
      return this.intl.t('requests.creation.landing.reimbursements.title');
    }
    if (this.args.origin === 'cards') {
      return this.intl.t('requests.creation.landing.cards.title');
    }
    if (this.args.origin === 'transfers') {
      return this.intl.t('requests.creation.landing.transfers.title');
    }

    if (canCreateExpenseReport) {
      return this.intl.t('requests.creation.landing.title-transfer');
    }

    if (canCreateCardRequest && canCreateTransfer) {
      return this.intl.t('requests.creation.landing.title');
    }

    if (canCreateTransfer) {
      return this.intl.t('requests.creation.landing.title-transfer');
    }

    return this.intl.t('requests.creation.landing.title-card');
  }

  get isKycSubmitted() {
    return this.organizationManager.membership.kycSubmitted;
  }

  get isItalianOrga() {
    return (
      variation('operational--boolean-disable-card-creation-for-italy') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  @action
  handleClose() {
    switch (this.args.origin) {
      case REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE:
        this.router.transitionTo('reimbursements');
        break;
      case 'reimbursements':
        this.router.transitionTo('reimbursements.requests');
        break;
      case 'cards':
        this.router.transitionTo('cards.my-cards');
        break;
      case 'transfers':
        this.router.transitionTo('transfers.requests');
        break;
      default:
        this.router.transitionTo('requests.pending');
    }
  }

  @action
  handleGo(internal) {
    this.router.transitionTo('requests.transfers.new', {
      queryParams: { currency: null, internal, origin: this.args.origin ?? null },
    });
  }

  @action
  handleGoExpenseReport() {
    let sendOrigin =
      this.args.origin === REQUEST_ORIGINS.reimbursements ||
      this.args.origin === REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE;

    this.flowLinkManager.transitionTo({
      name: 'request-expense-report',
      stepId: 'receipt',
      queryParams: sendOrigin ? { origin: this.args.origin } : {},
    });
  }

  @action
  handleGoMileage() {
    let sendOrigin =
      this.args.origin === REQUEST_ORIGINS.reimbursements ||
      this.args.origin === REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE;

    this.flowLinkManager.transitionTo({
      name: 'request-mileage',
      queryParams: sendOrigin ? { origin: this.args.origin } : {},
    });
  }

  @action
  handleGoMileageUpsell() {
    try {
      this.segment.track('upsell_mileage_clicked');
      this.subscriptionManager.upgradeWithRecommendation('request_reimbursement_mileage');
    } catch (error) {
      this.sentry.captureException(error);
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }

  @action
  handleGoBulk() {
    this.router.transitionTo('requests.transfers.multi.new', {
      queryParams: { currency: null, origin: this.args.origin ?? null },
    });
  }

  @action
  handleGoFlashCard() {
    if (this.isItalianOrga) {
      return this.modals.open('card/modals/bank-of-italy-restrictions');
    }

    this.segment.track('request_started', {
      origin: this.args.origin,
      request_type: 'flash_card',
    });

    this.flowLinkManager.transitionTo({
      name: 'requests-cards',
      stepId: 'budget',
      queryParams: { cardLevel: CARD_LEVELS.FLASH },
    });
  }

  @action
  handleGoVirtualCard() {
    if (this.isItalianOrga) {
      return this.modals.open('card/modals/bank-of-italy-restrictions');
    }

    this.segment.track('request_started', {
      origin: this.args.origin,
      request_type: 'virtual_card',
    });

    return this.flowLinkManager.transitionTo({
      name: 'requests-cards',
      stepId: 'budget',
      queryParams: { cardLevel: CARD_LEVELS.VIRTUAL },
    });
  }
}
