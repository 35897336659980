export default {
  "wrapper": "qaN",
  "title": "qav",
  "activity-tag": "qao",
  "select": "qan",
  "tooltip": "qai",
  "date-picker": "qaA",
  "internal-notes": "qaY",
  "scheduled": "qah"
};
