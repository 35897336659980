/* import __COLOCATED_TEMPLATE__ from './fees.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { Amount } from 'qonto/react/components/transactions/sidebar/header/amount/amount';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';

export default class TransactionsSidebarHeaderBaseFees extends Component {
  amount = Amount;

  disclaimerInline = Disclaimer.Inline;
  Counterparty = Counterparty;
  Date = Date;
  type = Type;

  get showAccountName() {
    let { showAccountName, highlightedItem: transaction } = this.args;

    return showAccountName || transaction.subject.get('isSDDCollectionFee');
  }
}
