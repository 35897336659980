/* import __COLOCATED_TEMPLATE__ from './custom-rate.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class VatAccountingMultiCustomRateComponent extends Component {
  @service intl;

  @tracked vatCustomRate;

  get isExistingRate() {
    return this.args.transactionRates?.includes(parseFloat(this.vatCustomRate));
  }

  get errorMessage() {
    if (this.isExistingRate) {
      return this.intl.t('attachment-viewer.bookkeeping.vat-already-exists.error');
    }
  }

  @action
  confirm() {
    this.args.onConfirm({ value: parseFloat(this.vatCustomRate) });
  }

  @action
  cancel() {
    this.args.onCancel();
  }
}
