/* import __COLOCATED_TEMPLATE__ from './ar-promo-card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/receivable-invoice';

export default class TransactionsSiderbarHeaderArPromoCard extends Component {
  @service segment;
  @service router;

  constructor() {
    super(...arguments);

    this.segment.track('ar-discoverability_banner_displayed', { type: 'tab' });
  }

  @action
  onClick() {
    this.segment.track('ar-discoverability_banner_clicked', { type: 'tab' });
    this.router.transitionTo('receivable-invoices.index', {
      queryParams: {
        status: STATUS.DRAFT,
      },
    });
  }
}
