/* import __COLOCATED_TEMPLATE__ from './pin-code-input.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class PinCodeInputComponent extends Component {
  @tracked hidden = true;

  get inputType() {
    return this.hidden ? 'password' : 'text';
  }

  get hasError() {
    return Boolean(this.args.errorMessage);
  }

  @action
  toggleView() {
    this.hidden = !this.hidden;
  }

  @action
  valueChanged(event) {
    let { value } = event.target;

    this.args.valueChanged(value);
  }
}
