/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';

export default class SuppliersTableHeaderComponent extends Component {
  get isUnarchived() {
    return this.args.status === SUPPLIER_STATUSES.UNARCHIVED;
  }
}
