/* import __COLOCATED_TEMPLATE__ from './quick-actions-buttons.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';

export default class QuickActionsButtonsComponent extends Component {
  spinner = Spinner;

  get isActionRunning() {
    return this.args.isApprovalRunning || this.args.isRejectRunning;
  }

  @action
  stopPropagation(event) {
    event.stopPropagation();
  }

  /**
   *
   * @param {'approve' | 'reject'} type
   * @param {KeyboardEvent} event
   */
  @action
  handleKeydown(type, event) {
    if (event.key !== 'Enter' && event.key !== ' ') {
      return;
    }

    if (type === 'approve') {
      this.args.onApprove();
    } else {
      this.args.onReject();
    }
  }
}
