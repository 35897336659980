export default {
  "sidebar": "cAb",
  "body": "cAy",
  "form": "cAU",
  "form-section": "cAj",
  "form-title": "cAW title2",
  "row": "cAu",
  "payment-details": "cYS",
  "col": "cYc",
  "footer": "cYq",
  "footer-button": "cYZ"
};
