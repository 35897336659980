/* import __COLOCATED_TEMPLATE__ from './step-state.hbs'; */
import Component from '@glimmer/component';

export default class StepState extends Component {
  get shouldDisplayResolutionIcon() {
    // Don't show resolution icon when loading
    return !this.args.isLoading && (this.args.isSuccessStep || this.args.isRejectedStep);
  }
  get resolutionStepIcon() {
    let { isRejectedStep, isSuccessStep } = this.args;

    if (isSuccessStep) {
      return {
        icon: 'icon_checkmark_rounded_filled',
      };
    }
    if (isRejectedStep) {
      return {
        icon: 'icon_cross_rounded_filled',
      };
    }
  }
}
