/* import __COLOCATED_TEMPLATE__ from './sorting-cell.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class ReceivableInvoicesTableHeader extends Component {
  get sortField() {
    let { sortBy } = this.args;
    return sortBy.startsWith('-') ? sortBy.slice(1) : sortBy;
  }

  get isAscending() {
    let { sortBy } = this.args;
    return !sortBy.startsWith('-');
  }

  @action
  handleSortBy(name) {
    let { handleSortBy } = this.args;
    let sortBy = '';

    //Revert the order when we click on the same column
    if (name === this.sortField && this.isAscending) {
      sortBy += '-';
    }

    sortBy += name;

    return handleSortBy?.(sortBy);
  }
}
