/* import __COLOCATED_TEMPLATE__ from './success-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';

import { SEPA_MODAL_CLOSE_REASONS } from 'qonto/constants/transfers';

export default class TransfersInternalSuccessModalComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service organizationManager;

  @tracked animationReady = false;

  get isKYTPending() {
    let isKYBPending = this.args.data.transfer.get('organization.kybPending');
    let isKYCPending = this.organizationManager.membership.kycPending;

    return isKYBPending || isKYCPending;
  }

  get modelData() {
    let { transfer } = this.args.data;
    let { kycPending, kycSubmitted } = this.organizationManager.membership;
    if (kycPending && kycSubmitted) {
      return this._getDataForInternalTransferKycPending();
    }

    return this._getDataForInternalTransfer(transfer);
  }

  get lottie() {
    return this.isKYTPending ? '/lotties/transfers/timeout.json' : '/lotties/success.json';
  }

  @action
  displayLottie() {
    this.animationReady = true;
  }

  @action
  handleNewTransfer() {
    this.args.close(SEPA_MODAL_CLOSE_REASONS.MAKE_NEW_TRANSFER);
  }

  _getDataForInternalTransferKycPending() {
    return {
      title: this.intl.t('transfers.modals.success.kyc-pending.title'),
      description: this.intl.t('transfers.modals.success.kyc-pending.description'),
    };
  }

  _getDataForInternalTransfer(transfer) {
    if (transfer.wasScheduled) {
      return {
        title: this.intl.t('transfers.modals.success.scheduled.title'),
        description: this.intl.t('transfers.modals.success.scheduled.description'),
      };
    }

    return {
      title: this.intl.t('transfers.modals.success.internal.title'),
      description: this.intl.t('transfers.modals.success.internal.description', {
        accountName: transfer.name,
      }),
    };
  }
}
