/* import __COLOCATED_TEMPLATE__ from './header-v2.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

import { DEFAULT_SORT_ORDER } from 'qonto/constants/sort';
import { SORT_TABS_MAP, TABS } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesTableHeaderComponent extends Component {
  @service intl;
  @service abilities;

  get showInvoiceSelector() {
    return (
      (this.abilities.can('update supplier-invoice') &&
        [TABS.ALL_INVOICES, TABS.INBOX, TABS.TO_PAY].includes(this.args.tab)) ||
      ![TABS.TASKS, TABS.SCHEDULED, TABS.TO_APPROVE, TABS.COMPLETED].includes(this.args.tab)
    );
  }

  get columnTypes() {
    return {
      [TABS.TASKS]: [
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: this.abilities.can('load transfer request') ? '32%' : '44%',
        },
        ...(this.abilities.can('load transfer request')
          ? [
              {
                name: 'requester',
                label: this.intl.t('request.table.header.requester'),
                sortable: true,
                width: '24%',
              },
            ]
          : []),
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: this.abilities.can('load transfer request') ? '24%' : '36%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.ALL_INVOICES]: [
        { type: this.abilities.can('update supplier-invoice') ? 'checkbox' : null },
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '25%',
        },
        {
          name: 'status',
          label: this.intl.t('supplier-invoices.table.header.status'),
          sortable: true,
          width: '15%',
        },
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: '15%',
        },
        {
          name: 'payment_date',
          label: this.intl.t('supplier-invoices.table.header.payment-date'),
          sortable: true,
          width: '15%',
        },
        {
          name: 'matched_transactions',
          label: this.intl.t('supplier-invoices.table.header.transactions'),
          sortable: true,
          width: '15%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '15%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.INBOX]: [
        { type: this.abilities.can('update supplier-invoice') ? 'checkbox' : null },
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '44%',
        },
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: '46%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.TO_PAY]: [
        { type: this.abilities.can('update supplier-invoice') ? 'checkbox' : null },
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '44%',
        },
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: '46%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.TO_APPROVE]: [
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '44%',
        },
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: '46%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.SCHEDULED]: [
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '30%',
        },
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: '23%',
        },
        {
          name: 'payment_date',
          label: this.intl.t('supplier-invoices.table.header.payment-date'),
          sortable: true,
          width: '37%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.COMPLETED]: [
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '25%',
        },
        {
          name: 'status',
          label: this.intl.t('supplier-invoices.table.header.status'),
          sortable: true,
          width: '15%',
        },
        {
          name: 'payment_date',
          label: this.intl.t('supplier-invoices.table.header.payment-date'),
          sortable: true,
          width: '25%',
        },
        {
          name: 'matched_transactions',
          label: this.intl.t('supplier-invoices.table.header.transactions'),
          sortable: true,
          width: '20%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '15%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
    };
  }

  get columns() {
    return this.columnTypes[this.args.tab];
  }

  get sortField() {
    return this.args.sortBy?.split(':')[0];
  }

  get sortOrder() {
    return this.args.sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;
  }

  @action
  changeSortBy(name) {
    let sortOrder = DEFAULT_SORT_ORDER;

    if (name === this.sortField) {
      switch (this.sortOrder) {
        case 'asc': {
          sortOrder = 'desc';
          break;
        }
        case 'desc': {
          return this.args.changeSortBy?.(
            SORT_TABS_MAP(variation('feature--boolean-approval-workflow-for-supplier-invoices'))[
              this.args.tab
            ]
          );
        }
        default: {
          sortOrder = 'asc';
        }
      }
    }

    return this.args.changeSortBy?.(`${name}:${sortOrder}`);
  }
}
