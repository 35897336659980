export default {
  "container": "cfl",
  "content": "cfX",
  "input-row": "cfC",
  "email-row": "cfk",
  "button-row": "cfJ",
  "btn-edit": "cfs",
  "input-column": "cfF",
  "form-btn-row": "cfg",
  "border-bottom": "cfD",
  "avatar-input": "cfV"
};
