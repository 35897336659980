export default {
  "card-header": "qTP",
  "counterparty-name": "qTl",
  "pan-section": "qTX header-section body-2",
  "infobox": "qTC header-section",
  "see-card-button": "qTk",
  "card-identifier-section": "qTJ",
  "card-icon": "qTs",
  "nickname": "qTF body-1",
  "pan": "qTg body-2"
};
