/* import __COLOCATED_TEMPLATE__ from './supplier-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import italianProvinces from 'qonto/constants/it-provinces';

const DEFAULT_VAT_OPTIONS = {
  FR: [undefined, 0, 2.1, 5.5, 10, 20],
  IT: [undefined, 0, 4, 5, 10, 22],
  ES: [undefined, 0, 4, 10, 21],
  DE: [undefined, 0, 7, 19],
  AT: [undefined, 0, 7, 10, 13, 19, 20],
  BE: [undefined, 0, 6, 12, 21],
  NL: [undefined, 0, 9, 21],
  PT: [undefined, 0, 6, 13, 23],
};

export default class SupplierModal extends Component {
  @service organizationManager;
  @service intl;
  @service modals;
  @service router;

  get vatOptions() {
    let organizationCountryCode = this.organizationManager.organization.legalCountry;
    return DEFAULT_VAT_OPTIONS[organizationCountryCode] || DEFAULT_VAT_OPTIONS.FR;
  }

  get errorMessages() {
    return this.args.data.supplier.errors.messages;
  }

  get hasSupplierNameError() {
    return this.errorMessages && this.args.data.supplier.errors.has('/supplier/name');
  }

  get hasSupplierIbanError() {
    return this.errorMessages && this.args.data.supplier.errors.has('/supplier/iban');
  }

  get hasSupplierEmailError() {
    return this.errorMessages && this.args.data.supplier.errors.has('/supplier/email');
  }

  get countryCode() {
    let { countryCode } = this.args.data.supplier;

    return countryCode || this.organizationManager.organization.legalCountry;
  }

  @cached
  get provinces() {
    return italianProvinces.map(key => ({
      key,
      label: `${key} - ${this.intl.t(`italy.provinces.${key.toLowerCase()}`)}`,
    }));
  }

  get selectedProvince() {
    return this.provinces.find(province => province.key === this.args.data.supplier.provinceCode);
  }

  /**
   * This function is required in order to reset or keep the selected
   * defaultVatRate in case the user switches the supplier country
   * after having already set the defaultVatRate.
   */
  updateDefaultVat(country) {
    let vatCountries = Object.keys(DEFAULT_VAT_OPTIONS);

    // Remove FR from array
    vatCountries.shift();

    let isFrenchToUnknownCountry = this.countryCode === 'FR' && !vatCountries.includes(country);
    let isUnknownCountryToFrench = !vatCountries.includes(this.countryCode) && country === 'FR';

    if (!isFrenchToUnknownCountry && !isUnknownCountryToFrench) {
      this.selectDefaultVatRate({ value: undefined });
    }
  }

  @action
  close() {
    this.modals.open('popup/confirmation', {
      title: this.intl.t('supplier-invoices.supplier-creation.exit-modal.title'),
      description: this.intl.t('supplier-invoices.supplier-creation.exit-modal.subtitle'),
      cancel: this.intl.t('supplier-invoices.supplier-creation.exit-modal.cancel.cta'),
      confirm: this.intl.t('supplier-invoices.supplier-creation.exit-modal.leave.cta'),
      confirmTask: this.closeTask,
    });
  }

  closeTask = dropTask(async close => {
    this.args.data.supplier.rollbackAttributes();
    close();
    if (this.args.data.referrerSupplierInvoiceId) {
      await this.router.transitionTo(
        'supplier-invoices.show',
        this.args.data.referrerSupplierInvoiceId
      );
      return;
    }

    await this.router.transitionTo('suppliers.index');
  });

  @action
  updateSupplierName(name) {
    this.args.data.supplier.name = name;
    this.args.data.supplier.errors.remove('/supplier/name');
  }

  @action
  updateEmailAddress(email) {
    this.args.data.supplier.email = email;
    this.args.data.supplier.errors.remove('/supplier/email');
  }

  @action
  updateIban(iban) {
    this.args.data.supplier.iban = iban;
    this.args.data.supplier.errors.remove('/supplier/iban');
  }

  @action
  updateCountryCode(country) {
    let { supplier } = this.args.data;

    supplier.countryCode = country;
  }

  @action
  selectDefaultVatRate({ value }) {
    this.args.data.supplier.defaultVatRate = value;
  }

  @action
  chipText(value) {
    if (typeof value === 'undefined') {
      return this.intl.t('supplier-invoices.supplier-creation.vat-rate.choice-chip.none');
    }

    return String(value).concat('%');
  }

  @action
  updateProvince({ key }) {
    this.args.data.supplier.provinceCode = key;
  }
}
