export default {
  "donut-chart-container": "czM",
  "donut-chart-wrapper": "czP",
  "chart": "czl",
  "donut-chart-legend-container": "czX caption",
  "donut-chart-legend-item-empty": "czC",
  "donut-chart-legend-bullet-empty": "czk",
  "donut-chart-legend-bullet": "czJ",
  "donut-chart-legend-text-empty": "czs",
  "donut-chart-legend-text": "czF",
  "donut-chart-custom-label-uncategorized": "czg",
  "custom-label-uncategorized-button": "czD body-1",
  "donut-chart-legend": "czV",
  "donut-chart-legend-item": "czL",
  "donut-chart-legend-other-item": "czT",
  "donut-chart-legend-uncategorized-item": "czz",
  "donut-chart-legend-text-name": "cza",
  "tooltip": "czH"
};
