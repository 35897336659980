/* import __COLOCATED_TEMPLATE__ from './check.hbs'; */
import Component from '@glimmer/component';

import { Amount } from 'qonto/react/components/transactions/sidebar/header/amount/amount';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';
import cmc7Splitter from 'qonto/utils/cmc7-splitter';

export default class TransactionsSidebarHeaderCheck extends Component {
  amount = Amount;
  Counterparty = Counterparty;
  Date = Date;
  type = Type;

  get checkCmc7() {
    return cmc7Splitter(this.args.highlightedItem.subject.get('cmc7'), ' ');
  }
}
