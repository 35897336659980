export default {
  "container": "qLl",
  "item": "qLX caption-bold",
  "hover": "qLC",
  "active": "qLk",
  "name-wrapper": "qLJ",
  "name": "qLs body-2",
  "count": "qLF",
  "actions-placeholder": "qLg",
  "actions": "qLD",
  "show": "qLV",
  "action-button": "qLL"
};
