import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dateToken } from '@qonto/ui-kit/utils/date-token';
import * as Sentry from '@sentry/ember';
import { bisectLeft } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { easeBackInOut, easeCubicInOut } from 'd3-ease';
import { interpolate } from 'd3-interpolate';
import { scaleBand, scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { line } from 'd3-shape';
import dayjs from 'dayjs';

import { canAnimate, metricSuffixer } from 'qonto/utils/chart';
import { getExtrema } from 'qonto/utils/chart/scale';

export default class ComboChartComponent extends Component {
  @service intl;

  animationDelay = 0;
  animationDuration = 800;
  chartSpacing = {
    top: 30,
    left: 40,
    vertical: 8,
    yScaleTop: 12,
  };
  defaultYMax = 50000;
  headroom = 1.3;
  guid = guidFor(this);
  isFirstDraw = true;
  tickFormatter = d =>
    dateToken({ date: d, locale: this.intl.primaryLocale, token: 'month-year-s' });

  get displayedMonths() {
    return this.args.displayedMonths || 12;
  }

  get defaultSeries() {
    return Array.from({ length: this.displayedMonths }).map((_, index) => ({
      time: new Date(new Date().getFullYear(), index),
      data: { value: 0 },
    }));
  }

  get chartInnerHeight() {
    let containerHeight = this.chartContainer?.clientHeight;
    return containerHeight - this.chartSpacing.top - this.chartSpacing.vertical * 2;
  }

  get chartWidth() {
    return this.chartContainer?.clientWidth;
  }

  get hasData() {
    return this.args.timeseries?.some?.(({ data }) => data);
  }

  get timeseries() {
    return this.hasData ? this.args.timeseries : this.defaultSeries;
  }

  get isAnimated() {
    return this.isFirstDraw && canAnimate();
  }

  get currentDateValue() {
    switch (this.args.rate) {
      case 'daily':
        return dayjs().startOf('day').toDate();
      case 'weekly':
        return dayjs().startOf('isoWeek').toDate();
      default:
        return dayjs().startOf('month').toDate();
    }
  }

  get isCurrentPeriodDisplayed() {
    return (
      this.hasData && dayjs().startOf('day').isBetween(this.startBound, this.endBound, 'day', '[]')
    );
  }

  get currentDateIndex() {
    if (this.isCurrentPeriodDisplayed) {
      let maxIndex = this.timeseries.length - 1;
      let index = bisectLeft(
        this.timeseries.map(({ time }) => time),
        this.currentDateValue
      );

      return index < maxIndex ? index : maxIndex;
    } else {
      return -1;
    }
  }

  get startBound() {
    return this.args.bounds?.min || this.args.timeseries[0].time;
  }

  get endBound() {
    return this.args.bounds?.max || this.args.timeseries.at(-1).time;
  }

  get isFutureRange() {
    if (this.args.timeseries) {
      return dayjs(this.startBound).isAfter(dayjs());
    } else {
      return false;
    }
  }

  get isPastRange() {
    if (this.args.timeseries) {
      return dayjs(this.endBound).isBefore(dayjs());
    } else {
      return false;
    }
  }

  /**
   * ----------------
   * D3 Setup methods
   * ----------------
   */

  /**
   * Sets the contextual d3 renderer
   */
  _setRenderer(container) {
    this.chartRenderer?.selectAll('*').remove();
    this.chartRenderer = select(container);
  }

  /**
   * -------------------------
   * Scales management methods
   * -------------------------
   */

  /**
   * Returns the Y Scale extrema
   * @returns {[yMin, yMax]} Minimal and Maximal values for the Y Scale
   */
  _getYExtrema(timeseries) {
    let values = timeseries.map(({ data }) => data?.value);
    return getExtrema(values, this.defaultYMax, this.headroom);
  }

  /**
   * Returns the chart D3 Y Scale
   * @param {*} timeseries Timevalues array
   * @returns {D3 Scale} Chart Y Scale
   */
  _getYScale(timeseries) {
    let [yMin, yMax] = this._getYExtrema(timeseries);

    return scaleLinear()
      .domain([yMin, yMax])
      .rangeRound([this.chartInnerHeight, this.chartSpacing.yScaleTop]);
  }

  /**
   * Returns the chart D3 X Scale
   * @param {*} timeseries Timevalues array
   * @returns {D3 Scale} Chart X Scale
   */
  _getXScale(timeseries) {
    return scaleBand()
      .domain(timeseries.map(d => d.time))
      .range([this.chartSpacing.left, this.chartWidth]);
  }

  /**
   * Sets the chart scales to the chart context
   * @param {*} timeseries Timevalues array
   */
  _setScales(timeseries) {
    this.yScale = this._getYScale(timeseries);
    this.xScale = this._getXScale(timeseries);
  }

  /**
   * -----------------------
   * Axis management methods
   * -----------------------
   */

  /**
   * Draws the X Axis of the graph based on a given scale
   * @param {*} scale D3 scale
   */
  _setXAxis(scale, tickFormatter) {
    let xAxis = axisBottom().scale(scale).tickFormat(tickFormatter).ticks(this.timeseries.length);

    this.chartRenderer
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(${[0, this.chartInnerHeight + this.chartSpacing.vertical]})`)
      .call(xAxis)
      .selectAll('.tick')
      .attr('class', 'x-axis-tick')
      .attr('data-test-cashflow-chart-x-tick', (_, index) => index);

    this.chartRenderer.selectAll('.x-axis-tick text').attr('y', this.chartSpacing.vertical * 2);
  }

  /**
   * Draws the Y Axis of the graph based on a given scale
   * @param {*} scale D3 Y scale
   */
  _setYAxis(scale, showBalance) {
    let yAxis = axisLeft()
      .scale(scale)
      .tickFormat(d => metricSuffixer(d))
      .ticks(6);

    this.chartRenderer
      .append('g')
      .attr('class', 'y-axis')
      .classed('hidden', !showBalance) // Needed on resize
      .attr('transform', `translate(${[this.chartSpacing.left, 0]})`)
      .call(yAxis)
      .selectAll('.tick')
      .attr('class', 'y-axis-tick')
      .attr('data-test-cashflow-chart-y-tick', (_, index) => index);
  }

  /**
   * Draws the graph horizontal (Y) grid lines based on given scale
   * @param {*} scale D3 Y scale
   */
  _setYGridlines(scale) {
    let tickSize = -this.chartWidth;
    let gridAxis = axisLeft().scale(scale).ticks(6).tickSize(tickSize).tickFormat('');
    let zeroAxis = axisLeft().scale(scale).tickValues([0]).tickSize(tickSize).tickFormat('');

    this.chartRenderer
      .append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(${[this.chartSpacing.left, 0]})`)
      .call(gridAxis)
      .selectAll('.tick')
      .attr('data-test-cashflow-chart-grid-line', (_, index) => index);

    this.chartRenderer
      .append('g')
      .attr('class', 'grid grid--zero')
      .attr('transform', `translate(${[this.chartSpacing.left, 0]})`)
      .call(zeroAxis)
      .selectAll('.tick')
      .attr('data-test-cashflow-chart-zero-grid-line', (_, index) => index);
  }

  /**
   * ---------------------------
   * Positionning helper methods
   * ---------------------------
   */

  /**
   * Returns X Position for chart line paths
   * @param {*} index Timevalue index
   * @param {*} timeseries Timevalue array
   * @param {*} scale D3 X Scale
   * @param {*} isCurrent The path represents a current timevalue
   * @returns {number} X Position
   */
  _getLineXPosition(index, timeseries, scale, isCurrent, isSettled) {
    if (this.args.timeseries) {
      this._captureAndReportToSentry(index, timeseries);
    }

    let time = timeseries[index].time;
    let isExtremum = index === timeseries.length - 1 || index === 0;

    if (isExtremum) {
      return isSettled
        ? this._getSettledLineXPosition(index, timeseries, scale, isCurrent)
        : this._getDottedLineXPosition(index, timeseries, scale, isCurrent);
    } else {
      return scale(time);
    }
  }

  _getSettledLineXPosition(index, timeseries, scale, isCurrent) {
    let time = timeseries[index].time;
    if (index === timeseries.length - 1) {
      return isCurrent ? scale(time) : this.chartWidth;
    } else {
      return this.chartSpacing.left;
    }
  }

  _getDottedLineXPosition(index, timeseries, scale, isCurrent) {
    let time = timeseries[index].time;
    if (index === timeseries.length - 1) {
      return this.chartWidth;
    } else {
      return isCurrent ? scale(time) : this.chartSpacing.left;
    }
  }

  /**
   * Returns Y Position for chart line paths
   * @param {*} index  Timevalue index
   * @param {*} timeseries Timevalue array
   * @param {*} scale D3 Y Scale
   * @returns {number} Y Position
   */
  _getLineYPosition(index, timeseries, scale) {
    let timevalueData = timeseries[index].data;
    return scale(timevalueData.value);
  }

  /**
   * --------------------
   * Line drawing methods
   * --------------------
   */

  /**
   * Draws the chart tendency lines and current situation dot
   * @param {*} timeseries
   * @param {*} xScale
   * @param {*} yScale
   */
  _drawBalance(timeseries, xScale, yScale) {
    let hasTendency = timeseries.length > 1;
    if (hasTendency) {
      if (this.isCurrentPeriodDisplayed || this.isPastRange) {
        this._drawBalanceLine(timeseries, xScale, yScale);
      }
      if (this.isCurrentPeriodDisplayed) {
        let index = this.currentDateIndex;
        let isCurrent = this.isCurrentPeriodDisplayed;
        this._drawBalanceLineDot(timeseries, xScale, yScale, index, isCurrent);
      }
      if (this.isCurrentPeriodDisplayed || this.isFutureRange) {
        this._drawCurrentBalanceLine(timeseries, xScale, yScale);
      }
    }
  }

  /**
   * Draws a tendency line
   * @param {*} timeseries Timeseries
   * @param {*} xScale D3 X base scale
   * @param {*} yScale D3 Y Scale
   */
  _drawBalanceLine(timeseries, xScale, yScale) {
    let settledLines = line()
      .x((_, index) =>
        this._getLineXPosition(index, timeseries, xScale, this.isCurrentPeriodDisplayed, true)
      )
      .y((_, index) => this._getLineYPosition(index, timeseries, yScale))
      .defined((_, index) =>
        this.isCurrentPeriodDisplayed ? index <= this.currentDateIndex : this.isPastRange
      );

    let settledPath = this.chartRenderer
      .append('path')
      .attr('d', settledLines(timeseries))
      .attr('class', 'cashflow-balance')
      .attr('data-test-cashflow-balance', (_, index) => index);

    if (this.isAnimated) {
      settledPath
        .attr('stroke-dasharray', '0,1')
        .transition()
        .ease(easeCubicInOut)
        .attrTween('stroke-dasharray', function () {
          let length = this.getTotalLength();
          return interpolate(`0,${length}`, `${length},${length}`);
        })
        .duration(this.animationDuration)
        .delay(this.animationDelay)
        .waitFor();

      this._cumulateDelay(this.animationDuration);
    }
  }

  /**
   * Draws a dotted tendency line
   * @param {*} timeseries Timeseries
   * @param {*} xScale D3 X base scale
   * @param {*} yScale D3 Y scale
   */
  _drawCurrentBalanceLine(timeseries, xScale, yScale) {
    let isCurrent = this.isCurrentPeriodDisplayed;
    let currentPeriodDefinition = this.isFutureRange
      ? timeseries
      : timeseries.slice(this.currentDateIndex);

    if (currentPeriodDefinition.length === 1) {
      currentPeriodDefinition = [...currentPeriodDefinition, ...currentPeriodDefinition];
    }

    let currentPeriodLines = line()
      .x((_, index) => this._getLineXPosition(index, currentPeriodDefinition, xScale, isCurrent))
      .y((_, index) => this._getLineYPosition(index, currentPeriodDefinition, yScale, isCurrent))
      .defined(() => this.isCurrentPeriodDisplayed || this.isFutureRange);

    let currentPeriodPath = this.chartRenderer
      .append('path')
      .attr('d', currentPeriodLines(currentPeriodDefinition))
      .attr('class', 'cashflow-balance')
      .attr('stroke-dasharray', '4')
      .attr('data-test-cashflow-balance-current', (_, index) => index);

    if (this.isAnimated) {
      let animationDuration = this.animationDuration / 2;
      let currentPeriodPathLength = currentPeriodPath.node().getTotalLength() * 1.1;
      currentPeriodPath
        .attr('stroke-dashoffset', currentPeriodPathLength)
        .attr('stroke-dasharray', function () {
          let dashing = 4;
          let dashLength = dashing * 2;
          let dashCount = Math.ceil(currentPeriodPathLength / dashLength);
          let newDashes = new Array(dashCount).join(`${dashing}, ${dashing} `);
          return `${newDashes} 0, ${currentPeriodPathLength}`;
        })
        .transition()
        .ease(easeCubicInOut)
        .attr('stroke-dashoffset', 0)
        .duration(animationDuration)
        .delay(this.animationDelay)
        .waitFor();

      this._cumulateDelay(animationDuration);
    } else {
      currentPeriodPath.attr('stroke-dasharray', '4');
    }
  }

  /**
   * Draws the line current situation dot
   * @param {*} timeseries Timeseries
   * @param {*} xScale D3 X base scale
   * @param {*} yScale D3 Y Scale
   * @param {*} index Dot position index
   * @param {*} isCurrent Is reflecting a current situation
   */
  _drawBalanceLineDot(timeseries, xScale, yScale, index, isCurrent) {
    let dotRadius = 3;
    let animationDuration = this.animationDuration / 2;
    let balanceDot = this.chartRenderer
      .selectAll('cashflow-balance-dot')
      .data([timeseries[index]])
      .enter()
      .append('circle')
      .attr('class', 'cashflow-balance-dot')
      .attr('data-test-cashflow-balance-dot', (_, index) => index)
      .attr('cx', this._getLineXPosition(index, timeseries, xScale, isCurrent, true))
      .attr('cy', this._getLineYPosition(index, timeseries, yScale))
      .attr('r', this.isAnimated ? 0 : dotRadius);

    if (this.isAnimated) {
      balanceDot
        .transition()
        .attr('r', dotRadius)
        .duration(animationDuration)
        .delay(this.animationDelay)
        .waitFor();

      this._cumulateDelay(animationDuration);
    }
  }

  /**
   * --------------------
   * Bars drawing methods
   * --------------------
   */

  /**
   * Draws chart double bars
   * @param {*} timeseries Timeseries
   * @param {*} xScale D3 X Scale
   * @param {*} yScale D3 Y Scale
   */
  _drawBars(timeseries, xScale, yScale) {
    let barEaseBackOffset = 0.1;
    let generalHeight = this.chartInnerHeight + this.chartSpacing.top;

    let getBarHeight = value => Math.abs(yScale(0) - yScale(value));
    let getBarInitialHeight = value => yScale(0) - getBarHeight(value) * barEaseBackOffset;

    let flowDataSelection = this.chartRenderer
      .selectAll('.cashflow-flow-container')
      .data(timeseries)
      .enter();

    let flowContainer = flowDataSelection
      .append('g')
      .attr('class', 'cashflow-flow-container')
      .attr('data-test-cashflow-flow-container', (_, index) => index)
      .attr('width', xScale.bandwidth())
      .attr('height', generalHeight - yScale(0))
      .attr('transform', timevalue => `translate(${xScale(timevalue.time)}, 0)`);

    let flowBarDataSelection = flowContainer
      .selectAll('.cashflow-flow-bar')
      .data((timevalue, index) => this._getFlowBarsData(timevalue, index))
      .enter()
      .append('rect')
      .attr('class', ({ type }) => `cashflow-flow-bar ${type}`)
      .attr('data-test-cashflow-flow-bar', '')
      .attr('x', xScale.bandwidth() / 2)
      .attr('rx', 2);

    if (this.isAnimated) {
      flowBarDataSelection = flowBarDataSelection
        .attr('y', flow => getBarInitialHeight(flow.value))
        .attr('height', flow => getBarHeight(flow.value) * barEaseBackOffset)
        .transition()
        .ease(easeBackInOut)
        .duration(this.animationDuration)
        .delay(this.animationDelay)
        .waitFor();

      this._cumulateDelay(this.animationDuration);
    }

    flowBarDataSelection
      .attr('y', ({ value }) => (value < 0 ? yScale(0) : yScale(value)))
      .attr('height', ({ value }) => getBarHeight(value));
  }

  /**
   * ----------
   * Animations
   * ----------
   */

  _cumulateDelay(animationDuration) {
    this.animationDelay += animationDuration;
  }

  _resetDelay() {
    this.animationDelay = 0;
  }

  _captureAndReportToSentry(index, timeseries) {
    let extra = {
      index,
      timeseriesLength: timeseries.length,
      timeseriesFirstTime: timeseries.length ? timeseries[0]?.time : 'none',
      timeseriesLastTime: timeseries.length ? timeseries.at(-1)?.time : 'none',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startOfToday: dayjs().startOf('day').toDate(),
      bounds: {
        start: this.startBound,
        end: this.endBound,
      },
    };

    if (index < 0) {
      Sentry.captureMessage('Overview bug debug: Index is negative', {
        extra,
      });
    }

    if (index >= timeseries.length) {
      Sentry.captureMessage('Overview bug debug: Index is higher than timeseries length', {
        extra,
      });
    }

    if (timeseries.includes(undefined)) {
      Sentry.captureMessage('Overview bug debug: Timeseries includes `undefined` element', {
        extra,
      });
    }
  }
}
