/* import __COLOCATED_TEMPLATE__ from './update-session.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class PopupSecurityUpdateEmailUpdateSessionComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service userManager;
  @service segment;

  constructor() {
    super(...arguments);
    this.segment.track('profile_email-update_success');
  }

  @action
  handleClick() {
    this.segment.track('profile_email-update_log-out');
    this.args.close();
  }
}
