export default {
  "card": "cxP",
  "card-primary": "cxl",
  "content": "cxX",
  "caption-text": "cxC",
  "content-primary": "cxk",
  "disabled": "cxJ",
  "illustration": "cxs",
  "illustration-primary": "cxF",
  "illustration-disabled": "cxg",
  "cta": "cxD",
  "dismiss-button": "cxV",
  "buttons": "cxL",
  "required-badge": "cxT"
};
