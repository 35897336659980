/* import __COLOCATED_TEMPLATE__ from './validation.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask, waitForQueue } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

const CONFIRMED_REJECTION = 'confirmed-rejection';

export default class RequestSidebarCardValidationComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service abilities;
  @service intl;
  @service router;
  @service segment;
  @service toastFlashMessages;
  @service organizationManager;
  @service modals;
  @service notifierCounterManager;
  @service sensitiveActions;

  constructor() {
    super(...arguments);
    this.setMainAccountTask.perform().catch(ignoreCancelation);
  }

  close() {
    this.args.close?.();
  }

  @reads('organizationManager.organization.activeAccounts') activeAccounts;
  @reads('organizationManager.membership') membership;

  get accounts() {
    return this.activeAccounts.filter(({ isRemunerated }) => !isRemunerated);
  }

  @action
  handleDeclineClick() {
    let params = {
      request_type: this.args.request.requestType,
      request_id: this.args.request.id,
      role: this.membership.role,
      ...this.args.scopeParams,
    };

    this.declineRequestTask.perform().catch(ignoreCancelation);
    this.segment.track('request_decline_clicked', params);
  }

  @action
  handleApproveClick() {
    let params = {
      request_type: this.args.request.requestType,
      request_id: this.args.request.id,
      role: this.membership.role,
      ...this.args.scopeParams,
    };

    this.sensitiveActions.runTask.perform(this.approveRequestTask).catch(ignoreCancelation);
    this.segment.track('request_approve_clicked', params);
  }

  @action
  handleRequestPendingCancelClick() {
    let { request } = this.args;
    this.cancelRequestTask.perform().catch(ignoreCancelation);
    this.segment.track('request_cancel_clicked', {
      request_type: request.requestType,
      request_id: request.id,
      ...this.args.scopeParams,
    });
  }

  get canShowAccountSelector() {
    let { isExpired, status } = this.args.request;
    let canShow =
      this.abilities.can('review card requests') &&
      this.activeAccounts.length > 1 &&
      status === 'pending' &&
      !isExpired;

    if (canShow) {
      this.setMainAccountTask.perform().catch(ignoreCancelation);
    }
    return canShow;
  }

  approveRequestTask = dropTask(async () => {
    try {
      let { initiator } = this.args.request;

      if (initiator.get('revoked')) {
        return this.toastFlashMessages.toastError(
          this.intl.t('requests.cards.toasts.revoked-member', {
            requesterName: initiator.get('fullName'),
          })
        );
      }

      await this.approveTask.linked().perform();

      let message = this.intl.t('requests.cards.toasts.request-approved');
      this.toastFlashMessages.toastInfo(message);
      this.segment.track('request_approved', {
        request_type: this.args.request.requestType,
        request_id: this.args.request.id,
        role: this.membership.role,
      });

      /*
        There is no event for card approvals so
        we need to manually trigger counter request here.
        This does not cover when another admin
        approves the request, then the counter is not updated
        as it would be with a websocket.
        These are edge cases so this solution will still
        improve the UX.
      */
      await this.notifierCounterManager.updateCounter();
      this.close();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
    }
  });

  approveTask = dropTask(async () => {
    await this.args.approve.linked().perform(this.args.request);
  });

  cancelRequestTask = dropTask(async () => {
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('requests.modals.cancel-modal.title'),
      description: this.intl.t('requests.modals.cancel-modal.description'),
      cancel: this.intl.t('btn.close'),
      confirm: this.intl.t('requests.modals.cancel-modal.cta'),
      confirmTask: this.cancelTask,
    });

    if (result !== undefined) {
      return;
    }

    let message = this.intl.t('requests.cards.toasts.request-cancelled');
    this.toastFlashMessages.toastInfo(message);
    this.segment.track('request_canceled', {
      request_type: this.args.request.requestType,
      request_id: this.args.request.id,
    });
    this.close();
  });

  cancelTask = dropTask(async close => {
    await this.args.cancel(this.args.request);
    close();
  });

  declineRequestTask = dropTask(async () => {
    let result = await this.modals.open('request/sidebar/modals/decline-request', {
      request: this.args.request,
      confirmTask: this.declineTask,
    });

    if (result !== CONFIRMED_REJECTION) {
      return;
    }

    let message = this.intl.t('requests.cards.toasts.request-declined');
    this.toastFlashMessages.toastInfo(message);
    this.segment.track('request_declined', {
      request_type: this.args.request.requestType,
      request_id: this.args.request.id,
      role: this.membership.role,
    });
    this.close();
  });

  declineTask = dropTask(async close => {
    await this.args.decline(this.args.request);
    close(CONFIRMED_REJECTION);
  });

  @action
  handleSetAccount(account) {
    this.args.setAccount(this.args.request, account);
    this.setMainAccountTask.perform().catch(ignoreCancelation);
  }

  @action
  handleReloadRequest() {
    this.router.transitionTo('requests.past', {
      queryParams: { highlight: this.args.request.id },
    });
  }

  @action
  handleSeeCard() {
    this.segment.track('request_see_item_clicked', {
      request_type: this.args.request.requestType,
      request_id: this.args.request.id,
      role: this.membership.role,
    });
    this.router.transitionTo('cards.index', {
      queryParams: {
        highlight: this.args.request.belongsTo('card').id(),
      },
    });
  }

  setMainAccountTask = dropTask(async () => {
    await waitForQueue('afterRender');
    let { currentAccount } = this.organizationManager;
    let bankAccount = await this.args.request.belongsTo('bankAccount').load();
    if (
      this.abilities.can('review card requests') &&
      !bankAccount &&
      this.args.request.status === 'pending'
    ) {
      this.args.request.bankAccount = currentAccount;
      return currentAccount;
    } else {
      return this.args.request.bankAccount;
    }
  });
}
