/* import __COLOCATED_TEMPLATE__ from './supplier-information.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { IT_FISCAL_REGIME_DESCRIPTION } from 'qonto/constants/self-billing';

export default class SelfBillingFormSupplierInformationComponent extends Component {
  taxRegime = IT_FISCAL_REGIME_DESCRIPTION;

  @action
  updateName(name) {
    this.args.invoice.set('supplierSnapshot.name', name);
    this.args.invoice.errors.remove('supplierSnapshotName');
  }

  @action
  updateZipCode(zipCode) {
    this.args.invoice.set('supplierSnapshot.zipCode', zipCode);
    this.args.invoice.errors.remove('supplierSnapshotZipCode');
  }

  @action
  updateAddress(address) {
    this.args.invoice.set('supplierSnapshot.address', address);
    this.args.invoice.errors.remove('supplierSnapshotAddress');
  }

  @action
  updateCity(city) {
    this.args.invoice.set('supplierSnapshot.city', city);
    this.args.invoice.errors.remove('supplierSnapshotCity');
  }

  @action
  updateCountry(country) {
    this.args.invoice.set('supplierSnapshot.countryCode', country);
    this.args.invoice.errors.remove('supplierSnapshotCountryCode');
  }
}
