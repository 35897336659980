/* import __COLOCATED_TEMPLATE__ from './temporary-announcement.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class TopbarTemporaryAnnouncementComponent extends Component {
  topBanner = TopBanner;

  @action
  close() {
    let { bannerId } = this.args.content;
    safeLocalStorage.setItem(bannerId, true);

    this.args.close();
  }
}
