export default {
  "name-wrapper": "cmY",
  "preset-name": "cmh",
  "invoices-entry-period-container": "cmf",
  "transactions-entry-period-container": "cmK",
  "period-wrapper": "cmG",
  "period-btn": "cmr",
  "cancel-placeholder": "cmb",
  "period-cancel-btn": "cmy",
  "transactions-entry-container": "cmU",
  "invoices-entry-container": "cmj",
  "dropdown": "cmW",
  "dropdown-opened": "cmu",
  "dropdown-closed": "cpS"
};
