/* import __COLOCATED_TEMPLATE__ from './share-panel.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox, Disclaimer, TextAreaField } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReceivableInvoicesSharePanelShareByEmail extends Component {
  checkbox = Checkbox;
  disclaimerBlock = Disclaimer.Block;
  textAreaField = TextAreaField;

  @service intl;
  @service segment;
  @service networkManager;
  @service toastFlashMessages;
  @service sentry;
  @service errors;
  @service store;
  @service router;

  @tracked displaySubjectErrors = false;
  @tracked areEmailsInvalid = false;
  @tracked sendTo = this.args.sendTo || '';
  @tracked displaySendToErrors = false;
  @tracked saveEmailTemplate = false;

  sendDocumentByEmail = dropTask(async () => {
    if (this.args.isQuote) {
      return await this.shareQuoteByEmailTask.perform();
    }
    return await this.shareByEmailTask.perform();
  });

  @action
  trackingEvent(eventName) {
    this.toastFlashMessages.toastInfo(this.intl.t('receivable-invoices.share-link.toaster-copied'));
    this.segment.track(eventName);
  }

  get showDraftDisclaimer() {
    return !this.args.isAccountantTabActive && this.args.isDraft;
  }

  get previousRoute() {
    return this.router.currentRoute?.queryParams?.origin;
  }

  get showFrEinvoiceDisclaimer() {
    let creationRoutes = [
      'receivable-invoices.edit',
      'receivable-invoices.new',
      'receivable-invoices.duplicate',
      'receivable-invoices.draft-finalize',
    ];

    if (creationRoutes.includes(this.previousRoute)) {
      return !this.args.isDraft && this.args.document?.isEinvoice;
    }
    return false;
  }

  get documentType() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.document-types.quotes');
    }

    if (this.args.isDraft) {
      return this.intl.t('receivable-invoices.document-types.drafts');
    }

    return this.intl.t('receivable-invoices.document-types.invoices');
  }

  get sendToFieldMessage() {
    if (!this.displaySendToErrors) {
      return this.intl.t('receivable-invoices.share-email.field-send-to.disclaimer');
    }

    if (this.areEmailsInvalid) {
      return this.intl.t('receivable-invoices.share-email.field-send-to.error');
    }

    if (!this.sendTo) {
      return this.intl.t('receivable-invoices.share-email.required-field');
    }
  }

  get shouldShowSubtitle() {
    return !this.args.isAccountantTabActive && !this.args.isCreditNote;
  }

  get subtitle() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.share-email.subtitle.quotes');
    }

    if (this.args.isDraft) {
      return this.intl.t('receivable-invoices.share-email.subtitle.drafts');
    }

    return this.intl.t('receivable-invoices.share-email.subtitle.invoices');
  }

  @action
  updateSendTo(value) {
    this.sendTo = value;
    this.displaySendToErrors = false;
  }

  get subjectFieldMessage() {
    this.updateDisplaySubjectErrors(false, this.subjectHasError);

    if (this.displaySubjectErrors) {
      return this.intl.t('receivable-invoices.share-email.required-field');
    }
  }

  get emailsArray() {
    let { sendTo } = this;

    if (!sendTo) return [];

    //Delete space after ',' char,
    //Delete ',' char when first or last param
    //Return an array of emails from a string where emails are separated by a comma
    let emailsArray = sendTo.replace(/^,/, '').replace(/,$/, '').split(',');

    return emailsArray.map(email => email.trim());
  }

  get subjectHasError() {
    let { emailSubject } = this.args;
    return !emailSubject;
  }

  get saveTemplate() {
    return this.args.showUpdateEmailTrigger && this.saveEmailTemplate;
  }

  shareByEmailTask = task(async () => {
    this.updateDisplaySubjectErrors(true, this.subjectHasError);
    let isSendToEmpty = !this.sendTo;
    this.areEmailsInvalid = false;
    this.displaySendToErrors = true;

    if (!isSendToEmpty && !this.displaySubjectErrors) {
      this.sendEmailTrackingEvent();

      let { emailSubject, emailMessage, copyToSelf, close } = this.args;

      try {
        await this.networkManager.request(this.sendURL, {
          method: 'POST',
          data: JSON.stringify({
            send_to: this.emailsArray,
            email_title: emailSubject,
            email_body: emailMessage,
            copy_to_self: copyToSelf,
            save_template: this.saveTemplate,
          }),
        });

        this.toastFlashMessages.toastSuccess(this.sendEmailSuccessMessage);
        close();
      } catch (error) {
        if (
          error.status === 422 &&
          error.errors.some(
            ({ code, source }) => code === 'invalid' && source?.pointer.includes('sendto')
          )
        ) {
          this.areEmailsInvalid = true;
        } else {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry) {
            this.sentry.captureException(error);
          }
          if (this.errors.shouldFlash(error)) {
            this.toastFlashMessages.toastError(
              this.intl.t('receivable-invoices.share-email.toast-error')
            );
          }
        }
      }
    }
  });

  shareQuoteByEmailTask = task(async () => {
    this.updateDisplaySubjectErrors(true, this.subjectHasError);
    let isSendToEmpty = !this.sendTo;
    this.areEmailsInvalid = false;
    this.displaySendToErrors = true;

    if (!isSendToEmpty && !this.displaySubjectErrors) {
      this.sendEmailTrackingEvent();
      let { emailSubject, emailMessage, copyToSelf, close } = this.args;

      try {
        await this.networkManager.request(this.sendURL, {
          method: 'POST',
          data: JSON.stringify({
            send_to: this.emailsArray,
            email_title: emailSubject,
            email_body: emailMessage,
            copy_to_self: copyToSelf,
            save_template: this.saveTemplate,
          }),
        });
        this.toastFlashMessages.toastSuccess(this.sendEmailSuccessMessage);
        close();
      } catch (error) {
        if (
          error.status === 422 &&
          error.errors.some(
            ({ code, source }) => code === 'invalid' && source?.pointer.includes('sendto')
          )
        ) {
          this.areEmailsInvalid = true;
        } else {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry) {
            this.sentry.captureException(error);
          }
          if (this.errors.shouldFlash(error)) {
            this.toastFlashMessages.toastError(
              this.intl.t('receivable-invoices.share-email.toast-error')
            );
          }
        }
      }
    }
  });

  get sendURL() {
    let adapter;
    let { isCreditNote, isQuote } = this.args;
    if (isCreditNote) {
      adapter = 'receivable-credit-note';
    } else if (isQuote) {
      adapter = 'quote';
    } else {
      adapter = 'receivable-invoice';
    }
    return this.store.adapterFor(adapter).sendUrl(this.args.document.id);
  }

  sendEmailTrackingEvent() {
    let {
      isDefaultEmailTemplate,
      isDraft,
      isAccountantTabActive,
      isCreditNote,
      isQuote,
      showUpdateEmailTrigger,
    } = this.args;

    let props = { isQontoDefault: isDefaultEmailTemplate && !showUpdateEmailTrigger };

    if (isDraft) {
      if (isAccountantTabActive) {
        return this.segment.track('invoice-draft_send-by-email-accountant_confirmed');
      } else {
        return this.segment.track('invoice-draft_send-by-email_confirmed', props);
      }
    } else if (isCreditNote) {
      return this.segment.track('credit-note_send-by-email_confirmed');
    } else if (isQuote) {
      return this.segment.track('quote_send-by-email_confirmed', props);
    } else {
      return this.segment.track('invoice_send-by-email_confirmed', props);
    }
  }

  get sendEmailSuccessMessage() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-sending.send-quote.toast-success');
    } else if (this.args.isDraft) {
      return this.intl.t('receivable-invoices.share-email.draft.toast-success');
    } else if (this.args.isCreditNote) {
      return this.intl.t('receivable-invoices.share-email.credit-note-toaster');
    } else {
      return this.intl.t('receivable-invoices.share-email.toaster');
    }
  }

  updateDisplayErrors(clickOnSend, hasErrors) {
    if (clickOnSend && hasErrors) {
      return hasErrors;
    } else if (!hasErrors) {
      return false;
    }
    return null;
  }

  updateDisplaySubjectErrors(clickOnSend, hasErrors) {
    let updateValue = this.updateDisplayErrors(clickOnSend, hasErrors);
    if (updateValue !== null) {
      this.displaySubjectErrors = updateValue;
    }
  }
}
