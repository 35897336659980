/* import __COLOCATED_TEMPLATE__ from './flow.hbs'; */
import { service } from '@ember/service';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

import OverviewChartTooltipMonthComponent from './month';

export default class OverviewChartTooltipFlowComponent extends OverviewChartTooltipMonthComponent {
  @service intl;

  get title() {
    return this.args.flow?.isInflow
      ? this.intl.t('overview.inflows')
      : this.intl.t('overview.outflows');
  }

  get flowPreviousPeriod() {
    let frequencyInterval = this.args.selectedFrequency === 'quarterly' ? 'quarter' : 'month';
    let startDate = dateToken({
      date: dayjs().startOf(frequencyInterval).subtract(1, frequencyInterval).toDate(),
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      locale: this.intl.primaryLocale,
    });

    let endDate = dateToken({
      date: dayjs().subtract(1, frequencyInterval).toDate(),
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      locale: this.intl.primaryLocale,
    });

    return `${startDate} - ${endDate}`;
  }

  get flowVariation() {
    let { variation } = this.args.flow;

    return Number.isFinite(variation)
      ? this.intl.formatNumber(variation, {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
          signDisplay: 'always',
        })
      : '--';
  }

  get flowVariationDisplayClass() {
    let { variation, isInflow } = this.args.flow;

    if (variation && Number.isFinite(variation)) {
      let isPositive = isInflow ? variation > 0 : variation < 0;
      return isPositive ? 'positive' : 'negative';
    }
  }

  get frequencyIntervalComparison() {
    if (this.args.selectedFrequency === 'quarterly') {
      return this.intl.t('overview.quarter-comparison');
    }

    return this.intl.t('overview.month-comparison');
  }
}
