/* import __COLOCATED_TEMPLATE__ from './donut.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class OverviewChartDonutComponent extends Component {
  @service intl;

  @tracked tooltipTarget;
  @tracked tooltipData;

  get hasData() {
    // Empty state != no data at all
    return Array.isArray(this.args.data);
  }

  get dataLabels() {
    let { data, groupByType } = this.args;

    return data.map(({ name }) => {
      if (name === 'other') {
        return this.intl.t('overview.other');
      } else if (name === 'uncategorized') {
        if (groupByType.key === 'initiator_id') {
          return this.intl.t('overview.uncategorized.team-member');
        } else if (groupByType.key === 'team_id') {
          return this.intl.t('overview.uncategorized.team');
        } else if (groupByType.key === 'label_lists') {
          return this.intl.t('overview.uncategorized.custom-label', {
            customLabelCategory: groupByType.label,
          });
        }
      }
      return name;
    });
  }

  get isCustomLabelListUncategorized() {
    let { data, groupByType } = this.args;

    return groupByType?.key === 'label_lists' && data?.[0].name === 'uncategorized';
  }

  get dataLocalClasses() {
    let { data } = this.args;
    let localeClassesMap = {
      other: 'donut-chart-legend-other-item',
      uncategorized: 'donut-chart-legend-uncategorized-item',
    };

    return data.map(({ name }) => localeClassesMap[name] || 'donut-chart-legend-item');
  }

  @action
  handleHighlight(target, data) {
    this.tooltipTarget = target;
    this.tooltipData = data;
  }
}
