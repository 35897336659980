/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

import { ensureSafeComponent } from '@embroider/util';
import { dropTask } from 'ember-concurrency';

export default class TransfersRequestTableApproverRowComponent extends Component {
  @service organizationManager;
  @service intl;
  @service segment;

  get requestComponentName() {
    let type = dasherize(this.args.item.requestType);
    return ensureSafeComponent(`request/table/cell/${type}`, this);
  }

  get isRequestedByCurrentUser() {
    let currentUserId = this.organizationManager.membership.id;
    let initiatorId = this.args.item.get?.('initiator.id') || this.args.item.initiator.id;

    return currentUserId === initiatorId;
  }

  get negativeTooltipMessage() {
    return this.isRequestedByCurrentUser
      ? this.intl.t('btn.cancel_request')
      : this.intl.t('transfers.requests.quick-reject-tooltip');
  }

  get approveTooltipMessage() {
    return this.args.item.lastStep
      ? this.intl.t('transfers.tooltip.pay-by-transfer')
      : this.intl.t('transfers.requests.quick-approve-tooltip');
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  @action
  onBankAccountSelect(account) {
    this.args.onBankAccountSelect(this.args.item, account);
  }

  @action
  onBankAccountInteraction(event) {
    this.segment.track('bank_account_picker_opened', {
      origin: 'transfers',
    });

    this.stopPropagation(event);
  }

  @action
  onRowClick(id) {
    if (this.onTransferApprove.isRunning || this.onTransferReject.isRunning) {
      return;
    }

    this.args.highlightItem(id);
  }

  onTransferApprove = dropTask(async () => {
    await this.args.onTransferApprove(this.args.item);
  });

  onTransferReject = dropTask(async () => {
    await this.args.onTransferReject(this.args.item);
  });
}
