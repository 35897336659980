export default {
  "progress-container": "qgV",
  "line-container": "qgL",
  "dashed-line": "qgT",
  "solid-line": "qgz",
  "circle-container": "qga",
  "circle": "qgH",
  "current-step-dot": "qgO",
  "success-icon": "qgm",
  "rejected-icon": "qgp"
};
