/* import __COLOCATED_TEMPLATE__ from './custom-template-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { Disclaimer, RadioButtonControlled, Spinner } from '@repo/design-system-kit';
import { didCancel, dropTask } from 'ember-concurrency';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

const DEFAULT_COLUMNS_ID = ['settlement_date_utc', 'counterparty_name', 'amount'];

export default class TransactionsCustomExportsCustomTemplateModalComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  radioButtonControlled = RadioButtonControlled;
  spinner = Spinner;

  @service segment;
  @service sentry;
  @service store;
  @service intl;
  @service modals;
  @service toastFlashMessages;
  @service localeManager;
  @service abilities;
  @service zendeskLocalization;

  descriptionHtml = htmlSafe(
    this.intl.t('transactions.exports.custom-template.description', {
      innerLink: `<a
      target="_blank"
      rel="noopener noreferrer"
      class="body-link"
      href="${this.intl.t('transactions.exports.custom-template.description-faq-link', {
        faqUrl: this.zendeskLocalization.getLocalizedArticle(8002095),
      })}" data-test-custom-export-decription-link>${this.intl.t(
        'transactions.exports.custom-template.description-faq'
      )}</a>`,
    })
  );

  @tracked exportTemplate;
  @tracked showValidations = false;
  @tracked showColumnValidations = false;
  @tracked _format;
  @tracked _separator;

  constructor() {
    super(...arguments);

    if (!this.args.data.exportTemplate) {
      // create new template
      this.exportTemplate = this.store.createRecord('export-template', {
        type: 'custom',
        columns: this.defaultSelectedColumns,
      });
    } else {
      // edit existing template
      this.exportTemplate = this.args.data.exportTemplate;
    }
  }

  get defaultSelectedColumns() {
    let { columns } = this.args.data;
    return columns
      .filter(column => DEFAULT_COLUMNS_ID.includes(column.id))
      .sort((a, b) => {
        return DEFAULT_COLUMNS_ID.indexOf(a.id) - DEFAULT_COLUMNS_ID.indexOf(b.id);
      });
  }

  closeTask = dropTask(async () => {
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('transactions.exports.custom-template.leave.title'),
      description: this.intl.t('transactions.exports.custom-template.leave.description'),
      cancel: this.intl.t('transactions.exports.custom-template.leave.cancel-cta'),
      confirm: this.intl.t('transactions.exports.custom-template.leave.leave-cta'),
    });

    if (result === 'confirm') {
      if (this.exportTemplate.isNew) {
        this.exportTemplate.unloadRecord();
      } else {
        this.exportTemplate.rollbackAttributes();
      }

      this.args.close();
    }
  });

  submitTemplateTask = dropTask(async () => {
    if (!this.persistedFormat) {
      safeLocalStorage.setItem('custom-export-format', this.format);
    }

    if (this.args.data.exportTemplate) {
      this.segment.track('custom-page_save-template-button_clicked');
    } else {
      this.segment.track('custom-page_create-template-button_clicked');
    }

    this.showValidations = true;
    this.showColumnValidations = true;
    await this.exportTemplate.validate();

    if (
      this.exportTemplate.validations.isValid &&
      this.exportTemplate.columns.every(c => Object.keys(c).length > 0)
    ) {
      this.exportTemplate.columns.forEach((column, index) => {
        column.position = index + 1;
      });
      this.exportTemplate.format = this.format;
      if (this.exportTemplate.format === 'csv') {
        this.exportTemplate.separator = this.separator;
      }

      await this.exportTemplate.save();

      if (this.args.data.exportTemplate) {
        this.toastFlashMessages.toastSuccess(
          this.intl.t('transactions.exports.custom-template.toasts.saved')
        );
      } else {
        this.toastFlashMessages.toastSuccess(
          this.intl.t('transactions.exports.custom-template.toasts.created')
        );
      }

      this.args.close('success');
    }
  });

  @action
  submitTemplate(event) {
    event.preventDefault();

    this.submitTemplateTask.perform().catch(error => {
      let errorInfo = ErrorInfo.for(error);

      if (!didCancel(error) && errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    });
  }

  get canRemoveColumns() {
    return this.exportTemplate.columns.length > 1;
  }

  @action getPosition(index) {
    return index + 1;
  }

  @action
  reorderItems(columns) {
    this.exportTemplate.columns = [...columns];
  }

  @action
  removeColumn(column) {
    this.exportTemplate.columns = this.exportTemplate.columns.filter(c => c !== column);
  }

  get canAddColumn() {
    return Boolean(this.availableColumnsOptions.length);
  }

  @action
  addColumn() {
    this.showColumnValidations = false;
    this.exportTemplate.columns = [...this.exportTemplate.columns, {}];
  }

  a11yAnnouncementConfig = {
    ACTIVATE: ({ index, maxLength }) => {
      return this.intl.t('transactions.exports.custom-template.a11y.ember-sortable.activate', {
        itemCount: maxLength,
        itemIndex: index + 1,
      });
    },
    MOVE: ({ index, maxLength, delta }) => {
      return this.intl.t('transactions.exports.custom-template.a11y.ember-sortable.move', {
        itemCount: maxLength,
        newItemIndex: index + 1 + delta,
      });
    },
    CONFIRM: () => {
      return this.intl.t('transactions.exports.custom-template.a11y.ember-sortable.confirm');
    },
    CANCEL: () => {
      return this.intl.t('transactions.exports.custom-template.a11y.ember-sortable.cancel');
    },
  };

  /**
   * Retrieves the columns and formats the elements of type 'label-list' by fetching their color and name to populate column options
   * @return {Array.<Column>} The formatted columns
   *
   * @typedef {Object} Column
   * @property {String} type - The type of the column ('predefined' or 'label-list')
   * @property {String} id - The id of the label list (for 'label-list' type) or the slug of the column (for 'predefined' type)
   * @property {String} label - The label of the column
   * @property {String} [color] - The color of the column (applies only to 'label-list' type)
   */
  @cached
  get columnsOptions() {
    let { columns } = this.args.data;

    return columns.map(column => {
      if (column.type === 'label_list') {
        let labelList = this.store.peekRecord('label-list', column.id);
        return { ...column, color: labelList.color, label: labelList.name };
      } else {
        return column;
      }
    });
  }

  @cached
  get availableColumnsOptions() {
    return this.columnsOptions.filter(column => !this.exportTemplate.columnsById.get(column.id));
  }

  @cached
  get allColumnsOptionsById() {
    return this.columnsOptions.reduce((result, column) => {
      result[column.id] = column;
      return result;
    }, {});
  }

  @action
  selectColumn(column, selectedColumn) {
    let columns = [...this.exportTemplate.columns];
    columns.splice(columns.indexOf(column), 1, { ...selectedColumn });
    this.exportTemplate.columns = columns;
  }

  get formatOptions() {
    let { intl } = this;
    return [
      { name: intl.t('transactions.exports.export.export-type.sub-type.excel'), value: 'xls' },
      { name: intl.t('transactions.exports.custom-template.format.csv'), value: 'csv' },
    ];
  }

  get persistedFormat() {
    return safeLocalStorage.getItem('custom-export-format');
  }

  get format() {
    if (this._format) {
      return this._format;
    } else if (this.args.data.exportTemplate?.format) {
      return this.exportTemplate.format;
    } else if (this.persistedFormat) {
      return this.persistedFormat;
    } else {
      return 'xls';
    }
  }

  @action
  handleFormatSelection(format) {
    this._format = format;
  }

  get separator() {
    if (this._separator) {
      return this._separator;
    } else if (this.args.data.exportTemplate?.separator) {
      return this.exportTemplate.separator;
    } else {
      return this.localeManager.locale === 'en' ? ',' : ';';
    }
  }

  @action
  handleSeparatorSelection(separator) {
    this._separator = separator;
  }

  get showPermissionsDisclaimer() {
    return !(
      this.abilities.can('read bankAccount') &&
      this.abilities.can('full account details bankAccount') &&
      this.abilities.can('see sensitive fields beneficiaries')
    );
  }
}
