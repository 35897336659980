/* import __COLOCATED_TEMPLATE__ from './requirements.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

export default class SavingsAccountsStepsRequirementsComponent extends Component {
  checkbox = Checkbox;

  @service segment;

  @tracked allowFATCAError = false;
  @tracked allowBannedCountriesError = false;
  @tracked allowProSecrecyError = false;

  @action
  toggleAllowFATCA() {
    this.args.context.toggleAllowFATCA();
    this.allowFATCAError = false;
  }

  @action
  toggleAllowBannedCountries() {
    this.args.context.toggleAllowBannedCountries();
    this.allowBannedCountriesError = false;
  }

  @action
  toggleAllowProSecrecy() {
    this.args.context.toggleAllowProSecrecy();
    this.allowProSecrecyError = false;
  }

  handleNextTask = dropTask(async () => {
    this.allowFATCAError = !this.args.context.allowFATCA;
    this.allowBannedCountriesError = !this.args.context.allowBannedCountries;
    this.allowProSecrecyError = !this.args.context.allowProSecrecy;

    if (
      this.args.context.allowFATCA &&
      this.args.context.allowBannedCountries &&
      this.args.context.allowProSecrecy
    ) {
      this.segment.track('savings_compliance_cta');
      await this.args.context.loadMemberships();
      this.args.transitionToNext();
    }
  });
}
