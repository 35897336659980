export default {
  "banner": "cTp",
  "content": "cTx",
  "title-row": "cTw",
  "title": "cTN title-3",
  "badge": "cTv",
  "text": "cTo body-2",
  "missing-info-disclaimer": "cTn",
  "button": "cTi btn btn--primary",
  "spinner": "cTA",
  "illustration": "cTY",
  "image": "cTh"
};
