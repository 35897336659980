/* import __COLOCATED_TEMPLATE__ from './month.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

export default class OverviewChartTooltipMonthComponent extends Component {
  @service intl;

  get monthlyCashflow() {
    let monthlyCashflow = this.args.monthlyCashflow;
    if (dayjs(monthlyCashflow.time).isAfter(Date.now())) {
      return Object.keys(monthlyCashflow).forEach(key => {
        monthlyCashflow[key] = undefined;
      });
    } else {
      return monthlyCashflow;
    }
  }

  get title() {
    if (!this.monthlyCashflow) return '';

    let d = dayjs(this.monthlyCashflow.time).toDate();
    if (this.args.selectedFrequency === 'quarterly') {
      return `Q${dayjs(d).quarter()} ${dayjs(d).year()}`;
    }

    return dateToken({ date: d, locale: this.intl.primaryLocale, token: 'month-year-l' });
  }

  get monthlyTotalTranslation() {
    if (this.args.selectedFrequency === 'quarterly') {
      return this.args.isCurrent
        ? this.intl.t('overview.quarter-to-date')
        : this.intl.t('overview.quarterly-total');
    }
    return this.args.isCurrent
      ? this.intl.t('overview.month-to-date')
      : this.intl.t('overview.monthly-total');
  }

  get period() {
    return this.args.selectedFrequency === 'quarterly'
      ? this.intl.t('cash-flow.frequency-selector.quarter')
      : this.intl.t('cash-flow.frequency-selector.month');
  }

  get startOfPeriod() {
    return this.intl.t('overview.start-of-period', { period: this.period });
  }

  get endOfPeriod() {
    if (this.args.isCurrent) return this.intl.t('overview.current');

    return this.intl.t('overview.end-of-period', { period: this.period });
  }
}
