/* import __COLOCATED_TEMPLATE__ from './transition-modal.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class TransfersKYCIntroComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service flowLinkManager;
  @service organizationManager;
  @service router;
  @service segment;

  animationReady = false;
  defaultLottieSrc = '/lotties/fourthline/intro.json';

  constructor() {
    super(...arguments);

    this.segment.track('submit_kyc_information_viewed', {
      origin: 'transfer_flow',
    });

    assert(
      '<Transfers::Kyc::TransitionModal /> must have a @data.transfer',
      this.args.data.transfer
    );
  }

  @action
  handleConfirm() {
    let { personalInfoProvided } = this.organizationManager.membership;
    if (personalInfoProvided) {
      this.router.transitionTo('kyc.start', {
        queryParams: {
          trackingEventOrigin: 'transfer_flow',
        },
      });
    } else {
      this.segment.track('submit_kyc_information_start');
      this.flowLinkManager.transitionTo({
        name: 'management-kyc',
        stepId: 'user-info',
      });
    }
    this.args.close();
  }

  @action
  handleClose() {
    this.router.transitionTo('transfers.pending');
    this.args.close();
  }
}
