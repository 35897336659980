export default {
  "payment-details": "cip",
  "label-payment-conditions": "cix",
  "dropdown-content": "ciw",
  "dropdown-option": "ciN",
  "activate-sdd-container": "civ",
  "activate-sdd-header": "cio",
  "selector-toggle-sdd": "cin",
  "selector-toggle-sdd-subtitle": "cii",
  "selector-toggle-content": "ciA",
  "selector-toggle": "ciY",
  "new-badge": "cih",
  "new-badge-activation": "cif",
  "mt-16": "ciK",
  "sdd-mandate-select-loading": "ciG"
};
