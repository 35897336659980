/* import __COLOCATED_TEMPLATE__ from './wizard.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { next, schedule } from '@ember/runloop';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

import isFunction from 'qonto/utils/is-function';

/**
 * Create multi-step, full-screen experiences.
 * @class Wizard
 */
export default class Wizard extends Component {
  @service router;

  constructor() {
    super(...arguments);
    assert('a Wizard must be provided a array of Step objects', this.args.steps);
    assert(
      'a Wizard must be provided a current step id that matches a query param',
      this.args.currentStepId
    );

    next(() => {
      this.replaceStepQueryParam();
    });
  }

  get onComplete() {
    return isFunction(this.args.onComplete) ? this.args.onComplete : () => {};
  }

  get steps() {
    return this.args.steps;
  }

  get currentStep() {
    return this.steps.find(step => step.id === this.args.currentStepId);
  }

  get currentStepIndex() {
    return this.steps.findIndex(step => step.id === this.args.currentStepId);
  }

  get nextStepId() {
    if (isFunction(this.currentStep.getNextStep)) {
      return this.currentStep.getNextStep(this.args.context);
    }

    let nextStep = this.steps[this.currentStepIndex + 1];
    return isPresent(nextStep) ? nextStep.id : null;
  }

  get currentStepName() {
    return isFunction(this.currentStep.name)
      ? this.currentStep.name(this.args.context)
      : this.currentStep.name;
  }

  get currentStepPosition() {
    return this.currentStepIndex + 1;
  }

  get currentStepComponentName() {
    return isFunction(this.currentStep.componentName)
      ? this.currentStep.componentName()
      : this.currentStep.componentName;
  }

  get hideGoBack() {
    if (typeof this.args.hideGoBack !== 'undefined') {
      return this.args.hideGoBack;
    }

    let { currentStepIndex, steps } = this;
    let lastStepIndex = steps.length - 1;

    return currentStepIndex === 0 || currentStepIndex === lastStepIndex;
  }

  @action transitionToNext() {
    let nextStepId = this.nextStepId;
    if (nextStepId) {
      this.router.transitionTo({ queryParams: { step: nextStepId } });
    } else {
      this.onComplete();
    }
  }

  @action goBack() {
    this.args.onGoBack?.();
    window.history.back();
  }

  @action replaceStepQueryParam() {
    schedule('afterRender', () => {
      let step = this.router.currentRoute?.queryParams.step;
      if (!step || step !== this.steps[0].id) {
        this.router.replaceWith({ queryParams: { step: this.steps[0].id } });
      }
    });
  }
}
