/* import __COLOCATED_TEMPLATE__ from './sidebar-labels.hbs'; */
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import fade from 'ember-animated/transitions/fade';

const VISIBLE_SLICE_COUNT = 3;
const VISIBLE_SLICE_COUNT_READONLY = 2;

export default class SidebarLabels extends Component {
  @service organizationManager;

  @tracked showMore = false;

  transition = fade;

  get visibleListsNumber() {
    if (!this.args.readonly) return VISIBLE_SLICE_COUNT;

    let filledCount = this.filledLists.length;
    return filledCount < VISIBLE_SLICE_COUNT_READONLY ? filledCount : VISIBLE_SLICE_COUNT_READONLY;
  }

  get filledLists() {
    let { labelLists, selectedLabels } = this.args;
    return labelLists.filter(list => selectedLabels?.find(item => get(item, 'labelList') === list));
  }

  get labelLists() {
    return this.args.readonly ? this.filledLists : this.args.labelLists;
  }

  get visibleLists() {
    return this.labelLists.slice(0, this.visibleListsNumber);
  }

  get collapsibleLists() {
    return this.labelLists.slice(this.visibleListsNumber);
  }

  get isEmpty() {
    return !this.args.labelLists?.length;
  }

  @action
  toggleShowMore() {
    if (this.args.trackToggleShowMore) this.args.trackToggleShowMore();
    this.showMore = !this.showMore;
  }
}
