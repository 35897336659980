/* import __COLOCATED_TEMPLATE__ from './supplier-invoice-details.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class SelfBillingFormSupplierInvoiceDetailsComponent extends Component {
  @tracked issueDate = this.args.invoice.issueDate;
  numberMaxLength = 20;

  @action
  updateSupplierInvoiceIssueDate(date) {
    this.issueDate = date;
    this.args.invoice.set('supplierInvoiceSnapshot.issueDate', date || undefined);
  }
}
