export default {
  "selector": "cnH",
  "pension-selector-content": "cnO",
  "withholding-tax-selector-content": "cnm",
  "withholding-tax-selector-content-row": "cnp",
  "power-select-field": "cnx",
  "rate-field": "cnw",
  "stamp-revenue-field": "cnN",
  "dropdown-option": "cnv",
  "dropdown-content": "cno"
};
