export default {
  "row": "qwM",
  "counterparty-name": "qwP",
  "counterparty-status": "qwl",
  "amount": "qwX",
  "next-date": "qwC",
  "end-date": "qwk",
  "frequency": "qwJ",
  "active": "qws",
  "cell": "qwF body-2",
  "no-padding": "qwg",
  "cell-content": "qwD",
  "counterparty": "qwV",
  "status-avatar": "qwL",
  "beneficiary-name": "qwT",
  "empty": "qwz",
  "disabled": "qwa",
  "fadein-item": "qwH"
};
