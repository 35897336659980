/* import __COLOCATED_TEMPLATE__ from './update-avatar-form.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import LocalFile from 'qonto/objects/local-file';

export default class UpdateAvatarForm extends Component {
  @tracked cropper;
  @tracked crop;

  @action cropAction(cropper, event) {
    let cropData = event.detail;
    let imageData = cropper.getImageData();
    this.cropper = cropper;
    this.crop = { cropData, imageData };

    return this.handleAvatarChanges();
  }

  @action handleAvatarChanges() {
    let { cropper } = this;

    if (cropper) {
      next(() => {
        let canvas = cropper.getCroppedCanvas();

        this.args.handleFinalFile(
          LocalFile.create({
            fileName: this.args.avatar.fileName,
            fileContentType: 'image/png',
            fileUrl: canvas.toDataURL(),
          })
        );
      });
    }
  }
}
