export default {
  "cell-content": "qSV",
  "icon": "qSL",
  "status_check-m": "qST",
  "description": "qSz body-1",
  "from": "qSa body-2",
  "from--abort": "qSH",
  "description--abort": "qSO",
  "title": "qSm body-2"
};
