export default {
  "row": "crV",
  "icon": "crL",
  "cell": "crT",
  "cell-amount": "crz",
  "cell-status": "cra",
  "cell-date": "crH",
  "cell-content": "crO",
  "align-right": "crm"
};
