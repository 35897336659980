/* import __COLOCATED_TEMPLATE__ from './indicator.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class OverviewFiltersIndicatorComponent extends Component {
  get indicatorType() {
    return this.args.data?.indicatorType || 'inflows';
  }

  @action
  onSubmitAdvancedFilters(filters, period) {
    this.args.close({ filters, period });
  }

  @action
  onClose() {
    this.args.close();
  }
}
