export default {
  "container": "qLn",
  "header": "qLi",
  "title": "qLA caption-bold",
  "search-icon": "qLY",
  "text": "qLh body-2",
  "trigger": "qLf",
  "loading-state": "qLK",
  "dropzone-placeholder-wrapper": "qLG",
  "dropzone-placeholder": "qLr",
  "file-loading": "qLb",
  "search-qonto-cta": "qLy",
  "search-qonto-cta-content": "qLU"
};
