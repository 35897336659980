/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class PowerSelectAccountsFooterComponent extends Component {
  @service router;

  @action
  hoverActions(event) {
    this.args.hoverActions?.(event);
  }
}
