/* import __COLOCATED_TEMPLATE__ from './savings-accounts.hbs'; */
import Component from '@glimmer/component';

import { SORT_BY, STATUS } from 'qonto/constants/savings-account';
import { sortByKeys } from 'qonto/utils/sort-by-keys';

function activeOrClosingAccount({ status }) {
  return [STATUS.ACTIVE, STATUS.CLOSING].includes(status);
}

export default class SavingsAccountsComponent extends Component {
  get savingsAccountsBalance() {
    return this.args.savingsAccounts.reduce((sum, { amount, closureExpectedAmount, status }) => {
      if (status === STATUS.ACTIVE) {
        return sum + amount;
      } else if (status === STATUS.CLOSING) {
        return sum + closureExpectedAmount;
      }
      return sum;
    }, 0);
  }

  get activeOrClosingAccounts() {
    return this.args.savingsAccounts.filter(activeOrClosingAccount);
  }

  get savingsAccountsSorted() {
    let savingsAccounts = [...this.args.savingsAccounts];
    return savingsAccounts.sort(sortByKeys(SORT_BY));
  }
}
