/* import __COLOCATED_TEMPLATE__ from './empty-states.hbs'; */
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

export default class RequestsProvidersPastEmptyStates extends Component {
  @service abilities;
  @service intl;
  @service router;
  @service flowLinkManager;
  @service organizationManager;
  @service zendeskLocalization;

  canReadEbics = this.abilities.can('read ebics request');
  canCreateCardRequests = this.abilities.can('create card request');
  canReviewCardRequests = this.abilities.can('review card request');
  canCreateTransferRequests = this.abilities.can('create transfer request');
  canReviewTransferRequests = this.abilities.can('review transfer request');
  canInviteMembers = this.abilities.can('create invite');
  lottieSrc = '/lotties/empty-state/search-no-result.json';

  get subtitle() {
    if (this.canReadEbics) {
      let linkText = htmlSafe(
        `<a
            href=${this.intl.t('transfers.requests.third-party.empty.link', {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(4359616),
            })}
            target="_blank"
            rel="noopener noreferrer"
            class="body-link"
          >${this.intl.t('transfers.requests.third-party.empty.link-text')}</a>`
      );
      let { legalCountry } = this.organizationManager.organization;

      if (legalCountry === 'DE') {
        return this.intl.t('empty-states.requests.subtitle.ebics', { htmlSafe: true, linkText });
      } else {
        return this.intl.t('empty-states.requests.subtitle.third-party', {
          htmlSafe: true,
          linkText,
        });
      }
    }

    if (this.canReviewTransferRequests && this.canReviewCardRequests && this.canInviteMembers) {
      return this.intl.t('empty-states.requests.rev-transfer-rev-card-invite.subtitle');
    }

    if (this.canReviewTransferRequests && this.canReviewCardRequests && !this.canInviteMembers) {
      return this.intl.t('empty-states.requests.subtitle.past-reviewed');
    }

    if (this.canCreateTransferRequests && this.canReviewCardRequests) {
      return this.intl.t('empty-states.requests.subtitle.past-reviewed-submitted');
    }

    if (this.canReviewTransferRequests && this.canCreateCardRequests) {
      return this.intl.t('empty-states.requests.subtitle.past-reviewed-submitted');
    }
  }

  get emptyStates() {
    let transitionToRequestLanding = () =>
      this.router.transitionTo('requests.landing', this.organizationManager.organization.slug);
    let transitionToInviteFlow = () => this.flowLinkManager.transitionTo({ name: 'member-invite' });

    // Organizations without the requests feature, but with the ebics feature
    if (this.canReadEbics) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        lottieSrc: this.lottieSrc,
      };
    }

    // Admins and managers with review permissions
    if (this.canReviewTransferRequests && this.canReviewCardRequests && this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.invite-members'),
          callback: transitionToInviteFlow,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review permissions but without invite permissions
    if (this.canReviewTransferRequests && this.canReviewCardRequests && !this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review card request, create transfer request and invite permissions
    if (this.canCreateTransferRequests && this.canReviewCardRequests && this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.invite-members'),
          callback: transitionToInviteFlow,
        },
        secondaryButton: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review transfer request, create card request and invite permissions
    if (this.canReviewTransferRequests && this.canCreateCardRequests && this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.invite-members'),
          callback: transitionToInviteFlow,
        },
        secondaryButton: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review card request and create transfer request permissions but without invite permissions
    if (this.canCreateTransferRequests && this.canReviewCardRequests && !this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review transfer request and create card request permissions but without invite permissions
    if (this.canReviewTransferRequests && this.canCreateCardRequests && !this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
  }
}
