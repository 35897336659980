export default {
  "row": "qmI",
  "cell": "qmt",
  "empty": "qmM",
  "icon": "qmP",
  "type": "qml",
  "amount-content": "qmX",
  "cell-approve": "qmC",
  "approve-content": "qmk",
  "row-compact": "qmJ"
};
