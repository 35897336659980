export default {
  "row": "cLT",
  "terminated": "cLz",
  "status-avatar": "cLa",
  "amount": "cLH body-1",
  "item-info": "cLO",
  "date-cell": "cLm",
  "cell": "cLp body-2",
  "status-icon": "cLx",
  "amount-cell": "cLw",
  "item-name-cell": "cLN",
  "item-name-container": "cLv"
};
