/* import __COLOCATED_TEMPLATE__ from './colorpicker.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class ReceivableInvoicesOnboardingColorpickerComponent extends Component {
  @action
  onChange(event) {
    let { value } = event.target;

    this.args.update(value);
  }
}
