export default {
  "header": "crj",
  "header-type": "crW",
  "close": "cru",
  "close-icon": "cbS",
  "header-infos": "cbc",
  "title": "cbq body-1",
  "declined": "cbZ",
  "canceled": "cbR",
  "approved": "cbQ",
  "pending": "cbe",
  "date": "cbB body-2",
  "mileage-icon": "cbE",
  "distance-subtitle": "cbd body-2",
  "link-icon": "cbI",
  "container": "cbt",
  "picto": "cbM",
  "general": "cbP",
  "amount": "cbl",
  "calculation-container": "cbX",
  "counterparty": "cbC body-2",
  "infobox": "cbk"
};
