/* import __COLOCATED_TEMPLATE__ from './transfer-sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class TransferSidebarComponent extends Component {
  @service segment;
  @service intl;
  @service organizationManager;
  @service internationalOutManager;
  @service store;

  @tracked timeline;

  constructor() {
    super(...arguments);
    this.checkAndLoadTransferTimeline();
  }

  get transferCanBeEdited() {
    let { isStanding, isScheduled, isPending } = this.args.transfer;
    return (isStanding || isScheduled) && isPending;
  }

  get isUserKycSubmittedAndPending() {
    if (this.isUserInitiator) {
      let { kycPending, kycSubmitted } = this.organizationManager.membership;
      return kycSubmitted && kycPending;
    }

    return false;
  }

  get isUserInitiator() {
    let { id: userId } = this.organizationManager.membership;
    let { id: initiatorId } = this.args.transfer.initiator;

    return userId === initiatorId;
  }

  @action
  checkAndLoadTransferTimeline() {
    if (
      ['declined', 'canceled'].includes(this.args.transfer.status) ||
      !this.args.transfer.isInternationalOut
    ) {
      return;
    }

    this.loadTransferTimelineTask.perform(this.args.transfer).catch(ignoreCancelation);
  }

  get shouldDisplayKycDisclaimer() {
    if (this.isUserInitiator) {
      let { kycPending, kycSubmitted } = this.organizationManager.membership;

      return !kycSubmitted || kycPending;
    }

    let initiator = this.findInitiatorMembership();

    if (initiator) {
      return !initiator.kycSubmitted || initiator.kycPending;
    }

    return false;
  }

  findInitiatorMembership() {
    let initiatorMembership = this.store.peekAll('membership').filter(({ id }) => {
      return this.args.transfer.initiator.id === id;
    });

    if (initiatorMembership.length > 0) {
      let [initiator] = initiatorMembership;
      return initiator;
    }
    return null;
  }

  loadTransferTimelineTask = dropTask(async ({ id }) => {
    try {
      this.timeline = await this.internationalOutManager.getTransferTimeline(id);
    } catch {
      this.timeline = null;
    }
  });
}
