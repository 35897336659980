export default {
  "wrapper": "qSZ",
  "request-header": "qSR",
  "request": "qSQ",
  "scheduled": "qSe",
  "scheduled-label": "qSB",
  "note": "qSE",
  "note-label": "qSd",
  "note-label-danger": "qSI",
  "note-content": "qSt",
  "status": "qSM",
  "transfer-amount": "qSP"
};
