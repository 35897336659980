export default {
  "container": "cYw",
  "form-container": "cYN",
  "content": "cYv",
  "fields": "cYo",
  "input-settings": "cYn",
  "current-year": "cYi",
  "next-invoice-number": "cYA",
  "preview-container": "cYY",
  "actions": "cYh",
  "close-button": "cYf btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cYK",
  "preview-image": "cYG"
};
