export default {
  "transaction": "qVn",
  "transaction-details": "qVi",
  "transaction-details-amount": "qVA body-1",
  "credit": "qVY",
  "negative-amount": "qVh",
  "transaction-details-description": "qVf body-2",
  "transaction-remove-cta": "qVK",
  "avatar": "qVG mr-16",
  "spinner": "qVr"
};
