/* import __COLOCATED_TEMPLATE__ from './select-supplier.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { isTesting, macroCondition } from '@embroider/macros';
import { restartableTask, timeout } from 'ember-concurrency';

const DEBOUNCE_TIMEOUT = 200;

export default class SelectSupplier extends Component {
  @service intl;
  @service organizationManager;
  @service store;

  get suppliers() {
    return this.handleInputTask.lastSuccessful?.value || [];
  }

  @action
  onBlur(dropdown) {
    dropdown.actions.close();
  }

  handleInputTask = restartableTask(async value => {
    this.args.update(value);

    if (!value) {
      return [];
    }

    if (macroCondition(!isTesting())) {
      await timeout(DEBOUNCE_TIMEOUT);
    }

    let inputValueOption = {
      name: value,
      customOptionMessage: this.intl.t(
        'supplier-invoices.edit.modal.supplier-details.supplier-name.supplier-not-found'
      ),
    };
    try {
      let { organization } = this.organizationManager;
      let suppliers = await this.store.query('beneficiary', {
        organization_id: organization.id,
        query: value,
      });
      return [inputValueOption, ...suppliers.map(({ name, iban, id }) => ({ name, iban, id }))];
    } catch {
      return [inputValueOption];
    }
  });
}
