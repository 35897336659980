/* import __COLOCATED_TEMPLATE__ from './validation.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { KYC_STATUS, ROLES } from 'qonto/constants/membership';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ExpenseReportRequestValidation extends Component {
  @service abilities;
  @service intl;
  @service segment;
  @service toastFlashMessages;
  @service organizationManager;
  @service modals;
  @service sensitiveActions;
  @service zendeskLocalization;

  close() {
    this.args.close?.();
  }

  @reads('args.request.errors.status.0') errorStatus;

  @reads('organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts')
  activeAccounts;
  @reads('organizationManager.membership.kycSubmitted') kycSubmitted;
  @reads('organizationManager.membership.personalInfoProvided') personalInfoProvided;

  get insufficientFundsMessage() {
    return this.isManager
      ? this.intl.t('transfers.errors.manager.multi_insufficient_funds')
      : this.intl.t('transfers.errors.multi_insufficient_funds');
  }

  get confirmErrors() {
    let { confirmErrors = [] } = this.args;

    let errorMessages = [];

    for (let confirmError of confirmErrors) {
      if (confirmError === 'biller_insufficient_funds') {
        errorMessages.push(this.intl.t('validations.errors.insufficient_funds'));
      }

      if (confirmError === 'iban_sepa_error') {
        errorMessages.push(
          this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.body', {
            transferFaqLink: htmlSafe(
              `<a
                href="${this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.url', {
                  faqUrl: this.zendeskLocalization.getLocalizedArticle(4359546),
                })}"
                target="_blank"
                rel="noopener noreferrer"
              >${this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.link-text')}</a>`
            ),
            htmlSafe: true,
          })
        );
      }
    }

    return errorMessages;
  }

  get spendLimitWarningTranslation() {
    let { spendLimitsWarning, spendLimits } = this.args;
    let canReadSpendLimitWarnings = this.isManager && spendLimitsWarning;

    if (!canReadSpendLimitWarnings) return null;

    if (spendLimitsWarning === 'insufficient_per_transfer_limits') {
      return this.intl.t('requests.transfers.sidebar.disclaimers.per-transfer-limit', {
        per_transfer_limit: this.intl.formatNumber(spendLimits.per_transfer_limit.value),
      });
    } else if (spendLimitsWarning === 'insufficient_monthly_limits') {
      return this.intl.t('requests.transfers.sidebar.disclaimers.monthly-transfer-limit', {
        balance_monthly_transfer_limit: this.intl.formatNumber(
          Number(spendLimits.monthly_transfer_limit.value) -
            Number(spendLimits.current_month_spendings.value)
        ),
      });
    }
  }

  get showTransferRequestSpendLimitWarning() {
    let { spendLimitsWarning, request } = this.args;

    return (
      spendLimitsWarning?.length > 0 &&
      this.abilities.cannot('view own request info disclaimer transfer-limit', request)
    );
  }

  get isApproveCTADisabled() {
    let {
      loadingRequest,
      request,
      confirmErrors,
      spendLimitsWarning,
      canShowInsufficientWarning,
      hasInsuficientFunds,
    } = this.args;
    let insufficientWarningCase = this.hasInsufficientFundWarning && canShowInsufficientWarning;

    return (
      this.kycNotApproved ||
      loadingRequest ||
      request.hasPastEndingDate ||
      insufficientWarningCase ||
      confirmErrors?.length > 0 ||
      spendLimitsWarning?.length > 0 ||
      hasInsuficientFunds
    );
  }

  get isDeclineCTADisabled() {
    return this.kycNotApproved;
  }

  get kycNotApproved() {
    let { kycStatus } = this.organizationManager.membership;

    return kycStatus !== KYC_STATUS.ACCEPTED;
  }

  get warnings() {
    let warningMessages = [];

    let { confirmWarnings = [], canShowAccountSelector } = this.args;

    if (canShowAccountSelector) {
      // filter out insufficient_fund errors, because warning shown on dropdown itself
      confirmWarnings = confirmWarnings.filter(
        ({ warningType }) => warningType !== 'insufficient_funds'
      );
    }

    if (
      !canShowAccountSelector &&
      confirmWarnings?.some(({ warningType }) => warningType === 'top_up_required')
    ) {
      confirmWarnings = confirmWarnings.filter(
        ({ warningType }) => warningType !== 'top_up_required'
      );

      let warningMessage = this.intl.t('requests.warnings.insufficient_funds');

      confirmWarnings.push({ warningMessage, warningType: 'topUpRequired' });
    }

    let insufficientFundsWarning = confirmWarnings.find(
      ({ warningType }) => warningType === 'insufficient_funds'
    );
    if (this.isManager && insufficientFundsWarning) {
      insufficientFundsWarning.warningMessage = this.intl.t(
        'requests.warnings.manager.insufficient_funds'
      );
    }

    return [...warningMessages, ...confirmWarnings];
  }

  get hasInsufficientFundWarning() {
    return this.args.confirmWarnings?.some(({ warningType }) =>
      ['top_up_required', 'insufficient_funds'].includes(warningType)
    );
  }

  get insufficientFundsMultiAccountError() {
    return this.isManager
      ? this.intl.t('transfers.warnings.manager.insufficient_funds_multi_account')
      : this.intl.t('transfers.warnings.insufficient_funds_multi_account');
  }

  get isManager() {
    return this.organizationManager.membership.role === ROLES.MANAGER;
  }

  get isRequester() {
    return this.args.request.initiator.get('id') === this.organizationManager.membership.id;
  }

  approveTask = dropTask(async () => {
    await this.args.approve.linked().perform(this.args.request);
    this.segment.track('request_approved', { request_type: 'expense_report' });
  });

  cancelRequestModalTask = dropTask(async () => {
    await this.modals.open('popup/destructive', {
      title: this.intl.t('requests.modals.cancel-modal.title'),
      description: this.intl.t('requests.modals.cancel-modal.description'),
      cancel: this.intl.t('btn.close'),
      confirm: this.intl.t('btn.cancel'),
      confirmTask: this.cancelRequestTask,
    });
  });

  @action
  declineRequest() {
    this.segment.track('request_declined_clicked', {
      request_type: 'expense_report',
      origin: 'reimbursements',
      method: 'sidepanel',
    });
    this.modals.open('request/sidebar/modals/decline-request', {
      request: this.args.request,
      confirmTask: this.declineTask,
    });
  }

  @action
  approveRequest() {
    this.sensitiveActions.runTask.perform(this.approveWithConditionTask).catch(ignoreCancelation);
  }

  declineTask = dropTask(async close => {
    await this.args.decline(this.args.request);
    close();
    this.segment.track('request_declined', {
      request_type: 'expense_report',
    });

    this.toastFlashMessages.toastInfo(
      this.intl.t('requests.reimbursement.toasts.request-rejected')
    );
    this.close();
  });

  approveRequestTask = dropTask(async request => {
    try {
      let message = !request.lastStep
        ? this.intl.t('approval-workflows.requests.approved.toast.success')
        : this.intl.t('requests.reimbursement.toasts.request-approved', { count: 1 });

      await this.approveTask.linked().perform();

      this.toastFlashMessages.toastSuccess(message);
      this.close();
    } catch (error) {
      if (error.errors.some(err => err.detail?.code === 'biller_amount_insufficient_funds')) {
        if (this.modals.count) {
          this.modals.top.close();
        }

        this.args.showAccountInsufficientFundsWarninge();

        return this.toastFlashMessages.toastInfo(this.insufficientFundsMessage);
      }

      if (hasMFAError(error?.errors)) {
        throw error;
      }
    }
  });

  approveWithConditionTask = dropTask(async () => {
    if (this.hasInsufficientFundWarning && this.args.canShowInsufficientWarning === false) {
      this.args.toggleCanShowInsufficientWarning(true);
      return;
    }

    await this.approveRequestTask.linked().perform(this.args.request);
  });

  cancelRequestTask = dropTask(async close => {
    try {
      await this.args.cancel.perform(this.args.request);
      this.segment.track('request_cancel_clicked', {
        request_type: 'expense_report',
        origin: 'reimbursements',
        method: 'sidepanel',
      });
      this.toastFlashMessages.toastInfo(this.intl.t('requests.cards.toasts.request-cancelled'));
      close();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  @action
  handleSetAccount(account) {
    this.args.toggleCanShowInsufficientWarning?.();
    this.args.setAccount(this.args.request, account);
  }
}
