export default {
  "attachment-viewer": "cyh",
  "hide-sidebar": "cyf",
  "sidebar": "cyK",
  "header": "cyG",
  "header-top": "cyr",
  "header-icon": "cyb",
  "header-amount": "cyy",
  "infobox": "cyU",
  "vat": "cyj",
  "validation": "cyW"
};
