/* import __COLOCATED_TEMPLATE__ from './cancel.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

export default class ReimbursementsModalsCancelComponent extends Component {
  @service toastFlashMessages;
  @service intl;
  @service errors;

  confirmTask = task({ drop: true }, async () => {
    let { request } = this.args.data;

    try {
      await request.cancelRequest();

      this.toastFlashMessages.toastInfo(this.intl.t('reimbursements.toast.cancel'));

      this.args.close('success');
    } catch (error) {
      if (error.errors?.[0]?.detail?.code === 'request_not_pending') {
        this.args.close();

        this.toastFlashMessages.toastError(
          this.intl.t('supplier-invoices.preview.request.info-toast.same-invoice')
        );

        // Now that we know that request status is outdated, we refresh it for the request and the invoice
        try {
          await Promise.all([this.args.data.request.reload(), this.args.data.invoice.reload()]);
        } catch (error) {
          this.errors.handleError(error);
        }
      } else {
        this.errors.handleError(error);
      }
    }
  });
}
