/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import urlFromRouteInfo from 'qonto/utils/url-from-route-info';

export default class OrgListItemLinkComponent extends Component {
  @service router;

  get isActive() {
    let { link } = this.args;

    if (!link || link.href) {
      return false;
    }

    let currentUrl = urlFromRouteInfo(this.router, this.router.currentRoute);
    return currentUrl.startsWith(`/organizations/${link.slug}/`);
  }
}
