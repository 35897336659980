export default {
  "account-container": "cOf",
  "badge": "cOK",
  "subtitle": "cOG title-4",
  "accounts-filter": "cOr",
  "account-filter-label": "cOb",
  "status": "cOy",
  "account-info": "cOU",
  "balance": "cOj",
  "revealed-balance": "cOW",
  "hidden-balance": "cOu"
};
