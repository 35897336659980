import { service } from '@ember/service';

import Component from '@qonto/qonto-sca/components/popup/security/mfa/confirmation';
import { MFA_METHODS } from '@qonto/qonto-sca/constants/mfa';

export default class PopupSecurityMfaConfirmationComponent extends Component {
  @service segment;

  constructor() {
    super(...arguments);
    if (
      this.mfaMethod === MFA_METHODS.SMS &&
      (this.actionType === 'email.update' || this.actionType === 'email.update.migration')
    ) {
      let phoneNumber = this.phoneNumber ?? this.userManager.currentUser?.phoneNumber;
      if (phoneNumber === this.userManager.currentUser?.phoneNumber) {
        this.segment.track('profile_email-update_otp_required');
      }
      if (this.phoneNumber) {
        this.segment.track('profile_email-update_migration-otp_required');
      }
    }
  }
}
