export default {
  "header-cell": "cmm",
  "method-col": "cmp",
  "expiration-col": "cmx",
  "status-col": "cmw",
  "amount-col": "cmN",
  "header-content": "cmv caption-bold",
  "empty": "cmo",
  "align-right": "cmn"
};
