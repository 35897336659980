export default {
  "y-axis-placeholder": "cTW",
  "y-axis-navigable": "cTu",
  "y-axis-tick": "czS",
  "x-axis-placeholder": "czc",
  "x-axis-navigable": "czq",
  "x-axis-tick-wrapper": "czZ",
  "x-axis-tick": "czR",
  "chart-loading-renderer": "czQ"
};
