/* import __COLOCATED_TEMPLATE__ from './update-password-modal.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

export default class UpdatePasswordModal extends Component {
  disclaimer = Disclaimer.Block;

  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service segment;

  handleConfirmTask = dropTask(async () => {
    try {
      await this.args.data.user.changePassword();

      let message = this.intl.t('toasts.update_password_successful');
      this.toastFlashMessages.toastSuccess(message);
      this.segment.track('change_password_completed');
      this.args.close();
    } catch (error) {
      if (hasMFAError(error)) {
        throw error;
      }
      if (!(error instanceof InvalidError)) {
        this.errors.handleError(error);
      }
    }
  });
}
