export default {
  "row": "qms body-1",
  "active": "qmF",
  "cell": "qmg",
  "empty": "qmD",
  "ellipsis": "qmV",
  "text-secondary": "qmL",
  "transfer-type": "qmT",
  "transfer-type__icon": "qmz",
  "quick-actions": "qma",
  "amount": "qmH",
  "cell-approve": "qmO",
  "cell-type": "qmm",
  "cell-requester": "qmp",
  "account-select": "qmx",
  "row-compact": "qmw",
  "row-sidebar": "qmN",
  "cell-amount": "qmv",
  "hidden": "qmo",
  "animated": "qmn",
  "fadein-item": "qmi",
  "animated-cell": "qmA"
};
