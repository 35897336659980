/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';
import { isNil } from 'es-toolkit';

export default class ProductsTableItemComponent extends Component {
  checkbox = Checkbox;

  get hasMissingDetails() {
    return isNil(this.args.item.unitPrice?.value);
  }
}
