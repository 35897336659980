export default {
  "notifications-settings": "cVT",
  "opened": "cVz",
  "icon": "cVa",
  "header": "cVH",
  "header-toggle": "cVO",
  "avatar-image": "cVm",
  "title": "cVp",
  "icon--right": "cVx",
  "toggle-container": "cVw",
  "toggle": "cVN"
};
