export default {
  "bullet": "caH",
  "slice-0": "caO",
  "slice-1": "cam",
  "slice-2": "cap",
  "slice-3": "cax",
  "slice-4": "caw",
  "slice-5": "caN",
  "slice-6": "cav",
  "slice-other": "cao",
  "slice-uncategorized": "can",
  "label": "cai",
  "ellipsis": "caA",
  "rest": "caY",
  "previous-date-range": "cah caption"
};
