/* import __COLOCATED_TEMPLATE__ from './paginated-table.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { DEFAULT_SORT_ORDER } from 'qonto/constants/sort';

export default class PaginatedTableComponent extends Component {
  placeholderRows = this.args.placeholderRows ?? 8;
  showNavLoading = this.args.showNavLoading ?? true;

  get shouldShowFooter() {
    let { total_count, per_page } = this.args?.navigation ?? {};

    return total_count > per_page || total_count > 25;
  }

  get sortField() {
    return this.args.sortBy?.split(':')[0];
  }

  get sortOrder() {
    let { sortBy } = this.args;

    return sortBy ? sortBy.split(':')[1] : DEFAULT_SORT_ORDER;
  }

  @action
  onScroll(isScrolledTop) {
    this.args.onScroll?.(isScrolledTop);
  }

  @action
  handleSortBy(name) {
    let sortOrder = DEFAULT_SORT_ORDER;

    if (name === this.sortField) {
      sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    return this.args.handleSortBy?.(`${name}:${sortOrder}`);
  }
}
