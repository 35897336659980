export default {
  "row": "cfY",
  "cell": "cfh",
  "greyed-out": "cff",
  "struck-through": "cfK",
  "flex-cell": "cfG",
  "overdue": "cfr",
  "align-right": "cfb",
  "customer-name-container": "cfy",
  "icon": "cfU",
  "warning": "cfj",
  "with-separator": "cfW",
  "align-center": "cfu"
};
