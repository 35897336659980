export default {
  "invoice-sidebar": "cAD",
  "body": "cAV",
  "box": "cAL",
  "box-header": "cAT",
  "footer": "cAz",
  "footer-button": "cAa",
  "footer-button-tooltip": "cAH",
  "sidebar-box": "cAO",
  "border-top": "cAm",
  "row": "cAp",
  "status-container": "cAx",
  "due-date-container": "cAw",
  "greyed-out": "cAN",
  "struck-through": "cAv",
  "overdue": "cAo",
  "primary-actions": "cAn cAO",
  "primary-action": "cAi",
  "danger-action": "cAA",
  "button-icon": "cAY",
  "button-label": "cAh body-2",
  "related-credit-note-title": "cAf caption-bold",
  "paid-at-placeholder": "cAK",
  "collapsible-timeline": "cAG",
  "timeline-status-label": "cAr"
};
