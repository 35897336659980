/* import __COLOCATED_TEMPLATE__ from './multi-transfer-detail.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { SPEND_LIMITS_WARNINGS } from 'qonto/utils/extract-confirmation-response';

export default class RequestHeaderMultiTransferDetailComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service abilities;

  get showInfoDisclaimer() {
    let { request, warnings } = this.args;
    if (
      !warnings ||
      this.abilities.cannot('view own request info disclaimer transfer-limit', request)
    ) {
      return false;
    }

    return SPEND_LIMITS_WARNINGS.some(warning => warnings.includes(warning));
  }
}
