/* import __COLOCATED_TEMPLATE__ from './vat.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { AmountField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import vatAmount from 'qonto/utils/vat-amount';

export default class RequestSidebarExpenseReportDetailsVatComponent extends Component {
  @service intl;
  @service organizationManager;

  amountField = AmountField;

  get selectedVat() {
    return {
      clearable: Boolean(this.args.request.vatRate),
      value: this.args.request.vatRate,
    };
  }

  @reads('organizationManager.organization.vatRatesCategories') vatRatesCategories;

  get optionsVatRates() {
    return this.filteredVatRatesCategories.map(vatRate => {
      let label = '';
      if (vatRate > 0) {
        label = `${this.intl.formatNumber(vatRate)}%`;
      } else if (vatRate === null) {
        label = this.intl.t('transactions.sidebar.vat.accounting.none');
      } else {
        label =
          vatRate === 0
            ? this.intl.t('transactions.sidebar.vat.accounting.no-rate')
            : this.intl.t('transactions.sidebar.vat.accounting.other');
      }
      return {
        clearable: this.args.hideCustomInputs ? false : vatRate !== 0,
        label,
        value: vatRate,
      };
    });
  }

  get filteredVatRatesCategories() {
    if (this.args.hideCustomInputs) {
      return [
        null,
        ...this.vatRatesCategories.filter(vatRate => {
          return vatRate !== -1;
        }),
      ];
    }

    return this.vatRatesCategories;
  }

  @action
  handleChangeVat({ value }) {
    let { amount } = this.args.request;
    let newVatAmount = vatAmount(amount.value, value);

    this.args.onSelect?.(this.args.request, {
      vatRate: value,
      vatAmount: newVatAmount ? { value: newVatAmount, currency: 'EUR' } : newVatAmount,
    });
  }
}
