export default {
  "container": "cij",
  "text-content": "ciW",
  "text-wrapper": "ciu",
  "actions-container": "cAS",
  "illustration": "cAc",
  "feature-tour-link": "cAq",
  "link-illustration": "cAZ",
  "lottie": "cAR"
};
