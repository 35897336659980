export default {
  "card-details-container": "qSj",
  "title": "qSW caption-bold mb-16",
  "section": "qSu",
  "divider": "qcS",
  "top-divider": "qcc",
  "content": "qcq body-2",
  "description": "qcZ",
  "justify-end": "qcR",
  "icon": "qcQ ml-8"
};
