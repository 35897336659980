export default {
  "savings-account": "qEz",
  "closed": "qEa",
  "badge": "qEH",
  "subtitle": "qEO",
  "amount": "qEm",
  "processing": "qEp",
  "gain": "qEx",
  "active": "qEw",
  "progress": "qEN",
  "progress-bar": "qEv",
  "progress-text": "qEo",
  "info": "qEn",
  "menu-item": "qEi body-2",
  "close-account": "qEA"
};
