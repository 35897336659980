/* import __COLOCATED_TEMPLATE__ from './choose-transfer-type.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { Badge, BadgeHighlight } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { PAY_LATER_ELIGIBILITY_STATUSES } from 'qonto/constants/financing';
import {
  ELIGIBILITY_STATUS,
  INELIGIBILITY_REASON,
} from 'qonto/constants/international-out/eligibility';
import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class ChooseTransferType extends Component {
  badge = Badge;
  badgeHighlight = BadgeHighlight;

  @service intl;
  @service router;
  @service organizationManager;
  @service subscriptionManager;
  @service segment;
  @service modals;
  @service abilities;
  @service flowLinkManager;
  @service zendeskLocalization;

  get isInternalTransfersEnabled() {
    let canCreateInternalTransfer = this.abilities.can('create internal transfer');
    let { hasMultipleActiveCurrentRemuneratedAccounts } = this.organizationManager.organization;

    return canCreateInternalTransfer && hasMultipleActiveCurrentRemuneratedAccounts;
  }

  get isEligibleToInternationalOutTransfer() {
    return this.args.eligibilities?.internationalOut?.status === ELIGIBILITY_STATUS.ELIGIBLE;
  }

  get isEligibleToPromotionalInternationalOutTransfer() {
    return (
      this.args.eligibilities?.internationalOutPromotion?.status === ELIGIBILITY_STATUS.ELIGIBLE
    );
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('multi_transfers');
  }

  get isPayLaterDisclaimerVisible() {
    return (
      this.abilities.can('create pay later transfer in financing') &&
      this.args.eligibilities?.payLater?.eligibility === PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE
    );
  }

  @action onTrialClick() {
    this.segment.track('upsell_bulk-transfer_clicked', {
      trial_state: TRACKING_TRIAL_STATE.FREE_TRIAL,
    });
    this.modals.open('discover/trial/confirm', {
      isFullScreenModal: true,
      trialInfo: this.trialInfo,
    });
  }

  @action
  handleClose() {
    this.router.transitionTo('transfers.index');
  }

  @action
  handleGoExternal(withInvoice = false) {
    if (withInvoice) {
      this.segment.track('transfer-landing_page-billpay_clicked');
    } else {
      this.segment.track('transfer-landing_page_sepa_creation_clicked');
    }

    this.flowLinkManager.transitionTo({
      name: 'sepa-transfer',
      stepId: withInvoice ? 'invoice-upload' : 'beneficiaries',
    });
  }

  @action
  handleGoInternal() {
    this.router.transitionTo('transfers.internal.new');
  }

  @action
  handleGoBulk() {
    this.segment.track('transfer_bulk_create_page_loaded');

    this.router.transitionTo('transfers.multi.new', {
      queryParams: { currency: null },
    });
  }

  handleGoInternationalOutTask = dropTask(async () => {
    if (this.abilities.can('create transfer') && this.isEligibleToInternationalOutTransfer) {
      this.flowLinkManager.transitionTo({
        name: 'international-out',
        stepId: 'quote',
        ...(this.isEligibleToPromotionalInternationalOutTransfer && {
          queryParams: { voucher: true },
        }),
      });
    } else {
      await this.modals.open(
        'transfers/international-out/modals/non-eligible',
        this.#getInternationalOutModalContent()
      );
    }
  });

  #getInternationalOutModalContent() {
    if (
      this.args.eligibilities?.internationalOut?.reason === INELIGIBILITY_REASON.KYB_NOT_APPROVED
    ) {
      return {
        title: this.intl.t('transfers.landing.international-modal.kyb-pending.title'),
        subtitle: this.intl.t('transfers.landing.international-modal.kyb-pending.subtitle.text', {
          link: htmlSafe(
            `<a href=${this.intl.t(
              'transfers.landing.international-modal.kyb-pending.subtitle.link',
              {
                faqUrl: this.zendeskLocalization.getLocalizedArticle(4359565),
              }
            )} target="_blank" rel="noopener noreferrer">  ${this.intl.t(
              'transfers.landing.international-modal.kyb-pending.subtitle.link-text'
            )}</a>`
          ),
          htmlSafe: true,
        }),
      };
    }

    if (
      this.args.eligibilities?.internationalOut?.reason ===
      INELIGIBILITY_REASON.FLEX_KYB_NOT_APPROVED
    ) {
      let organization = this.organizationManager.organization;

      if (organization.isDeFreelancer) {
        return {
          title: this.intl.t('transfers.landing.international-modal.flex-kyb.freelancers-DE.title'),
          subtitle: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.freelancers-DE.subtitle'
          ),
        };
      }

      if (organization.isDeCompanyCreation) {
        return {
          title: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-DE.title'
          ),
          subtitle: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-DE.subtitle.text',
            {
              link: htmlSafe(
                `<a href=${this.intl.t(
                  'transfers.landing.international-modal.flex-kyb.unregistered-DE.subtitle.link'
                )} target="_blank" rel="noopener noreferrer">  ${this.intl.t(
                  'transfers.landing.international-modal.flex-kyb.unregistered-DE.subtitle.link-text'
                )}</a>`
              ),
              htmlSafe: true,
            }
          ),
        };
      }

      if (organization.legalCountry === 'IT') {
        return {
          title: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-IT.title'
          ),
          subtitle: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-IT.subtitle.text',
            {
              link: htmlSafe(
                `<a href=${this.intl.t(
                  'transfers.landing.international-modal.flex-kyb.unregistered-IT.subtitle.link'
                )} target="_blank" rel="noopener noreferrer">  ${this.intl.t(
                  'transfers.landing.international-modal.flex-kyb.unregistered-IT.subtitle.link-text'
                )}</a>`
              ),
              htmlSafe: true,
            }
          ),
        };
      }

      if (organization.legalCountry === 'ES') {
        return {
          title: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-ES.title'
          ),
          subtitle: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-ES.subtitle.text',
            { htmlSafe: true }
          ),
        };
      }
    }

    return {
      title: this.intl.t('transfers.landing.international-modal.generic.title'),
      subtitle: this.intl.t('transfers.landing.international-modal.generic.subtitle.text', {
        link: htmlSafe(
          `<a href=${this.intl.t(
            'transfers.landing.international-modal.generic.subtitle.FAQ-link.url',
            {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(4359565),
            }
          )} target="_blank" rel="noopener noreferrer">  ${this.intl.t(
            'transfers.landing.international-modal.generic.subtitle.FAQ-link.text'
          )}</a>`
        ),
        htmlSafe: true,
      }),
    };
  }
}
