export default {
  "container": "qwY",
  "container-column": "qwh",
  "content-wrapper": "qwf",
  "media-wrapper": "qwK",
  "content": "qwG",
  "title": "qwr",
  "subtitle": "qwb",
  "content-slot": "qwy",
  "sticky-panel": "qwU",
  "sticky-actions": "qwj"
};
