import { registerDestructor } from '@ember/destroyable';
import { service } from '@ember/service';
import Component from '@glimmer/component';

/**
 * When this component is rendered, it is guarding from unintended navigation events
 * like page refresh or redirection by showing a confirmation prompt.
 *
 * ? You can pass following arguments:
 * - `allowRouterTransitions` - will not guard against ember transitions
 * - `allowBrowserUnload` - will not guard against browser refresh/close
 *
 * https://gitlab.qonto.co/tech/divein/-/blob/master/text/2021/2021-10-19-FRONTEND-navigation-guard.md
 */
export default class NavigationGuardComponent extends Component {
  @service('navigation-guard') navigationGuardService;

  constructor(...args) {
    super(...args);

    let guardFn = this.args.guardFn ?? (() => true);

    this.navigationGuardService.useNativeModal(this.args.useNativeModal ?? true);
    this.navigationGuardService.addGuard(guardFn, {
      allowRouterTransitions: this.args.allowRouterTransitions,
      allowBrowserUnload: this.args.allowBrowserUnload,
    });

    registerDestructor(this, () => this.navigationGuardService.removeGuard(guardFn));
  }
}
