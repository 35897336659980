export default {
  "referral-invite": "cKf",
  "referral": "cKK",
  "illustration": "cKG",
  "steps": "cKr",
  "step": "cKb",
  "share-referral": "cKy",
  "choice": "cKU",
  "line": "cKj",
  "email": "cKW",
  "email-input": "cKu",
  "input-field--error": "cGS",
  "error-message": "cGc",
  "external-link-icon": "cGq"
};
