/* import __COLOCATED_TEMPLATE__ from './payment-modal.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { PaymentMethodSelector } from '@repo/domain-kit/invoices';

import { TrackRender } from 'qonto/react/components/track-render';

export default class ReceivableInvoicesPaymentModalComponent extends Component {
  PaymentMethodSelector = PaymentMethodSelector;
  DisclaimerBlock = Disclaimer.Block;
  TrackRender = TrackRender;

  get enabledPaymentMethods() {
    return this.args.paymentMethods.filter(({ enabled }) => enabled);
  }

  get someMethodsDisabled() {
    return this.enabledPaymentMethods.length < this.args.paymentMethods.length;
  }
}
