/* import __COLOCATED_TEMPLATE__ from './beneficiary.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { State } from 'qonto/react/components/transfers/international-out/state';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import maybeT from 'qonto/utils/maybe-t';

export const DEFAULT_LOADING_OPTIONS = {
  count: 10,
  widths: {
    value: { md: 70 },
    label: { sm: 110, md: 170 },
  },
};

export default class TransfersInternationalOutModalsBeneficiaryComponent extends Component {
  state = State;

  @service internationalOutManager;
  @service intl;
  @service localeManager;

  @tracked beneficiaryDetails = [];
  @tracked isError = false;

  placeholders = Array.from({ length: DEFAULT_LOADING_OPTIONS.count }, (_, index) => {
    let { label, value } = DEFAULT_LOADING_OPTIONS.widths;
    return {
      labelWidthInPx: index % 2 === 1 ? label.sm : label.md,
      valueWidthInPx: value.md,
    };
  });

  constructor() {
    super(...arguments);
    this.loadBeneficiaryDetailsTask.perform().catch(ignoreCancelation);
  }

  loadBeneficiaryDetailsTask = dropTask(async () => {
    this.isError = false;

    try {
      this.beneficiaryDetails = (
        await this.internationalOutManager.getBeneficiaryDetails(this.args.data.beneficiary.id)
      ).map(this.#serializeBeneficiaryDetail);
    } catch {
      this.isError = true;
    }
  });

  #serializeBeneficiaryDetail = ({ key, title, value }) => {
    return {
      label: maybeT(
        {
          key: `international-out.dynamic-form.label.${key}`,
          fallback: title,
        },
        this.intl,
        this.localeManager.locale
      ),
      value,
    };
  };
}
