/* import __COLOCATED_TEMPLATE__ from './nationalities.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import {
  addEmptyNationality,
  removeNationalityFromArray,
  updateNationalityInArray,
} from 'qonto/utils/update-nationalities';

/**
 * ! This component duplicates the component in the `qonto-register-js` application.
 * ! Please make sure to keep them in sync. You can see the `qonto-register-js` component here:
 * ! https://gitlab.qonto.co/front/qonto-web/-/blob/master/apps/qonto-register-js/app/components/select/nationalities.js
 */
export default class SelectNationalitiesComponent extends Component {
  @tracked optionalNationalities = this.args.initialOptionalNationalities || [];

  get canAddOptionalNationality() {
    return this.optionalNationalities.length < 3;
  }

  @action
  addOptionalNationality() {
    if (this.canAddOptionalNationality) {
      this.optionalNationalities = addEmptyNationality(this.optionalNationalities);
      this.args.onUpdateOptionalNationalities(this.optionalNationalities);
    }
  }

  @action
  updateOptionalNationality(index, countryCode) {
    this.optionalNationalities = updateNationalityInArray(
      this.optionalNationalities,
      index,
      countryCode
    );
    this.args.onUpdateOptionalNationalities(this.optionalNationalities);
  }

  @action
  removeOptionalNationality(index) {
    this.optionalNationalities = removeNationalityFromArray(this.optionalNationalities, index);
    this.args.onUpdateOptionalNationalities(this.optionalNationalities);
  }
}
