/* import __COLOCATED_TEMPLATE__ from './additional-items.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer, TextAreaField } from '@repo/design-system-kit';

export default class RequestTransferAdditionalItems extends Component {
  disclaimerBlock = Disclaimer.Block;
  textAreaField = TextAreaField;

  @service attachmentsManager;
  @service modals;

  get uploaderErrors() {
    let { enableTransferValidations, requestTransfer } = this.args;
    if (enableTransferValidations) {
      if (requestTransfer.attachmentsFiles.length) {
        return this.attachmentsManager.fileErrors;
      }
      return requestTransfer.validations.attrs.attachments.errors;
    }

    return false;
  }

  @action addAttachment(file) {
    this.attachmentsManager.addAttachment(false, this.args.requestTransfer, file);
  }

  @action removeAttachment(file) {
    this.attachmentsManager.removeAttachment(false, this.args.requestTransfer, file);
  }

  @action cancelUploadAttachment(file) {
    this.attachmentsManager.cancelUploadAttachment(this.args.requestTransfer, file);
  }

  @action showAttachment(selectedFile) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile,
      model: this.args.requestTransfer,
      download: this.attachmentsManager.downloadAttachment,
    });
  }
}
