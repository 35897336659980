/* import __COLOCATED_TEMPLATE__ from './pending-layout.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class RequestSidebarTransferPendingLayoutComponent extends Component {
  @service abilities;

  get warning() {
    let canValidate = this.abilities.can('review transfer request');
    let { beneficiaryWarning, request } = this.args;

    if (canValidate && request.hasRequestMultiTransfer && request.newBeneficiary) {
      return 'new_beneficiary';
    }

    return beneficiaryWarning;
  }

  get showValidations() {
    let { request } = this.args;
    let userId = request.get('initiator.userId');
    return (
      !request.hasRequestMultiTransfer &&
      (this.abilities.can('cancel transfer requests', userId) ||
        this.abilities.can('review transfer requests'))
    );
  }
}
