/* import __COLOCATED_TEMPLATE__ from './cashflow.hbs'; */
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask, restartableTask } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { reads } from 'macro-decorators';

import { PERIOD_KEYS } from 'qonto/constants/overview';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { CashflowTimevalue, utcToZonedDate } from 'qonto/utils/chart';
import { isPeriodWithinInterval } from 'qonto/utils/date';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class CashflowComponent extends Component {
  @service intl;
  @service organizationManager;
  @service segment;
  @service abilities;
  @service toastFlashMessages;
  @service sentry;
  @service userManager;

  @tracked _selectedPeriod;
  @reads('userManager.currentUser.timezone') timezone;
  @reads('organizationManager.membership.id') membershipId;
  didComponentMount = false;

  periods = [
    {
      label: this.intl.t('overview.period-selection.last-twelve-months'),
      key: 'last_12_months',
      interval: 'last_12_months',
    },
    {
      label: this.intl.t('overview.period-selection.year-to-date'),
      key: 'year_to_date',
      interval: 'current_year',
    },
  ];

  currentMonthPeriod = { startDate: dayjs().startOf('month').toDate(), endDate: new Date() };

  constructor() {
    super(...arguments);
    this.storageKey = `cashflow-period-${this.membershipId}`;

    this._fetchPersistedPeriod();
    schedule('actions', () => {
      this.refreshWidgetTask.perform().catch(ignoreCancelation);
    });
  }

  get aggregations() {
    return [
      {
        name: 'display_at_monthly',
        type: 'time',
        data: {
          property: 'display_at',
          interval: 'monthly',
        },
      },
    ];
  }

  get bankAccounts() {
    return this.args.bankAccounts ? [this.args.bankAccounts] : undefined;
  }

  get selectedPeriod() {
    return this._selectedPeriod || this.periods[0];
  }

  get isCurrentMonthDisplayed() {
    let isPresetPeriod = this.periods
      .map(period => period.interval)
      .includes(this.selectedPeriod.interval);
    let isWithinCustomPeriod =
      this.selectedPeriod.key === PERIOD_KEYS.CUSTOM_PERIOD &&
      isPeriodWithinInterval(this.currentMonthPeriod, {
        start: this.selectedPeriod.startDate,
        end: this.selectedPeriod.endDate,
      });
    return isPresetPeriod || isWithinCustomPeriod;
  }

  get statistics() {
    let { name } = this.aggregations[0];
    let base = this.args.statistics;
    let comparison = this.args.comparisonStatistics;
    let isEmpty = statistics =>
      !statistics || statistics.every(statistics => !Object.keys(statistics).length);

    return {
      base: isEmpty(base) ? undefined : base?.map(({ data }) => data && data[name]),
      comparison: isEmpty(comparison)
        ? undefined
        : comparison?.map(({ data }) => data && data[name]),
    };
  }

  get timeseries() {
    if (!(this.args.isLoading || this.args.isUpdating) && this.statistics.base) {
      let [credits, debits, balances] = this.statistics.base;
      let comparisonCredit, comparisonDebit;

      if (this.statistics.comparison) {
        let [[credit], [debit]] = this.statistics.comparison;
        comparisonCredit = credit;
        comparisonDebit = debit;
      }

      return this._formatTimeseries(
        credits,
        debits,
        balances,
        comparisonCredit,
        comparisonDebit,
        this.timezone
      );
    }
  }

  get originDate() {
    return this.organizationManager.organization.createdAt;
  }

  updatePeriodTask = dropTask(async (period, closeDropdown) => {
    closeDropdown?.();
    if (period) {
      this._selectedPeriod = this._formatPeriod(period);
      this.persistPeriod(period);
      await this.refreshWidgetTask.perform(period).catch(ignoreCancelation);
      this.segment.track('dashboard_widget_update', {
        widget_type: 'combo_chart',
        period_set_to: period.key,
      });
    }
  });

  updateBankAccountsTask = dropTask(async () => {
    await this.refreshWidgetTask.perform().catch(ignoreCancelation);
  });

  refreshWidgetTask = dropTask(async (period = this.selectedPeriod) => {
    let comparisonPeriod = this.isCurrentMonthDisplayed
      ? { interval: 'comparison_for_current_month' }
      : undefined;

    if (!(this.args.isLoading || this.args.isUpdating)) {
      try {
        await this.args.onUpdate(this.aggregations, period, this.bankAccounts, comparisonPeriod);
      } catch (error) {
        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));

        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 401) {
          this.sentry.captureException(error);
        }
      }
    }
  });

  _formatTimeseries(credits, debits, balances, lastMonthCredit, lastMonthDebit, timezone) {
    if (credits && debits && balances) {
      let startTime = utcToZonedDate(balances[0].start_date);
      let baseArray = Array.from({ length: 12 }).map((_, index) => ({
        time: dayjs(startTime).add(index, 'month').toDate(),
      }));

      return baseArray.map(({ time }, index) => {
        let balance = balances[index] || this._getLastAvailableBalance(balances, time);
        let inflows = credits[index];
        let outflows = debits[index];
        let isCurrentMonthBalance =
          balance.start_date === this.currentMonthPeriod.startDate.getTime();

        let previousInflows = isCurrentMonthBalance ? lastMonthCredit : credits[index - 1];
        let previousOutflows = isCurrentMonthBalance ? lastMonthDebit : debits[index - 1];

        return new CashflowTimevalue(
          inflows,
          outflows,
          { ...balance, start_date: time },
          previousInflows,
          previousOutflows,
          timezone
        );
      });
    }
  }

  _getLastAvailableBalance(balances, time) {
    return {
      ...balances.at(-1),
      start_date: time.getTime(),
      end_date: dayjs(time).endOf('month').valueOf(),
    };
  }

  persistPeriod(period) {
    safeLocalStorage.setItem(this.storageKey, JSON.stringify(period));
  }

  _fetchPersistedPeriod() {
    let persistedPeriod = JSON.parse(safeLocalStorage.getItem(this.storageKey));
    let shouldResetPeriod =
      this.abilities.cannot('access advanced overview') &&
      persistedPeriod?.period?.key === PERIOD_KEYS.CUSTOM_PERIOD;

    if (shouldResetPeriod) {
      this._selectedPeriod = this.periods[0];
      this.persistPeriod(this.selectedPeriod);
    } else {
      this._selectedPeriod = this._formatPeriod(persistedPeriod);
    }
  }

  _formatPeriod(period) {
    if (period) {
      let matchingPeriod = this.periods.find(localPeriod => localPeriod.key === period.key);
      period.label =
        matchingPeriod?.label || this.intl.t('overview.period-selection.custom-period');
      period.startDate = period.startDate ? new Date(period.startDate) : undefined;
      period.endDate = period.endDate ? new Date(period.endDate) : undefined;
      return period;
    }
  }

  triggerRerenderTask = restartableTask(async () => {
    /**
     * Here, we need to check if the component is mounted or not.
     * We need to trigger the task only when the component props are updated, not when we mount
     * the component for the first time (or when it reload)
     */
    if (!this.didComponentMount) {
      this.didComponentMount = true;
    } else {
      this.didComponentMount = false;
      await this.updateBankAccountsTask.perform();
    }
    return this.args.bankAccounts;
  });

  lastBankAccount = trackedTask(this, this.triggerRerenderTask, () => [this.args.bankAccounts]);
}
