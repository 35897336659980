/* import __COLOCATED_TEMPLATE__ from './matched-transactions.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { dropTask, timeout } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { MATCHED_TRANSACTIONS_DELAY } from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
export default class MatchedTransactionsComponent extends Component {
  @service flowLinkManager;
  @service store;
  @service segment;
  @service toastFlashMessages;
  @service intl;
  @service sentry;
  @service errors;

  get filterGroup() {
    return {
      conditional: 'and',
      expressions: [
        {
          property: 'attachment_ids',
          values: [this.args.invoice?.belongsTo('attachment').id()],
          operator: 'in',
        },
      ],
    };
  }

  @action openMarkAsPaidFlow() {
    /**
     * @todo This logic should be moved to the flow setup once it is possible to abort the transition
     * @see https://www.notion.so/qonto/Allow-flows-to-abort-a-transition-into-the-flows-route-b657347ce54a49d686e410ede0920132
     */
    let hasAttachment = Boolean(this.args.invoice?.belongsTo('attachment').id());
    if (!hasAttachment) {
      this.sentry.captureMessage(`Invoice ${this.args.invoice.id} is missing an attachment.`);
      return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }

    this.segment.track('receivable_invoice_add_transaction', { origin: 'invoice_modal' });
    this.flowLinkManager.transitionTo({
      name: 'match-invoice',
      stepId: 'select-transaction',
      queryParams: { trigger: 'receivableInvoices', invoiceId: this.args.invoice.id },
    });
  }

  onUnlinkTask = dropTask(async refreshTransactionsTask => {
    let currentlyLinkedTransactions = refreshTransactionsTask.last.value;
    try {
      this.segment.track('receivable_invoice_unlink_transaction', { origin: 'invoice_modal' });
      if (currentlyLinkedTransactions?.length === 1) {
        this.args.onUnlinkLastTransactionTask?.perform();
      }
      await timeout(MATCHED_TRANSACTIONS_DELAY);
      await refreshTransactionsTask.perform();
      let refreshedLinkedTransactions = refreshTransactionsTask.last.value;

      if (refreshedLinkedTransactions?.length > 0) {
        this._updatePaymentDate(refreshedLinkedTransactions);
        this.toastFlashMessages.toastSuccess(
          this.intl.t('receivable-invoices.invoice-modal.success.unlinked-paid-transaction')
        );
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });

  #updatePaymentDate(transactions) {
    if (transactions.length === 0) return;

    let paymentDates = transactions.map(({ emittedAt }) => dayjs(emittedAt));
    this.args.invoice.paidAt = dayjs.max(paymentDates).format(DATE_PICKER_FIELD_FORMAT);
  }

  _updatePaymentDate(transactions) {
    if (transactions.length === 0) return;

    let paymentDates = transactions.map(({ emittedAt }) => emittedAt);
    this.args.invoice.paidAt = dayjs(dayjs.max(paymentDates)).format(DATE_PICKER_FIELD_FORMAT);
  }
}
