/* import __COLOCATED_TEMPLATE__ from './due-date-selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { differenceInCalendar } from 'qonto/utils/date';

export const COMMON_CREDIT_PERIODS = [0, 15, 30];

export const COMMON_QUOTE_EXPIRY_PERIODS = [15, 30, 90];

const CREDIT_PERIODS_TRACKING_EVENTS = {
  [-1]: { period: 'custom' },
  0: { period: 'today' },
  15: { period: 'in15days' },
  30: { period: 'in30days' },
};

export default class ReceivableInvoicesFormDueDateSelectorComponent extends Component {
  @service intl;
  @service segment;
  @tracked selectedOption = this.defaultSelectedOption();

  get date() {
    let { invoice } = this.args;
    let date = this.args.isQuote ? invoice.expiryDate : invoice.dueDate;

    return date;
  }

  get minDate() {
    let { invoice } = this.args;
    return invoice.issueDate;
  }

  get dateSelectorOptions() {
    let options = this.args.isQuote ? COMMON_QUOTE_EXPIRY_PERIODS : COMMON_CREDIT_PERIODS;
    return [...options, -1].map(value => {
      let label = '';
      let clearable = false;
      if (value > 0) {
        label = this.intl.t(
          'receivable-invoices.invoice-creation.invoice-details.due-date.options.relative',
          { number: value }
        );
      } else if (value === 0) {
        label = this.intl.t(
          'receivable-invoices.invoice-creation.invoice-details.due-date.options.on-receipt',
          { number: value }
        );
      } else {
        label = this.intl.t(
          'receivable-invoices.invoice-creation.invoice-details.due-date.options.custom'
        );
        clearable = true;
      }

      return {
        clearable,
        label,
        value,
      };
    });
  }

  defaultSelectedOption() {
    return this.args.isQuote
      ? this._defaultSelectedExpiryPeriod()
      : this._defaultSelectedCreditPeriod();
  }

  _defaultSelectedExpiryPeriod() {
    if (!this.args.invoice.expiryDate) return undefined;

    let currentExpiryPeriod = differenceInCalendar(
      this.args.invoice.expiryDate,
      this.args.invoice.issueDate,
      'day'
    );

    let selectedPeriod = { clearable: true, value: -1 };
    if (COMMON_QUOTE_EXPIRY_PERIODS.includes(currentExpiryPeriod)) {
      selectedPeriod = {
        clearable: false,
        value: currentExpiryPeriod,
      };
    }

    this.args.onPeriodChange?.(selectedPeriod.value);
    return selectedPeriod;
  }

  _defaultSelectedCreditPeriod() {
    if (!this.args.invoice.dueDate) return undefined;

    let currentCreditPeriod = differenceInCalendar(
      this.args.invoice.dueDate,
      this.args.invoice.issueDate,
      'day'
    );

    let selectedPeriod = { clearable: true, value: -1 };
    if (COMMON_CREDIT_PERIODS.includes(currentCreditPeriod)) {
      selectedPeriod = {
        clearable: false,
        value: currentCreditPeriod,
      };
    }

    this.args.onPeriodChange?.(selectedPeriod.value);
    return selectedPeriod;
  }

  @action onDateChange(date) {
    if (this.args.isQuote) {
      this.args.invoice.expiryDate = date;
    } else {
      this.args.invoice.dueDate = date;
    }
  }

  @action updateExpiryOrDueDateOption(option) {
    this.selectedOption = option;
    this.args.onPeriodChange?.(option.value);

    return this.args.isQuote
      ? this.updateExpiryDateOption(option)
      : this.updateDueDateOption(option);
  }

  updateExpiryDateOption(option) {
    if (option.value >= 0) {
      this.args.invoice.expiryDate = dayjs(this.args.invoice.issueDate)
        .add(option.value, 'day')
        .format(DATE_PICKER_FIELD_FORMAT);
      return;
    }

    this.args.invoice.expiryDate = undefined;
  }

  updateDueDateOption(option) {
    if (option.value !== undefined) {
      this.segment.track('invoice_due_date-change', CREDIT_PERIODS_TRACKING_EVENTS[option.value]);
    }

    if (option.value >= 0) {
      this.args.invoice.dueDate = dayjs(this.args.invoice.issueDate)
        .add(option.value, 'day')
        .format(DATE_PICKER_FIELD_FORMAT);
      return;
    }

    this.args.invoice.dueDate = undefined;
  }
}
