/* import __COLOCATED_TEMPLATE__ from './matched-transaction.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';
import { dropTask, timeout } from 'ember-concurrency';

import { TRANSACTION_UPDATE_DELAY_MS } from 'qonto/constants/supplier-invoice';

export default class TransactionsInvoicesMatchedTransactionComponent extends Component {
  @service intl;
  @service toastFlashMessages;

  spinner = Spinner;

  unlinkTransactionTask = dropTask(async () => {
    try {
      let attachment = await this.args.attachment;
      await this.args.transaction.unlinkAttachment([attachment]);
      await timeout(TRANSACTION_UPDATE_DELAY_MS);
      this.args.onUnlink();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}
