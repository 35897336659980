/* import __COLOCATED_TEMPLATE__ from './x-phone-input.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { PREFERRED_COUNTRIES } from 'qonto/constants/countries';

export default class XPhoneInputComponent extends Component {
  @tracked isValidNumber = false;

  @service organizationManager;

  initialCountry =
    this.args.initialCountry ||
    this.organizationManager.organization?.legalCountry ||
    PREFERRED_COUNTRIES[0];
  preferredCountries = PREFERRED_COUNTRIES;

  get autovalidate() {
    return this.args.autovalidate ?? true;
  }

  get disabled() {
    return this.args.disabled ?? false;
  }

  get required() {
    return this.args.required ?? true;
  }

  @action
  handleKeyPress(e) {
    // prevent letters and `+` to be input
    // but allow `ctrl+a`
    let character = e.key;

    let isLetterOrPlus = character && character.match(/[a-z+]/i);
    let isCtrlPressed = e.ctrlKey;
    let isMetaPressed = e.metaKey;
    let isBackSpace = character === 'Backspace';
    let isTab = character === 'Tab';
    let isEnter = character === 'Enter';

    if (isLetterOrPlus && !isCtrlPressed && !isMetaPressed && !isBackSpace && !isTab && !isEnter) {
      e.preventDefault();
      return false;
    }
  }

  @action
  handleUpdate(number, { isValidNumber }) {
    this.isValidNumber = isValidNumber;
    return this.args.update?.(number, { isValidNumber });
  }
}
