import { service } from '@ember/service';

import Component from '@qonto/qonto-sca/components/popup/security/sca/waiting';

export default class ScaAuthModalWaitingComponent extends Component {
  @service segment;

  constructor() {
    super(...arguments);
    let { actionType } = this.args;
    if (actionType === 'email.update') {
      this.segment.track('profile_email-update_sca_required');
    } else if (actionType === 'email.update.migration') {
      this.segment.track('profile_email-update_migration-sca_required');
    }
  }
}
