/* import __COLOCATED_TEMPLATE__ from './invoiced-amount.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { ProgressBar } from '@repo/design-system-kit';

import { INVOICED_STATUS } from 'qonto/constants/receivable-invoice';

export default class InvoicedAmountComponent extends Component {
  progressBar = ProgressBar;

  @service intl;

  get invoicingDocument() {
    return this.args.invoicingDocument;
  }

  get currency() {
    return this.invoicingDocument.currency || 'EUR';
  }

  get invoicedAmount() {
    return Number(this.invoicingDocument?.invoicedAmount) || 0;
  }

  get invoicedAmountText() {
    return this.intl.formatMoney(this.invoicingDocument?.invoicedAmount || 0, {
      currency: this.currency,
    });
  }

  get amountDueText() {
    return this.intl.formatMoney(this.invoicingDocument.amountDue, {
      currency: this.currency,
    });
  }

  // progress shown in the progress bar
  get invoicedAmountProgress() {
    let invoicedAmountProgress = 0;
    if (this.isInvoicedAmountFull) {
      invoicedAmountProgress = Number(this.invoicingDocument.amountDue);
    } else if (this.invoicedAmount > 0) {
      invoicedAmountProgress = this.invoicedAmount;
    }
    return invoicedAmountProgress;
  }

  get isInvoicedAmountFull() {
    return [INVOICED_STATUS.FULLY_INVOICED].includes(this.invoicingDocument.invoicedStatus);
  }

  get showInvoicedAmountProgressBar() {
    return this.invoicedAmount !== 0 && !this.isInvoicedAmountFull;
  }
}
