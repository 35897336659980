/* import __COLOCATED_TEMPLATE__ from './multi.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import fade from 'ember-animated/transitions/fade';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';
import { TrackedObject } from 'tracked-built-ins';

import CURRENCIES from 'qonto/constants/currencies';
import { EmptyState } from 'qonto/react/components/vat-accounting/multi/empty-state';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import vatAmount from 'qonto/utils/vat-amount';

const MAX_VAT_LINES = 8;
const OTHER_RATE = 'other';

export const calculateVatAmount = (transactionAmount, vatRate) => {
  let amount = vatAmount(transactionAmount, vatRate);
  return parseFloat(amount);
};

export const calculateAmountExcludingVat = (vatAmount, vatRate) => {
  if (!vatRate) return 0;
  return parseFloat(vatAmount) / (parseFloat(vatRate) / 100);
};

class VatLine {
  @tracked rate;
  amount;
  amountExcludingVat;
  transactionId;
  id;

  get label() {
    return this.rate || this.rate === 0 ? `${this.rate} %` : null;
  }

  get isValid() {
    if (!this.amount || !this.amountExcludingVat) return true;
    if (!this.amount.value || !this.amountExcludingVat.value) return true;

    return this.amount.value >= 0 && this.amountExcludingVat.value >= 0;
  }

  constructor(line) {
    let { amount, amountExcludingVat } = line || {};
    let defaultAmount = { value: null, currency: CURRENCIES.default };

    Object.assign(this, {
      ...line,
      amount: new TrackedObject(amount || defaultAmount),
      amountExcludingVat: new TrackedObject(amountExcludingVat || defaultAmount),
    });
  }
}

class VatOption {
  label;
  value;
  disabled;

  constructor(line) {
    Object.assign(this, line);
  }

  isEqual(other) {
    return this.value === other.value;
  }
}

export default class VatAccountingMultiComponent extends Component {
  @service intl;
  @service toastFlashMessages;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service store;

  @reads('args.model.vat.items') vatItems;
  @reads('organizationManager.organization.vatRatesCategories') vatRatesCategories;

  @tracked lines;
  @tracked showCustomRate;

  transition = fade;
  amountField = AmountField;
  emptyStateComponent = EmptyState;

  constructor() {
    super(...arguments);

    this.lines = this.vatItems?.length
      ? this.vatItems.map(line => new VatLine(line))
      : [new VatLine()];
  }

  get vatRates() {
    let validRateCategories = this.vatRatesCategories.filter(rate => rate >= 0);

    return [...new Set([...validRateCategories, ...this.transactionRates])].sort((a, b) => a - b);
  }

  get transactionRates() {
    return this.lines.map(({ rate }) => rate).filter(rate => rate || rate === 0);
  }

  get canAddVatLine() {
    return this.lines.length < MAX_VAT_LINES;
  }

  get totalVatAmount() {
    let totalVatAmountValue = this.lines.reduce((totalAmount, { amount = {} }) => {
      let parsedValue = parseFloat(amount.value);

      return isNaN(parsedValue) ? totalAmount : totalAmount + Math.round(parsedValue * 100) / 100;
    }, 0);

    return {
      value: totalVatAmountValue.toFixed(2),
      currency: CURRENCIES.default,
    };
  }

  @action
  onVatAmountChange(index, amountValue) {
    this.lines[index].amount.value = amountValue;
    this._computeMultiVatLine(index);
    this.segment.track('attachment_modale_transaction_vat_amount_edited');

    this.saveVatLine(index);
  }

  @action
  onExcludingVatAmountChange(index, amountValue) {
    this.lines[index].amountExcludingVat.value = amountValue;
    this.saveVatLine(index);
  }

  @action
  selectedVatOption(index) {
    return new VatOption({
      label: this.lines[index]?.label,
      value: this.lines[index]?.rate,
      disabled: false,
    });
  }

  @action
  vatOptions(index) {
    let rates = this.vatRates.map(
      vatRate =>
        new VatOption({
          label: `${this.intl.formatNumber(vatRate)} %`,
          value: vatRate,
          disabled:
            this.transactionRates.includes(vatRate) &&
            this.transactionRates.indexOf(vatRate) !== index,
        })
    );

    let otherRate = new VatOption({
      label: this.intl.t('transactions.sidebar.vat.accounting.other'),
      value: OTHER_RATE,
      disabled: false,
    });

    return [...rates, otherRate];
  }

  @action
  addVatLine() {
    this.lines = [...this.lines, new VatLine()];
    this.segment.track('attachment_modale_transaction_vat_added');
  }

  @action
  onUpdateVatRate(index, { value: rate }) {
    if (this.transactionRates.includes(rate)) {
      return;
    }

    if (rate === OTHER_RATE) {
      this.showCustomRate = index;
      return;
    }

    this.showCustomRate = undefined;
    this.lines[index].rate = rate;

    if (this.lines.length === 1) this._computeSingleVatLine(index);
    else if (this.lines.length > 1) this._computeMultiVatLine(index);

    this.saveVatLine(index);
    this.segment.track('attachment_modale_transaction_vat_rate_updated');
  }

  @action
  saveVatLine(index) {
    let { amount, amountExcludingVat, rate, transactionId, id } = this.lines[index];
    let line = { amount, amountExcludingVat, rate, transactionId, id };

    if (!this.lines[index].isValid) return;

    if (!id) {
      this.createVatLineTask
        .perform(line)
        .then(({ id, transaction }) => {
          this.lines[index].id = id;
          this.lines[index].transactionId = transaction.id;
        })
        .catch(ignoreCancelation)
        .catch(error => this._handleVatError(error));
    } else {
      this.saveVatLineTask
        .perform(line)
        .catch(ignoreCancelation)
        .catch(error => this._handleVatError(error));
    }
  }

  @action
  onCancelCustomRate() {
    this.showCustomRate = undefined;
  }

  createVatLineTask = dropTask(async line => {
    let createdLine = this.store.createRecord('vat', {
      ...line,
      transaction: this.args.model,
    });

    return await createdLine.save();
  });

  removeVatLineTask = dropTask(
    waitFor(async index => {
      let line = this.lines[index];
      let { id } = line;

      if (id) {
        try {
          await this.deleteVatLineTask.perform(line);
          this.lines = this.lines.filter((_, currentIndex) => currentIndex !== index);
          this.segment.track('attachment_modale_transaction_vat_deleted');
        } catch (error) {
          this._handleVatError(error);
        }
      } else {
        this.lines = this.lines.filter((_, currentIndex) => currentIndex !== index);
      }
    })
  );

  saveVatLineTask = dropTask(async line => {
    return await this.store.adapterFor('vat').updateVat(line);
  });

  deleteVatLineTask = dropTask(async line => {
    return await this.store.adapterFor('vat').deleteVat(line);
  });

  _handleVatError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 400) {
      this.sentry.captureException(error);
    }
    if (error.status === 404) {
      this.toastFlashMessages.toastError(
        this.intl.t('attachment-viewer.bookkeeping.changes-unsaved.error-toast')
      );
    } else {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }

  _computeSingleVatLine(index) {
    let transactionAmount = this.args.model.amount;
    let vatRate = this.lines[index].rate;

    let vatAmount = calculateVatAmount(transactionAmount, vatRate);
    let amountExcludingVat = transactionAmount - vatAmount;

    this.lines[index].amount.value = vatAmount.toFixed(2);
    this.lines[index].amountExcludingVat.value = amountExcludingVat.toFixed(2);
  }

  _computeMultiVatLine(index) {
    let line = this.lines[index];
    let { amount, rate } = line;

    if (amount?.value && rate) {
      let amountExcludingVat = calculateAmountExcludingVat(amount.value, rate);
      this.lines[index].amountExcludingVat.value = amountExcludingVat.toFixed(2);
    }
  }
}
