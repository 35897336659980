/* import __COLOCATED_TEMPLATE__ from './association-kyb.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class AssocationKybTopbarComponent extends Component {
  topBanner = TopBanner;

  @service organizationManager;
  @service segment;

  @reads('organizationManager.organization') organization;

  @action
  close() {
    safeLocalStorage.setItem('association-kyb-topbar-dismissed', true);
    this.segment.track('association_topbar_close_clicked');
    this.args.close();
  }
}
