export default {
  "row": "cwp",
  "cell": "cwx",
  "title": "cww",
  "internal-note": "cwN",
  "unit": "cwv",
  "price": "cwo",
  "quick-actions": "cwn",
  "missing-details-container": "cwi",
  "missing-details-label": "cwA body-2",
  "warning-icon": "cwY",
  "empty": "cwh",
  "checkbox": "cwf",
  "align-checkbox": "cwK"
};
