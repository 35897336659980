export default {
  "modal": "qDF",
  "section": "qDg",
  "header": "qDD",
  "title": "qDV",
  "icon": "qDL",
  "content": "qDT",
  "export-template-wrapper": "qDz",
  "export-template-radio": "qDa",
  "export-template-content": "qDH",
  "export-template-label": "qDO",
  "export-template-button": "qDm",
  "export-template-list": "qDp",
  "inactive": "qDx",
  "export-template-title": "qDw",
  "export-template-description": "qDN",
  "export-template-item-content": "qDv",
  "permission-disclaimer-container": "qDo",
  "export-template-footer": "qDn",
  "export-template-cta-container": "qDi",
  "upsell": "qDA",
  "upsell-section": "qDY",
  "_content": "qDh",
  "_cta": "qDf",
  "upsell-title": "qDK",
  "upsell-description": "qDG"
};
