/* import __COLOCATED_TEMPLATE__ from './beneficiary-form.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { isIbanFromSepaZone, parseInvalidBeneficiaryErrors } from 'qonto/utils/beneficiaries';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class TransfersBeneficiarySepaFormComponent extends Component {
  @service segment;
  @service abilities;
  @service intl;
  @service networkManager;
  @service internationalOutManager;

  @tracked isValidationEnabled = false;

  constructor() {
    super(...arguments);
    this.checkInternationalOutEligibilityTask.perform().catch(ignoreCancelation);
  }

  get beneficiary() {
    return this.args.beneficiary;
  }

  get canReadCustomLabels() {
    return this.abilities.can('read custom-labels');
  }

  get canViewBookeeping() {
    return this.abilities.can('view bookkeeping');
  }

  get hasLabelsList() {
    return this.beneficiary.organization.get('labelLists').length > 0;
  }

  get ibanErrorMessage() {
    let message = this.beneficiary.validations.attrs.iban?.message;

    if (message) {
      return message === 'invalid_iban' ? this.intl.t('validations.errors.invalid_iban') : message;
    }

    if (!this.isIbanFromSepaZone && !this.isEligibleToInternationalTransfers) {
      return this.intl.t('validations.errors.iban_not_sepa');
    }

    return null;
  }

  get isIbanFromSepaZone() {
    return isIbanFromSepaZone(this.beneficiary.iban);
  }

  checkInternationalOutEligibilityTask = dropTask(async () => {
    this.isEligibleToInternationalTransfers = await this.internationalOutManager.isEligible();
  });

  saveBeneficiaryTask = dropTask(async () => {
    this.isValidationEnabled = false;
    // eslint-disable-next-line ember/no-array-prototype-extensions
    this.beneficiary.errors.clear();

    let { validations } = await this.beneficiary.validate();
    this.isValidationEnabled = true;

    if (validations.isValid && this.isIbanFromSepaZone) {
      try {
        await this.beneficiary.save();
        this.args.transitionToNext(this.beneficiary);
      } catch (error) {
        if (error instanceof InvalidError && error.errors) {
          let { errors } = error;

          let parsedFieldErrors = parseInvalidBeneficiaryErrors(errors);
          this.networkManager.errorModelInjector(this.beneficiary, parsedFieldErrors, error);

          this._scrollToError();
        }
      }
    } else {
      this._scrollToError();
    }
  });

  @action
  saveLabels(labelsList, label) {
    let labelsToUpdate = getLabelsToUpdate(this.beneficiary.labels, labelsList, label);
    this.beneficiary.labels = labelsToUpdate;
  }

  @action
  updateEmail(email) {
    this.beneficiary.email = isEmpty(email) ? null : email;
  }

  @action
  updateVatRate(beneficiary, { vatRate }) {
    beneficiary.vatRate = vatRate;
  }

  @action
  handleLinkClick() {
    this.segment.track('transfer-sepa_swift-redirection_clicked');

    this.args.transitionToFlow({
      flowName: 'international-out',
      stepId: 'quote',
      queryParams: { targetCurrency: 'EUR' },
    });
  }

  _scrollToError() {
    next(() => scrollIntoView('[data-has-error]'));
  }
}
