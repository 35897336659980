/* import __COLOCATED_TEMPLATE__ from './indicator.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { bisect } from 'd3-array';
import { interpolateNumber } from 'd3-interpolate';
import { scalePoint } from 'd3-scale';
import { select } from 'd3-selection';
import { area } from 'd3-shape';
import { dropTask, waitForQueue } from 'ember-concurrency';

import ComboChartComponent from 'qonto/components/overview/chart/combo-chart';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const DOT_DIAMETER = 3;

export default class IndicatorChartComponent extends ComboChartComponent {
  @service intl;
  @service theme;
  @service sentry;

  @tracked activeDateIndex;
  @tracked activeSample;
  @tracked indicatorFigure;
  @tracked tooltipTarget;

  chartContainer;
  areaClip = `${this.guid}-areaClip`;
  chartId = `chart-container-${guidFor(this)}`;

  constructor() {
    super(...arguments);

    this.setGraphTask.perform().catch(ignoreCancelation);
  }

  get boundToken() {
    return this.args.rate === 'monthly'
      ? DATE_FORMAT_TOKENS.MONTH_YEAR_S
      : DATE_FORMAT_TOKENS.DATE_YEAR_S;
  }

  get chartHeight() {
    return this.chartInnerHeight + this.chartSpacing.vertical + this.chartSpacing.top;
  }

  get chartWidth() {
    return this.chartContainer?.clientWidth - DOT_DIAMETER;
  }

  get isDrawable() {
    if (Array.isArray(this.args.timeseries)) {
      return (
        !this.isPastRange || this.args.timeseries.some(({ data }) => data?.value !== undefined)
      );
    }
  }

  get isBalance() {
    return this.args.type === 'balance';
  }

  get showBalance() {
    if (!this.isBalance) return true;
    return this.args.showBalance;
  }

  get icon() {
    return this.showBalance ? 'icon_eye_hidden_outlined' : 'icon_eye_outlined';
  }

  get ariaLabel() {
    return this.showBalance
      ? this.intl.t('a11y.icons.alt-values.hide')
      : this.intl.t('a11y.icons.alt-values.show');
  }

  get textWidthOffset() {
    return this.indicatorFigure?.node()?.getBBox().width + 8 || 0;
  }

  querySelector(selector) {
    return document.querySelector(selector);
  }

  setGraphTask = dropTask(async () => {
    try {
      await waitForQueue('afterRender');

      this.chartContainer = document.getElementById(this.chartId);
      this.drawGraph();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  drawGraph() {
    this.chartSpacing.yScaleTop = 38;

    this._setRenderer(this.chartContainer);
    this._setScales(this.timeseries);
    this._setYGridlines(this.yScale);
    this._setXAxis(this.xScale, '');
    this._setYAxis(this.yScale, this.showBalance);

    this._drawIndicatorFigure(this.args.keyIndicator);

    if (this.timeseries.length === 1) {
      this._drawBalanceLineDot(this.timeseries, this.xScale, this.yScale, 0, true);
      this._drawTimeTick(this.timeseries[0], this.xScale);
    } else {
      this._drawTimeBounds(this.args.bounds);

      if (this.isDrawable) {
        this._drawBalanceArea(this.timeseries, this.centeredXScale, this.yScale);
        this._drawBalance(this.timeseries, this.centeredXScale, this.yScale);
      }
    }

    this.chartRenderer
      .on('mousemove', event => this.drawHoverDisplay(event))
      .on('mouseleave', event => this.resetHoverDisplay(event));

    this.isFirstDraw = false;
  }

  drawHoverDisplay(event) {
    let mouseX = event.offsetX;

    if (this.isDrawable && mouseX > this.chartSpacing.left) {
      let sampleIndex = this._getMatchingSampleIndex(this.timeseries, this.xScale, mouseX);

      if (sampleIndex >= 0) {
        let sample = this.timeseries[sampleIndex];
        let dotX = this.xScale(sample.time);
        let dotY = this.yScale(sample.data.value);

        if (!this.hoverDotAxis) {
          this.hoverDotAxis = this.chartRenderer
            .append('line')
            .attr('x1', dotX)
            .attr('x2', dotX)
            .attr('y1', this.chartSpacing.top)
            .attr('y2', this.chartInnerHeight)
            .attr('class', 'chart-hover-dot-axis')
            .attr('data-test-chart-hover-dot-axis', '');
        } else {
          this.hoverDotAxis.attr('x1', dotX).attr('x2', dotX);
        }

        if (!this.hoverDotBackground) {
          this.hoverDotBackground = this.chartRenderer
            .append('circle')
            .attr('class', 'chart-hover-dot-background')
            .attr('data-test-chart-hover-dot-background', '')
            .attr('cx', dotX)
            .attr('cy', dotY)
            .attr('r', 7.5);
        } else {
          this.hoverDotBackground.attr('cx', dotX).attr('cy', dotY);
        }

        if (!this.hoverDot) {
          this.hoverDot = this.chartRenderer
            .append('circle')
            .attr('class', 'chart-hover-dot')
            .attr('data-test-chart-hover-dot', '')
            .attr('cx', dotX)
            .attr('cy', dotY)
            .attr('r', 3);
        } else {
          this.hoverDot.attr('cx', dotX).attr('cy', dotY);
        }

        this._setSampleTooltip(sample, sampleIndex);
      }
    }
  }

  resetHoverDisplay() {
    this._resetHoverDot();
    this._resetTooltip();
  }

  ignoredFirstResize = false;

  @action
  onResize() {
    if (this.chartContainer && this.ignoredFirstResize) {
      this.drawGraph();
    } else {
      this.ignoredFirstResize = true;
    }
  }

  _getXScale(timeseries) {
    return scalePoint()
      .domain(timeseries.map(d => d.time))
      .range([this.chartSpacing.left, this.chartWidth - 1]);
  }

  _setScales(timeseries) {
    this.yScale = this._getYScale(timeseries);
    this.xScale = this._getXScale(timeseries);
    this.centeredXScale = time => this.xScale(time) + this.xScale.bandwidth() / 2;
  }

  _getMatchingSampleIndex(timeseries, xScale, mouseX) {
    let timesPositions = timeseries.map(({ time }) => xScale(time));
    return bisect(timesPositions, mouseX) - 1;
  }

  _drawIndicatorFigure(figure) {
    let formatNumber = number =>
      this.intl.formatNumber(number, { currency: 'EUR', style: 'currency' });

    this.indicatorFigure = this.chartRenderer.select(`#${this.chartId} text`);

    if (this.isAnimated) {
      this.indicatorFigure
        .transition()
        .tween('text', () => {
          let interpolator = interpolateNumber(0, figure);
          return function (t) {
            select(this).text(formatNumber(interpolator(t).toFixed(2)));
          };
        })
        .duration(this.animationDuration)
        .waitFor();
    }
  }

  _drawTimeBounds(bounds) {
    if (bounds) {
      this.chartRenderer
        .append('text')
        .attr('text-anchor', 'start')
        .attr('x', this.chartSpacing.left)
        .attr('y', this.chartHeight)
        .attr('class', 'chart-time-bound')
        .attr('data-test-chart-bound-min', '')
        .text(
          dateToken({ date: bounds.min, locale: this.intl.primaryLocale, token: this.boundToken })
        );

      this.chartRenderer
        .append('text')
        .attr('text-anchor', 'end')
        .attr('x', this.chartWidth)
        .attr('y', this.chartHeight)
        .attr('class', 'chart-time-bound')
        .attr('data-test-chart-bound-max', '')
        .text(
          dateToken({ date: bounds.max, locale: this.intl.primaryLocale, token: this.boundToken })
        );
    }
  }

  _drawTimeTick(timevalue, xScale) {
    this.chartRenderer
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('x', xScale(timevalue.time))
      .attr('y', this.chartHeight)
      .attr('class', 'chart-time-bound')
      .attr('data-test-chart-time-tick', '')
      .text(
        dateToken({ date: timevalue.time, locale: this.intl.primaryLocale, token: this.boundToken })
      );
  }

  _drawBalanceArea(timeseries, xScale, yScale) {
    let currentDatePosition = this.isCurrentPeriodDisplayed
      ? xScale(timeseries[this.currentDateIndex].time)
      : this.chartWidth;

    // Combine the settled line timeseries with the dotted line timeseries
    let enhancedTimeseries = [...timeseries];
    enhancedTimeseries.push(timeseries.at(-1));

    let balanceArea = area()
      .x((_, index) => {
        let isLast = index === enhancedTimeseries.length - 1;
        return this._getLineXPosition(index, enhancedTimeseries, xScale, isLast, !isLast);
      })
      .y0(this.yScale(0))
      .y1((_, index) => {
        let isLast = index === enhancedTimeseries.length - 1;
        return this._getLineYPosition(index, enhancedTimeseries, yScale, isLast);
      });

    this.chartRenderer
      .append('path')
      .attr('d', balanceArea(enhancedTimeseries))
      .attr('clip-path', `url(#${this.areaClip})`)
      .attr('data-test-chart-area', '')
      .attr('class', 'chart-area');

    select(`#${this.areaClip} rect`)
      .transition()
      .duration(this.animationDuration)
      .delay(this.animationDelay)
      .attr('width', currentDatePosition)
      .waitFor()
      .transition()
      .delay(this.animationDuration / 2)
      .duration(this.animationDuration / 2)
      .attr('width', this.chartWidth)
      .waitFor();
  }

  _setSampleTooltip(sample, sampleIndex) {
    this.tooltipTarget = this.chartContainer.querySelector('.chart-hover-dot');
    this.activeSample = { ...sample.data, count: sample.display?.count, time: sample.time };
    this.activeDateIndex = sampleIndex;
  }

  _resetHoverDot() {
    if (this.hoverDot) {
      this.hoverDot.remove();
      this.hoverDot = undefined;
    }
    if (this.hoverDotBackground) {
      this.hoverDotBackground.remove();
      this.hoverDotBackground = undefined;
    }
    if (this.hoverDotAxis) {
      this.hoverDotAxis.remove();
      this.hoverDotAxis = undefined;
    }
  }

  _resetTooltip() {
    this.tooltipTarget = undefined;
    this.activeSample = undefined;
    this.activeDateIndex = undefined;
  }
}
