/* import __COLOCATED_TEMPLATE__ from './ubo.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SavingsAccountsStepsAboutComponent extends Component {
  @service segment;

  get isValid() {
    let { tinErrors, countryErrors } = this.args.context;
    return (
      (!tinErrors || Object.keys(tinErrors).length === 0) &&
      (!countryErrors || Object.keys(countryErrors).length === 0)
    );
  }

  @action
  transitionToNext() {
    this.args.context.displayUboErrors();
    if (this.isValid) {
      this.segment.track('savings_ubo_cta');
      this.args.transitionToNext();
    }
  }
}
