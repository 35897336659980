export default {
  "modal": "qIQ",
  "container": "qIe",
  "form-container": "qIB",
  "preview-container": "qIE",
  "preview-content": "qId",
  "switch-container": "qII",
  "supplier-invoice-preview": "qIt",
  "header": "qIM",
  "footer": "qIP",
  "form-content": "qIl",
  "form-content-section": "qIX",
  "form-disclaimer": "qIC",
  "hidden": "qIk"
};
