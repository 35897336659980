export default {
  "container": "cxx",
  "cards-container": "cxw",
  "card": "cxN",
  "expanded": "cxv",
  "hidden": "cxo",
  "collapsed": "cxn",
  "with-expand-collapse-animation": "cxi",
  "with-dismiss-animation": "cxA",
  "has-one-card": "cxY",
  "dismissing": "cxh",
  "with-hover-animation": "cxf",
  "toggle-button": "cxK",
  "toggle-container": "cxG",
  "toggle-button-icon": "cxr",
  "has-two-cards": "cxb"
};
