/* import __COLOCATED_TEMPLATE__ from './prismic-dom.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class PrismicDomComponent extends Component {
  @service sentry;

  @action
  onUnknownTag(node) {
    this.sentry.withScope(scope => {
      scope.setExtra('node', node);
      this.sentry.captureMessage(`Prismic: unknown tag '${node.type}'`);
    });
  }
}
