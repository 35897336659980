export default {
  "header-cell": "cGj",
  "header-content": "cGW caption-bold",
  "active": "cGu",
  "empty": "crS",
  "request-type": "crc",
  "date": "crq",
  "requester": "crZ",
  "amount": "crR",
  "header-status": "crQ"
};
