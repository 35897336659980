/* import __COLOCATED_TEMPLATE__ from './invoice-preview.hbs'; */
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

export default class TransfersSepaInvoicePreviewProvider extends Component {
  get isGermanEinvoicingFFActive() {
    return variation('feature--invoices-e-invoicing-germany');
  }

  get invoiceAttachmentFile() {
    if (this.isGermanEinvoicingFFActive) {
      return (
        this.args.context.invoice?.displayAttachment?.file ??
        this.args.context.invoice?.attachment.file ??
        null
      );
    }
    return this.args.context.invoice?.attachment.file ?? null;
  }

  get isPdfPreviewWithNoToolbar() {
    let isXRechnungPreview =
      this.args.context.invoice?.attachment.id &&
      this.args.context.invoice?.displayAttachment?.id &&
      this.args.context.invoice?.attachment.id !== this.args.context.invoice?.displayAttachment?.id;
    return this.isGermanEinvoicingFFActive && isXRechnungPreview;
  }

  get isInvalidGermanEInvoice() {
    return (
      this.isGermanEinvoicingFFActive &&
      this.args.context.invoice?.isAttachmentNonFinancial &&
      this.args.context.invoice?.isGermanEInvoice
    );
  }
}
