/* import __COLOCATED_TEMPLATE__ from './attachments.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Button, Disclaimer } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';
import { and, not, reads } from 'macro-decorators';

import { ErrorState } from 'qonto/react/components/transactions/sidebar/details/attachments/error-state';

const MAX_ATTACHMENTS = 20;
const MAX_ATTACHMENTS_SKELETON_COUNT = 3;

export default class TransactionsSiderbarDetailsAttachment extends Component {
  errorState = ErrorState;
  disclaimerInline = Disclaimer.Inline;
  button = Button;

  @service abilities;
  @service organizationManager;
  @service segment;
  @service router;

  @not('args.highlightedItem.isTax') isNotTax;
  @not('args.highlightedItem.isFee') isNotFee;
  @not('args.highlightedItem.isBiller') isNotBiller;
  @not('args.highlightedItem.isNrcPayment') isNotNrcPayment;
  @not('args.highlightedItem.isPagoPaPayment') isNotPagoPaPayment;
  @and('isNotFee', 'isNotTax', 'isNotPagoPaPayment') showMenu;
  @reads('args.highlightedItem.debit') isOutgoingTransfer;

  @tracked transactionWithNewAttachment;

  get hasNoAttachments() {
    return !this.args.highlightedItem.attachmentIds?.length;
  }

  get showReceiptRemindersDisclaimer() {
    return (
      this.abilities.can('view ReceiptReminder') &&
      this.isNotFee &&
      this.isNotPagoPaPayment &&
      this.isOutgoingTransfer
    );
  }

  get showFindOnQontoCta() {
    return (
      this.args.highlightedItem.attachmentIds.length < MAX_ATTACHMENTS &&
      this.abilities.can('read receivable-invoice') &&
      this.abilities.can('access supplier-invoice') &&
      this.abilities.can('view supplier-invoice') &&
      this.isNotPagoPaPayment &&
      this.isNotBiller &&
      this.isNotNrcPayment
    );
  }

  get hasAutoAttachments() {
    let { attachmentIds, automaticallyAddedAttachmentIds } = this.args.highlightedItem;
    return (
      this.abilities.can('access email forward attachment') &&
      automaticallyAddedAttachmentIds?.some(id => attachmentIds?.includes(id))
    );
  }

  get showAttachmentRequestButton() {
    return (
      this.abilities.can('request attachments in transaction', this.args.highlightedItem) &&
      this.hasNoAttachments &&
      this.isNotPagoPaPayment
    );
  }

  get showArPromoCard() {
    let { highlightedItem: transaction } = this.args;

    let isTransactionIncome = transaction.isSwiftIncome || transaction.isIncome;
    let transactionHasNewAttachment = this.transactionWithNewAttachment === transaction.id;

    return isTransactionIncome && transactionHasNewAttachment && this.args.hasNoQuoteAndInvoice;
  }

  get hasCardAcquirerPayout() {
    return (
      this.args.highlightedItem.isTapToPay &&
      this.args.highlightedItem.attachments.some(
        attachment => attachment.attachmentType === 'card_acquirer_payout'
      )
    );
  }

  get filesLoadingSkeletonCount() {
    let { attachmentIds } = this.args.highlightedItem;
    let count = Math.min(attachmentIds?.length || 0, MAX_ATTACHMENTS_SKELETON_COUNT);
    return Array.from({ length: count }, (_, i) => i);
  }

  @action
  handleFindInvoices() {
    this.args.handleShowSuggestedAttachments();
    this.segment.track('find_on_qonto_button_clicked');
  }

  @action
  handleAddAttachment(file) {
    let { highlightedItem: transaction } = this.args;

    this.args.saveAttachment(transaction, file, () => {
      this.transactionWithNewAttachment = transaction.id;
    });
  }

  markAttachmentAsLostTask = dropTask(
    waitFor(async dropdown => {
      this.args.highlightedItem.attachmentLost = true;
      dropdown.actions.close();

      try {
        await this.args.highlightedItem.markAttachmentAsLost();
      } catch {
        this.args.highlightedItem.attachmentLost = false;
      }
    })
  );

  markAttachmentAsFoundTask = dropTask(
    waitFor(async dropdown => {
      this.args.highlightedItem.attachmentLost = false;
      dropdown.actions.close();

      try {
        await this.args.highlightedItem.unmarkAttachmentAsLost();
      } catch {
        this.args.highlightedItem.attachmentLost = true;
      }
    })
  );

  markAttachmentAsRequiredTask = task(
    waitFor(async dropdown => {
      this.args.highlightedItem.attachmentRequired = true;
      dropdown.actions.close();

      try {
        await this.args.highlightedItem.markAttachmentAsRequired();
      } catch {
        this.args.highlightedItem.attachmentRequired = false;
      }
    })
  );

  markAttachmentAsNotRequiredTask = task(
    waitFor(async dropdown => {
      this.args.highlightedItem.attachmentRequired = false;
      dropdown.actions.close();

      try {
        await this.args.highlightedItem.unmarkAttachmentAsRequired();
      } catch {
        this.args.highlightedItem.attachmentRequired = true;
      }
    })
  );
}
