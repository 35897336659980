/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import { REQUEST_STATUS_COLORS } from 'qonto/constants/requests';

export default class RequestTableItemRequesterComponent extends Component {
  tag = Tag;

  get requestAmount() {
    let { request } = this.args;

    switch (request.requestType) {
      case 'mileage':
        return { value: request.amount.value, currency: request.amount.currency };
      case 'expense_report': {
        return request.amount;
      }
    }
  }

  get status() {
    let { status, displayedStatus } = this.args.request;
    return {
      displayedStatus,
      color: REQUEST_STATUS_COLORS[status],
    };
  }
}
