/* import __COLOCATED_TEMPLATE__ from './stack.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';

const EXPAND_COLLAPSE_ANIMATION_DURATION = macroCondition(isTesting()) ? 0 : 0.35;
const DISMISS_ANIMATION_DURATION = macroCondition(isTesting()) ? 0 : 0.3;
const HOVER_ANIMATION_DURATION = macroCondition(isTesting()) ? 0 : 0.3;

export default class ProductDiscoveryUserActionsStackComponent extends Component {
  @service segment;
  @service sentry;

  @tracked isExpanded = false;

  cardsContainerId = `cards-container-${guidFor(this)}`;

  // Animations
  @tracked isAnimatingExpandCollapse = false;
  @tracked isAnimatingDismissActionId = null;
  @tracked hoverAnimationCounter = 0;

  get isAnimatingHover() {
    return this.hoverAnimationCounter > 0;
  }

  get isCollapsed() {
    return !this.isExpanded;
  }

  get userActions() {
    return this.args.userActions.map(action => ({
      ...action,
      uniqueId: crypto.randomUUID(),
    }));
  }

  get hasMoreThanOneUserAction() {
    return this.userActions.length > 1;
  }

  onDismissActionTask = dropTask(async action => {
    this.segment.track('product-discovery_user-action_dismissed', {
      name: action.name,
      price_plan_code: this.args.pricePlanCode,
      origin: this.args.origin,
    });

    // We track the view event for the next card
    if (this.isCollapsed && this.userActions.length > 1) {
      this.trackViewEvent(this.userActions[1]);
    }

    this.isAnimatingDismissActionId = action.uniqueId;
    await timeout(DISMISS_ANIMATION_DURATION * 1000);
    this.isAnimatingDismissActionId = null;

    // We use unlink because we don't want to cancel the task if the component is destroyed
    await this.args.onDismissCardTask.unlinked().perform(action);
  });

  handleToggleClickTask = dropTask(async () => {
    if (this.isCollapsed) {
      this.userActions.forEach((action, index) => {
        if (index >= 1 && index <= 3) {
          this.trackViewEvent(action);
        }
      });
      this.segment.track('product-discovery_expand-button_clicked', {
        origin: this.args.origin,
      });
    }

    this.isExpanded = !this.isExpanded;

    this.isAnimatingExpandCollapse = true;
    await timeout(EXPAND_COLLAPSE_ANIMATION_DURATION * 1000);
    this.isAnimatingExpandCollapse = false;
  });

  // These tasks are used to add and remove the CSS class for the hover effect on the expand/collapse button.
  // The CSS class has to be added to the element only when the animation is running and removed when it's not,
  // otherwise it interferes with other animations since we are animating always the same property (transform).
  handleToggleMouseEnterTask = restartableTask(async () => {
    while (this.hoverAnimationCounter <= (HOVER_ANIMATION_DURATION * 1000) / 5) {
      this.hoverAnimationCounter++;
      await timeout(5);
    }
  });
  handleToggleMouseLeaveTask = restartableTask(async () => {
    await this.handleToggleMouseEnterTask.cancelAll();
    while (this.hoverAnimationCounter > 0) {
      this.hoverAnimationCounter--;
      await timeout(5);
    }
  });

  @action
  handleCardDidConstruct(action, index) {
    // We track the event only for the first card
    if (index === 0) {
      this.trackViewEvent(action);
    }
  }

  @action
  handleCtaClick(action) {
    this.segment.track('product-discovery_user-action_clicked', {
      name: action.name,
      price_plan_code: this.args.pricePlanCode,
      origin: this.args.origin,
    });
  }

  trackViewEvent(action) {
    this.segment.track('product-discovery_user-action_viewed', {
      name: action.name,
      status: action.status,
      price_plan_code: this.args.pricePlanCode,
      origin: this.args.origin,
    });
  }
}
