/* import __COLOCATED_TEMPLATE__ from './transfer.hbs'; */
import Component from '@glimmer/component';

export default class RequestTableCellTransferComponent extends Component {
  get icon() {
    let { request } = this.args;

    if (request.once) {
      return 'feature_transfer_direct-m';
    } else if (
      request.weekly ||
      request.monthly ||
      request.quarterly ||
      request.halfYearly ||
      request.yearly
    ) {
      return 'feature_transfer_recurring-m';
    } else {
      return 'feature_transfer_scheduled-m';
    }
  }

  get isRequestExpired() {
    let { request } = this.args;
    if (
      request.weekly ||
      request.monthly ||
      request.quarterly ||
      request.halfYearly ||
      request.yearly
    ) {
      return request.hasPastEndingDate;
    }
  }
}
