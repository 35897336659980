/* import __COLOCATED_TEMPLATE__ from './edit-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { camelize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import styles from 'qonto/components/supplier-invoices/edit-form.module.css';
import CURRENCIES from 'qonto/constants/currencies';
import {
  EDITABLE_DESCRIPTION_INVOICE_STATUSES,
  EINVOICING_LIFECYCLE_EVENT_DESCRIPTION,
  EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES,
  EINVOICING_LIFECYCLE_EVENT_STATUS_CODES,
  EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES,
  GENERIC_IBAN,
  GERMAN_INVOICE_FORMATS,
  INVOICE_STATUSES,
  SOURCE_TYPES,
} from 'qonto/constants/supplier-invoice';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { SPEND_LIMIT_DISCLAIMER_TYPES } from 'qonto/constants/transfers';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import removeKey from 'qonto/utils/remove-key';

const XMLFormat = 'application/xml';

class FormValidationError extends Error {
  constructor(...params) {
    super(...params);
    this.name = 'FormValidationError';
  }
}

class Form {
  @tracked errors;
  @tracked values;

  constructor(schema) {
    this.schema = schema;
    this.values = { ...schema.initialValues };
    this.validations = { ...schema.validationsSchema };
  }

  get isDirty() {
    return Object.entries(this.values).some(([key, value]) => {
      if (value instanceof Date) {
        return !dayjs(this.schema.initialValues[key]).isSame(dayjs(value), 'day');
      }
      if (key === 'amount') {
        return this.isAmountDirty;
      }

      return this.schema.initialValues[key] !== value;
    });
  }

  get isAmountDirty() {
    return (
      this.schema.initialValues.amount !== this.values.amount &&
      Number(this.schema.initialValues.amount) !== Number(this.values.amount)
    );
  }

  get isValid() {
    if (this.errors) {
      return Object.values(this.errors).every(value => !value);
    }

    return true;
  }

  get isIBANObfuscated() {
    return /^(?:••••|xxxx)/i.test(this.values.iban);
  }

  async submit({ partial = false } = {}) {
    if (this.isIBANObfuscated) {
      this.values.iban = null;
    }

    try {
      if (this.isValid) {
        await this.schema.onSubmit(this.values, partial);
      } else {
        throw new FormValidationError();
      }
    } catch (error) {
      if (error.isAdapterError) {
        this.errors = normalizeErrors(error.errors, this.validations);
      }
      throw error;
    }
  }

  reset() {
    this.values = { ...this.schema.initialValues };
    this.errors = undefined;
    this.schema.onReset();
  }

  addError(field, error) {
    this.errors = { ...this.errors, [field]: error };
  }

  updateField(prop, value, hasInvalidDate) {
    this.values = { ...this.values, [prop]: value };
    let error = (typeof hasInvalidDate === 'boolean' && hasInvalidDate) || false;
    this.errors = { ...this.errors, [prop]: error };
    if (prop === 'amount') {
      this.errors = { ...this.errors, currency: false };
    }
  }
}

function normalizeErrors(errors = [], validations) {
  let errorsObject = {};

  errors.forEach(it => {
    let pathElements = it.source.pointer.split('/');
    let errorKey = pathElements.at(-1);
    errorKey = errorKey === 'value' ? 'amount' : errorKey;
    let errorCode = it.code;

    errorsObject[camelize(errorKey)] =
      errorCode === 'required' ? validations[errorCode] : validations[errorKey];
  });
  return errorsObject;
}

export default class SupplierInvoicesEditFormComponent extends Component {
  disclaimerBlock = Disclaimer.Block;
  disclaimerInline = Disclaimer.Inline;

  @service abilities;
  @service intl;
  @service localeManager;
  @service modals;
  @service errors;
  @service toastFlashMessages;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service store;
  @service zendeskLocalization;

  Disclaimer = Disclaimer.Inline;

  @reads('organizationManager.organization') organization;

  @action
  redirectToReviewDuplicatesModal(event) {
    event.preventDefault();
    let { invoice_format, ...eventPayload } = this.loggingPayload;

    this.modal = this.modals.open('supplier-invoices/review-duplicates-modal', {
      invoiceId: this.args.invoice.id,
      isFullScreenModal: true,
      ...eventPayload,
      invoice_format,
    });

    this.handleDuplicatesEventLogging(eventPayload);
  }

  handleDuplicatesEventLogging(payload) {
    this.segment.track('supplier-invoices_duplicates-disclaimer_clicked', {
      ...payload,
    });
  }

  get loggingPayload() {
    if (variation('feature--invoices-e-invoicing-germany')) {
      let isEInvoice = this.args.invoice.isEinvoice ?? false;
      let eInvoiceType = null;

      let { attachment = null, isGermanEInvoice = false } = this.args.invoice;

      let fileContentType = attachment?.file?.fileContentType ?? null;

      if (isGermanEInvoice) {
        eInvoiceType =
          fileContentType === XMLFormat
            ? GERMAN_INVOICE_FORMATS.XRECHNUNG
            : GERMAN_INVOICE_FORMATS.ZUGFERD;
      } else {
        eInvoiceType = GERMAN_INVOICE_FORMATS.OTHER;
      }

      return {
        is_einvoice: isEInvoice,
        einvoice_type: eInvoiceType,
        invoice_format: this.getFileType(fileContentType),
      };
    } else {
      return {};
    }
  }

  getFileType(fileType) {
    if (!fileType) return null;

    let types = {
      image: /image/,
      pdf: /pdf/,
      xml: /xml/,
    };

    return Object.entries(types).find(entry => entry[1].test(fileType))?.[0] ?? null;
  }

  form = new Form({
    onSubmit: (values, partial) =>
      partial || this.args.partial
        ? this.savePartialInvoiceTask.perform(values)
        : this.saveInvoiceTask.perform(values),
    onReset: () => this.args.onClose?.(),

    initialValues: this.defaultValues,

    validationsSchema: this.validationsSchema,
  });

  constructor() {
    super(...arguments);
    // send the form instance to the parent
    this.args.onCreate?.(this.form);
    this.checkTransferLimitsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.withScope(scope => {
            if (errorInfo.httpStatus === 422 && error.errors?.length) {
              error.errors.forEach((e, i) => {
                scope.setContext(`error_${i}`, e);
              });
            }

            this.sentry.captureException(error);
          });
        }
      });
  }

  get defaultValues() {
    return {
      supplierName: this.args.invoice.supplierName,
      iban: this.args.invoice.iban,
      invoiceNumber: this.args.invoice.invoiceNumber,
      issueDate: this.args.invoice.issueDate,
      dueDate: this.args.invoice.dueDate,
      paymentDate: this.args.invoice.paymentDate,
      amount: this.args.invoice.totalAmount?.value,
      currency: this.args.invoice.totalAmount?.currency || 'EUR',
      description: this.args.invoice.description,
    };
  }

  get validationsSchema() {
    return {
      amount: this.intl.t('supplier-invoices.edit.modal.supplier-details.amount-error'),
      iban: this.intl.t('supplier-invoices.edit.modal.supplier-details.iban-error'),
      required: this.intl.t('validations.errors.blank'),
    };
  }

  get isReadonly() {
    return [INVOICE_STATUSES.paid, INVOICE_STATUSES.scheduled].includes(this.args.invoice.status);
  }

  get isInbox() {
    return [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(this.args.invoice.status);
  }

  get transferLimits() {
    return this.checkTransferLimitsTask.lastSuccessful?.value;
  }

  get isRunning() {
    return this.saveInvoiceTask.isRunning || this.checkTransferLimitsTask.isRunning;
  }

  get isSwiftInvoice() {
    return this.form.values.currency !== CURRENCIES.default;
  }

  get showSelfInvoiceSection() {
    let isEInvoice = this.args.invoice?.source === SOURCE_TYPES.E_INVOICING;
    return this.abilities.can('read self-invoice') && !isEInvoice;
  }

  get showDisclaimer() {
    let {
      hasDuplicates,
      selfInvoiceId,
      isAttachmentNonFinancial,
      isGermanEInvoice,
      hasDiscrepancies,
    } = this.args.invoice;

    if (!this.abilities.can('review supplier-invoice')) {
      return false;
    }

    if (hasDuplicates && !selfInvoiceId) {
      return true;
    }

    if (isAttachmentNonFinancial) {
      return true;
    }

    if (
      variation('feature--invoices-e-invoicing-germany') &&
      isGermanEInvoice &&
      hasDiscrepancies
    ) {
      return true;
    }

    return false;
  }

  get shouldShowEInvoiceDiscrepanciesInfoBox() {
    let { hasDiscrepancies, isGermanEInvoice } = this.args.invoice;
    return (
      variation('feature--invoices-e-invoicing-germany') && hasDiscrepancies && isGermanEInvoice
    );
  }

  get showGermanEinvoicingForm() {
    return (
      this.args.invoice?.isGermanEInvoice &&
      !this.args.invoice?.isAttachmentNonFinancial &&
      EDITABLE_DESCRIPTION_INVOICE_STATUSES.includes(this.args.invoice?.status)
    );
  }

  // French E-invoicing
  get showFrenchEinvoicingForm() {
    return (
      this.args.invoice?.frenchEInvoicing &&
      EDITABLE_DESCRIPTION_INVOICE_STATUSES.includes(this.args.invoice?.status)
    );
  }

  get frenchEinvoicingEventsToDisplay() {
    let events = this.args.invoice.einvoicingLifecycleEvents || [];

    return events?.filter(item =>
      EINVOICING_LIFECYCLE_EVENT_STATUS_CODES.includes(item.status_code)
    );
  }

  get showFrenchEinvoicingTimeline() {
    return Boolean(this.frenchEinvoicingEventsToDisplay.length);
  }

  get frenchEinvoicingCurrentStatusCode() {
    return this.frenchEinvoicingEventsToDisplay.at(-1)?.status_code;
  }

  get frenchEinvoicingTimelineTriggerText() {
    let latestEventDescription = EINVOICING_LIFECYCLE_EVENT_DESCRIPTION(
      this.intl,
      this.frenchEinvoicingCurrentStatusCode
    );

    let htmlTemplate = `<div><span class="${styles['timeline-status-label']}">${this.intl.t('supplier-invoices.einvoicing-timeline.title')}</span><span class="ml-8">${latestEventDescription}</span></div>`;

    return htmlSafe(htmlTemplate);
  }

  get frenchEinvoicingTimelineSteps() {
    return this.frenchEinvoicingEventsToDisplay.map((event, index) => {
      let description = EINVOICING_LIFECYCLE_EVENT_DESCRIPTION(this.intl, event.status_code);
      let timeFormat = this.localeManager.locale === 'en' ? 'hh:mm A' : 'HH:mm';
      let caption = dayjs(event.timestamp).format(`MMM DD, YYYY · ${timeFormat}`);
      let reason = event.reason;
      let comment = event.reason_message;
      let showPdpTooltip = description?.includes('PDP');
      let isLastEvent = index === this.frenchEinvoicingEventsToDisplay.length - 1;
      let stepIcon = this.frenchEInvoicingTimelineStepIcon(event.status_code, isLastEvent);

      return {
        icon: { type: stepIcon, filled: isLastEvent },
        description,
        caption,
        additionalInformation:
          (reason || comment) &&
          htmlSafe(`${
            reason
              ? this.intl.t('supplier-invoices.einvoicing-timeline.subtitle.reason', { reason })
              : ''
          }
          ${
            comment
              ? `${reason ? `<br/>` : ''}${this.intl.t('supplier-invoices.einvoicing-timeline.subtitle.comment', { comment })}`
              : ''
          }`),
        tooltipInfoMessage: showPdpTooltip
          ? this.intl.t('receivable-invoices.einvoicing-timeline.pdp-tooltip')
          : null,
        isLastEvent,
      };
    });
  }

  // These two getters will be removed after the experiment for ap-credit-notes
  get showCreditNote() {
    let { isCreditNote } = this.args.invoice;
    return variation('experiment--boolean-ap-credit-notes') && isCreditNote;
  }

  get invoiceNumberLabel() {
    return this.showCreditNote
      ? this.intl.t('supplier-invoices.edit.modal.credit-note-details.number-label')
      : this.intl.t('supplier-invoices.edit.modal.invoice-details.number-label');
  }

  get payableAmount() {
    let { payableAmount } = this.args.invoice || {};
    return this.intl.formatMoney(payableAmount?.value, { currency: payableAmount?.currency });
  }

  get showPayableAmountDisclaimer() {
    let { isCreditNote, relatedInvoices } = this.args.invoice || {};

    return !isCreditNote && relatedInvoices?.length;
  }

  frenchEInvoicingTimelineStepIcon(statusCode, isLastEvent) {
    let isfrenchEInvoicingWarningStatus =
      EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES.includes(statusCode);
    let isfrenchEInvoicingErrorStatus =
      EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES.includes(statusCode);

    if (isfrenchEInvoicingWarningStatus && isLastEvent) {
      return `${statusCode === 214 ? 'default-colour ' : ''}warning-filled`;
    }
    if (isfrenchEInvoicingWarningStatus) {
      return 'warning';
    }
    if (isLastEvent && !isfrenchEInvoicingErrorStatus) {
      return 'success';
    }
    if (isfrenchEInvoicingErrorStatus) {
      return 'reject';
    }

    return 'default';
  }

  @action
  trackTimelineOpened() {
    this.segment.track('invoice-timeline_drawer_opened', {
      source: 'AP',
      latest_status: this.frenchEinvoicingCurrentStatusCode,
    });
  }

  get faqLink() {
    return this.intl.t('supplier-invoices.einvoicing-disclaimer.url', {
      faqUrl: this.zendeskLocalization.getLocalizedArticle('fr-einvoice'),
    });
  }

  submitFormTask = restartableTask(async () => {
    let submitted = true;
    try {
      await this.form.submit();
    } catch (error) {
      submitted = false;
      let errorInfo = ErrorInfo.for(error);
      if (
        errorInfo.shouldSendToSentry &&
        error.status !== 400 &&
        !(error instanceof FormValidationError)
      ) {
        this.sentry.captureException(error);
      }
    }
    return submitted;
  });

  @action
  updateField(prop, value, hasInvalidDate) {
    this.form.updateField(prop, value, hasInvalidDate);

    if (prop === 'amount') {
      this.submitAmountTask.perform().catch(ignoreCancelation);
    }

    if (['amount', 'currency'].includes(prop)) {
      this.checkTransferLimitsTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry) {
            this.sentry.withScope(scope => {
              if (errorInfo.httpStatus === 422 && error.errors?.length) {
                error.errors.forEach((e, i) => {
                  scope.setContext(`error_${i}`, e);
                });
              }

              this.sentry.captureException(error);
            });
          }
        });
    }

    if (prop === 'supplierSnapshot') {
      this.submitSupplierSnapshotTask.perform().catch(ignoreCancelation);
    }
  }

  @action
  selectSupplier(item) {
    this.form.updateField('supplierName', item.name);

    /**
     * Saving the beneficiaryId to find back the related beneficiary in the Request Form
     * https://gitlab.qonto.co/front/qonto-js/-/merge_requests/25321
     */
    this.beneficiaryId = item.id;

    if (item.customOptionMessage) {
      this.segment.track('supplier-invoices_edit_use-as-supplier-name_clicked');
    } else {
      this.segment.track('supplier-invoices_edit_select-existing-supplier_clicked');
      if (item.iban) {
        this.form.updateField('iban', item.iban);
      }
    }
  }

  @action
  cancelEdit() {
    this.form.reset();
  }

  submitSupplierSnapshotTask = restartableTask(async () => {
    await timeout(DEBOUNCE_MS);
    try {
      await this.form.submit({ partial: true });
    } catch (error) {
      this.localErrorHandler(error);
    }
  });

  submitAmountTask = restartableTask(async () => {
    await timeout(DEBOUNCE_MS);
    if (this.form.isAmountDirty && !this.form.errors.amount) {
      try {
        await this.form.submit({ partial: true });
      } catch (error) {
        this.localErrorHandler(error);
      }
    }
  });

  localErrorHandler(error) {
    if (error.status !== 400 && !(error instanceof FormValidationError)) {
      this.errors.handleError(error);
    }
  }

  savePartialInvoiceTask = dropTask(
    async values => await this._saveInvoiceTask.perform(values, { partial: true })
  );

  saveInvoiceTask = restartableTask(
    async values => await this._saveInvoiceTask.perform(values, {})
  );

  _saveInvoiceTask = restartableTask(async (values, adapterOptions) => {
    let { iban, invoiceNumber, supplierName, issueDate, dueDate, paymentDate, description } =
      values;

    if (typeof values.amount !== 'undefined') {
      this.args.invoice.totalAmount = {
        value: values.amount,
        currency: values.currency,
      };
    }

    this.args.invoice.supplierName = supplierName;
    this.args.invoice.invoiceNumber = invoiceNumber;
    this.args.invoice.issueDate = issueDate;
    this.args.invoice.dueDate = dueDate;
    this.args.invoice.paymentDate = paymentDate;
    this.args.invoice.description = description;
    this.args.invoice.beneficiaryId = this.beneficiaryId;
    this.args.invoice.iban = this.form.isIBANObfuscated ? null : iban;

    try {
      await this.args.invoice.save({ adapterOptions });

      let initialStateTrackPayload = {
        ...this.form.schema.initialValues,
      };

      let finalStateTrackPayload = {
        ...this.form.values,
      };

      this.segment.track('supplier-invoices_edit-save_clicked', {
        initial_state: removeKey(initialStateTrackPayload, 'iban'),
        final_state: removeKey(finalStateTrackPayload, 'iban'),
      });

      this.form.schema.initialValues = this.defaultValues;

      if (!adapterOptions.partial) {
        this.args.onClose?.();
      }
    } catch (error) {
      let { status } = error;

      this.args.invoice.rollbackAttributes();

      if (status === 400) {
        if (error.errors.some(e => e.code === 'required')) {
          this.segment.track('supplier-invoices_edit_mandatory-fields');
        }
        throw error;
      } else if (status === 0 || status === 500) {
        let message = this.intl.t('supplier-invoices.edit.modal.error-toast.save-changes');
        this.toastFlashMessages.toastError(message);
        throw error;
      } else {
        this.errors.handleError(error);
      }
    }
  });

  checkTransferLimitsTask = restartableTask(async () => {
    let { amount, currency, invoiceNumber, supplierName } = this.form.values;

    if (
      this.abilities.cannot('create transfer') ||
      this.args.invoice.status === INVOICE_STATUSES.paid ||
      !parseFloat(amount) > 0 ||
      this.isSwiftInvoice
    )
      return;

    let debounce = macroCondition(isTesting()) ? 0 : 500;
    await timeout(debounce);

    let amountDetails = {
      amountCurrency: currency,
      amount,
    };

    if (this.transfer) {
      this.transfer.setProperties({ ...amountDetails });
    } else {
      this.transfer = this.store.createRecord('transfer', {
        bankAccount: this.organization.mainAccount,
        organization: this.organization,
        iban: GENERIC_IBAN,
        name: supplierName || 'name',
        activityTag: 'other_expense',
        reference: invoiceNumber || 'reference',
        ...amountDetails,
      });
    }

    let response = await this.transfer.confirm();
    this._manageAmountValidation(response);
    return response;
  });

  /**
   * Handles validation for amount field based on response warnings
   * @param {Object} response - Response from the transfer confirmation
   * @param {Array} response.warnings - Array of warning codes
   * @private
   */
  _manageAmountValidation(response) {
    // Reset error state
    this.form.errors = { ...this.form.errors, amount: false };

    // Early return if no warnings
    if (!response?.warnings?.length) {
      return;
    }

    // Check for any amount-related warnings
    let hasAmountWarning = response.warnings.some(
      warning =>
        warning === SPEND_LIMIT_DISCLAIMER_TYPES.AMOUNT_LIMIT ||
        warning === SPEND_LIMIT_DISCLAIMER_TYPES.MONTHLY_LIMIT ||
        warning === SPEND_LIMIT_DISCLAIMER_TYPES.PER_TRANSFER_LIMIT
    );

    if (hasAmountWarning) {
      this.form.errors = {
        ...this.form.errors,
        amount: this.intl.t('supplier-invoices.edit.modal.supplier-details.amount-error'),
      };
    }
  }
}
