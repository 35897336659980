/* import __COLOCATED_TEMPLATE__ from './foldable-item.hbs'; */
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

export default class RemindersConfigurationFoldableItemComponent extends Component {
  id = `foldable-item-${guidFor(this)}`;

  get isExpandable() {
    return this.args.isExpandable ?? true;
  }
}
