/* import __COLOCATED_TEMPLATE__ from './transactions.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { restartableTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class OverviewWidgetsTransactionsComponent extends Component {
  @service sentry;

  // This task exists only for error handling purposes
  transactionsTask = restartableTask(async () => {
    try {
      return await this.args.transactionsTask.linked().perform();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get hasTransactions() {
    return this.args.transactionsTask.lastSuccessful?.value?.transactions?.length > 0;
  }
}
