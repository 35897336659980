export default {
  "mileage-validation": "cjp",
  "actions": "cjx",
  "action-btn": "cjw",
  "action-btn-right": "cjN",
  "request-mileage-validation": "cjv",
  "account-selector": "cjo",
  "has-error": "cjn",
  "funds-disclaimer": "cji",
  "account-options": "cjA",
  "account-balance": "cjY",
  "warnings": "cjh"
};
