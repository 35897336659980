/* import __COLOCATED_TEMPLATE__ from './q-page-selector.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class QPageSelector extends Component {
  perPageOptions = [25, 50, 100];

  get previousAvailable() {
    return this.args.currentPage > 1;
  }

  get nextAvailable() {
    return this.args.currentPage < this.args.totalPages;
  }

  get summary() {
    let currentPage = this.args.currentPage;
    let perPage = this.args.perPage;
    let totalCount = this.args.totalCount;
    let offset = currentPage * perPage;
    let end = Math.min(offset, totalCount);
    let start = totalCount && offset - perPage + 1;

    return `${start}-${end} ${this.args.paginationLabel ?? 'of'} ${totalCount}`;
  }

  @action
  handlePrevious() {
    if (this.previousAvailable) {
      this.args.changePage(this.args.currentPage - 1);
    }
  }

  @action
  handleNext() {
    if (this.nextAvailable) {
      this.args.changePage(this.args.currentPage + 1);
    }
  }
}
