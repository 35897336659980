export default {
  "modal": "qCk",
  "action-container": "qCJ",
  "similar-label": "qCs",
  "info-icon": "qCF",
  "container": "qCg",
  "subtitle": "qCD caption-bold",
  "error": "qCV",
  "item-container": "qCL"
};
