/* import __COLOCATED_TEMPLATE__ from './secure-account.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class PopupSecuritySecureAccountComponent extends Component {
  @service userManager;
  @service segment;

  constructor() {
    super(...arguments);
    this.segment.track('profile_email-update_information_displayed');
  }
}
