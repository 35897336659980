export default {
  "form": "qlG",
  "form-section": "qlr",
  "form-title": "qlb title2",
  "divider": "qly",
  "row": "qlU",
  "col": "qlj",
  "actions": "qlW",
  "buttons": "qlu",
  "collapsible-timeline": "qXS",
  "timeline-status-label": "qXc"
};
