/* import __COLOCATED_TEMPLATE__ from './delete.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class PaymentMethodDeleteModalComponent extends Component {
  @service intl;
  @service segment;

  get translations() {
    return {
      title: this.intl.t('subscription.payment-methods.delete.title'),
      description: this.intl.t('subscription.payment-methods.delete.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('subscription.payment-methods.delete.confirm'),
    };
  }

  get data() {
    return { ...this.translations, confirmTask: this.confirmTask };
  }

  confirmTask = dropTask(async () => {
    this.segment.track('payment-methods_delete_confirmed');
    let { confirmTask, paymentMethodId } = this.args.data;

    await confirmTask.linked().perform(paymentMethodId);
    this.args.close();
  });
}
