export default {
  "header-cell": "qzm",
  "header-content": "qzp caption-bold",
  "active": "qzx",
  "align-right": "qzw",
  "empty": "qzN",
  "align-checkbox": "qzv",
  "col-5": "qzo",
  "col-9": "qzn",
  "emitted-at-header-tag": "qzi"
};
