/* import __COLOCATED_TEMPLATE__ from './sidebar-actions.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { STATUS } from 'qonto/constants/requests';
import { GERMAN_INVOICE_FORMATS, INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { SPEND_LIMIT_TYPES, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { SPEND_LIMITS_WARNINGS } from 'qonto/utils/extract-confirmation-response';
import { prepareLimitsData } from 'qonto/utils/transfers';

const XMLFormat = 'application/xml';

export default class SidebarActionsComponent extends Component {
  @service modals;
  @service router;
  @service toastFlashMessages;
  @service abilities;
  @service intl;
  @service segment;
  @service flowLinkManager;
  @service beneficiariesManager;
  @service organizationManager;

  get shouldDisplaySubmitKycDisclaimer() {
    return (
      this.organizationManager.membership.shouldSubmitKyc &&
      this.organizationManager.organization.kybPending
    );
  }

  get showLinkCreditNoteCta() {
    return variation('experiment--boolean-ap-credit-notes') && this.args.invoice?.isCreditNote;
  }

  get showCTA() {
    return (
      this.abilities.can('update supplierInvoice') &&
      [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(this.args.invoice?.status)
    );
  }

  get hasTransferWarnings() {
    let { warnings, market } = this.args.transferLimits || {};

    return (
      warnings?.some(warning => SPEND_LIMITS_WARNINGS.includes(warning)) || market?.opened === false
    );
  }

  get canPayByTransfer() {
    return (
      this.abilities.can('create transfer') && (!this.hasTransferWarnings || this.args.isSwift)
    );
  }

  get hasRequestTransferAbility() {
    return this.abilities.can('request transfer supplierInvoice');
  }

  get canRequestTransfer() {
    return !this.args.isSwift;
  }

  get showDeclinedNote() {
    let { invoice, request } = this.args;

    return (
      [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(invoice?.status) &&
      request?.status === STATUS.DECLINED
    );
  }

  get showSwiftDisclaimers() {
    return this.args.isSwift && this.hasTransferWarnings;
  }

  get swiftDisclaimer() {
    if (this.args.transferLimits) {
      let { warnings, spendLimits, market } = this.args.transferLimits;

      if (warnings) {
        let { aboveLimitType, monthly, monthSpendings, perTransfer } = prepareLimitsData(
          warnings,
          spendLimits
        );

        if (market?.opened === false) {
          return this.intl.t('supplier-invoices.cta.request-transfer-tooltip.swift-unavailable');
        }

        return aboveLimitType === SPEND_LIMIT_TYPES.MONTHLY
          ? this.intl.t('supplier-invoices.cta.request-transfer-tooltip.monthly-limit', {
              balance_monthly_transfer_limit: monthly - monthSpendings,
            })
          : this.intl.t('supplier-invoices.cta.request-transfer-tooltip.per-transfer-limit', {
              per_transfer_limit: perTransfer,
            });
      }
    }
  }

  openTransactionsModalTask = dropTask(async () => {
    let { invoice, submitForm } = this.args;

    if (submitForm) {
      let submitted = await submitForm.perform();
      if (!submitted) return;
    }

    this.segment.track('supplier-invoices_mark-as-paid_clicked', {
      tab: invoice.status,
      is_non_financial_document: invoice.isAttachmentNonFinancial,
      ...this.germanEInvoiceEventFields,
    });

    this.modals.open(
      'supplier-invoices/transactions-modal',
      {
        isFullScreenModal: true,
        attachment: invoice.attachment,
        invoice,
        onCloseModal: () => {
          this.router.transitionTo('supplier-invoices.index');
          this.toastFlashMessages.toastSuccess(
            this.intl.t('supplier-invoices.success-toast.mark-as-paid')
          );
        },
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  });

  payByTransfer = dropTask(async () => {
    let { invoice, submitForm } = this.args;

    if (submitForm) {
      let submitted = await submitForm.perform();
      if (!submitted) return;
    }

    this.segment.track('supplier-invoices_pay-by-transfer_clicked', {
      is_non_financial_document: invoice.isAttachmentNonFinancial,
      ...this.germanEInvoiceEventFields,
    });

    let isFx = invoice.totalAmount && invoice.totalAmount.currency !== 'EUR';

    // The pay by transfer feature has not be linked to the new international-out transfer flow.
    // If the invoice is considered a non-SWIFT invoice, we should not redirect.
    // However, the condition should never be met, as the quick action has been disabled in that case.
    // We added this check as a simple safety net.
    if (isFx) {
      return;
    }

    this.flowLinkManager.transitionTo({
      name: 'sepa-transfer',
      stepId: 'invoice',
      queryParams: {
        origin: TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
        supplierInvoiceId: invoice.id,
      },
    });
  });

  @action
  openAttachmentLinkModal() {
    let { invoice } = this.args;
    this.modals.open(
      'attachments/attachments-suggested/modal',
      {
        creditNote: invoice,
        onClose: close => close(),
        hasSupplierInvoices: true,
        transaction: {},
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }

  requestTransfer = dropTask(async () => {
    let { invoice, submitForm } = this.args;

    if (submitForm) {
      let submitted = await submitForm.perform();
      if (!submitted) return;
    }

    this.segment.track('supplier-invoices_request-transfer_clicked');
    this.router.transitionTo('requests.transfers.new', {
      queryParams: {
        supplierInvoiceId: invoice.id,
      },
    });
  });

  get germanEInvoiceEventFields() {
    if (variation('feature--invoices-e-invoicing-germany')) {
      let isEInvoice = this.args.invoice.isEinvoice;
      let eInvoiceType = null;

      let { attachment = null, isGermanEInvoice = false } = this.args.invoice;

      if (isGermanEInvoice) {
        eInvoiceType =
          attachment?.file?.fileContentType === XMLFormat
            ? GERMAN_INVOICE_FORMATS.XRECHNUNG
            : GERMAN_INVOICE_FORMATS.ZUGFERD;
      } else {
        eInvoiceType = GERMAN_INVOICE_FORMATS.OTHER;
      }

      return {
        is_einvoice: isEInvoice,
        einvoice_type: eInvoiceType,
      };
    } else {
      return {};
    }
  }
}
