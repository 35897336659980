/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { camelize } from '@ember/string';
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import {
  FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY,
  FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY,
  INVOICE_STATUSES,
} from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class SupplierInvoicesTableComponent extends Component {
  @service abilities;
  @service intl;
  @service router;
  @service organizationManager;
  @service segment;

  @reads('organizationManager.organization.legalCountry') legalCountry;

  get sortedInvoices() {
    let { invoices, sortBy } = this.args;

    if (invoices && sortBy) {
      let [property, option] = this.args.sortBy.split(':').map(camelize);

      let [missing, defined] = [[], []];
      invoices.forEach(invoice =>
        invoice[property] ? defined.push(invoice) : missing.push(invoice)
      );

      return option === 'asc' ? [...missing, ...defined] : [...defined, ...missing];
    } else {
      return invoices;
    }
  }

  get areSomeSelected() {
    if (this.args.localState.isLoading) return false;
    return this.args.selected.length > 0;
  }

  get areAllSelected() {
    if (this.args.localState.isLoading) return false;

    let nonScheduledInvoices = this.args.invoices.filter(
      invoice => invoice.status !== INVOICE_STATUSES.scheduled
    );
    if (nonScheduledInvoices.length === 0) return false;
    return this.args.selected.length === nonScheduledInvoices.length;
  }

  @action toggleSelectAll() {
    this.segment.track('supplier-invoices_bulk_select_all');
    if (this.areSomeSelected) {
      this.args.selectItems([]);
    } else {
      this.args.selectItems(
        this.args.invoices
          .filter(invoice => invoice.status !== INVOICE_STATUSES.scheduled)
          .map(({ id }) => id)
      );
    }
  }

  @action isSelected(id) {
    return this.args.selected.includes(id);
  }

  @action selectItem(id) {
    let newArray = [...this.args.selected];
    let idIndex = newArray.indexOf(id);

    if (idIndex === -1) {
      newArray.push(id);
    } else {
      newArray.splice(idIndex, 1);
    }
    this.args.selectItems(newArray);
  }

  get isItalianOrganization() {
    return this.legalCountry === 'IT';
  }

  get inboxSubtitle() {
    if (!this.isItalianOrganization) return null;

    return this.abilities.can('create transfer')
      ? this.intl.t('supplier-invoices.section.tabs.imported.empty-state.body-italy')
      : this.intl.t('supplier-invoices.section.tabs.imported.empty-state.body-italy-no-transfer');
  }

  get displayToReviewEmptyStateBlock() {
    return this.args.status === INVOICE_STATUSES.toReview && !this.isItalianOrganization;
  }

  get emptyStateOptions() {
    let { status } = this.args;

    let dualNavEmptyStates = {
      to_review: {
        title: this.intl.t('supplier-invoices.section.tabs.imported.empty-state.title'),
        subtitle: this.inboxSubtitle,
        lottieSrc: '/lotties/supplier-invoices/empty-state/to-review.json',
      },
      pending: {
        title: this.intl.t('supplier-invoices.section.tabs.pending-requests.empty-state.title'),
        subtitle: this.intl.t('supplier-invoices.section.tabs.pending-requests.empty-state.body'),
        lottieSrc: '/lotties/empty-state/no-request-pending.json',
      },
      scheduled: {
        title: this.intl.t('supplier-invoices.section.tabs.processing.empty-state.title'),
        subtitle: this.intl.t('supplier-invoices.section.tabs.processing.empty-state.body'),
        lottieSrc: '/lotties/supplier-invoices/empty-state/scheduled.json',
      },
      paid: {
        title: this.intl.t('supplier-invoices.section.tabs.completed.empty-state.title'),
        subtitle: this.abilities.can('create transfer')
          ? this.intl.t('supplier-invoices.section.tabs.completed.empty-state.body-authorised')
          : this.intl.t('supplier-invoices.section.tabs.completed.empty-state.body'),
        lottieSrc: '/lotties/supplier-invoices/empty-state/paid.json',
      },
      'paid,archived': {
        title: this.intl.t('supplier-invoices.section.tabs.completed.empty-state.title'),
        subtitle: this.abilities.can('create transfer')
          ? this.intl.t('supplier-invoices.section.tabs.completed.empty-state.body-authorised')
          : this.intl.t('supplier-invoices.section.tabs.completed.empty-state.body'),
        lottieSrc: '/lotties/supplier-invoices/empty-state/paid.json',
      },
    };

    return dualNavEmptyStates[status];
  }

  @action
  handleShowInvoice(invoice) {
    this.segment.track('supplier-invoices_details_viewed', {
      hasDuplicates: invoice.hasDuplicates,
      ...(variation('feature--boolean-improve-si-table') ? this.args.trackingEventOptions : {}),
    });
    this.router.transitionTo('supplier-invoices.show', invoice.id);

    if (this.args.firstEInvoiceId === invoice.id) {
      let { organization } = this.organizationManager;
      let dismissalKey =
        organization?.legalCountry === 'DE'
          ? FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY
          : FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY;
      safeLocalStorage.setItem(dismissalKey, true);
    }
  }

  @action
  handleItemKeydown(invoice, event) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.router.transitionTo('supplier-invoices.show', invoice.id);
    }
  }
}
