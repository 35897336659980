export default {
  "vat-lines": "qNX",
  "vat-line": "qNC",
  "vat-input": "qNk",
  "vat-rate": "qNJ",
  "vat-option-button": "qNs",
  "disabled": "qNF",
  "vat-option-tooltip": "qNg",
  "remove-label": "qND",
  "remove-button": "qNV",
  "vat-total": "qNL",
  "vat-total-amount": "qNT",
  "add-vat-line": "qNz",
  "with-vat-lines": "qNa"
};
