/* import __COLOCATED_TEMPLATE__ from './donut.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class OverviewFiltersDonutComponent extends Component {
  get side() {
    return this.args.data?.side || 'credit';
  }

  @action
  onSubmitAdvancedFilters(filters, period, source) {
    this.args.close({ filters, period, source });
  }

  @action
  onClose() {
    this.args.close();
  }
}
