/* import __COLOCATED_TEMPLATE__ from './pending-invoice-quick-actions.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { REQUEST_EVENT_METHOD, REQUEST_ORIGINS } from 'qonto/constants/requests';

export default class SupplierInvoicesPendingInvoiceQuickActionsComponent extends Component {
  @service organizationManager;
  @service segment;

  @tracked
  bankAccount = this.organizationManager.organization.mainAccount;

  get activeAccounts() {
    return this.organizationManager.organization.activeAccounts;
  }

  get hideAccountSelect() {
    return this.activeAccounts.length === 1;
  }

  approveRequestTask = dropTask(async () => {
    this.segment.track('request_approved_clicked', {
      origin: REQUEST_ORIGINS.SUPPLIER_INVOICES,
      method: REQUEST_EVENT_METHOD.QUICK_ACTIONS,
    });

    await this.args.approveRequestTask.perform({
      supplierInvoice: this.args.invoice,
      bankAccount: this.bankAccount,
    });
  });

  rejectRequestTask = dropTask(async () => {
    this.segment.track('request_declined_clicked', {
      origin: REQUEST_ORIGINS.SUPPLIER_INVOICES,
      method: REQUEST_EVENT_METHOD.QUICK_ACTIONS,
    });

    await this.args.rejectRequestTask.perform(this.args.invoice);
  });

  stopPropagation(event) {
    event.stopPropagation();
  }
}
