export default {
  "display-block": "qxJ",
  "pay-later-tooltip": "qxs",
  "disabled": "qxF",
  "stepper": "qxg",
  "installments": "qxD",
  "flex-items": "qxV",
  "day-label": "qxL body-2",
  "installment-amount": "qxT body-1",
  "spacing": "qxz",
  "info-icon": "qxa",
  "detail": "qxH",
  "detail-value": "qxO",
  "extra": "qxm",
  "extra-value": "qxp",
  "badge": "qxx",
  "title": "qxw",
  "link": "qxN"
};
