/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer, TextAreaField } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';

import CURRENCIES from 'qonto/constants/currencies';
import {
  ES_FREELANCER_LEGAL_FORM,
  NUMBERING_MODE,
  STATUS,
  TERM_AND_CONDITIONS_MAX_LENGTH,
  TERM_AND_CONDITIONS_MAX_LENGTH_FOR_IT,
} from 'qonto/constants/receivable-invoice';
import { defaultValues } from 'qonto/models/receivable-invoice/item';

export default class ReceivableInvoicesCreditNotesFormComponent extends Component {
  disclaimerInline = Disclaimer.Inline;
  textAreaField = TextAreaField;

  @service intl;
  @service organizationManager;

  get nextCreditNoteNumber() {
    return this.args.settings.creditNoteNextNumberFormatted;
  }

  @action onIssueDateChange(date) {
    this.args.creditNote.issueDate = date;
  }

  @action updateNumber(number) {
    this.args.creditNote.number = number.replace(/[^\w/\-?:().,'+]/g, '');
    this.args.creditNote.notifyPropertyChange('number');
  }

  @action
  deleteItem(item) {
    item.deleteRecord();
  }

  @action
  addItem() {
    this.args.creditNote.items.createRecord(
      defaultValues(this, this.args.creditNote.items.at(0)?.vatRate)
    );
  }

  get areItemsDisabled() {
    return [STATUS.UNPAID, STATUS.CANCELED].includes(
      this.args.creditNote.receivableInvoice.get('status')
    );
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get isEInvoicing() {
    return this.legalCountry === 'IT';
  }

  get prefixTermsAndConditionsLength() {
    let { organization } = this.organizationManager;
    //Here we add 3 characters because we separate the prefix from actual terms and conditions with " - "
    return `${organization.legalName}, ${organization.shortLegalForm}`.length + 3;
  }

  get termsAndConditionsMaxLength() {
    let theoreticalMaxLength = TERM_AND_CONDITIONS_MAX_LENGTH;
    if (this.legalCountry === 'IT') {
      theoreticalMaxLength = TERM_AND_CONDITIONS_MAX_LENGTH_FOR_IT;
    }

    let maxLength = theoreticalMaxLength - this.prefixTermsAndConditionsLength;
    return String(maxLength);
  }

  get numberErrorMessage() {
    let errors = this.args.creditNote.errors.errorsFor('number');
    if (errors.length === 0) return null;
    if (errors.some(e => e.message === 'credit_note_number_already_exists')) {
      return this.intl.t(
        'receivable-invoices.credit-note-creation.credit-note-details.credit-note-number.try-again-error'
      );
    }
    return this.intl.t('receivable-invoices.credit-note-creation.errors.required-field');
  }

  get isAutomaticNumberingEnabled() {
    return this.args.settings?.numberingMode === NUMBERING_MODE.AUTOMATIC;
  }

  get currency() {
    return this.args.creditNote?.currency || CURRENCIES.default;
  }

  get isSpanishFreelancer() {
    return (
      this.legalCountry === 'ES' &&
      this.organizationManager.organization.legalForm === ES_FREELANCER_LEGAL_FORM
    );
  }

  get showFiscalDetails() {
    return (
      this.isEInvoicing ||
      (variation('improvement--boolean-invoicing-es-irpf') &&
        this.isSpanishFreelancer &&
        this.args.creditNote?.withholdingTax?.rate)
    );
  }

  get showGlobalDiscount() {
    let status = this.args.creditNote?.receivableInvoice.get('status');
    let hasDiscount = Boolean(this.args.creditNote?.discount);
    let isFeatureFlagEnabled = variation('feature--boolean-invoicing-global-discount');

    if (!isFeatureFlagEnabled) {
      return false;
    }

    if (status === STATUS.PAID) {
      return true;
    } else if (status === STATUS.UNPAID || status === STATUS.CANCELED) {
      return hasDiscount;
    } else {
      return false;
    }
  }

  get isGlobalDiscountDisabled() {
    let status = this.args.creditNote?.receivableInvoice.get('status');
    return status !== STATUS.PAID;
  }
}
