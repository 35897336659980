/* import __COLOCATED_TEMPLATE__ from './status-avatar.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CategoryAvatar } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components/category-avatar';
import { colorsMap } from 'qonto/react/utils/cashflow-categories/categories-data-map';

export default class StatusAvatarComponent extends Component {
  @service categoriesManager;
  @service abilities;

  reactCashflowCategoryAvatar = CategoryAvatar;

  get hasEnrichedLogo() {
    return this.args.avatar.startsWith('https');
  }

  get hasCategoryAssignmentArg() {
    return this.args?.categoryAssignment !== undefined;
  }

  get shouldUseCategoryAvatar() {
    return (
      this.abilities.can('assign category') &&
      !this.hasEnrichedLogo &&
      this.hasCategoryAssignmentArg
    );
  }

  get cashflowCategory() {
    let category = this.categoriesManager.flatCategories.find(
      ({ id }) => this.args?.categoryAssignment?.id === id
    );

    if (!category) {
      return { icon: 'icon_money_clip', color: colorsMap.default };
    }

    return { icon: category.iconKey, color: category.colorKey };
  }
}
