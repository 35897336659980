/* import __COLOCATED_TEMPLATE__ from './bank-accounts-select.hbs'; */
import Component from '@glimmer/component';

export default class BankAccountsSelectComponent extends Component {
  get accounts() {
    let { accounts, showExternalAccounts } = this.args;

    if (!accounts) return [];

    if (showExternalAccounts) {
      return accounts;
    }

    return accounts.filter(account => !account.isExternalAccount);
  }
}
