/* import __COLOCATED_TEMPLATE__ from './financing-income.hbs'; */
import Component from '@glimmer/component';

import { Amount } from 'qonto/react/components/transactions/sidebar/header/amount';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';

export default class TransactionsSidebarHeaderFinancingIncome extends Component {
  amount = Amount;
  Counterparty = Counterparty;
  Date = Date;
  type = Type;
}
