export default {
  "section-container": "cri",
  "disclaimer-section": "crA",
  "trip-title": "crY caption-bold mb-16",
  "google-link-container": "crh",
  "link-icon": "crf",
  "trip-details": "crK body-2",
  "trip-details-icon": "crG",
  "trip-details-title": "crr",
  "subtitle": "crb caption-bold mb-16",
  "disclaimer": "cry",
  "visualizer": "crU"
};
