export default {
  "header-cell": "qsG",
  "header-content": "qsr caption-bold",
  "active": "qsb",
  "empty": "qsy",
  "align-right": "qsU",
  "type": "qsj",
  "requester": "qsW",
  "request-date": "qsu",
  "amount": "qFS",
  "status": "qFc"
};
