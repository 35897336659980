export default {
  "card": "cTD",
  "header": "cTV",
  "avatar": "cTL",
  "title": "cTT",
  "subtitle": "cTz",
  "label": "cTa",
  "item": "cTH body-2",
  "header-icon": "cTO",
  "mt-8": "cTm"
};
