/* import __COLOCATED_TEMPLATE__ from './transfer-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { not } from 'macro-decorators';

import { Slug } from 'qonto/react/components/transactions/sidebar/details/slug';

export default class TransactionsSidebarTransferDetailsComponent extends Component {
  slug = Slug;

  @not('args.transaction.declined') hasAccountingValue;

  @service abilities;
  @service intl;
  @service modals;
  @service organizationManager;

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.attachmentModal && !this.attachmentModal.result) {
      this.attachmentModal.close();
    }
  }

  get showAttachments() {
    return this.hasAccountingValue;
  }

  get showBookkeeping() {
    return this.abilities.can('view vat bookkeeping') && this.hasAccountingValue;
  }

  get showAnalyticLabels() {
    return (
      this.abilities.can('access custom-label') &&
      this.abilities.can('read custom-label') &&
      this.hasAccountingValue
    );
  }

  get showBudget() {
    let { transaction, isBudgetSupervisor } = this.args;

    return (
      this.abilities.can('update transaction budget') &&
      transaction.debit &&
      isBudgetSupervisor &&
      this.hasAccountingValue
    );
  }

  @action
  handleOpenDetails() {
    this.handleShowAttachment();
  }

  @action
  handleShowAttachment(file) {
    let selectedFile = file || this.args.transaction.attachments?.[0]?.file;

    this.attachmentModal = this.modals.open(
      'attachments/attachment-viewer-modal',
      {
        isFullScreenModal: true,
        hasSidebar: true,
        title: this.intl.t('transactions.sidebar.bookkeeping.bookkeeping-details'),
        transaction: this.args.transaction,
        selectedFile,
        onDownload: (...args) => this.args.saveAttachment(this.args.transaction, ...args),
        onRemove: (...args) => this.args.confirmRemoveAttachment(...args),
        onSaveAttachment: (...args) => this.args.saveAttachment(...args),
        onSaveLabel: (...args) => this.args.saveLabel(...args),
        onSelectTag: (...args) => this.args.selectTag(...args),
        onSave: (...args) => this.args.saveTransaction(...args),
        markAsReviewTask: (...args) => this.args.markAsReviewTask.perform(...args),
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }
}
