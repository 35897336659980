export default {
  "mileage-validation": "cbV",
  "actions": "cbL",
  "action-btn": "cbT",
  "action-btn-success": "cbz",
  "action-btn-right": "cba",
  "request-mileage-validation": "cbH",
  "account-selector": "cbO",
  "has-error": "cbm",
  "funds-disclaimer": "cbp",
  "account-options": "cbx",
  "account-balance": "cbw",
  "warnings": "cbN"
};
