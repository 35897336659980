/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action, get } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SkeletonLoader } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';

import { NO_PERIOD_ID } from 'qonto/constants/budget';
import { OPERATION_TYPES, STATUS } from 'qonto/constants/transfers';
import { CashflowCategoryManager } from 'qonto/react/components/transactions/sidebar/category/cashflow-category';
import { TransactionsSidebarFooter } from 'qonto/react/components/transactions/sidebar/footer';
import periodOptions from 'qonto/routes/transactions/index/period-options';
import { ErrorInfo } from 'qonto/utils/error-info';
import { isTaskCancelation } from 'qonto/utils/ignore-error';

export default class TransactionsSidebarComponent extends Component {
  @service store;
  @service abilities;
  @service intl;
  @service sentry;
  @service toastFlashMessages;
  @service segment;
  @service organizationManager;
  @service categorizationRulesManager;
  @service internationalOutManager;

  @tracked timeline;

  lastHighlightedItemId;
  TransactionsSidebarFooter = TransactionsSidebarFooter;
  CashflowCategoryManager = CashflowCategoryManager;
  skeletonLoader = SkeletonLoader;

  constructor() {
    super(...arguments);

    if (this.args.showSuggestedAttachments) {
      // Needed here to avoid an attempt to modify the showSuggestedAttachments property after it has already been used within the same rendering cycle.
      next(() => this.args.handleShowSuggestedAttachments());
    }
  }

  /**
   * Determines whether the timeline should be displayed for international transfers
   */

  get shouldShowTimeline() {
    let subject = this.args.highlightedItem.subject;
    let isNotCanceledOrDeclined = ![STATUS.CANCELED, STATUS.DECLINED].includes(
      get(subject, 'status')
    );
    let isInternationalOut = get(subject, 'operationType') === OPERATION_TYPES.INTERNATIONAL_OUT;

    return isNotCanceledOrDeclined && isInternationalOut;
  }

  /**
   * Task to load and manage timeline data
   */
  loadTimelineTask = dropTask(async () => {
    if (!this.shouldShowTimeline) {
      return;
    }

    try {
      this.timeline = await this.internationalOutManager.getTransferTimeline(
        get(this.args.highlightedItem.subject, 'id')
      );
    } catch {
      this.timeline = null;
    }
  });

  get activeCategoryId() {
    return this.categorizationRulesManager.getActiveCategoryId([this.args.highlightedItem]);
  }

  get isLoadingAttachmentSectionElements() {
    return this.fetchAttachmentsTask.isRunning;
  }

  @action
  saveLabel(labelList, label, source) {
    this.args.saveLabel(labelList, label, source ?? 'transaction_details');
  }

  @action
  trackToggleShowMore() {
    this.segment.track('transaction_analytic_label_expand_clicked', {
      source: 'transaction_details',
    });
  }

  shouldTriggerTask(highlightedItem) {
    if (highlightedItem.id !== this.lastHighlightedItemId) {
      this.lastHighlightedItemId = highlightedItem.id;
      return true;
    }
    return false;
  }

  fetchAttachmentsTask = dropTask(async transaction => {
    await this.store.query('attachment', {
      organization_id: this.organizationManager.organization.id,
      filters: { ids: transaction.attachmentIds },
    });
  });

  fetchPeriodOptionsTask = dropTask(async transaction => {
    let transactionId = transaction.id;
    if (this.abilities.can('update transaction budget')) {
      let [allocatablePeriods, allocatedPeriod] = await Promise.all([
        this.store.adapterFor('budget').allocatablePeriods({ transactionId }),
        this.store.adapterFor('budget').allocatedPeriod(transactionId),
      ]);
      let result = periodOptions(allocatablePeriods, allocatedPeriod);

      if (result.options.length === 0) {
        return {
          options: [
            { name: this.intl.t('team-budgets.allocation.no-budget-available'), disabled: true },
          ],
          selectedOption: null,
        };
      } else {
        result.options.push({
          name: this.intl.t('team-budgets.allocation.no-budget'),
          id: NO_PERIOD_ID,
        });
      }
      return result;
    } else {
      return { options: [], selectedOption: null };
    }
  });

  handleError(error) {
    if (!isTaskCancelation(error)) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(
          new Error(
            `Transaction related attachments and period options fetch fails with status ${error.status}`,
            { cause: error }
          )
        );
      }
    }
  }

  enrichTransactionTask = task(async highlightedItem => {
    if (!this.shouldTriggerTask(highlightedItem)) {
      return;
    }
    await Promise.all([
      this.fetchAttachmentsTask.perform(highlightedItem).catch(error => this.handleError(error)),
      this.fetchPeriodOptionsTask.perform(highlightedItem).catch(error => this.handleError(error)),
    ]);
  });

  transactionDataTask = trackedTask(this, this.enrichTransactionTask, () => [
    this.args.highlightedItem,
  ]);
}
