/* import __COLOCATED_TEMPLATE__ from './validation.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask, task, waitForQueue } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { KYC_STATUS, ROLES } from 'qonto/constants/membership';
import { REQUEST_TYPES } from 'qonto/constants/requests';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class RequestValidation extends Component {
  @service abilities;
  @service intl;
  @service router;
  @service segment;
  @service toastFlashMessages;
  @service userManager;
  @service organizationManager;
  @service modals;
  @service sensitiveActions;
  @service zendeskLocalization;

  @tracked canShowInsufficientWarning = null;
  @tracked selectedAccountHasInsufficientFunds = false;

  close() {
    this.args.close?.();
  }

  @reads('args.request.errors.status.0') errorStatus;

  @reads('organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts')
  activeAccounts;
  @reads('organizationManager.organization.hasMultipleActiveCurrentRemuneratedAccounts')
  hasMultipleActiveCurrentRemuneratedAccounts;
  @reads('organizationManager.membership.kycSubmitted') kycSubmitted;
  @reads('organizationManager.membership.personalInfoProvided') personalInfoProvided;

  constructor() {
    super(...arguments);

    this.handleInsufficientWarningFlagTask.perform().catch(ignoreCancelation);
  }

  get hasInsuficientFunds() {
    let { totalTransfersAmount, bankAccount } = this.args.request;

    return (
      totalTransfersAmount > bankAccount.authorizedBalance ||
      this.selectedAccountHasInsufficientFunds
    );
  }

  get insufficientFundsMessage() {
    return this.isManager
      ? this.intl.t('transfers.errors.manager.multi_insufficient_funds')
      : this.intl.t('transfers.errors.multi_insufficient_funds');
  }

  get canShowAccountSelector() {
    let { spendLimitsWarning } = this.args;

    return (
      !this.kycNotApproved &&
      this.abilities.can('review transfer request') &&
      !this.args.request.hasPastEndingDate &&
      this.hasMultipleActiveCurrentRemuneratedAccounts &&
      !spendLimitsWarning &&
      this.args.request.lastStep
    );
  }

  get isDestinationAccountSameIbanAsOrigin() {
    let { confirmErrors = [] } = this.args;
    let isSameIBAN = confirmErrors.includes('same_iban_error');

    return isSameIBAN;
  }

  get confirmErrors() {
    let { confirmErrors = [] } = this.args;

    let errorMessages = [];

    for (let confirmError of confirmErrors) {
      if (confirmError === 'biller_insufficient_funds') {
        errorMessages.push(this.intl.t('validations.errors.insufficient_funds'));
      }

      if (confirmError === 'expired') {
        errorMessages.push(this.intl.t('validations.errors.expired-request'));
      }

      if (confirmError === 'iban_sepa_error') {
        errorMessages.push(
          this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.body', {
            transferFaqLink: htmlSafe(
              `<a
              href="${this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.url', {
                faqUrl: this.zendeskLocalization.getLocalizedArticle(4359546),
              })}"
              target="_blank"
              rel="noopener noreferrer"
              data-test-beneficiary-not-sepa-link
              >${this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.link-text')}</a>`
            ),
            htmlSafe: true,
          })
        );
      }
    }

    return errorMessages;
  }

  get spendLimitWarningTranslation() {
    let { spendLimitsWarning, spendLimits } = this.args;
    let canReadSpendLimitWarnings = Boolean(this.isManager && spendLimitsWarning && spendLimits);

    if (!canReadSpendLimitWarnings) return null;

    if (spendLimitsWarning === 'insufficient_per_transfer_limits') {
      return this.intl.t('requests.transfers.sidebar.disclaimers.per-transfer-limit', {
        per_transfer_limit: this.intl.formatNumber(spendLimits.per_transfer_limit.value),
      });
    } else if (spendLimitsWarning === 'insufficient_monthly_limits') {
      return this.intl.t('requests.transfers.sidebar.disclaimers.monthly-transfer-limit', {
        balance_monthly_transfer_limit: this.intl.formatNumber(
          Number(spendLimits.monthly_transfer_limit.value) -
            Number(spendLimits.current_month_spendings.value)
        ),
      });
    }
  }

  get showTransferRequestSpendLimitWarning() {
    let { spendLimitsWarning, request, isMultiTransfer } = this.args;

    return (
      spendLimitsWarning?.length > 0 &&
      this.abilities.cannot('view own request info disclaimer transfer-limit', request) &&
      !isMultiTransfer &&
      !this.kycNotApproved
    );
  }

  get isApproveCTADisabled() {
    let { loadingRequest, request, confirmErrors, spendLimitsWarning } = this.args;
    let insufficientWarningCase =
      this.hasInsufficientFundWarning && this.canShowInsufficientWarning;

    return (
      this.kycNotApproved ||
      loadingRequest ||
      request.hasPastEndingDate ||
      insufficientWarningCase ||
      confirmErrors?.length > 0 ||
      spendLimitsWarning?.length > 0 ||
      this.hasInsuficientFunds
    );
  }

  get isDeclineCTADisabled() {
    return this.kycNotApproved;
  }

  get kycNotApproved() {
    let { kycRequired, kycStatus } = this.organizationManager.membership;

    return kycRequired && kycStatus !== KYC_STATUS.ACCEPTED;
  }

  get warnings() {
    let warningMessages = [];

    let { hasRequestMultiTransfer, hasPastDate, hasPastEndingDate, operationType } =
      this.args.request;

    if (hasPastEndingDate && !hasRequestMultiTransfer) {
      let warningMessage;
      let initiator = this.args.request.get('initiator');
      let initiatorName = `${initiator.get('firstName')} ${initiator.get('lastName')}`;
      warningMessage = this.intl.t('requests.warnings.approver_single_ending_date', {
        requester_name: initiatorName,
      });
      warningMessages.push({ warningMessage, warningType: 'standingPastEndingDate' });
    } else if (hasPastDate) {
      let warningMessage;
      if (hasRequestMultiTransfer) {
        warningMessage = this.abilities.can('create transfer request')
          ? this.intl.t('requests.warnings.requester_bulk_past_date')
          : this.intl.t('requests.warnings.approver_bulk_past_date');
      } else {
        warningMessage = this.abilities.can('create transfer request')
          ? this.intl.t('requests.warnings.requester_single_past_date')
          : this.intl.t('requests.warnings.approver_single_past_date');
      }

      warningMessages.push({ warningMessage, warningType: 'standingPastDate' });
    }

    let { confirmWarnings = [] } = this.args;

    if (this.canShowAccountSelector) {
      // filter out insufficient_fund errors, because warning shown on dropdown itself
      confirmWarnings = confirmWarnings.filter(
        ({ warningType }) => warningType !== 'insufficient_funds'
      );
    }

    if (
      !this.canShowAccountSelector &&
      confirmWarnings?.some(({ warningType }) => warningType === 'top_up_required')
    ) {
      confirmWarnings = confirmWarnings.filter(
        ({ warningType }) => warningType !== 'top_up_required'
      );

      let warningMessage = this.intl.t('requests.warnings.insufficient_funds');

      confirmWarnings.push({ warningMessage, warningType: 'topUpRequired' });
    }

    if (operationType === 'scheduled') {
      // filter out top_up_required errors if the request is not scheduled for later
      confirmWarnings = confirmWarnings.filter(
        ({ warningType }) => warningType !== 'top_up_required'
      );
    }

    let insufficientFundsWarning = confirmWarnings.find(
      ({ warningType }) => warningType === 'insufficient_funds'
    );
    if (this.isManager && insufficientFundsWarning) {
      insufficientFundsWarning.warningMessage = this.intl.t(
        'requests.warnings.manager.insufficient_funds'
      );
    }

    return [...warningMessages, ...confirmWarnings];
  }

  get hasInsufficientFundWarning() {
    return (
      !this.args.loadingRequest &&
      (this.args.confirmWarnings?.some(({ warningType }) => warningType === 'insufficient_funds') ||
        (this.args.confirmWarnings?.some(({ warningType }) => warningType === 'top_up_required') &&
          (this.args.request.hasPastDate || this.args.request.operationType === 'scheduled')))
    );
  }

  get insufficientFundsMultiAccountError() {
    return this.isManager
      ? this.intl.t('transfers.warnings.manager.insufficient_funds_multi_account')
      : this.intl.t('transfers.warnings.insufficient_funds_multi_account');
  }

  get isManager() {
    return this.organizationManager.membership.role === ROLES.MANAGER;
  }

  get cancelLabel() {
    return this.args.isMultiTransfer
      ? this.intl.t('btn.cancel_all')
      : this.intl.t('btn.cancel_request');
  }

  get declineLabel() {
    return this.args.isMultiTransfer ? this.intl.t('btn.decline_all') : this.intl.t('btn.decline');
  }

  get approveLabel() {
    if (this.args.isMultiTransfer) {
      return this.intl.t('btn.approve_all');
    }

    return this.args.request.lastStep
      ? this.intl.t('requests.transfers.sidebar.actions.approve')
      : this.intl.t('btn.approve');
  }

  get canCancelRequest() {
    let { isMultiTransfer, request } = this.args;

    let initiatorId = request.get('initiator.id');

    if (isMultiTransfer) {
      return (
        initiatorId === this.organizationManager.membership.id ||
        this.abilities.can('view own request info disclaimer transfer-limit', request)
      );
    }

    let initiatorUserId = request.get('initiator.userId');

    return (
      this.abilities.can('cancel transfer request', initiatorUserId) ||
      this.abilities.can('view own request info disclaimer transfer-limit', request)
    );
  }

  get canDeclineRequest() {
    let { isMultiTransfer, request } = this.args;

    if (isMultiTransfer) {
      return request.initiator.get('id') !== this.organizationManager.membership.id;
    }

    return true;
  }

  get canReviewRequest() {
    let { request } = this.args;
    let isTransferOrReimbursement = [
      REQUEST_TYPES.TRANSFER,
      REQUEST_TYPES.MILEAGE,
      REQUEST_TYPES.EXPENSE_REPORT,
    ].includes(request.requestType);

    if (isTransferOrReimbursement && request.lastStep) {
      let canCreateTransfer = this.abilities.can('create transfer');
      return (
        this.abilities.can('review transfer request') &&
        this.abilities.cannot('view own request info disclaimer transfer-limit', request) &&
        canCreateTransfer
      );
    }

    return (
      this.abilities.can('review transfer request') &&
      this.abilities.cannot('view own request info disclaimer transfer-limit', request)
    );
  }

  approveTask = dropTask(async () => {
    await this.args.approve.linked().perform(this.args.request);
  });

  @action
  handleClick() {
    let params = {
      member_id: this.organizationManager.organization.id,
      user_id: this.userManager.currentUser.id,
      request_id: this.args.request.id,
      insufficient_funds_error: this.hasInsufficientFundWarning,
    };

    let trackingEvent = this.args.isMultiTransfer
      ? 'bulk_transfer_request_approved'
      : 'single_transfer_request_approved';
    this.segment.track(trackingEvent, params);

    this.sensitiveActions.runTask.perform(this.approveWithConditionTask).catch(ignoreCancelation);
  }

  cancelRequestTask = dropTask(async () => {
    let result = await this.modals.open('request/transfer/modals/cancel', {
      request: this.args.request,
      invoice: this.args.invoice,
      isMultiTransfer: this.args.isMultiTransfer,
    });

    if (result === 'success') {
      this.args.cancel?.();
    }

    this.close();
  });

  @action
  declineRequest() {
    let eventName = this.args.isMultiTransfer
      ? 'bulk_transfer_request_declined'
      : 'single_transfer_request_declined';
    this.segment.track(
      eventName,
      this.getSegmentData({ insufficient_funds_error: this.hasInsufficientFundWarning })
    );

    this.modals.open('request/sidebar/modals/decline-request', {
      request: this.args.request,
      confirmTask: this.declineTask,
    });
  }

  declineTask = dropTask(async close => {
    await this.args.decline(this.args.request);
    close();

    if (!this.args.invoice) {
      this.toastFlashMessages.toastInfo(this.intl.t('toasts.request_declined'), 'transfer_create');
    }
    this.close();
  });

  approveRequestTask = dropTask(async () => {
    try {
      let { isMultiTransfer, request } = this.args;

      let message = this.intl.t('toasts.request_approved', {
        count: isMultiTransfer ? 2 : 1,
      });

      if (!request.once) {
        message = request.hasPastDate
          ? this.intl.t('toasts.request_scheduled_past_date_approved')
          : this.intl.t('toasts.request_scheduled_approved');
      }

      if (!request.lastStep) {
        message = this.intl.t('approval-workflows.requests.approved.toast.success');
      }

      await this.approveTask.linked().perform();

      if (!this.args.invoice) {
        this.toastFlashMessages.toastSuccess(message, 'transfer_create');
      }
      this.close();
    } catch (error) {
      if (error.errors?.some(err => err.detail?.code === 'insufficient_funds')) {
        if (this.modals.count) {
          this.modals.top.close();
        }

        this.selectedAccountHasInsufficientFunds = true;

        return this.toastFlashMessages.toastInfo(this.insufficientFundsMessage);
      }

      if (hasMFAError(error?.errors)) {
        throw error;
      }
    }
  });

  approveWithConditionTask = dropTask(async () => {
    if (this.hasInsufficientFundWarning && this.canShowInsufficientWarning === false) {
      this.canShowInsufficientWarning = true;
      return;
    }

    await this.approveRequestTask.linked().perform();
  });

  declineRequestTask = dropTask(async () => {
    await this.declineTask.perform();

    this.toastFlashMessages.toastInfo(this.intl.t('toasts.request_declined'), 'transfer_create');
    this.close();
  });

  getSegmentData(additionalData) {
    return {
      request_id: this.args.request.id,
      user_id: this.userManager.currentUser.id,
      member_id: this.organizationManager.organization.id,
      ...additionalData,
    };
  }

  handleInsufficientWarningFlagTask = task(async () => {
    await waitForQueue('afterRender');
    this.canShowInsufficientWarning = !this.canShowAccountSelector;
  });

  @action
  handleSetAccount(account) {
    this.selectedAccountHasInsufficientFunds = false;
    this.canShowInsufficientWarning = !this.canShowAccountSelector;
    this.args.setAccount(this.args.request, account);

    let eventName = this.args.isMultiTransfer
      ? 'bulk_transfer_request_account_selected'
      : 'single_transfer_request_account_selected';
    this.segment.track(eventName, this.getSegmentData({ bank_account_id: account.id }));
  }

  @action
  handleShowDeclineModal() {
    this.showDeclineModal = true;
    let eventName = this.args.isMultiTransfer
      ? 'bulk_transfer_request_declined'
      : 'single_transfer_request_declined';
    this.segment.track(
      eventName,
      this.getSegmentData({ insufficient_funds_error: this.hasInsufficientFundWarning })
    );
  }

  @action
  handleReloadRequest() {
    if (this.args.isMultiTransfer) {
      this.router.transitionTo('requests.past.multi-transfer-detail', this.args.request.id);
    } else {
      this.router.transitionTo('requests.past', {
        queryParams: { highlight: this.args.request.id },
      });
    }
  }
}
