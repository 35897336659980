/* import __COLOCATED_TEMPLATE__ from './notifications-settings.hbs'; */
import { notifyPropertyChange } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Toggle } from '@repo/design-system-kit';
import fade from 'ember-animated/transitions/fade';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, subscriptionNamespace } from 'qonto/constants/hosts';
import {
  NOTIFICATION_CATEGORIES,
  NOTIFICATION_CATEGORIES_ORDER,
} from 'qonto/constants/notifications';
import { DEBOUNCE_MS } from 'qonto/constants/timers';

export default class NotificationsSettings extends Component {
  @service networkManager;
  @service store;
  @service abilities;

  toggle = Toggle;

  @tracked opened = false;

  transition = fade;

  get subscriptions() {
    if (variation('feature--boolean-login-improvement')) {
      return this.organization.subscriptions;
    }

    return this.args.membership.subscriptions;
  }

  get organization() {
    if (variation('feature--boolean-login-improvement')) {
      return this.args.organization;
    }

    return this.args.membership.organization;
  }

  get notificationCategories() {
    let categories = {};

    this.subscriptions.forEach(subscription => {
      let { catCode, code } = subscription;

      if (
        catCode === NOTIFICATION_CATEGORIES.BUDGETS &&
        (this.abilities.cannot('access budget') || this.abilities.cannot('read details budget'))
      ) {
        return;
      }

      if (
        catCode === NOTIFICATION_CATEGORIES.CHECK &&
        !this.organization.get('features').includes('checks')
      ) {
        return;
      }

      if (
        code === 'account_activity_matching' &&
        this.abilities.cannot('access email forward attachment')
      ) {
        return;
      }

      if (
        code === 'account_activity_comments' &&
        !variation('feature--boolean-redesigned-transactions-table')
      ) {
        return;
      }

      if (!categories[catCode]) {
        categories[catCode] = [];
      }
      categories[catCode].push(subscription);
    });

    return this.sortCategories(categories);
  }

  sortCategories(categories) {
    let sortedCategories = {};

    NOTIFICATION_CATEGORIES_ORDER.forEach(category => {
      if (categories[category]) {
        sortedCategories[category] = categories[category];
      }
    });

    return sortedCategories;
  }

  get hasSubscriptions() {
    let subscription = this.subscriptions.find(subscription => {
      let { email, push } = subscription.devices;
      return email || push;
    });

    return Boolean(subscription);
  }

  onHeaderClick = dropTask(async organization => {
    this.opened = !this.opened;

    if (this.opened && variation('feature--boolean-login-improvement')) {
      await Promise.all([
        this.loadSubscriptionsTask.perform(organization),
        this.store.findRecord('organization', organization.id),
      ]);
    }
  });

  saveSubscriptionTask = dropTask(async () => {
    await Promise.all(
      this.subscriptions.map(subscription =>
        subscription.save({
          adapterOptions: {
            headers: { 'X-Qonto-Organization-ID': this.organization.id },
          },
        })
      )
    );
  });

  handleToggleUpdateTask = restartableTask(async () => {
    let value = !this.hasSubscriptions;
    this.subscriptions.forEach(subscription => {
      subscription.devices.email = value;
      subscription.devices.push = value;
    });

    if (variation('feature--boolean-login-improvement')) {
      notifyPropertyChange(this.args.organization, 'subscriptions');
    } else {
      notifyPropertyChange(this.args.membership, 'subscriptions');
      next(() => (this.opened = value));
    }

    await timeout(DEBOUNCE_MS);

    await this.saveSubscriptionTask.perform();
  });

  loadSubscriptionsTask = dropTask(async organization => {
    let subscriptionsPayload = await this.networkManager.request(
      `${apiBaseURL}/${subscriptionNamespace}/subscriptions`,
      {
        headers: { 'X-Qonto-Organization-ID': organization.id },
      }
    );

    subscriptionsPayload.subscriptions.forEach(subscription => {
      subscription.organization_id = organization.id;
      delete subscription.membership_id;
    });

    this.store.pushPayload(subscriptionsPayload);

    return subscriptionsPayload;
  });
}
