/* import __COLOCATED_TEMPLATE__ from './show-by-cookie.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

export const COOKIE_MAX_AGE_SEC = 31622400; // number of seconds in 12 months

export const SHOW_BY_COOKIE_RULES = {
  FIRST_OF_NEXT_MONTH: 'first-of-next-month',
  NEXT_YEAR: 'next-year',
};

/* This component is a simple wrapper to show a block depending on a cookie existence
For instance: show a banner by default, ans set a cookie when the user dismisses it so the banner does not spam them */
export default class ShowByCookieComponent extends Component {
  @service cookies;

  @tracked show = Boolean(!this.cookies.read(this.args.cookieId));

  @action
  close() {
    this._writeByRule();
    this.show = false;
    this.args.onClose?.();
  }

  _writeByRule() {
    /* Only 2 rules are possible. If more rules are needed in the future,
    please consider a refactor to pass the write functions in this.args
    instead of having them hard coded in the component. */
    switch (this.args.rule) {
      case SHOW_BY_COOKIE_RULES.FIRST_OF_NEXT_MONTH:
        this._writeFirstOfNextMonth();
        break;
      case SHOW_BY_COOKIE_RULES.NEXT_YEAR:
        this._writeNextYear();
        break;
    }
  }

  _writeFirstOfNextMonth() {
    let daysToEndOfMonth = dayjs().daysInMonth() - dayjs().date();
    let expires = dayjs().add(daysToEndOfMonth + 1, 'day');
    this.cookies.write(this.args.cookieId, true, {
      expires,
    });
  }

  _writeNextYear() {
    this.cookies.write(this.args.cookieId, true, {
      maxAge: COOKIE_MAX_AGE_SEC,
    });
  }
}
