export default {
  "details": "qle",
  "details-header": "qlB",
  "details-content": "qlE",
  "details-footer": "qld",
  "invoice-status-container": "qlI body-2",
  "invoice-dates": "qlt",
  "overdue": "qlM",
  "details-list": "qlP",
  "details-list-item": "qll",
  "details-list-item-label": "qlX body-2",
  "details-list-item-value": "qlC",
  "border-top": "qlk",
  "description": "qlJ",
  "description-value": "qls",
  "edit-button": "qlF body-2",
  "edit-icon": "qlg",
  "badge-union": "qlD",
  "edit-description": "qlV",
  "amount-disclaimer": "qlL",
  "strike-amount": "qlT"
};
