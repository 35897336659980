export default {
  "header-cell": "qQS",
  "header-content": "qQc caption-bold",
  "active": "qQq",
  "empty": "qQZ",
  "align-right": "qQR",
  "request-type": "qQQ",
  "requester": "qQe",
  "amount": "qQB"
};
