/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

export default class SubscriptionPromocodeFormComponent extends Component {
  badgeStatus = BadgeStatus;

  @service intl;

  titles = {
    initial: this.intl.t('subscription.promo-code.item.voucher-type.initial'),
    trial: this.intl.t('subscription.promo-code.item.voucher-type.free-trial'),
    subscription: this.intl.t('subscription.promo-code.item.voucher-type.subscription'),
    promotional: {
      physical_card_plus: this.intl.t('subscription.promo-code.item.voucher-type.promotional.plus'),
      physical_card_metal: this.intl.t(
        'subscription.promo-code.item.voucher-type.promotional.metal'
      ),
      additional_user: this.intl.t(
        'subscription.promo-code.item.voucher-type.promotional.additional-user'
      ),
    },
  };

  get title() {
    let { voucherType, voucherFeeType } = this.args.discount;
    if (voucherType === 'promotional') {
      return this.titles.promotional[voucherFeeType];
    } else {
      return this.titles[voucherType];
    }
  }
}
