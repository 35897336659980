export default {
  "modal": "cha",
  "action-container": "chH",
  "similar-label": "chO",
  "info-icon": "chm",
  "container": "chp",
  "subtitle": "chx caption-bold",
  "error": "chw",
  "item-container": "chN"
};
