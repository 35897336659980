/* import __COLOCATED_TEMPLATE__ from './cashflow.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { all, dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import buildQuery from 'qonto/utils/statistics';

export default class OverviewStatisticsCashflowComponent extends Component {
  @service store;
  @service organizationManager;
  @service sentry;

  @tracked cashflows = [];

  get isLoading() {
    return this.queryCashflowsTask.isRunning && this.queryCashflowsTask.performCount === 1;
  }

  get isUpdating() {
    return this.queryCashflowsTask.isRunning && this.queryCashflowsTask.performCount > 1;
  }

  get isError() {
    return this.getCashflowTask.last?.isError;
  }

  queryCashflowsTask = dropTask(
    async (aggregations, period, side, filters, bankAccounts, comparisonPeriod) => {
      try {
        await this.getCashflowTask
          .linked()
          .perform(aggregations, period, side, filters, bankAccounts, comparisonPeriod);
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(errorInfo.error);
        }
      }
    }
  );

  getCashflowTask = dropTask(
    async (aggregations, period, side, filters, bankAccounts, comparisonPeriod) => {
      let promises = [this._getPeriodCashflow(aggregations, period, side, filters, bankAccounts)];

      if (comparisonPeriod) {
        promises.push(
          this._getPeriodCashflow(aggregations, comparisonPeriod, side, filters, bankAccounts)
        );
      }

      this.cashflows = await all(promises);
    }
  );

  _getPeriodCashflow(aggregations, period, side, filters, bankAccounts) {
    let isPeriod = period.interval || period.startDate || period.endDate;

    if (isPeriod) {
      let organizationId = this.organizationManager.organization.id;
      let query = buildQuery(organizationId, aggregations, period, side, bankAccounts, filters);

      return this.store.adapterFor('transaction').getCashflow(query);
    }
  }
}
