export default {
  "summary-wrapper": "cGA",
  "summary-title": "cGY",
  "summary-list": "cGh",
  "summary-list-item-wrapper": "cGf",
  "summary-list-item-record": "cGK",
  "summary-list-item-avatar": "cGG",
  "summary-list-item-avatar-image": "cGr",
  "summary-list-item-name-count": "cGb",
  "item-count": "cGy"
};
