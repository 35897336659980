/* import __COLOCATED_TEMPLATE__ from './vat-rate.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ReceivableInvoicesFormVatRateComponent extends Component {
  @service intl;
  @service organizationManager;

  get value() {
    return this.args.vatRate || null;
  }

  get optionsVatRates() {
    return this.organizationManager.organization.vatRatesCategories.map(value => {
      let label = '';
      let clearable = false;
      if (value > 0) {
        label = `${this.intl.formatNumber(value)} %`;
      } else if (value === null) {
        label = this.intl.t('transactions.sidebar.vat.accounting.none');
      } else if (value === 0) {
        label = this.intl.t('transactions.sidebar.vat.accounting.no-rate');
      } else {
        label = this.intl.t('transactions.sidebar.vat.accounting.other');
        clearable = true;
      }

      return {
        clearable,
        label,
        value,
      };
    });
  }
}
