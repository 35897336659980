/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ProductDiscoveryUserActionsListComponent extends Component {
  @service segment;

  get userActions() {
    return this.args.userActions
      .map(userAction => {
        userAction.isDisabled = Boolean(
          userAction.blockedBy?.some(blocker =>
            this.args.userActions.some(action => action.name === blocker)
          )
        );
        return userAction;
      })
      .sort((a, b) => (!a.isDisabled && b.isDisabled ? -1 : 1));
  }

  @action
  handleCardDidConstruct(action) {
    this.segment.track('product-discovery_user-action_viewed', {
      name: action.name,
      status: action.isDisabled ? 'disabled' : action.status,
      price_plan_code: this.args.pricePlanCode,
    });
  }

  @action
  handleCtaClick(action) {
    this.segment.track('product-discovery_user-action_clicked', {
      name: action.name,
      price_plan_code: this.args.pricePlanCode,
    });
  }
}
