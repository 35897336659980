export default {
  "container": "cmJ",
  "label": "cms",
  "disabled": "cmF",
  "small": "cmg caption",
  "large": "cmD body-2",
  "caption": "cmV",
  "amount": "cmL",
  "right-aligned": "cmT",
  "expanded": "cmz",
  "amount--current": "cma",
  "amount--total": "cmH",
  "amount--sep": "cmO"
};
