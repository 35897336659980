export default {
  "row": "cWB",
  "active": "cWE",
  "dot": "cWd",
  "red": "cWI",
  "green": "cWt",
  "yellow": "cWM",
  "status": "cWP",
  "align-right": "cWl",
  "animated": "cWX",
  "fadein-item": "cWC",
  "cell": "cWk body-2",
  "cell-content": "cWJ",
  "amount": "cWs body-2",
  "subtitle": "cWF caption",
  "padding-left": "cWg",
  "padding-right": "cWD",
  "note": "cWV"
};
