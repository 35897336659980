export default {
  "section-container": "cUb",
  "disclaimer-section": "cUy",
  "trip-title": "cUU caption-bold mb-16",
  "google-link-container": "cUj",
  "link-icon": "cUW",
  "trip-details": "cUu body-2",
  "trip-details-icon": "cjS",
  "trip-details-title": "cjc",
  "subtitle": "cjq caption-bold mb-16",
  "disclaimer": "cjZ",
  "visualizer": "cjR"
};
