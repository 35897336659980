/* import __COLOCATED_TEMPLATE__ from './request-table.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class TransfersRequestTableComponent extends Component {
  @service router;

  get isApproveCompactView() {
    return this.args.isSingleAccountApprover && !this.args.isSidebarOpen;
  }
}
