/* import __COLOCATED_TEMPLATE__ from './invite.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
import { isValidEmail } from 'qonto/utils/email';
import { ErrorInfo } from 'qonto/utils/error-info';

const TIER_LEVEL = {
  ONE: 1,
  TWO: 2,
};

export default class ReferralInviteComponent extends Component {
  @service networkManager;
  @service organizationManager;
  @service toastFlashMessages;
  @service segment;
  @service intl;
  @service sentry;
  @service('errors') errorsService;

  @reads('organizationManager.organization.referralUrl') referralLink;
  referralMinimumTransactionAmount;

  @tracked email = null;
  @tracked errors = null;

  @action
  updateEmail(email) {
    this.errors = null;
    this.email = email;
  }

  get tier1Eligibility() {
    return this.#getTierProperties(TIER_LEVEL.ONE);
  }

  get tier2Eligibility() {
    return this.#getTierProperties(TIER_LEVEL.TWO);
  }

  get detailErrors() {
    let errorKey = !this.email
      ? 'empty-email'
      : !isValidEmail(this.email)
        ? 'incorrect-email'
        : this.errors[0].errors.email[0];
    return this.intl.t(`referral.errors.${errorKey}`, {
      emails: this.email,
      count: 1,
    });
  }

  #getTierProperties(tierLevel) {
    let { eligibility, reward_amount: referralRewardAmount } = this.args.eligibilities.find(
      eligibility => eligibility.tier === tierLevel
    );
    let { required_spent_amount: referralMinimumTransactionAmount } = eligibility;

    return {
      referralRewardAmount,
      referralMinimumTransactionAmount,
    };
  }

  sendReferralTask = dropTask(async () => {
    if (!isValidEmail(this.email)) {
      return (this.errors = true);
    }

    let organizationId = this.organizationManager.organization.id;
    let data = {
      referrals: { emails: [this.email], organization_id: organizationId },
    };
    let url = `${apiBaseURL}/${apiNamespace}/referrals`;

    try {
      await this.networkManager.request(url, {
        method: 'POST',
        data: JSON.stringify(data),
      });
      this.toastFlashMessages.toastSuccess(this.intl.t('referral.success.toast'));
    } catch (error) {
      if (error.responseJSON?.referrals) {
        this.errors = error.responseJSON.referrals;
      } else {
        if (ErrorInfo.for(error).shouldSendToSentry) this.sentry.captureException(error);
        this.toastFlashMessages.toastError(
          this.errorsService.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
        );
      }
    }
    this.segment.track('referral_share_click', { action: 'send_email' });
  });
}
