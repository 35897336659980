/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { CARD_LEVELS } from 'qonto/constants/cards';

export default class RequestSidebarCardHeaderComponent extends Component {
  @service intl;

  get pan() {
    return '•'.repeat(16);
  }

  get statusClass() {
    let { status } = this.args.request;

    if (status === 'declined' || status === 'canceled') {
      return 'danger';
    } else if (status === 'approved') {
      return 'success';
    } else if (status === 'pending') {
      return 'warning';
    }
  }

  get design() {
    let { requestType } = this.args.request;

    return requestType === 'virtual_card' ? CARD_LEVELS.VIRTUAL : CARD_LEVELS.FLASH;
  }

  get titleStatus() {
    let { approver, status } = this.args.request;
    let approverName;

    if (status === 'declined' || status === 'approved') {
      let firstName = approver.get('firstName');
      let lastName = approver.get('lastName');
      approverName = `${firstName.charAt(0)}. ${lastName}`;
    }

    if (status === 'declined') {
      return this.intl.t('requests.sidebar.header.status.declined', {
        approver_name: approverName,
      });
    } else if (status === 'approved') {
      return this.intl.t('requests.sidebar.header.status.accepted', {
        approver_name: approverName,
      });
    } else if (status === 'canceled') {
      return this.intl.t('requests.sidebar.header.status.canceled');
    } else if (status === 'pending') {
      return this.intl.t('requests.sidebar.header.status.pending');
    }
  }

  get lastActionDate() {
    let { createdAt, processedAt, status } = this.args.request;

    if (status === 'pending') {
      return this.intl.t('requests.sidebar.header.created-date', {
        created_at: dateToken({
          date: createdAt,
          locale: this.intl.primaryLocale,
          token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
        }),
      });
    }

    return this.intl.t('requests.sidebar.header.updated-date', {
      updated_at: dateToken({
        date: processedAt,
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      }),
    });
  }
}
