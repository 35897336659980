export default {
  "cell": "qkC",
  "cell-label-container": "qkk",
  "cell-total-amount": "qkJ",
  "cell-label": "qks",
  "overflow": "qkF",
  "sub-label": "qkg body-2",
  "overdue": "qkD",
  "file-name-warning": "qkV",
  "missing": "qkL",
  "pending-icon": "qkT",
  "icon": "qkz",
  "flex-label": "qka",
  "with-separator": "qkH",
  "self-invoice-icon": "qkO",
  "tooltip-wrapper": "qkm"
};
