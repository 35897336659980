/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class ReimbursementsRequestsTable extends Component {
  @action
  handleKeydown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.args.changeRow(event);
    }
  }
}
