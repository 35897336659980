export default {
  "fileupload": "qNZ",
  "file": "qNR",
  "label": "qNQ",
  "zone": "qNe",
  "error": "qNB",
  "hide": "qNE",
  "dropzone": "qNd",
  "hidden": "qNI",
  "upload-icon": "qNt",
  "dropping": "qNM",
  "bounce2": "qNP",
  "error-message": "qNl"
};
