/* import __COLOCATED_TEMPLATE__ from './transfer.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class RequestMultiTransferTableCellTransferComponent extends Component {
  @service intl;

  get icon() {
    let { request } = this.args;
    let status = request.get('status');
    let hasPastDate = request.hasPastDate;

    if (status === 'pending') {
      if (request.get('once')) {
        return 'feature_transfer_direct-m';
      } else if (request.get('later')) {
        return hasPastDate ? 'status_warning-m' : 'feature_transfer_scheduled-m';
      }
      return hasPastDate ? 'status_warning-m' : 'feature_transfer_recurring-m';
    } else if (status === 'approved') {
      return 'status_check-m';
    }

    return 'status_stop-m';
  }

  get title() {
    let { request } = this.args;

    let options = {
      beneficiary: request.get('beneficiaryName'),
    };

    if (request.later) {
      return this.intl.t('labels.transfer_to_scheduled', options);
    } else {
      return this.intl.t('labels.transfer_to', options);
    }
  }

  get requestFromInitiator() {
    let { request } = this.args;

    let firstName = request.get('initiator.firstName');
    let lastName = request.get('initiator.lastName');

    return this._getRequestFrom(firstName, lastName);
  }

  get requestFromApprover() {
    let { request } = this.args;

    let firstName = request.get('approver.firstName');
    let lastName = request.get('approver.lastName');

    return this._getRequestFrom(firstName, lastName);
  }

  _getRequestFrom(firstName, lastName) {
    let label = `${this.intl.t('labels.request_from')} ${firstName} ${lastName} - `;
    if (label.length > 25 && firstName) {
      return `${this.intl.t('labels.request_from')} ${firstName.substr(0, 1)}. ${lastName} - `;
    }

    return label;
  }
}
