export default {
  "header-cell": "cfO",
  "empty": "cfm",
  "header-content": "cfp caption-bold",
  "active": "cfx",
  "align-right": "cfw",
  "col-8": "cfN",
  "col-7": "cfv",
  "col-5": "cfo",
  "col-4": "cfn"
};
