/* import __COLOCATED_TEMPLATE__ from './q-cropper-preview.hbs'; */
import { guidFor } from '@ember/object/internals';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task, waitForQueue } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class QCropperPreview extends Component {
  @tracked previewHeight;
  @tracked previewWidth;
  cropContainer = `crop-container-${guidFor(this)}`;

  constructor() {
    super(...arguments);
    this.setPreviewDimensionsTask.perform().catch(ignoreCancelation);
  }

  setPreviewDimensionsTask = task(async () => {
    await waitForQueue('afterRender');
    let element = document.getElementById(this.cropContainer);
    let { crop } = this.args;
    if (crop) {
      let { width, height } = crop.cropData;
      let aspectRatio = width / height;
      this.previewWidth = element.getBoundingClientRect().width;
      this.previewHeight = this.previewWidth / aspectRatio;
    }
  });

  get previewStyle() {
    let { crop } = this.args;
    if (crop) {
      let { cropData, imageData } = crop;
      let { width, x, y } = cropData;
      let scaleRatio = width / this.previewWidth;

      let cropWidth = `${imageData.naturalWidth / scaleRatio}px`;
      let cropHeight = `${imageData.naturalHeight / scaleRatio}px`;
      let cropMarginLeft = `${-(x / scaleRatio)}px`;
      let cropMarginTop = `${-(y / scaleRatio)}px`;

      return htmlSafe(
        [
          `width: ${cropWidth}`,
          `height: ${cropHeight}`,
          `margin-left: ${cropMarginLeft}`,
          `margin-top: ${cropMarginTop}`,
        ].join(';')
      );
    }
  }

  get wrapperStyle() {
    return htmlSafe(`height:${this.previewHeight}px`);
  }
}
