/* import __COLOCATED_TEMPLATE__ from './react-bridge-with-providers.hbs'; */
import { CustomProviders } from 'qonto/react/react-bridge/custom-providers';
import ReactBridge from '@qonto/react-migration-toolkit/components/react-bridge';
import { service } from '@ember/service';

export default class ReactBridgeWithProviders extends ReactBridge {
  @service launchdarkly!: { allFlags: object };
  customProvidersComponent = CustomProviders;

  get customProvidersProps() {
    return {
      ldFlags: this.launchdarkly.allFlags,
    };
  }
}
