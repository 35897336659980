export default {
  "card": "coD",
  "error": "coV",
  "customer-info": "coL",
  "edit-customer-tooltip": "coT",
  "title": "coz",
  "flex-row": "coa",
  "dot-separator": "coH",
  "flex-span": "coO",
  "faq-link": "com",
  "tooltip-wrapper": "cop",
  "badge-union": "cox"
};
