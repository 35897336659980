/* import __COLOCATED_TEMPLATE__ from './about.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SavingsAccountsStepsAboutComponent extends Component {
  @service segment;

  @action
  handleNext() {
    this.segment.track('savings_about_cta');
    this.args.transitionToNext();
  }
}
