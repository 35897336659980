export default {
  "container": "cHS",
  "indicator-container": "cHc",
  "transactions-container": "cHq",
  "indicator": "cHZ",
  "cashflow-container": "cHR",
  "cashflow": "cHQ",
  "donut-container": "cHe",
  "donut": "cHB"
};
