/* import __COLOCATED_TEMPLATE__ from './dropzone.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SupplierInvoicesDetailsComponent extends Component {
  @service segment;

  @action
  handleDrop() {
    this.args.onDragAndDropStopped();
    this.segment.track('supplier-invoices_drag-and-drop_file-dropped');
  }
}
