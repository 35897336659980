export default {
  "document": "chk",
  "document-details": "chJ",
  "document-details-amount": "chs body-2",
  "negative": "chF",
  "positive": "chg",
  "rejected": "chD",
  "document-icon": "chV",
  "active": "chL",
  "document-details-link": "chT body-link",
  "canceled": "chz"
};
