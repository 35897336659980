/* import __COLOCATED_TEMPLATE__ from './input.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

export default class Input extends Component {
  guid = guidFor(this);

  @action
  handleKeypressOpenFileBrowser(e) {
    e.target.click();
  }
}
