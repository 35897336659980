export default {
  "row": "qaX",
  "col": "qaC",
  "header": "qak",
  "block": "qaJ",
  "col-5": "qas",
  "col-6": "qaF",
  "col-9": "qag",
  "col-12": "qaD",
  "hide": "qaV"
};
