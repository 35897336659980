export default {
  "wrapper": "qBJ",
  "header": "qBs mb-48",
  "form-title": "qBF title-2 mb-16",
  "form-subtitle": "qBg body-2",
  "form": "qBD",
  "section": "qBV mb-48",
  "section-title": "qBL title-3 mb-16",
  "form-footer": "qBT",
  "footer-btn": "qBz",
  "transfer-form": "qBa"
};
