/* import __COLOCATED_TEMPLATE__ from './text-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import isFunction from 'qonto/utils/is-function';

export default class TransfersInternationalOutDynamicFormTextFieldComponent extends Component {
  get #shouldRefreshOnChange() {
    let { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  onFocusOut() {
    let { field, onFocusOut, onRefresh } = this.args;

    onFocusOut(field.key);

    if (this.#shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }
}
