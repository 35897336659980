export default {
  "row": "qwO",
  "col": "qwm",
  "counterparty": "qwp",
  "header": "qwx",
  "icon": "qww",
  "col-12": "qwN",
  "col-8": "qwv",
  "col-6": "qwo",
  "col-4": "qwn",
  "col-2": "qwi",
  "hide": "qwA"
};
