/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

export default class SelfBillingShowInvoiceModalSidebarComponent extends Component {
  tag = Tag;

  @service intl;

  get countryName() {
    let { countryCode } = this.args.invoice.supplierSnapshot;

    return countryCode ? this.intl.formatCountry(countryCode) : '';
  }
}
