/* import __COLOCATED_TEMPLATE__ from './toggle-accordion.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { PAY_LATER_FLOW_ORIGIN, PAY_LATER_VALIDATION_ERRORS } from 'qonto/constants/financing';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { PaymentDetails } from 'qonto/react/components/transfers/sepa/pay-later/toggle/payment-details';

export default class TransfersSepaPayLaterToggleAccordionComponent extends Component {
  paymentDetails = PaymentDetails;

  @service intl;
  @service segment;
  @service zendeskLocalization;

  constructor() {
    super(...arguments);

    if (!this.args.isDedicatedFlow) {
      this.segment.track('pay-later_toggle_displayed', {
        origin: this.trackingEventOrigin,
      });
    }

    if (this.isAvailable) {
      this.segment.track('pay_later-toggle_available');
    }
  }

  get trackingEventOrigin() {
    if (this.args.origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES) {
      return PAY_LATER_FLOW_ORIGIN.SUPPLIER_INVOICES;
    }

    return PAY_LATER_FLOW_ORIGIN.PAY_BY_INVOICE;
  }

  get isAvailable() {
    let { isDedicatedFlow, isUnavailable } = this.args;

    return !isUnavailable && !isDedicatedFlow && !this.isDisabled;
  }

  get isTooltipDisabled() {
    if (this.args.isDedicatedFlow) return true;

    return !this.isDisabled && !this.isReadOnly;
  }

  get isReadOnly() {
    return this.args.isDedicatedFlow;
  }

  get isDisabled() {
    let { isUnavailable, errors } = this.args;

    return isUnavailable || errors?.length > 0;
  }

  get selectedInstallment() {
    let { installmentOptions, selectedOption } = this.args;

    return installmentOptions?.find(option => option.numberOfInstallments === selectedOption);
  }

  get tooltipMessage() {
    if (this.args.isUnavailable) {
      return this.intl.t('transfers.warnings.pay-later.tooltip.not-available');
    }

    let { errors } = this.args;

    if (!errors) return;

    switch (errors[0]) {
      case PAY_LATER_VALIDATION_ERRORS.PREVENT_FF:
        return this.intl.t('transfers.warnings.pay-later.tooltip.not-available');
      case PAY_LATER_VALIDATION_ERRORS.AMOUNT_LESS_THAN_THRESHOLD:
        return this.intl.t('transfers.warnings.pay-later.tooltip.minimum-amount', {
          amount: this.intl.formatNumber(this.args.minTransferAmount.value, {
            style: 'currency',
            currency: this.args.minTransferAmount.currency,
            minimumFractionDigits: 0,
          }),
        });
      case PAY_LATER_VALIDATION_ERRORS.AMOUNT_MORE_THAN_AVAILABLE_CREDIT:
        return this.intl.t('transfers.warnings.pay-later.tooltip.low-credit');
      case PAY_LATER_VALIDATION_ERRORS.INVALID_ISSUE_DATE:
      case PAY_LATER_VALIDATION_ERRORS.INVALID_DUE_DATE:
        return this.invalidDatesTooltipMessage();
      case PAY_LATER_VALIDATION_ERRORS.SELF_TRANSFER:
        return this.intl.t('transfers.warnings.pay-later.tooltip.self-transfer');
      case PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_NAME:
      case PAY_LATER_VALIDATION_ERRORS.MISSING_INVOICE_AMOUNT:
      case PAY_LATER_VALIDATION_ERRORS.MISSING_ISSUE_DATE:
      case PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_IDENTIFIER:
        return this.missingDetailsTooltipMessage();
      case PAY_LATER_VALIDATION_ERRORS.NOT_MAIN_ACCOUNT:
      case PAY_LATER_VALIDATION_ERRORS.SCHEDULING_RECURRENCE_ENABLED:
        return this.notMainAccountOrScheduledTooltipMessage();
      default:
        return '';
    }
  }

  get faq() {
    if (this.args.isPayLaterContractSigned) {
      return {
        label: this.intl.t('transfers.new.sepa.summary.pay-later.toggle.faq-link-label'),
        link: this.intl.t('transfers.new.sepa.summary.pay-later.toggle.faq-link', {
          faqUrl: this.zendeskLocalization.getLocalizedArticle(8484807),
        }),
      };
    } else {
      return {
        label: this.intl.t('transfers.new.sepa.summary.pay-later.toggle.not-signed.faq-link-label'),
        link: this.intl.t('transfers.new.sepa.summary.pay-later.toggle.not-signed.faq-link', {
          faqUrl: this.zendeskLocalization.getLocalizedArticle(8271688),
        }),
      };
    }
  }

  get toggleExtraDetails() {
    if (!this.args.checked) {
      return {
        label: this.intl.t('transfers.sepa.new.summary.pay-later.toggle.available-credit'),
        amount: this.args.availableCredit,
      };
    }

    return {
      label: this.intl.t('transfers.sepa.new.summary.pay-later.toggle.remaining-available-credit'),
      amount: this.args.remainingCredit,
    };
  }

  missingDetailsTooltipMessage() {
    let { errors } = this.args;
    let missingDetails = {
      supplierName: '',
      amount: '',
      issueDate: '',
      supplierIdentifier: '',
      htmlSafe: true,
    };

    if (errors.includes(PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_NAME)) {
      missingDetails.supplierName = this.intl.t(
        'transfers.warnings.pay-later.tooltip.missing-details.supplier-name',
        {
          htmlSafe: true,
        }
      );
    }

    if (errors.includes(PAY_LATER_VALIDATION_ERRORS.MISSING_INVOICE_AMOUNT)) {
      missingDetails.amount = this.intl.t(
        'transfers.warnings.pay-later.tooltip.missing-details.amount',
        {
          htmlSafe: true,
        }
      );
    }

    if (errors.includes(PAY_LATER_VALIDATION_ERRORS.MISSING_ISSUE_DATE)) {
      missingDetails.issueDate = this.intl.t(
        'transfers.warnings.pay-later.tooltip.missing-details.issue-date',
        {
          htmlSafe: true,
        }
      );
    }

    if (errors.includes(PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_IDENTIFIER)) {
      missingDetails.supplierIdentifier = this.intl.t(
        'transfers.warnings.pay-later.tooltip.missing-details.supplier-identifier',
        {
          legalCountry: this.args.legalCountry.toLowerCase(),
          htmlSafe: true,
        }
      );
    }

    return this.intl.t(
      'transfers.warnings.pay-later.tooltip.missing-details.title',
      missingDetails
    );
  }

  invalidDatesTooltipMessage() {
    let { errors } = this.args;

    if (
      errors.length >= 2 &&
      errors[0] === PAY_LATER_VALIDATION_ERRORS.INVALID_ISSUE_DATE &&
      errors[1] === PAY_LATER_VALIDATION_ERRORS.INVALID_DUE_DATE
    ) {
      return this.intl.t('transfers.warnings.pay-later.tooltip.issue-and-due-date', {
        issueDateDaysLimit: this.args.invoiceAttachmentLimits.issueDateDaysPast,
        dueDateDaysLimit: this.args.invoiceAttachmentLimits.dueDateDaysLimit,
      });
    }

    if (errors[0] === PAY_LATER_VALIDATION_ERRORS.INVALID_ISSUE_DATE) {
      return this.intl.t('transfers.warnings.pay-later.tooltip.issue-date', {
        duration: this.args.invoiceAttachmentLimits.issueDateDaysPast,
      });
    }

    if (errors[0] === PAY_LATER_VALIDATION_ERRORS.INVALID_DUE_DATE) {
      return this.intl.t('transfers.warnings.pay-later.tooltip.due-date', {
        duration: this.args.invoiceAttachmentLimits.dueDateDaysLimit,
      });
    }
  }

  notMainAccountOrScheduledTooltipMessage() {
    let { errors } = this.args;

    if (
      errors.length >= 2 &&
      errors[0] === PAY_LATER_VALIDATION_ERRORS.NOT_MAIN_ACCOUNT &&
      errors[1] === PAY_LATER_VALIDATION_ERRORS.SCHEDULING_RECURRENCE_ENABLED
    ) {
      return this.intl.t(
        'transfers.warnings.pay-later.tooltip.not-main-account-and-scheduled-transfer',
        {
          mainAccount: this.args.mainAccount,
        }
      );
    }

    if (errors[0] === PAY_LATER_VALIDATION_ERRORS.NOT_MAIN_ACCOUNT) {
      return this.intl.t('transfers.warnings.pay-later.tooltip.not-main-account', {
        mainAccount: this.args.mainAccount,
      });
    }

    if (errors[0] === PAY_LATER_VALIDATION_ERRORS.SCHEDULING_RECURRENCE_ENABLED) {
      return this.intl.t('transfers.warnings.pay-later.tooltip.scheduled-transfer');
    }
  }
}
