export default {
  "header-cell": "qFq",
  "header-content": "qFZ caption-bold",
  "active": "qFR",
  "empty": "qFQ",
  "type": "qFe",
  "requester": "qFB",
  "request-date": "qFE",
  "amount": "qFd",
  "receipt": "qFI",
  "quick-actions": "qFt"
};
