export default {
  "invoice-sidebar": "cYR",
  "body": "cYQ",
  "box": "cYe",
  "box-header": "cYB",
  "footer": "cYE",
  "footer-button": "cYd",
  "sidebar-box": "cYI",
  "border-top": "cYt",
  "row": "cYM",
  "status-container": "cYP",
  "due-date-container": "cYl",
  "mark-as-btn": "cYX",
  "primary-actions": "cYC cYI",
  "primary-action": "cYk body-1",
  "button-icon": "cYJ"
};
