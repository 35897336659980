/* import __COLOCATED_TEMPLATE__ from './upload-file.hbs'; */
import { get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { amazonS3AssetsUrl } from 'qonto/constants/hosts';

export default class TransferMultiUploadFile extends Component {
  spinner = Spinner;

  csvTemplateURL = `${amazonS3AssetsUrl}/documents/bulk-transfer-template.csv`;

  @service intl;
  @service organizationManager;

  get fileErrors() {
    let multiTransferErrorMessage = this.args.multiTransfer.errors
      .errorsFor('document')
      .find(item => get(item, 'attribute') === 'document')?.message;

    switch (multiTransferErrorMessage) {
      case 'unsupported_file_format':
      case 'invalid_file':
        return this.intl.t('transfers.multi.upload.errors.unsupported_file_format');
      case 'parse_error':
      case 'invalid_xml':
      case 'invalid_csv':
        return this.intl.t('transfers.multi.upload.errors.parse_error');
      case 'invalid_headers':
        return this.intl.t('transfers.multi.upload.errors.invalid_headers');
      case 'empty_file':
        return this.intl.t('transfers.multi.upload.errors.empty_file');
      case 'too_many_records':
        return this.intl.t('transfers.multi.upload.errors.too_many_records');
      case 'invalid_currency':
        return this.intl.t('transfers.multi.upload.errors.invalid_currency');
      case 'invalid_transfer_count':
        return this.intl.t('transfers.multi.upload.errors.invalid_transfer_count');
      case 'invalid_transfer_sum':
        return this.intl.t('transfers.multi.upload.errors.invalid_transfer_sum');
      default:
        return '';
    }
  }

  _uploadTask = dropTask(async file => {
    let queue = file.queue;
    this.args.multiTransfer.set('file', file);

    try {
      await this.args.uploadTask();
    } finally {
      // We use a dropTask meaning only the first dropped file is uploaded.
      // The other dropped files will still end up in the queue but will never
      // settle as they are never uploaded. We manually flush the queue after
      // upload is complete to make sure the user can upload other files.
      queue.files.forEach(f => queue.remove(f));
    }
  });
}
