export default {
  "content": "cuH",
  "balance": "cuO",
  "duration": "cum",
  "green-text": "cup",
  "balance-subtitle": "cux",
  "balance-duration": "cuw",
  "footer": "cuN",
  "progress": "cuv",
  "progress-bar": "cuo",
  "progress-bar-text": "cun",
  "progress-active": "cui"
};
