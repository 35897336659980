export default {
  "header-cell": "qwc",
  "align-right": "qwq",
  "header-content": "qwZ caption-bold",
  "active": "qwR",
  "empty": "qwQ",
  "col-12": "qwe",
  "col-8": "qwB",
  "col-6": "qwE",
  "col-4": "qwd",
  "col-2": "qwI",
  "hide": "qwt"
};
