/* import __COLOCATED_TEMPLATE__ from './quick-actions.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ArchivePopup extends Component {
  @service modals;

  @action archive() {
    let { supplier } = this.args;
    this.modals.open('suppliers/archive-popup', { supplier });
  }

  @action unarchive() {
    let { supplier } = this.args;
    this.modals.open('suppliers/unarchive-popup', { supplier });
  }
}
